<div class="animate__animated animate__fadeIn">
  <div class="row">
    <div class="col-ms caja-l">
      <header class="header-caja">
        <div class="logo">
          <img src="./assets/img/BayportLogoEs.png" alt="logo-bayport"/>
        </div>
        <div class="logo-responsive">
          <img
            src="./assets/img/LogoPestana.svg"
            style="margin-left: -31px"
            alt="logo-bayport"
          />
        </div>
        <div class="inicio-responsive">
          <span class="material-icons">person</span>
          <!--<a (click)="changeRoute()">{{ _storageService.textHeader }}</a>-->
        </div>
      </header>

      <div class="texto">
        <div class="texto-azul"><h1 class="l-blanca">¡Bienvenido a tu oficina virtual! Acá</h1></div>
        <div class="texto-azul"><h1 class="l-blanca">podrás gestionar las solicitudes de</h1></div>
        <div class="texto-azul"><h1 class="l-blanca">crédito de tus usuarios</h1></div>
      </div>
    </div>

    <div class="col-ms caja-r">
      <header class="header-caja">
        <div class="inicio-sesion">
          <div class="inicio-sesion-contenido">
            <!-- <a href="" [routerLink]="['/crear-cuenta']" style="text-decoration: underline">Crear cuenta</a>
            <span class="material-icons icon-color">person</span> -->

            <!--<a id="label-header" (click)="changeRoute()" style="text-decoration: none;">{{ _storageService.textHeader }}</a>-->
          </div>
        </div>
      </header>
      <!-- <router-outlet></router-outlet> -->
    </div>
  </div>
</div>
<!-- <app-footer></app-footer> -->
