<div class="main-c">
  <div class="header-title">
    <h1 class="dialog-title" mat-dialog-title>Cargar documentos</h1>
    <div class="close-dialog" mat-dialog-close><mat-icon class="close-icon">close</mat-icon> </div>
  </div>

  <div >
    <div mat-dialog-content>
      <div class="cdial" *ngIf="!archivo?.status">
          <p class="texto">
              Puedes subir PDF, PNG, JPG o utilizar tu teléfono móvil para tomar
              una fotografía
          </p>

          <div acciones class="containereliminar">
              <div class="file-container">
                  <label for="file-ind" class="file-upload-ad">
                    <input id="file-ind" type="file" accept=".png, .jpg, .pdf" (change)="fileChange($event)" />
                    <mat-icon aria-hidden="false" aria-label="Example home icon">attach_file</mat-icon>
                    <span>Cargar desde una carpeta</span>
                  </label>
                </div>
              <div class="botones" >
                <button *ngIf="!deviceService.isDesktop();" class="btn-cam-rag-card" mat-button (click)="openCamera()">
                  <span class="material-icons">
                    camera_alt
                  </span>
                  Cargar usando la cámara</button>
              </div>
            </div>
      </div>

      <div style="padding: 20px;" *ngIf="archivo?.status">
          <app-document [nombre]="archivo?.nombre" (eliminar)="eliminar($event)" style="width: 260px !important;"></app-document>
          <div class="form-group">
              <label class="label">Nombre de archivo</label>
              <input type="text" accept=".png, .jpg, .pdf" class="form-control nombre" [(ngModel)]="nuevoNombre">
          </div>
          <p *ngIf="nuevoNombre.trim()==''" style="color: red !important" class="texto">Debes asignar un nombre al archivo antes de poder enviar</p>

          <button mat-button class="btn-cam-rag-card" (click)="enviar()">
              Enviar
          </button>
      </div>
  </div>
  </div>

</div>
