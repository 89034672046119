import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpiredPasswordComponent } from './pwa/auth/expired-password/expired-password.component';
import { ValidacionComponent } from './pwa/validacion/validacion.component';
import { AuthGuard } from './guards/auth.guard';
import { BusquedaCarpetasComponent } from './pwa/busqueda-carpetas/busqueda-carpetas.component';
import { ResultadoCarpetaComponent } from './pwa/resultado-carpeta/resultado-carpeta.component';
import { DetalleCarpetasComponent } from './pwa/detalle-carpetas/detalle-carpetas.component';
import { CargaDocumentosComponent } from './pwa/carga-documentos/carga-documentos.component';
import { FormularioComponent } from './pwa/formulario/formulario.component';
import { InformacionAdicionalComponent } from './pwa/informacion-adicional/informacion-adicional.component';
import { NotificacionesComponent } from './pwa/notificaciones/notificaciones.component';
import { AutorizacionTransferenciaComponent } from './pwa/pre-autorizacion/autorizacion-transferencia/autorizacion-transferencia.component';
import { DatosClienteComponent } from './pwa/pre-autorizacion/datos-cliente/datos-cliente.component';
import { PreOfertaComponent } from './pwa/pre-oferta/pre-oferta.component';
import { RegistroCelularComponent } from './pwa/registro-celular/registro-celular.component';
import { RegistroCorreoComponent } from './pwa/registro-correo/registro-correo.component';
import { ReporteriaComponent } from './pwa/reporteria/reporteria.component';
import { RobAnalisisComponent } from './pwa/rob-analisis/rob-analisis.component';
import { SeguroVoluntarioComponent } from './pwa/seguro-voluntario/seguro-voluntario.component';
import { ValidarCorreoComponent } from './pwa/validar-correo/validar-correo.component';
import { CapacitacionPageComponent } from './pwa/capacitacion-page/capacitacion-page.component';
import { FirmaDocComponent } from './pwa/certificados/firma-doc/firma-doc.component';
import { PazysalvoComponent } from './pwa/certificados/pazysalvo/pazysalvo.component';
import { SaldoComponent } from './pwa/certificados/saldo/saldo.component';
import { ProSolicitudComponent } from './pwa/firma/pro-solicitud/pro-solicitud.component';
import { ValidAdoTermComponent } from './pwa/firma/valid-ado-term/valid-ado-term.component';
import { HomeComponent } from './pwa/home/home.component';
import { MiPerfilPageComponent } from './pwa/mi-perfil-page/mi-perfil-page.component';
import { ListadoCelularesComponent } from './pwa/pre-autorizacion/listado-celulares/listado-celulares.component';
import { OfertaComponent } from './pwa/oferta/oferta.component';

// Version 2
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AutoCentralComponent } from './pwa/auth/auto-central/auto-central.component';
import { IniciarSesionComponent } from './pwa/auth/iniciar-sesion/iniciar-sesion.component';
import { RecuperarContrasenaComponent } from './pwa/auth/recuperar-contrasena/recuperar-contrasena.component';
import { CambioContrasenaComponent } from './pwa/auth/cambio-contrasena/cambio-contrasena.component';
import { DebesSaberComponent } from './pwa/debes-saber/debes-saber.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'iniciar-sesion' },
      { path: 'iniciar-sesion', component: IniciarSesionComponent },
      { path: 'recuperar-contrasena', component: RecuperarContrasenaComponent },
      { path: 'auto-central', component: AutoCentralComponent },
      { path: 'expired-password', component: ExpiredPasswordComponent },
      { path: 'valid-ado-term', component: ValidAdoTermComponent },// eliminar el componente y sus derivados cuando manden la respuesta de ado al otro portal

    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'formulario-perfilamiento', component: FormularioComponent },
      { path: 'redireccionamiento/:token', component: CapacitacionPageComponent },
      { path: 'cambio-contrasena', component: CambioContrasenaComponent },
      { path: 'busqueda-carpetas', component: BusquedaCarpetasComponent },
      { path: 'resultado-carpetas', component: ResultadoCarpetaComponent },
      { path: 'detalle-carpetas', component: DetalleCarpetasComponent },
      { path: 'segurovoluntario', component: SeguroVoluntarioComponent },
      { path: 'rob-analisis', component: RobAnalisisComponent },
      { path: 'pre-oferta', component: PreOfertaComponent },
      { path: 'informacionadicional', component: InformacionAdicionalComponent },
      { path: 'notificaciones', component: NotificacionesComponent },
      { path: 'home', component: HomeComponent },
      { path: 'cargadocumentos', component: CargaDocumentosComponent },
      { path: 'reporteria', component: ReporteriaComponent },
      { path: 'pro-solicitud', component: ProSolicitudComponent },
      { path: 'saldo', component: SaldoComponent },
      { path: 'pazysalvo', component: PazysalvoComponent },
      { path: 'firma-doc', component: FirmaDocComponent },
      { path: 'capacitacion', component: CapacitacionPageComponent },
      { path: 'mi-perfil', component: MiPerfilPageComponent },
      { path: 'oferta', component: OfertaComponent },
      { path: 'registrocelular', component: RegistroCelularComponent },
      { path: 'modificarcelular', component: RegistroCelularComponent },
      { path: 'validarcorreo', component: ValidarCorreoComponent },
      { path: 'registrocorreo', component: RegistroCorreoComponent },
      { path: 'listadocelulares', component: ListadoCelularesComponent },
      { path: 'validaotp', component: ValidacionComponent },
      { path: 'autorizacionTransferencia', component: AutorizacionTransferenciaComponent },
      { path: 'datoscliente', component: DatosClienteComponent },
      { path: 'debes-saber', component: DebesSaberComponent },
      { path: 'debes-saber/:path', component: DebesSaberComponent },
      {
        path: 'mis-tramites',
        loadChildren: () =>
          import('./modules/mis-tramites/mis-tramites.module').then(
            (m) => m.MisTramitesModule
          ),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
