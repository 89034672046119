import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-file-upload-bancaria',
  standalone: true,
  templateUrl: './card-file-upload-bancaria.component.html',
  styleUrls: ['./card-file-upload-bancaria.component.css'],
  imports: [CommonModule],
})
export class CardFileUploadBancariaComponent {
  isError = false;
  nombreArchivo = '';
  archivoCargado = false;

  @Input() iconoCentral = '';
  @Input() titulo = '';
  @Input() texto = '';

  @Output() fileChange = new EventEmitter<any>();
  @Output() clearFile = new EventEmitter<any>();

  onFileChange(event: any) {
    const sizeInMB = event.target.files[0].size / (1024 * 1024);
    if (sizeInMB >= 5) {
      this.isError = true;
      return;
    }
    this.isError = false;
    this.nombreArchivo = event.target.files[0].name;

    this.archivoCargado = true;
    this.fileChange.emit(event);
  }

  onClearFile() {
    this.nombreArchivo = '';
    this.clearFile.emit();
  }
}
