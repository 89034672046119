// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.css'],
    host: { class: "app-menubar" }
})
export class MenubarComponent {

    /**
     * Variable que define el estado del sidebar
    */
    @Input() isOpen: boolean = true

    /**
     * Cambiar el valor del sidebar abierto o cerrado
     */
    @Output() toggleOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public serviceAuth: AuthService,
    ) { }

    toggleDetails(detail: HTMLDetailsElement) {
        detail.open = false
    }

    /**
     * Metodo para cambiar el estado del sidebar
     * 
     * @param value Valor opcional para cambiar el estado del sidebar
     */
    protected toggleSidebar(value: boolean | null = null): void {
        if (value === null) {
            this.toggleOpen.emit(this.isOpen);
        } else {
            this.toggleOpen.emit(value);
        }
    }
}
