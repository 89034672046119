// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Prime
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

// Pages
import { AutoCentralComponent } from '../../pwa/auth/auto-central/auto-central.component';
import { IniciarSesionComponent } from '../../pwa/auth/iniciar-sesion/iniciar-sesion.component';
import { RecuperarContrasenaComponent } from '../../pwa/auth/recuperar-contrasena/recuperar-contrasena.component';
import { CambioContrasenaComponent } from '../../pwa/auth/cambio-contrasena/cambio-contrasena.component';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { AuthLayoutComponent } from './auth-layout.component';
import { DebesSaberComponent } from '../../pwa/debes-saber/debes-saber.component';
import { MenuComponent } from '../main-layout/menu/menu/menu.component';
import { FooterComponent } from '../main-layout/menu/footer/footer.component';
import { HeaderComponent } from '../main-layout/menu/header/header.component';
import { LayoutComponent } from '../main-layout/menu/layout/layout.component';
import { SharedModule } from '@modules/shared/shared.module';
import { ConfirmadaComponent } from '../../components/confirmada/confirmada.component';

@NgModule({
    declarations: [
        RecuperarContrasenaComponent,
        IniciarSesionComponent,
        CambioContrasenaComponent,
        AutoCentralComponent,
        MainLayoutComponent,
        AuthLayoutComponent,
        DebesSaberComponent,
        MenuComponent,
        FooterComponent,
        HeaderComponent,
        LayoutComponent,
        ConfirmadaComponent
    ],
    imports: [
    // Angular
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    // Prime
    InputTextModule,
    PasswordModule,
]
})
export class AppV2Module { }
