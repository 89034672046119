import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-eliminar',
  templateUrl: './eliminar.component.html',
  styleUrls: ['./eliminar.component.css']
})
export class EliminarComponent implements OnInit {

  @Input() texto:string;
  constructor() { }

  ngOnInit(): void {
  }

}
