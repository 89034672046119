import { Component } from '@angular/core';
import { environmentVersion } from 'src/environments/version';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  versionEnv = environmentVersion.buildDate
  version: any;

  ngOnInit(): void {

    let date: Date = new Date(this.versionEnv);
    const datepipe: DatePipe = new DatePipe('en-US')
    let desdeStr = datepipe.transform(date, 'ddMMYYYYHHmm')
    this.version = desdeStr

  }
}
