<div class="modal download-spinner" *ngIf="loading.show">
    <div class="contente-loading">
        <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="texts">
            <h3>Estamos procesando tu solicitud</h3>
            <h4>Por favor, espera un momento.</h4>
            <h4>No actualices ni cierres esta ventana.</h4>
        </div>
    </div>
</div>