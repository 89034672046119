<div style="text-align: center;">
  <!-- <div>
    <webcam
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch"
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"
    ></webcam>
    <br />
  </div> -->

  <video id="player" autoplay style="height: 300px; display: block;"></video>

  <button
    mat-dialog-close
    style="
      width: 50px;
      height: 62px;
      background-color: var(--p-blue);
      color: white;
      border-radius: 50%;
    "
    (click)="tomarFoto()"
    mat-button
  >
    <mat-icon>camera_alt</mat-icon>
  </button>
</div>

<!-- <h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
  <li>{{ error | json }}</li>
</ul> -->
