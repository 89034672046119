<div class="modal download-spinner">
  <div>
    <mat-icon style="margin-right: 5px"
          ><mat-spinner diameter="100"> </mat-spinner
        ></mat-icon>
  </div>
  <div>
    <h3 style="color: white !important;">El servicio se encuentra en proceso, <br>
    espere un momento por favor...</h3>
  </div>
</div>
