<div class="header-mobile">
  <div class="logo">
    <img src="../../../assets/img/LogoPestana.svg" alt="logo" />
  </div>
  <div class="nuevo-credito">
    <span class="material-icons top-money">monetization_on</span>
    <span style="line-height: 15px" (click)="rout()"
      >Nuevo <br />
      crédito</span
    >
  </div>
  <!-- <div *ngIf="!_usuarioService.nuevoCreditoState" class="nuevo-credito opt-no-border"></div> -->

    <ng-content select="[menuResponsive]"></ng-content>
    <!-- <span class="material-icons top-menu">menu</span>
    <span>Menú</span> -->

  <!-- <div *ngIf="_uiService.showMenuTablet" class="menu" (click)="showMenuMobile()">
    <span class="material-icons top-menu">cancel</span>
    <span>Cerrar</span>
  </div> -->
</div>
