import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() titulo: string = "El servicio se encuentra en proceso";
  @Input() mensaje: string = "espere un momento por favor...";
}
