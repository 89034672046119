import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LaboralInfo } from '../../models/laboralInfo.interface';
import { Observable } from 'rxjs';
import { formatDates } from '../utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerfInfLabService {

  private endpoint = environment.ApiConecta+'perfilamiento/informacion/laboral'

  constructor(
    private http:HttpClient
  ) { }

  savInfLab(laboralInfo:LaboralInfo):Observable<any>{
    return this.http.post<LaboralInfo>(this.endpoint, laboralInfo);
  }
}
