<br>

<body>
  <br><br><br><br><br>
  <div class="circle yellow"></div>
  <div class="circle blue"></div>
  <!-- <br><br><br> -->
  <div *ngIf="showNews.url === ''" id="panel">
    <div class="desk font-omnes-semibold" style="padding-left: 15px; padding-right: 15px;">
      <h1 class="title-user ">¡Hola, {{ user }}!</h1>
      <div class="embed-wrapper">
        <iframe *ngIf="dashboard" class="embed" [src]="dashboard | safeUrl" frameborder="0"></iframe>
      </div>
    </div>
    <div class="row mobile font-omnes-semibold">
      <h1 class="title-user ">¡Hola {{ user }}!</h1>
      <div class="embed-wrapper">
        <iframe *ngIf="dashboard" class="embed" [src]="dashboard | safeUrl" frameborder="0"></iframe>
      </div>
    </div>
    <div class="row d-lg-flex w-100 cards-wrapper">
      <div class="col-md-6 card-wrap ">
        <div class="card-definition shadow" id="cred">
          <div class="card-head">
            <h3 class="card-title font-omnes-semibold">Nuevo crédito</h3>
          </div>
          <div class="card-body_">
            <p class="card-description font-omnes-medium">
              Empieza el proceso de solicitud de crédito nuevo, refinanciación o
              compra de cartera
            </p>
            <div [routerLink]="['/autorizacionTransferencia']"
              class="card-btn btn-dark d-flex justify-content-center align-items-center font-omnes-semibold">
              <span>Solicitar</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 card-wrap ">
        <div class="card-definition shadow" id="carp">
          <div class="card-head">
            <h3 class="card-title font-omnes-semibold">Mis créditos</h3>
          </div>
          <div class="card-body_">
            <p class="card-description font-omnes-medium">
              Busca las carpetas de tus clientes, por cédula, pagaduría, fecha de
              creación, nombre y apellido o número de carpeta
            </p>
            <div [routerLink]="['/busqueda-carpetas']"
              class="card-btn btn-yellow d-flex justify-content-center align-items-center font-omnes-semibold">
              <span>Buscar carpetas</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div> &nbsp; &nbsp;</div>
  <div [routerLink]="['/home/debes-saber/politicas']">
    <iframe style="height: 633px;border-radius: 4px;" class="embed-wrapper_"
      src="https://debessaber.col.prod.bayport-digital.com/cambios-de-politicas"></iframe>
  </div>
  <div *ngIf="showNews.url !== ''">
    <div>
      <iframe class="embed-wrapper_" [src]="showNews.url | safeUrl" [title]="showNews.name"></iframe>
    </div>
  </div>
</body>
