// Angular
import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  /**
   * Variable que define el estado del sidebar
   */
  public openSisebar: boolean = false;

  constructor(protected serviceAuth: AuthService) { }

  user = "";

  toggleDetails(detail: HTMLDetailsElement) {
    detail.open = false;
  }

  /**
   * Metodo para cambiar el estado del sidebar
   *
   * @param value Valor opcional para cambiar el estado del sidebar
   */
  protected toggleSidebar(value: boolean | null = null): void {
    if (value === null) {
      this.openSisebar = !this.openSisebar;
    } else {
      this.openSisebar = value;
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nombreUsuario();
    }, 0);
  }

  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    localStorage.setItem('tpcnl', decoded['tpcnl'].toLowerCase());

    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`
    } else {
      this.user = `${this.user[0]}`
    }
  }
}
