import { Component, OnInit } from '@angular/core';
//import { UploadService } from 'src/app/services/cargaArchivos/upload.service';

@Component({
  selector: 'app-validacion-terminada',
  templateUrl: './validacion-terminada.component.html',
  styleUrls: ['./validacion-terminada.component.css']
})
export class ValidacionTerminadaComponent implements OnInit {
  selectedFiles:any;
  //constructor(private uploadaws:UploadService) { }

  ngOnInit(): void {
  }
 /*  upload() {
    const file = this.selectedFiles.item(0);
    this.uploadaws.uploadFile(file,'origination/application/12345/identification/','identification.png');
    }
    
    selectFile(event:any) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    
    } */
}
