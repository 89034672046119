import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import * as moment from 'moment';

import { TiposIdentificacionService } from 'src/app/services/catalogos/tipos-identificacion.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service';
import { ParentescoService } from 'src/app/services/catalogos/parentesco.service';
import { AseguradoAdicionalService } from 'src/app/services/genOferta/asegurado-adicional.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DataService } from '../../services/data.service';
import { DataOfertaService } from '../../services/genOferta/dataOferta.service';

import { Asegurado } from 'src/app/models/asegurado';
import { Condicion } from 'src/app/models/condicion';

@Component({
  selector: 'app-seguro-voluntario',
  templateUrl: './seguro-voluntario.component.html',
  styleUrls: ['./seguro-voluntario.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class SeguroVoluntarioComponent implements OnInit {

  seccion = 'seleccion';
  tipoSeguro = '';
  tiposIdentificacion: Array<any> = [];
  isLinear = false;
  loading: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  planesIndividuales: any = [];
  planesFamiliares: any = [];
  planseleccionado = '';
  planActualizado: any;
  planOriginal: any;
  parentescos: Array<any> = [];
  numeroCarpeta = '';
  tasa: any;
  condicion: Condicion;
  hoy: Date = new Date();
  credito: string = '';
  ofertas: any;
  montoMaximo: any;
  montoMinimo: any;
  disabledButton: boolean = false;
  disabledButtonBeneficiaries: boolean = false;
  isLoading: boolean = false;

  private codigoSolicitud: string = '';
  public asegurados: Array<Asegurado> = [];
  public formularioAsegurados: FormGroup;
  public dynamicForms: FormGroup[] = [];

  back: string = '';
  constructor(
    private tiposiservice: TiposIdentificacionService,
    public data: DataService,
    private dialogServ: DialogService,
    private paretescSrv: ParentescoService,
    private aseguradoadServ: AseguradoAdicionalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private getofrtaServ: GenOfertaService,
    public dataOferta: DataOfertaService
  ) {
    this.hoy = new Date(this.hoy.getFullYear() - 1, 11, 31);
    this.back = this.router.getCurrentNavigation().extras.state.back;
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }

    this.getIdentificaciones();
    this.getParenesco();

    this.planOriginal = this.dataOferta.seguroVoluntario;
    this.planseleccionado = this.planOriginal.codigoPlan;
    this.tipoSeguro = this.planOriginal.indSeguroIndividual == 1 ? 'individual' : 'familiar';
    this.condicion = this.data.condicion;
    this.montoMaximo = this.data.montoMaximo;
    this.montoMinimo = this.data.montoMinimo;
    this.ofertas = this.data.ofertas;

    this.getOfertas();

    this.dynamicForms.push(
      this.formBuilder.group({
        fechaNacimiento: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        parentesco: [, Validators.required],
        primerApellido: ['', Validators.required],
        primerNombre: ['', Validators.required],
        segundoApellido: [''],
        segundoNombre: [''],
        tipoDocumento: ['', Validators.required],
      })
    );
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  public agregarAsegurado(): void {
    this.dynamicForms.push(
      this.formBuilder.group({
        fechaNacimiento: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        parentesco: ['', Validators.required],
        primerApellido: ['', Validators.required],
        primerNombre: ['', Validators.required],
        segundoApellido: [''],
        segundoNombre: [''],
        tipoDocumento: ['', Validators.required],
      })
    );
  }

  /*
    Función para obtener los tipos de identificación
  */
  private getIdentificaciones(): void {
    this.tiposiservice.getTipoIdentificacion().subscribe((res) => {
      this.tiposIdentificacion = res.rows;
      this.tiposIdentificacion = this.tiposIdentificacion.filter((resp) => resp.codigoTipoIdentificacion !== 'N.I.T');
    });
  }

  /*
    Función para generar las ofertas
  */
  private getOfertas(): void {
    this.loading = true;

    this.planesIndividuales = [];
    this.planesFamiliares = [];

    this.dataOferta.oferta.ofertasSeguro.forEach((oferta: any) => {
      if (oferta.seguroFamiliar.length == 0) {
        this.planesIndividuales.push(oferta);
      } else {
        this.planesFamiliares.push(oferta);
      }

      this.loading = false;
    });

    this.planesFamiliares.reverse();
    this.planesIndividuales.reverse();
  }

  /*Se guarda el tipo y plan seleccionado*/
  public seleccionaPlan(dato: any, tipo: string): void {
    this.planActualizado = dato;
    this.tipoSeguro = tipo;
    this.planseleccionado = dato.codigoPlan;
  }

  /*Determina el formulario a mostrar en dependiendo el plan*/
  public continuar(): void {
    this.isLoading = true;

    if (this.tipoSeguro !== 'individual') {
      this.getofrtaServ.checkPoliza(this.data.numeroCarpeta, parseInt(this.planseleccionado)).subscribe(() => {
        this.dataOferta.seguroVoluntario = this.planActualizado;
        this.seccion = 'planfamiliar';
        this.isLoading = false;
      }), (err: any) => {
        this.dialogServ.openDialog('Advertencia', err.error.message, false);
        this.isLoading = false;
      };
    } else {
      if (parseInt(this.planseleccionado) === parseInt(this.planOriginal.codigoPlan)) {
        this.getofrtaServ.checkPoliza(this.data.numeroCarpeta, parseInt(this.planseleccionado)).subscribe(() => {
          this.dataOferta.ajustarCondiciones = true;
          this.isLoading = false;

          this.regresar();
          return;
        }), (err: any) => {
          this.dialogServ.openDialog('Advertencia', err.error.message, false);
          this.isLoading = false;
        };
      } else {
        this.getofrtaServ.checkPoliza(this.data.numeroCarpeta, parseInt(this.planseleccionado)).subscribe(() => {
          this.dataOferta.seguroVoluntario = this.planActualizado;
          this.dataOferta.ajustarCondiciones = true;
          this.isLoading = false;

          this.regresar();
        }), (err: any) => {
          this.dialogServ.openDialog('Advertencia', err.error.message, false);
          this.isLoading = false;
        };
      }
    }
  }

  /*Obtiene los tipos de parentesco*/
  private getParenesco(): void {
    this.paretescSrv.getParentesco().subscribe((res) => {
      this.parentescos = res.rows;
      this.parentescos = this.parentescos.filter((result) => result.codigo !== '1');
    });
  }

  public guardarSeleccion(): void {
    this.disabledButtonBeneficiaries = true;

    let invalid: boolean = false;
    let asegurados: Asegurado[] = [];

    this.dynamicForms.forEach((form) => {
      if (form.invalid) invalid = true;
      asegurados.push(form.value);
    });

    if (!invalid) {
      this.aseguradoadServ.guardarAsegurdos(asegurados, this.data.numeroCarpeta).subscribe((res) => {
        this.dataOferta.seguroVoluntario = this.planActualizado;
        this.dataOferta.ajustarCondiciones = true;
        this.disabledButtonBeneficiaries = false;

        this.regresar();
      }, (error) => {
        this.dialogServ.openDialog('Advertencia', error.error.message, false);
        this.disabledButtonBeneficiaries = false;
      });
    } else {
      this.dialogServ.openDialog(
        'Información',
        'Debe completar los campos obligatorios',
        false
      );

      this.disabledButtonBeneficiaries = false;
    }
  }

  public onValidateDate(): void {
    for (const key in this.dynamicForms) {
      const { fechaNacimiento, parentesco } = this.dynamicForms[key].value;
      this.disabledButtonBeneficiaries = false;

      const currentDate = moment().startOf('day'); // Fecha actual a las 00:00:00
      const selectedDate = moment(fechaNacimiento).startOf('day'); // Fecha seleccionada a las 00:00:00

      if (!selectedDate.isValid()) {
        this.dialogServ.openDialog(
          'Error',
          'La fecha de nacimiento ingresada no es válida',
          false
        );
        this.disabledButtonBeneficiaries = true;
        return;
      }

      const age = currentDate.diff(selectedDate, 'years'); // Cálculo de la edad

      // Función para verificar si la edad está dentro del rango permitido
      const isAgeValid = (min: number, max: number) => age >= min && age < max;

      // Mostrar mensaje de advertencia y deshabilitar botón
      const showInvalidAgeDialog = () => {
        this.dialogServ.openDialog(
          'Advertencia',
          'No cumple con las políticas seguro voluntario',
          false
        );
        this.disabledButtonBeneficiaries = true;
      };
      /*PACOLP-855*/
      const ageRules = {
        3: { min: 18, max: 80 },
        4: { min: 1, max: 80 },
      };

      const parentescoNumber = Number(parentesco);
      const rule = ageRules[parentescoNumber];

      if (rule && !isAgeValid(rule.min, rule.max)) {
        showInvalidAgeDialog();
        return;
      } else {
        this.disabledButtonBeneficiaries = false; // Habilitar botón si todo es válido
      }
    }
  }

  public regresar(): void {
    this.router.navigateByUrl(this.back, {
      state: {
        numeroSolicitud: this.codigoSolicitud,
        seguroVoluntario: this.planOriginal,
        tasa: this.tasa,
        numeroCarpeta: this.data.numeroCarpeta,
        ofertas: this.ofertas,
        montoMaximo: this.montoMaximo,
        montoMinimo: this.montoMinimo,
        credito: this.credito,
      },
    });
  }

  public eliminarFamiliar(index: number): void {
    this.dynamicForms.splice(index, 1);
  }

}
