<div class="send-container" [ngClass]="{'scroll':listaPDF != '' }">

  <div class="busc-container">
    <div class="fondo-busc">
      <input class="busqueda" placeholder="Escribe tus observaciones..." [(ngModel)]="observaciones" type="text" required>
    </div>
    <mat-icon (click)="enviar()">send</mat-icon>
  </div>
  <div class="pdfs" *ngIf="listaPDF != '' ">
    <div *ngFor="let pdf of listaPDF; let i = index">
      <img src="../../../../../assets/img/pdf-icon.png" alt="pdf" style="height: 50px;"/>
      <div style="display: flex; flex-direction: column;">
        <p>{{pdf[0].name}}</p>
        <small (click)="deletePDF(i)">( Eliminar )</small>
      </div>
    </div>
  </div>
</div>