import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encriptar(data:Object):string{
    const enc = CryptoJS.AES.encrypt(JSON.stringify(data), 'bayportc').toString();
    return enc;
    
  }

  desencriptar(data:string):string{
    console.log(data);
    
    let dec = CryptoJS.AES.decrypt(data,'bayportc');
    var decryptedData = JSON.parse(dec.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
}
