import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seguroVoluntario'
})
export class SeguroVoluntarioPipe implements PipeTransform {

  transform(seguro:string): string {
    if(seguro === null) return 'No seleccionado';
    return seguro;
  }

}
