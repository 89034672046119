<!--Script para asociar un cliente con Qualtrics-->
<script>
  if (typeof QSI === "unlimited") {
    QSI = {};
    QSI.config = {
      externalReference: extRef
    };
  }
</script>
<script type="text/javascript">
  (function () {
    var g = function (e, h, f, g) {
      this.get = function (a) {
        for (
          var a = a + "=",
          c = document.cookie.split(";"),
          b = 0,
          e = c.length;
          b < e;
          b++
        ) {
          for (var d = c[b]; " " == d.charAt(0);)
            d = d.substring(1, d.length);
          if (0 == d.indexOf(a)) return d.substring(a.length, d.length);
        }
        return null;
      };
      this.set = function (a, c) {
        var b = "",
          b = new Date();
        b.setTime(b.getTime() + 6048e5);
        b = "; expires=" + b.toGMTString();
        document.cookie = a + "=" + c + b + "; path=/; ";
      };
      this.check = function () {
        var a = this.get(f);
        if (a) a = a.split(":");
        else if (100 != e)
          "v" == h && (e = Math.random() >= e / 100 ? 0 : 100),
            (a = [h, e, 0]),
            this.set(f, a.join(":"));
        else return !0;
        var c = a[1];
        if (100 == c) return !0;
        switch (a[0]) {
          case "v":
            return !1;
          case "r":
            return (
              (c = a[2] % Math.floor(100 / c)),
              a[2]++,
              this.set(f, a.join(":")),
              !c
            );
        }
        return !0;
      };
      this.go = function () {
        if (this.check()) {
          var a = document.createElement("script");
          a.type = "text/javascript";
          a.src = g;
          document.body && document.body.appendChild(a);
        }
      };
      this.start = function () {
        var t = this;
        "complete" !== document.readyState
          ? window.addEventListener
            ? window.addEventListener(
              "load",
              function () {
                t.go();
              },
              !1
            )
            : window.attachEvent &&
            window.attachEvent("onload", function () {
              t.go();
            })
          : t.go();
      };
    };
    try {
      new g(
        100,
        "r",
        "QSI_S_ZN_esRLbr5J5Upbmse",
        "https://znesrlbr5j5upbmse-bayport.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_esRLbr5J5Upbmse"
      ).start();
    } catch (i) { }
  })();
</script>
<div id="ZN_esRLbr5J5Upbmse"><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div>
<app-main-container header="" subheader="">
  <div class="contenido">
    <h1>Estamos procesando la solicitud del crédito de <span class="text-uppercase"> {{nomClient}} </span></h1>

    <p>
      Este proceso puede tardar algunas horas, te enviaremos un mensaje de texto y
      un correo electrónico cuando haya finalizado el análisis.
    </p>

    <p>
      Mientras esperas, te recomendamos leer las últimas noticias que Bayport
      tiene para ti, o sí deseas, puedes cerrar esta ventana.
    </p>

    <div class="row" style="margin-top: 40px !important">
      <mat-card class="example-card" style="margin-right: 75px">
        <img mat-card-image src="../../../../assets/img/playa.jpg" alt="Photo of a Shiba Inu" />
        <mat-card-content>
          <div class="ref">
            <a [routerLink]="['/documentos']">Bayport entre las mejores empresas del país</a>
          </div>
          <p>
            Si dentro de tu proyecto de vida está hacer realidad una idea de
            emprendimiento te contamos.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="example-card" style="margin-right: 75px">
        <img mat-card-image src="../../../../assets/img/medellin.jpg" alt="Photo of a Shiba Inu" />
        <mat-card-content>
          <div class="ref">
            <a [routerLink]="['/documentos']">#1 en créditos por libranza</a>
          </div>
          <p>
            Si dentro de tu proyecto de vida está hacer realidad una idea de
            emprendimiento te contamos como puedes empezar a trabajar en ello para
            hacerlo realidad.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="example-card">
        <img mat-card-image src="../../../../assets/img/unnamed.jpg" alt="Photo of a Shiba Inu" />
        <mat-card-content>
          <div class="ref">
            <a [routerLink]="['/documentos']" routerLinkActive="router-link-active">¿Cómo invertir en finca raíz?</a>
          </div>

          <p>
            Si dentro de tu proyecto de vida está hacer realidad una idea de
            emprendimiento.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</app-main-container>
