<app-login-header-mb>
  <div menuResponsive class="sesion" routerLink="/iniciar-sesion">
    <span style="color: var(--p-blue);" class="material-icons top-money">person</span>
    <span style="width: 90%; color: var(--p-blue); line-height: 15px; font-size: 18px; text-align: center;"
      >Iniciar
      sesión</span
    >
  </div>
</app-login-header-mb>
<app-header-login-tb>
  <div menuResponsive class="sesion-tb" routerLink="/crear-cuenta">
    <span class="material-icons s-30">person</span>
    <span class="nuevo-credito-desc">Crear<br />cuenta</span>
  </div>
</app-header-login-tb>
<div class="content">
  <div class="cont-form">
    <h1>Crear Cuenta</h1>
    <form [formGroup]="newAccount">
      <h4>Usuario</h4>
      <br class="separador"/>
      <mat-form-field class="example-full-width" appearance="outline">
        <input
          matInput
          name="user"
          formControlName="user"
          maxlength="15"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="newAccount.controls.user.hasError('required')">
          El usuario es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="newAccount.controls.user.hasError('pattern')">
          El usuario solo acepta <strong>números</strong>
        </mat-error>
        <mat-error *ngIf="newAccount.controls.user.hasError('minlength')">
          Minimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="newAccount.controls.user.hasError('maxlength')">
          Maximo 15 caracteres
        </mat-error>
      </mat-form-field>

      <br />
      <h4>Contraseña</h4>
      <br class="separador"/>
      <mat-form-field class="example-full-width" appearance="outline">
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          maxlength="33"
          [errorStateMatcher]="matcher"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-error *ngIf="newAccount.controls.password.hasError('required')">
          El password es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="newAccount.controls.password.hasError('pattern')">
          El password no coincide con el <strong>patrón</strong>
        </mat-error>
        <mat-error *ngIf="newAccount.controls.password.hasError('minlength')">
          Minimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="newAccount.controls.password.hasError('maxlength')">
          Maximo 33 caracteres
        </mat-error>
      </mat-form-field>

      <p>{{ errorLog }}</p>
    </form>


    <div class="validaciones" >

      <div class="indicador-box">
        <div class="indicador" [ngClass]="newAccount.controls['password'].hasError('required') || newAccount.controls['password'].hasError('minlength')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 8 caractéres</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="newAccount.controls['password'].hasError('required') || newAccount.controls['password'].hasError('hasCapitalCase')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 mayúscula</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="newAccount.controls['password'].hasError('required') || newAccount.controls['password'].hasError('hasSmallCase')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 minúscula</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="newAccount.controls['password'].hasError('required') || newAccount.controls['password'].hasError('hasSpecialCharacters')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 caractér especial (+*%$_)</span>
      </div>

      <div *ngIf="newAccount.invalid && showError" class="create-account-error animate__animated animate__fadeIn">
        <p class="error">La contraseña ingresada no cumple con los requisitos necesarios. Revisa nuevamente</p>
      </div>

    </div>

    <!--<a class="olvide-pass" >¿Olvidaste tu contraseña?</a>-->
    <p id="mensaje">Al dar clic en crear cuenta estás aceptando nuestros
      <a class="olvide-pass" [routerLink]="['/crear-cuenta']">Términos y Condiciones,</a>
      nuestra
      <a class="olvide-pass" [routerLink]="['/crear-cuenta']">Política de privacidad de Bayport.</a>
    </p>
    <button type="button" id="creacuenta" class="btn btn-primary" (click)="onLogin()">
      Crear cuenta
    </button>
    <!--<button class="g-recaptcha"
        data-sitekey="6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP"
        data-callback='onSubmit'
        data-action='submit'>Submit</button>-->
  </div>
</div>
