<div class="animate__animated animate__fadeIn">
<div class="row">
    <div class="col-ms caja-l">
      <header class="header-caja">
        <div class="logo">
          <img src="assets/img/BayportLogo.svg" alt="logo-bayport" />
        </div>
        <div class="logo-responsive">
          <img
            src="assets/img/LogoPestana.svg"
            alt="logo-bayport"
          />
        </div>
      </header>

      <div id="txtcontainer" class="texto">
        <div class="texto-azul"><label>No importa si estas reportado.</label></div>
        <div class="texto-azul"><label>Desembolso en menos de 24</label></div>
        <div class="texto-azul"><label>horas y solo necesitas tu cédula</label></div>
        <div class="texto-azul"><label>para empezar el tramite</label></div>
      </div>
    </div>
  </div>
</div>
