<app-login-header-mb>
    <div menuResponsive class="sesion" routerLink="/iniciar-sesion">
      <span style="color: var(--p-blue);" class="material-icons top-money">person</span>
      <span style="width: 90%; color: var(--p-blue); line-height: 15px; font-size: 18px; text-align: center;"
        >Iniciar
        sesión</span
      >
    </div>
  </app-login-header-mb>
  <app-header-login-tb>
    <div menuResponsive class="sesion-tb" routerLink="/crear-cuenta">
      <span class="material-icons s-30">person</span>
      <span class="nuevo-credito-desc">Iniciar<br />Sesión</span>
    </div>
  </app-header-login-tb>
  <div class="content">
    <div class="cont-form">
      <form [formGroup]="recuperacionForm">
        <h1>Recuperar contraseña</h1>
        <div class="d-flex flex-column  ">
            <label>Contraseña actual</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input
                matInput
                class="w-100"
                name="actual"
                formControlName="actual"
                maxlength="15"
              />
              <mat-error *ngIf="recuperacionForm.controls.actual.hasError('required')">
                La contraseña actual es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex flex-column">
          <label>Nueva contraseña</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input
              matInput
              class="w-100"
              name="nueva"
              formControlName="nueva"
              maxlength="15"
            />
            <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('required')">
              La contraseña es <strong>requerido</strong>
            </mat-error>
            <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('minlength')">
              Minimo 8 caracteres
            </mat-error>
            <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('hasNumber')">
                Minimo un numero
            </mat-error>
            <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('hasCapitalCase')">
                Minimo una mayúscula
            </mat-error>
            <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('hasSmallCase')">
                Minimo una minúscula
            </mat-error>
          </mat-form-field>
        </div>
        <div class="d-flex flex-column  ">
            <label>Confirmar contraseña</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input
                matInput
                class="w-100"
                name="confirmar"
                formControlName="confirmar"
                maxlength="15"

              />
              <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('required')">
                La contraseña es <strong>requerido</strong>
              </mat-error>
              <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('minlength')">
                Minimo 8 caracteres
              </mat-error>
              <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('hasNumber')">
                  Minimo un numero
              </mat-error>
              <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('hasCapitalCase')">
                  Minimo una mayúscula
              </mat-error>
              <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('hasSmallCase')">
                  Minimo una minúscula
              </mat-error>
            </mat-form-field>
          </div>
  
        <button type="submit" id="recuperar" class="btn btn-primary w-100" (click)="changePassword()">Cambiar contraseña</button>
      </form>
    </div>
  </div>
  
