import { HostListener } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificaService } from 'src/app/services/notificaciones/notifica.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {

  @ViewChild('listdrawer2') listdrawer2: MatSidenav;
  screenWidth2: number = window.innerWidth;
  public allNotificaciones: any[] = [];
  public notificaciones: any[] = [];
  public itemColor = 0;
  public notificacion: any = [];
  pdfs: any = [];
  public buscar: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private serviceNotifica: NotificaService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    this.Notificaciones();
  }

  openNotificacion(numero_carpeta, numeroItem, desc) {
    if (this.screenWidth2 < 641) {
      this.listdrawer2.close();
    }

    this.itemColor = numeroItem;

    let getNotificacion = this.notificaciones.find(noti => noti.numeroCarpeta === numero_carpeta && noti.descripcion === desc);
    this.notificacion = getNotificacion;
  }

  recargando(event) {
    if (event) {
      this.Notificaciones();
      console.log("Se actualizaron las notificaciones: ", event);
    }
  }

  Notificaciones() {
    this.serviceNotifica.getNotificaciones().subscribe(
      response => {
        if (response.count > 0) {
          this.allNotificaciones = response.rows;
          this.notificaciones = this.allNotificaciones.sort((a, b) => <any>new Date(b.fechaInserta).getTime() - <any>new Date(a.fechaInserta).getTime());
          this.notificacion = this.notificaciones[0];
          console.log(this.allNotificaciones);
        } else {
          this.notificacion = [];
          this.notificaciones = [];
        }
        localStorage.setItem('notificacion', JSON.stringify(response.count));
      },
      err => {
        console.log(<any>err);
      }
    );
  }

  detectChange() {
    if (this.buscar == '') {
      this.notificaciones = this.allNotificaciones;
    }
  }

  buscarNotificacion() {

    if (this.buscar == '') {
      this.dialogService.openDialog('Opps', 'Debes digitar cédula o número de carpeta',false)
      return
    }


    this.buscar = this.buscar.toUpperCase();
    let busqueda = this.buscar;
    let expresion = new RegExp(`${busqueda}.*`, "i");

    let findNotificacion = this.allNotificaciones.filter(noti => noti.numeroCarpeta == this.buscar || expresion.test(noti.nombreCliente) || noti.identificacion == this.buscar);

    if (findNotificacion.length == 0) {
      this.dialogService.openDialog('Opps', 'No se encontró ninguna notificación, vuelva a buscar nuevamente', false)
      return
    }

    this.notificaciones = findNotificacion;

  }

  regresar(event) {
    this.listdrawer2.open();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth2 = window.innerWidth;
    this.cdr.detectChanges();


    if (this.screenWidth2 > 640) {
      this.listdrawer2.open()
    }

  }

}
