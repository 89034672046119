import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
@Component({
  selector: 'app-validar-correo',
  templateUrl: './validar-correo.component.html',
  styleUrls: ['./validar-correo.component.scss'],
})
export class ValidarCorreoComponent {
  seccion = 'datos';
  formularioInformacion: FormGroup;
  correos: Array<any> = [];
  correo: string;
  loading: boolean = false;
  mail = '';
  havemails: boolean = true;
  intentosContinuar = 0;

  constructor(
    private router: Router,
    private data: DataService,
    private getinfoServ: GetInformacionService,
    private dialogService: DialogService,
  ) { }

  ngAfterViewInit(): void {
    this.data.isResume = false;
    this.correos = [];
    this.getinfoServ
      .getInfoClient({ carpeta: this.data.numeroCarpeta.toString() })
      .subscribe(
        (resp) => {
          this.correos = resp.data.resultado.infoClient.listaCorreo
            ? resp.data.resultado.infoClient.listaCorreo
            : [];
          this.correos.push({
            email: 'Ninguna de los anteriores',
          });
        },
        (error) => {
          console.log('Falle consulta informacion correos' + error);
        }
      );
  }

  async savemail(): Promise<Promise<void>> {
    this.loading = true;
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      this.data.isNewEmail = false;
      if (this.mail === 'Ninguna de los anteriores') {
        this.data.isNewEmail = true;
      }

      const isVoid = this.mail.includes('Ninguna');
      let value = isVoid ? 0 : 1;

      this.getinfoServ
        .saveCascadaMail({
          ind: value,
          correo: this.mail,
          carpeta: this.data.numeroCarpeta.toString(),
          cedula: this.data.cedula.toString(),
        })
        .subscribe(
          async (resp) => {
            this.router.navigateByUrl('/' + resp.data.ruta);
          },
          (error) => {
            this.loading = false;
            this.intentosContinuar = 0;
            this.modalAuto(error.error.message);
          }
        );
    } catch (error) {
      this.modalAuto(error.error.message);
      this.loading = false;
      this.intentosContinuar = 0;
    }
  }

  modalAuto(message = '') {
    this.dialogService.openDialog('Correo incorrecto', message, false);
  }
}
