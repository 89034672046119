import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EdoLaboralService {

  private api = environment.ApiConecta+"parametros/estado-laboral/"

  constructor(
    private http:HttpClient
  ) { }

  getEdoLab(pagaduria:string):Observable<any>{
    return this.http.get(this.api+pagaduria);
  }
}
