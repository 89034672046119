<div class="main-container-component">
  <h1 class="card-header" *ngIf="header">
    {{ header }}
  </h1>
  <p class="card-subheader" *ngIf="subheader">
    {{ subheader }}
  </p>

  <div class="card-body center-content">
    <div class="card-body-card center-content">
      <div class="card-body-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

</div>
