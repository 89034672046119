import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChPassComponent } from './auth/ch-pass/ch-pass.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { CrearCuentaComponent } from './auth/crear-cuenta/crear-cuenta.component';
import { ExpiredPasswordComponent } from './auth/expired-password/expired-password.component';
import { ValidAccountComponent } from './auth/valid-account/valid-account.component';
import { PoliticasComponent } from './auto-central/politicas/politicas.component';
import { ValidacionTerminadaComponent } from './auto-central/validacion-terminada/validacion-terminada.component';
import { ValidacionComponent } from './auto-central/validacion/validacion.component';
import { AuthGuard } from './guards/auth.guard';
import { BusquedaCarpetasComponent } from './pwa/busqueda-carpetas/busqueda-carpetas.component';
import { CargaCedulaComponent } from './pwa/carga-cedula/carga-cedula.component';
import { CargaDocumentosComponent } from './pwa/carga-documentos/carga-documentos.component';
import { FormularioComponent } from './pwa/formulario/formulario.component';
import { InformacionAdicionalComponent } from './pwa/informacion-adicional/informacion-adicional.component';
import { MenuComponent } from './pwa/menu/menu.component';
import { NotificacionesComponent } from './pwa/notificaciones/notificaciones.component';
import { AutorizacionTransferenciaComponent } from './pwa/pre-autorizacion/autorizacion-transferencia/autorizacion-transferencia.component';
import { AutorizacioncentralriesgoComponent } from './pwa/pre-autorizacion/autorizacioncentralriesgo/autorizacioncentralriesgo.component';
import { DatosClienteComponent } from './pwa/pre-autorizacion/datos-cliente/datos-cliente.component';
import { CameraComponent } from './pwa/pre-autorizacion/formato-acr/components/camera/camera.component';
import { FormatoAcrComponent } from './pwa/pre-autorizacion/formato-acr/formato-acr.component';
import { PregSegComponent } from './pwa/pre-autorizacion/preg-seg/preg-seg.component';
import { ValidTermComponent } from './pwa/pre-autorizacion/valid-term/valid-term.component';
import { PreOfertaComponent } from './pwa/pre-oferta/pre-oferta.component';
import { PwaComponent } from './pwa/pwa.component';
import { RegistroCelularComponent } from './pwa/registro-celular/registro-celular.component';
import { RegistroCorreoComponent } from './pwa/registro-correo/registro-correo.component';
import { ReporteriaComponent } from './pwa/reporteria/reporteria.component';
import { RobAnalisisComponent } from './pwa/rob-analisis/rob-analisis.component';
import { SeguroVoluntarioComponent } from './pwa/seguro-voluntario/seguro-voluntario.component';
import { ValidarCorreoComponent } from './pwa/validar-correo/validar-correo.component';
import { ValidIdentComponent } from './valid-ident/valid-ident.component';

// Flujo ADO
import { RecuperacionComponent } from './auth/recuperacion/recuperacion.component';
import { CapacitacionPageComponent } from './pwa/capacitacion-page/capacitacion-page.component';
import { AceptacionFirmaComponent } from './pwa/certificados/aceptacion-firma/aceptacion-firma.component';
import { FirmaDocComponent } from './pwa/certificados/firma-doc/firma-doc.component';
import { PazysalvoComponent } from './pwa/certificados/pazysalvo/pazysalvo.component';
import { ResumenCreditoComponent } from './pwa/certificados/resumen-credito/resumen-credito.component';
import { SaldoComponent } from './pwa/certificados/saldo/saldo.component';
import { DocumentosComponent } from './pwa/firma/documentos/documentos.component';
import { DocsContratoComponent } from './pwa/firma/f-docs/docs-contrato/docs-contrato.component';
import { FDocsComponent } from './pwa/firma/f-docs/f-docs.component';
import { LoaderComponent } from './pwa/firma/f-docs/loader/loader.component';
import { ProSolicitudComponent } from './pwa/firma/pro-solicitud/pro-solicitud.component';
import { ValidAdoTermComponent } from './pwa/firma/valid-ado-term/valid-ado-term.component';
import { ValidacionIdentidadComponent } from './pwa/firma/validacion-identidad/validacion-identidad.component';
import { HomeComponent } from './pwa/home/home.component';
import { MiPerfilPageComponent } from './pwa/mi-perfil-page/mi-perfil-page.component';
import { ListadoCelularesComponent } from './pwa/pre-autorizacion/listado-celulares/listado-celulares.component';

// Version 2
import { OfertaComponent } from './seiz/oferta/oferta.component';
import { AppV2Component } from './v2/app.v2.component';
import { AutoCentralComponent } from './v2/pages/auth/auto-central/auto-central.component';
import { IniciarSesionComponent } from './v2/pages/auth/iniciar-sesion/iniciar-sesion.component';
import { RecuperarContrasenaComponent } from './v2/pages/auth/recuperar-contrasena/recuperar-contrasena.component';
import { CambioContrasenaComponent } from './v2/pages/home/cambio-contrasena/cambio-contrasena.component';
import { DebesSaberComponent } from './v2/pages/home/debes-saber/debes-saber.component';
import { HomeComponent as HomeV2Component } from './v2/pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: AppV2Component,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'iniciar-sesion' },
      { path: 'iniciar-sesion', component: IniciarSesionComponent },
      { path: 'recuperar-contrasena', component: RecuperarContrasenaComponent },
      { path: 'auto-central', component: AutoCentralComponent },
      { path: `expired-password`, component: ExpiredPasswordComponent },
      {
        path: 'home/cambio-contrasena',
        component: HomeV2Component,
        canActivate: [AuthGuard],
        children: [{ path: '', component: CambioContrasenaComponent }],
      },
      {
        path: 'home/debes-saber',
        component: HomeV2Component,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: DebesSaberComponent },
          { path: ':path', component: DebesSaberComponent },
        ],
      },
    ],
  },
  // Legacy
  {
    path: '',
    component: HomeV2Component,
    children: [
      { path: `crear-cuenta`, component: CrearCuentaComponent },
      { path: `ch-pass`, component: ChPassComponent },
      { path: `recuperacion`, component: RecuperacionComponent },
      { path: `change-password`, component: ChangePasswordComponent },
      { path: `valid-account`, component: ValidAccountComponent },
      { path: `validIdent`, component: ValidIdentComponent },
    ],
  },
  {
    path: '',
    component: HomeV2Component,
    children: [
      { path: '', pathMatch: 'full', redirectTo: `formulario-perfilamiento` },
      { path: `pwa`, component: PwaComponent },
      { path: `menu`, component: MenuComponent },
      {
        path: `formulario-perfilamiento`,
        component: FormularioComponent,
        canActivate: [AuthGuard],
      },
      { path: `carga-cedula`, component: CargaCedulaComponent },
      {
        path: `busqueda-carpetas`,
        component: BusquedaCarpetasComponent,
        canActivate: [AuthGuard],
      },
      { path: 'segurovoluntario', component: SeguroVoluntarioComponent },
      {
        path: 'rob-analisis',
        component: RobAnalisisComponent,
        canActivate: [AuthGuard],
      },
      { path: 'pre-oferta', component: PreOfertaComponent },
      { path: 'cargadocumentos', component: CargaDocumentosComponent },
      {
        path: 'informacionadicional',
        component: InformacionAdicionalComponent,
      },
      { path: 'notificaciones', component: NotificacionesComponent },
      { path: 'home', component: HomeComponent },
      { path: 'cargadocumentos', component: CargaDocumentosComponent },
      { path: 'reporteria', component: ReporteriaComponent },
      { path: 'validacion-identidad', component: ValidacionIdentidadComponent },
      { path: 'documentos', component: DocumentosComponent },
      { path: 'pro-solicitud', component: ProSolicitudComponent },
      { path: 'loader', component: LoaderComponent },
      { path: 'docs-contrato', component: DocsContratoComponent },
      { path: 'f-docs', component: FDocsComponent },
      { path: 'saldo', component: SaldoComponent },
      { path: 'pazysalvo', component: PazysalvoComponent },
      { path: 'resumen-credito', component: ResumenCreditoComponent },
      { path: 'aceptacion-firma', component: AceptacionFirmaComponent },
      { path: 'firma-doc', component: FirmaDocComponent },
      { path: 'valid-ado-term', component: ValidAdoTermComponent },
      {
        path: 'redireccionamiento/:token',
        component: CapacitacionPageComponent,
      },
      {
        path: 'capacitacion',
        component: CapacitacionPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mi-perfil',
        component: MiPerfilPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mis-tramites',
        loadChildren: () =>
          import('./modules/mis-tramites/mis-tramites.module').then(
            (m) => m.MisTramitesModule
          ),
      },
      {
        path: 'seiz/oferta',
        component: OfertaComponent,
      },
      { path: 'registrocelular', component: RegistroCelularComponent },
      { path: 'modificarcelular', component: RegistroCelularComponent },
      { path: 'validarcorreo', component: ValidarCorreoComponent },
      { path: 'registrocorreo', component: RegistroCorreoComponent },
      { path: 'listadocelulares', component: ListadoCelularesComponent },
      { path: 'validaotp', component: ValidacionComponent },
    ],
  },
  {
    path: 'documentos',
    component: DocumentosComponent,
  },
  {
    path: '',
    component: AutoCentralComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: `auto-central` },
      { path: `politicas`, component: PoliticasComponent },
      { path: 'validacion', component: ValidacionComponent },
      { path: 'validacion-terminada', component: ValidacionTerminadaComponent },
    ],
  },
  {
    path: '',
    component: ValidIdentComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: `validIdent` },
      { path: `validIdent`, component: ValidIdentComponent },
    ],
  },
  {
    path: '',
    component: HomeV2Component,
    children: [
      { path: '', pathMatch: 'full', redirectTo: `pre-autorizacion` },
      {
        path: 'autorizacionTransferencia',
        component: AutorizacionTransferenciaComponent,
      },
      {
        path: 'datoscliente',
        component: DatosClienteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'autorizacioncentral',
        component: AutorizacioncentralriesgoComponent,
      },
      { path: 'validTerm', component: ValidTermComponent },
      { path: 'preguntasSeguridad', component: PregSegComponent },
      { path: 'formatoAcr', component: FormatoAcrComponent },
      { path: 'camara', component: CameraComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
