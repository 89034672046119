<div class="content">
  <!--<div class="caja-izquierda">

    <header class="header-caja">
      <div class="logo">
        <img src="./assets/img/BayportLogo.svg" alt="logo-bayport">
      </div>
      <div class="logo-responsive">
        <img src="./assets/img/LogoPestana.svg" style="margin-left: -31px;" alt="logo-bayport">
      </div>
      <div class="inicio-responsive">
        <span class="material-icons">person</span>
        <a (click)="changeRoute()">{{ _storageService.textHeader }}</a>
      </div>
    </header>

    <div class="texto">
      <div class="texto-azul"><p>Bienvenidos a nuestro Portal de</p></div>
      <div class="texto-azul"><p>Agentes: un lugar donde</p></div>
      <div class="texto-azul"><p>encontrarás todo lo relacionado</p></div>
      <br>
      <div class="texto-azul"><p>con tus créditos</p></div>
    </div>

  </div>-->
</div>
