import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-ch-pass',
  templateUrl: './ch-pass.component.html',
  styleUrls: ['./ch-pass.component.css']
})
export class ChPassComponent implements OnInit {

  chPassForm: FormGroup;
  errorLog: String = '';
  matcher = new MyErrorStateMatcher();

  constructor(private formBuilder: FormBuilder, private api: AuthService) {
    this.chPassForm = new FormGroup({
      password: new FormControl('', [
        Validators.pattern('^[a-zA-Z0-9!"#$%&()*+,-./:;<=>?@_`|~ ]+$'),
        Validators.minLength(8),
        Validators.maxLength(33),
        Validators.required,
      ]),
    });
   }

  ngOnInit(): void {
  }

}
