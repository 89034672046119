import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { RcSolicitudService } from 'src/app/services/recuperacion/rc-solicitud.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { DialogCambioPasswordComponent } from '../../dialogs/dialog-cambio-password/dialog-cambio-password.component';

@Component({
  selector: 'app-cambia-password',
  templateUrl: './cambia-password.component.html',
  styleUrls: ['./cambia-password.component.css']
})
export class CambiaPasswordComponent implements OnInit {

  public recuperacionForm:FormGroup;
  public isValidConfirmar:boolean = false;
  public showActual:boolean = false;
  public showPassword:boolean = false;
  public showConfirmar:boolean = false;
  private debounceTime?:NodeJS.Timeout;
  public showError: boolean = false;
  public showV = false;

  constructor(
    private _serivice:RcSolicitudService,
    private router:Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.iniciarFormulario();

    if (parseInt(localStorage.getItem('change-password')) === 1) {
      this.dialog.open(DialogCambioPasswordComponent);
    }
  }

  iniciarFormulario(){
    this.recuperacionForm = new FormGroup({
      actual:new FormControl('',Validators.required),
      nueva: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
      confirmar: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
    })
  }

  changeComfirmar(query){
    const { nueva } = this.recuperacionForm.value;
    if( this.debounceTime ) clearTimeout(this.debounceTime );
    this.debounceTime = setTimeout( ()=>{
      if(nueva == query){
        this.isValidConfirmar = true;
      }else{
        this.isValidConfirmar = false;
      }
    },500);
  }

  changePassword(){
    const  {nueva:clave } = this.recuperacionForm.value;
    this._serivice.updatePassword(clave).subscribe(resp=>{
      console.log('MENSAJE CAMBIO', resp);
      Swal.fire({
        title: '¡Creaste una nueva contraseña!',
        confirmButtonText: 'Ir al inicio',
        icon: 'success',
        confirmButtonColor: '#009BDC',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/home']);
        }
      })
    },error=>{
      console.log('MENSAJE ERROR',error.error.message);
      Swal.fire('Opps !!', error.error.message, 'error');
    });
  }

  showValid(event: any){
    if(event == ''){
      this.showV = false;
    } else {
      this.showV = true;
    }
  }

}
