import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenVerificationService {
  private helper = new JwtHelperService();
  private isInvalid: boolean = false;

  constructor(private authService: AuthService) {}

  public async isExpired() {
    let token: any = this.authService.usuario;

    try {
      this.isInvalid = await this.helper.isTokenExpired(token.access_token);
    } catch (e) {
      this.isInvalid = true;
    }
    return this.isInvalid;
  }
}
