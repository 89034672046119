<app-spinner *ngIf="loading"></app-spinner>
<app-main-container header="Validación de identidad" subheader="Datos del solicitante del crédito">
  <div class="contenido">
    <div [formGroup]="formularioInformacion">
      <p>Ingresa los siguientes datos del solicitante para poder continuar con la validación de información ante centrales de riesgo.</p>
      <div class="form-field">
        <label class="form-label">Tipo de documento</label>
        <div class="radio-group">
          <div *ngFor="let id of tiposIdentificacion">
            <input type="radio" [id]="id.codigoTipoIdentificacion" name="tipoIdentificacion"
              [value]="id.codigoTipoIdentificacion" formControlName="tipoIdentificacion" />
            <label [htmlFor]="id.codigoTipoIdentificacion">{{
              id.nombre
              }}</label>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label for="numeroIdentificacion" class="form-label">Número de documento</label>
        <input type="number" inputmode="numeric" class="form-control" placeholder="*Número de documento"
          id="numeroIdentificacion" formControlName="numeroIdentificacion" autocomplete="off">
        <div class="form-error" *ngIf="formularioInformacion.controls.numeroIdentificacion.invalid && !formularioInformacion.controls.numeroIdentificacion.pristine">
          Número de documento es obligatorio
        </div>
      </div>
      <div class="form-field">
        <label for="primerApellido" class="form-label">Apellido</label>
        <input type="text" class="form-control" placeholder="*Apellido"
          id="primerApellido" formControlName="primerApellido" autocomplete="off">
        <div class="form-error" *ngIf="formularioInformacion.controls.primerApellido.hasError('required') && !formularioInformacion.controls.primerApellido.pristine">
          Este campo es obligatorio
        </div>
      </div>
      <div class="form-field">
        <label for="celular" class="form-label">Celular</label>
        <input type="number" inputmode="numeric" class="form-control" placeholder="*Celular" autocomplete="off"
          id="celular" formControlName="celular">
          <div class="form-error" *ngIf="formularioInformacion.controls.celular.hasError('required') && !formularioInformacion.controls.celular.pristine">
            Este campo es obligatorio
          </div>
          <div class="form-error" *ngIf="formularioInformacion.controls.celular.hasError('pattern')">
            Verifique el formato de teléfono
          </div>
      </div>
      <button-look-feel color="secondary" (clicked)="guardarinfoPersonal()" [disabled]="formularioInformacion.invalid"
        [loading]="loading">Continuar</button-look-feel>
    </div>
  </div>
</app-main-container>
