import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asegurabilidad } from 'src/app/models/asegurbilidad';
import { Desembolso } from 'src/app/models/desembolso';
import { FinancieraAdicional } from 'src/app/models/financieraAdicional';
import { LaboralAdicional } from 'src/app/models/laboralAdicional';
import { PersonalAdicional } from 'src/app/models/PersonalAdicional';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoadicionalService {

  private endpoint = environment.ApiConecta+'perfilamiento/adicional/';

  constructor(private http:HttpClient) { }


  personalAdicional(personalAdicional:PersonalAdicional):Observable<any>{
    return this.http.post(this.endpoint+'informacion/adicional',personalAdicional)
  }

  laboralAdicional(laboralAdicional:LaboralAdicional):Observable<any>{
    return this.http.post(this.endpoint+'informacion/laboral',laboralAdicional);
  }

  financieraAdicional(financieraAdicional:FinancieraAdicional):Observable<any>{
    return this.http.post(this.endpoint+'informacion/financiera',financieraAdicional);
  }

  desembolso(desembolso:Desembolso):Observable<any>{
    return this.http.post(this.endpoint+'desembolso',desembolso);
  }

  asegurabilidad(asegurabilidad:Asegurabilidad):Observable<any>{
    return this.http.post(this.endpoint+'asegurabilidad',asegurabilidad);
  }


}
