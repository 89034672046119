import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RcSolicitud, ResponseSolicitud } from '../../models/rcSolicitud.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RcSolicitudService {

  private endpoint = environment.ApiConecta;

  constructor(
    private http:HttpClient
  ) { }

  solicitarRecuperacion(canal: string, usuario: string): Observable<RcSolicitud>{
    return this.http.post<RcSolicitud>(this.endpoint, {canal,usuario});
  }

  recoveryPasword(usuario:string){
    return this.http.post(this.endpoint + 'account/recover-pwd', {usuario} );
  }

  updatePassword(clave:string):Observable<ResponseSolicitud>{
    return this.http.post<ResponseSolicitud>(this.endpoint + 'account/update-pwd', {clave} );
  }

}
