<div class="header-tablet">
  <ng-content select="[menuResponsive]"></ng-content>
  <!-- <div class="menu">
    <span class="material-icons">menu</span>
    <span class="menu-desc">Menú</span>
  </div> -->
  <!-- <div class="menu" (click)="showMenuTablet()">
    <span class="material-icons">cancel</span>
    <span class="menu-desc">Cerrar</span>
  </div> -->
  <div class="logo">
    <img src="../../../assets/img/BayportLogo.svg" alt="logo" />
  </div>
  <div class="search">
    <span class="material-icons">search</span>
    <span class="search-desc">Buscar</span>
  </div>
  <div class="nuevo-credito">
    <span class="material-icons s-30">monetization_on</span>
    <span class="nuevo-credito-desc" (click)="rout()">Nuevo<br />crédito</span>
  </div>
  <!-- <div class="nuevo-credito opt-no-border"></div> -->
</div>

<!-- <div *ngIf="!showDefault" class="buscador animate__animated animate__fadeIn">
  <div class="header-dash">
    <form [formGroup]="formBusquedaSobrepuesto" #formInfoPersonalRef="ngForm">
      <button
        mat-button
        class="btn-search bg-white"
        (click)="showModalSearch(inputSearch.value)"
      >
        <mat-icon>search</mat-icon>
      </button>
      <input
        #inputSearch
        class="input-search"
        type="text"
        placeholder="¿Tienes preguntas o deseas buscar algo?"
        name="search"
        formControlName="search"
        (keyup.enter)="showModalSearch(inputSearch.value)"
        autocomplete="off"
        autofocus
      >
      <button
        mat-button
        class="btn-search bg-transparent"
        (click)="changeDefault()"
      >
        <span>Cancelar</span>
      </button>
    </form>
  </div>
  <div class="autocomplete-div">
    <div class="autocomplete">
      <h1>Accesos Rápidos</h1>
      <div class="lista-accesos">
        <div class="item-acceso" *ngFor="let a of asks">
          <span (click)="this.showResults(a)">{{ a.ask }}</span>
        </div>
      </div>
    </div>
  </div>
</div> -->
