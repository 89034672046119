// Angular
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { RcActualizaPassService } from 'src/app/services/recuperacion/rc-actualiza-pass.service';

// Utils
import { FormChecks } from 'src/app/pwa/auth/form-checks';

@Component({
  selector: 'app-cambio-contrasena',
  templateUrl: './cambio-contrasena.component.html',
  styleUrls: ['./cambio-contrasena.component.scss']
})
export class CambioContrasenaComponent {

  public passwordForm: FormGroup;
  public incorrectPassword: boolean = false
  public imputCorrect: boolean = true
  public changedPassword: boolean = false

  hide: boolean = true;
  showError: boolean = false;
  errorLog: string = '';
  numuser = "";
  otp = "";

  constructor(
    private fb: FormBuilder,
    private api: RcActualizaPassService,
    public activatedRoute: ActivatedRoute,
    public dialogService: DialogService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    let state = this.activatedRoute.paramMap.pipe(map(() => window.history.state))

    state.subscribe(x => {
      this.otp = x.otp;
      this.numuser = x.numuser;
    })

    this.passwordForm = this.fb.group({
      'old-password': [null, [
        Validators.required,
      ]],
      'new-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
        Validators.minLength(8),
        FormChecks.checkNumber,
        FormChecks.checkSpecialCharacter,
        FormChecks.checkMayus,
        FormChecks.checkMinus
      ]],
      'confirm-password': [{
        value: null,
        disabled: true
      }, [
        Validators.required,
      ]]
    }, {
      validators: FormChecks.checkIfEqual
    });

    // this.passwordForm.get('passwordconfirm').valueChanges.subscribe((value) => {
    //   if (value !== null) {
    //     this.incorrectPassword = false
    //   } else {
    //     this.incorrectPassword = true
    //   }
    // })
  }

  // validacion de clave actual
  validationPassword() {
    const user = localStorage.getItem('user')
    this.api.validationPassword(
      user,
      this.passwordold.value,
      "1",
      1
    )
      .subscribe(
        (data: any) => {
          if (data.faError == -12) {
            this.incorrectPassword = true
            this.imputCorrect = true
            this.passwordForm.get('new-password').disable();
            this.passwordForm.get('confirm-password').disable();
          } else {
            this.incorrectPassword = false
            this.imputCorrect = false
            this.passwordForm.get('new-password').enable();
            this.passwordForm.get('confirm-password').enable();
          }
        }
      );

  }
  // Método para obtener el control del formulario
  get passwordold() {
    return this.passwordForm.get('old-password');
  }

  // Método para obtener el control del formulario
  get passwordnew() {
    return this.passwordForm.get('new-password');
  }

  // Método para obtener el control del formulario
  get passwordconfirm() {
    return this.passwordForm.get('confirm-password');
  }

  protected actRc() {
    let responses: any;
    const user = localStorage.getItem('user')

    this.api.validationPassword(
      user,
      this.passwordold.value,
      this.passwordnew.value,
      2
    )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.errorLog = data.faDescripcion;
          this.dialogService.openDialog("!", `${this.errorLog}`, false)
          responses = data;

          if (responses.code == 200) {
            this.changedPassword = true
            this.router.navigate(['/home']);
          }
          else {
            this.errorLog = responses.faDescripcion;
          }
        },
        (error) => {
          this.dialogService.openDialog("!", `Error actualizando contraseña`, false)
          this.errorLog = error.error.message;
        }
      );
  }


}


