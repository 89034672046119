<app-main-container header="Validación de identidad" subheader="Datos del solicitante del crédito">
  <div class="contenido">
    <p>Para la validación de identidad del solicitante, como corredor/asesor y en calidad del responsable de la
      información, deberás seleccionar el celular que confirma el solicitante:
    </p>
    <br>
    <div class="row">
      <mat-radio-group [(ngModel)]="telefono" aria-labelledby="example-radio-group-label" class="telefono-group">
        <mat-radio-button class="telefono-button" *ngFor="let celular of celulares" [value]="celular.celular">
          {{ celular.celular }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <button-look-feel *ngIf="haveCellphones" [disabled]="telefono == ''" [loading]="loading" color="secondary" (clicked)="savenumber()">Continuar</button-look-feel>
  </div>
</app-main-container>
