import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetBanksResponse } from 'src/app/models/catalogs/catalogs.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogParamsService {
  private readonly api = `${environment.ApiConecta}parametros`;
  constructor(
    private http: HttpClient
  ) { }

  getBanks(): Observable<GetBanksResponse> {
    return this.http.get<GetBanksResponse>(`${this.api}/bancos`);
  }

  getIdentifacationType(): Observable<any> {
    return this.http.get(`${this.api}/tipos-identificacion`);
  }
  
  getExecutiveType(): Observable<any>{
    return this.http.get(`${this.api}/tipo-ejecutivo`);
  }

  getRegional(): Observable<any> {
    return this.http.get(`${this.api}/regional`);
  }

  getChannelType(): Observable<any> {
    return this.http.get(`${this.api}/tipo-canal`);
  }

  getSalesChannel(): Observable<any> {
    return this.http.get(`${this.api}/canal-venta`);
  }

  getGenders(): Observable<any> {
    return this.http.get(`${this.api}/generos`);
  }

  getScholarship(): Observable<any> {
    return this.http.get(`${this.api}/estudios-realizados`);
  }
}
