<div class="container">
  <div id="busqueda">
    <h1 class="titulo mb-0 pb-0" style="padding-bottom: 1%">
      Búsqueda de carpetas
    </h1>
    <h3 class="subtitulo fw-semibold">
      Selecciona el criterio por el que deseas ubicar la carpeta
    </h3>
    <app-tabs [tabs]="myTabs" [defaultTabIndex]="0"></app-tabs>
    <!-- Busqueda por cedula -->
    <ng-template #cedulaTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Cédula
          </h6>
          <input pInputText placeholder="*número de cédula" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
            (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
        </div>
        <div class="px-6 flex justify-content-center">
          <button-look-feel [block]="true" size="small" [compressed]="false" [loading]="searching"
            [disabled]="searching" color="secondary" (click)="buscar('cedula')">
            Buscar
          </button-look-feel>

        </div>
      </div>
    </ng-template>
    <!-- Busqueda por cedula -->
    <!-- Busqueda por fecha -->
    <ng-template #fechaTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Fecha de inicio
          </h6>
          <div class="input-group">
            <input class="p-inputtext-sm" pInputText name="dp" placeholder="aaaa/mm/dd" [(ngModel)]="fechaInicial"
              ngbDatepicker #d="ngbDatepicker" [placement]="placement" style="width: 360px"
              (click)="d.toggle()" />
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button">
              <i class="bi bi-calendar"></i>
            </button>
          </div>
        </div>
        <div class="flex flex-column gap-2 mb-2">
          <label class="text-white m-0 labelBC">
            <strong> Fecha de finalización </strong>
          </label>
          <div class="input-group">
            <input class="p-inputtext-sm" pInputText placeholder="aaaa/mm/dd" name="dp" [(ngModel)]="fechaFinal"
              ngbDatepicker [markDisabled]="isDisabled" #df="ngbDatepicker"
              style="width: 360px" (click)="df.toggle()" />
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="df.toggle()" type="button">
              <i class="bi bi-calendar"></i>
            </button>
          </div>
        </div>

        <div class="px-6 flex justify-content-center">
          <button-look-feel [block]="true" size="small" [compressed]="false" [loading]="searching"
            [disabled]="searching" color="secondary" (click)="buscar('fecha')">
            Buscar
          </button-look-feel>
        </div>
      </div>
    </ng-template>
    <!-- Busqueda por fecha -->
    <!-- Busqueda por pagaduria -->
    <ng-template #pagaduriaTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Convenio
          </h6>
          <select (change)="getPagadurias(this.convenio)" class="form-select p-inputtext-sm" pInputText
            aria-label="Default select example" [(ngModel)]="convenio" required style="width: 360px">
            <option *ngFor="let item of filteredConvenio" [value]="item.codigo">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Pagaduría
          </h6>
          <select class="form-select" pInputText aria-label="Default select example" [(ngModel)]="pagaduria">
            <option *ngFor="let item of filteredPagaduria" [value]="item.codigoPagaduria">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="px-6 flex justify-content-center">
          <button-look-feel [block]="true" size="small" [compressed]="false" [loading]="searching"
            [disabled]="searching" color="secondary" (click)="buscar('pagaduria')">
            Buscar
          </button-look-feel>
        </div>
      </div>
    </ng-template>
    <!-- Busqueda por pagaduria -->
    <!-- Busqueda por nombre -->
    <!-- <ng-template #nombreTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Nombre
          </h6>
          <input pInputText placeholder="*nombre" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
            (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
        </div>
        <div class="px-6 flex justify-content-center">
          <button pButton type="submit" (click)="buscar('nombre')" [disabled]="searching"
            class="px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none buscar">
            <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Buscar
          </button>
        </div>
      </div>
    </ng-template> -->
    <!-- Busqueda por nombre -->
    <!-- Busqueda por apellido -->
    <ng-template #apellidoTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Apellido
          </h6>
          <input pInputText placeholder="*apellido" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
            (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
        </div>
        <div class="px-6 flex justify-content-center">
          <button-look-feel [block]="true" size="small" [compressed]="false" [loading]="searching"
            [disabled]="searching" color="secondary" (click)="buscar('apellido')">
            Buscar
          </button-look-feel>
        </div>
      </div>
    </ng-template>
    <!-- Busqueda por apellido -->
    <!-- Busqueda por carpeta -->
    <ng-template #carpetaTemplate>
      <div class="bg-blue-gradient w-1/2 px-6 sm:py-6">
        <div class="flex flex-column gap-2 mb-2">
          <h6 class="labelBC">
            Número de carpeta
          </h6>
          <input pInputText placeholder="*número de carpeta" class="p-inputtext-sm" [(ngModel)]="entradabusqueda"
            (ngModelChange)="entradabusqueda = $event.toUpperCase()" />
        </div>
        <div class="px-6 flex justify-content-center">
          <button-look-feel [block]="true" size="small" [compressed]="false" [loading]="searching"
            [disabled]="searching" color="secondary" (click)="buscar('numcarpeta')">
            Buscar
          </button-look-feel>
        </div>
      </div>
    </ng-template>
    <!-- Busqueda por carpeta -->
  </div>
</div>
