import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocSolicitudService {

  private endpoint = environment.ApiConecta;

  constructor(private http: HttpClient) {}

  getDocumentosSolicitud(numeroCarpeta: string): Observable<any> {
    return this.http.get(this.endpoint + 'documentos-solicitud', {
      params: { numeroCarpeta },
    });
  }

  uploadAwsRoute(
    numeroCarpeta: string,
    rutaAws: string,
    secuenciaDocumento: number,
    idFolder?: number
  ): Observable<any> {
    return this.http.post(this.endpoint + 'document/upload', {
      numeroCarpeta,
      rutaAws,
      secuenciaDocumento,
      idFolder
    });
  }

}
