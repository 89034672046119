<div class="card" *ngIf="!viewpdf">
  <div *ngIf="paso1">
    <h4>{{ titulo }}</h4>
  </div>

  <div class="cont-card" *ngIf="paso1">
    <span style="font-size: 16px;">
      {{text}}
    </span>
    <ng-container [ngTemplateOutlet]="onLoad" *ngIf="type==='load'"></ng-container>
    <ng-container [ngTemplateOutlet]="onCommon" *ngIf="type==='common'"></ng-container>
  </div>

  <div *ngIf="paso2">
    <div class="cont-card">
      <img id="tarjeta" src="assets/img/tarjeta.PNG" alt="tarjeta" />
      <div style="margin-top: 10px;">
        <span style="font-size: 16px;">Archivo cargado exitosamente.</span>
      </div>
    </div>
    <div class="cont-card">
      <a class="elimiar cont-card" (click)="eliminarArchivo()" role="button" style="font-size: 16px;">Eliminar archivo y
        cargar otro</a>
    </div>
  </div>



</div>

<div class="cards" *ngIf="viewpdf">
  <object *ngIf="pdfSource.length > 0" class="obj-pdf s-pdf" [data]="pdfSource | safeUrl" type="application/pdf">
    <iframe *ngIf="pdfSource.length > 0" [src]="pdfSource | safeUrl"></iframe>
  </object>
</div>

<!-- TEMPLATES -->
<ng-template #onLoad>
  <div class="btn-select-card">
    <label for="update-account" class="h-100 d-flex align-items-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon" style="padding: 0px 24px 0px 8px;">attach_file
      </mat-icon>
      <span style="font-size: 16px;"> Cargar desde una carpeta</span>
    </label>
    <input id="update-account" accept=".pdf" type="file" class="bayport-file" style="display: none"
      (change)="onAddFile($event)" />
  </div>
  <div class="bc-cam" *ngIf="enableCamera">
    <button class="btn-cam-rag-card cont-card d-flex align-items-center " mat-button>
      <span class="material-icons" style="padding: 0px 4px 0px 8px;"> camera_alt </span>
      <span>
        Cargar usando la cámara
      </span>
    </button>
  </div>
</ng-template>

<ng-template #onCommon>
  <div class="btn-select-card d-flex justify-content-center" (click)="viewp()">
    <label for="update-account" class="common-label h-100 d-flex align-items-center justify-content-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon" style="padding: 0px 24px 0px 8px;">
        visibility
      </mat-icon>
      <span style="font-size: 16px;">Ver</span>
    </label>
    <input id="update-account" type="button" class="bayport-file" style="display: none"
      />
  </div>
  <div class="btn-select-card additional d-flex justify-content-center" (click)="onDownload()" >
    <label for="download" class="common-label h-100 d-flex align-items-center justify-content-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon" style="padding: 0px 24px 0px 8px;">
        cloud_download
      </mat-icon>
      <!-- <a [href]="" style="font-size: 16px;" [download]="pdfSource">Descargar</a> -->
      <span style="font-size: 16px;">Descargar</span>
      <!-- <a href="pdf-test.pdf" download>Descarga</a> -->
    </label>
    <input id="download" type="button" class="bayport-file" style="display: none"
       />
  </div>
</ng-template>
