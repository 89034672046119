import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenVerificationService } from '../services/auth/token-verification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: TokenVerificationService,
    private router: Router
  ) {}
  async canActivate(): Promise<boolean> {
    if (!(await this.authService.isExpired())) {
      return true;
    }
    localStorage.removeItem('credentials');
    this.router.navigateByUrl('/iniciar-sesion');
    return false;
  }
}
