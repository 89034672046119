import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UploadService {


    private endpoint = environment.ApiConecta + 'document/bucket/key'
    private s3: S3;
    constructor(private http: HttpClient) {

        
    }

    getUrl(numeroCarpeta: string, identificacion: string, nombreArchivo: string): Observable<any> {
        return this.http.post(this.endpoint, { numeroCarpeta, identificacion, nombreArchivo });
    }

    uploadFileURL(file: File, data: any): Observable<any> {
        //comparar el tipo de archivo para obtener los campos correctos
        let form = new FormData();
        Object.keys(data.fields).forEach(key => form.append(key, data.fields[key]));
        form.append('Content-Type', file.type)
        form.append('file', file);
        return this.http.post(data.url, form);
    }
}
