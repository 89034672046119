import { Injectable } from '@angular/core';
import { parseJwt } from 'src/app/token';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  public parseJwt(): any {
    const credentials: { usuario:string, access_token:string } = JSON.parse(localStorage.getItem('credentials'));
    return parseJwt(credentials.access_token);
  }
}
