<div class="d-flex flex-column align-items-center">

  <p class="mb-0 text-center" *ngFor="let rule of rules">
    <img src="assets/icons/check-blue.svg" alt="" />
    <span [innerHTML]="rule"></span>
  </p>

  <app-card-dotted-line class="mt-6">
    <div class="d-md-flex justify-content-between">
      <div class="mx-2 mt-2">
        <h5 class="me-md-4" [innerHTML]="title"></h5>
        <p class="mb-1 me-md-4" [innerHTML]="subtitle"></p>
      </div>
      <div class="d-flex flex-column align-items-center">
        <button-look-feel *ngIf="contract" size="small" color="outline" (clicked)="getContrato()" [loading]="data.contrato_url==null">
          descargar <img src="./../../../assets/icons/descargar-btn-cyan.svg"
            style="width: 12px; margin-top: -5px; margin-left: 6px;" /></button-look-feel>
        <button-look-feel size="small" color="secondary" (clicked)="openDialogFile()"
          [disabled]="data.contrato_url==null && contract">
          cargar <img src="./../../../assets/icons/cargar-btn-blue.svg"
            style="width: 12px; margin-top: -5px; margin-left: 6px;" /></button-look-feel>
        <input #fileInput multiple type="file" accept=".png, .jpg, .pdf" (change)="onFileSelected($event)"
          style="display: none;" />
      </div>
    </div>
    <div class="contenedor-archivos  mx-2 my-2 border-top" *ngIf="masAdicionals.length>0">
      <app-load-file-card *ngFor="let doc of masAdicionals; let i = index" [fileName]="doc.nombre"
        [fileSize]="'4.3Mb'" [loading]="true" (eliminarArchivo)="deleteDymamic(i)"></app-load-file-card>
    </div>
  </app-card-dotted-line>
</div>
<div class="d-flex justify-content-between align-items-center my-5">
  <app-hyperlink-icon iconPosition="start" icon="arrow">Volver</app-hyperlink-icon>
  <button-look-feel size="small" color="secondary" [disabled]="masAdicionals.length==0" [loading]="loading" (clicked)="subirArchivos()">Enviar</button-look-feel>
</div>
