import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-reporteria',
  templateUrl: './reporteria.component.html',
  styleUrls: ['./reporteria.component.scss']
})
export class ReporteriaComponent implements OnInit {


  user = "";
  user_id: string;

  constructor() { }

  ngOnInit(): void {
    this.nombreUsuario();
  }

  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    this.user_id = decoded['sub'];
    localStorage.setItem('tpcnl', decoded['tpcnl'].toLowerCase());
    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`
    } else {
      this.user = `${this.user[0]}`
    }

  }

}
