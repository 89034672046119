<div class="wrap">
    <div class="w-100 btn-wrapper">
        <button class="close" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="w-100 mt-3 d-flex flex-wrap justify-content-center content-wrapper">
        <pdf-viewer class="w-pdf-view" [src]="src" [zoom]="zoom_to" [original-size]="false" [autoresize]="true"></pdf-viewer>
        <div class="d-flex flex-wrap buttons-wrapper zin-btn">
            <button class="w-100 zin-btn" (click)="zoom_in()">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button class="w-100 zout-btn"(click)="zoom_out()">
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>
    </div>
    
</div>