import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CambioestadocarpetaService {

  private endpoint = environment.ApiConectaDocker + 'cambio-estado';

  constructor(private http: HttpClient) {}

  cambioEstadocarpeta(numeroCarpeta: number, estadoNuevo: string, observacion: string): Observable<any> {
    return this.http.post(this.endpoint, {
      numeroCarpeta,
      estadoNuevo,
      observacion,
    });
  }

}
