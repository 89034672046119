import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NewCredito } from '../../models/new-credito';

@Injectable({
  providedIn: 'root'
})
export class RefinanciacionService {

  private endpoint = environment.ApiConecta+'refinanciacion/nuevo/credito';

  constructor(
    private http:HttpClient
  ) { }

  crearCredito(nuevoCredito: NewCredito): Observable<any>{
    return this.http.post<NewCredito>(this.endpoint, nuevoCredito);
  }

  busquedaCreditos(numeroCedula: any, codPagaduria: any): Observable<any> {
    return this.http.get(`${environment.ApiConecta}refinanciacion/busqueda-creditos/${numeroCedula}/pagaduria/${codPagaduria}`);
  }

  eliminarCredito(numeroCarpeta: any): Observable<any> {
    return this.http.delete(`${environment.ApiConecta}refinanciacion/eliminar/credito/${numeroCarpeta}`);
  }

  busquedaCreditoActivo(numeroCedula: number, codPagaduria: number): Observable<any> {
    return this.http.get(`${environment.ApiConecta}refinanciacion/busqueda-creditos/${numeroCedula}/activos/${codPagaduria}`,{});
  }

  loggerPorRefinanciacion(isRefinanciacion:boolean,creditosActivos:number,userInfo:any): Observable<any>{
    return this.http.post(`${environment.ApiConecta}refinanciacion/logger`,{
      isRefinanciacion,
      creditosActivos,
      userInfo
    });
  }


}
