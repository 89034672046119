<div style="text-align: center;">
<h1>Cédula de cara frontal</h1>
<h2>Escanea el código QR con tu teléfono para tomar la foto utilizando la cámara</h2>
<mat-card class="example-card">
  <img mat-card-image src="../../../../assets/img/QR.PNG" alt="">
  <mat-card-content>
    <p>
      Escanealo usando tu cámara
    </p>
  </mat-card-content>
</mat-card>
</div>
