<div class="card">
    <div class="titlecontainer">
        
     <div class="circle">
         <img *ngIf="status" src="assets/img/check.svg" width="50" height="50">
    </div><span>{{title}}</span>
    </div>
    <p class="text">
       {{message}}
    </p>
    <ng-content select="[accion]"></ng-content>
</div>