import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sucursal'
})
export class SucursalPipe implements PipeTransform {
  transform(sucursal:string): string {
    if(sucursal === null) return 'No seleccionado';
    return sucursal;
  }

}
