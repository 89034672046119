import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// Definir los nombres personalizados
const I18N_VALUES = {
  weekdays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'], // Nombres cortos de días
  months: [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ]
};

@Injectable()
export class DatepickerEsService extends NgbDatepickerI18n {
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES.weekdays[weekday - 1]; // Lunes a Domingo
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES.weekdays[weekday - 1]; // Etiqueta de día (requerido en Angular 15+)
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES.months[month - 1].substring(0, 3); // Ene, Feb, Mar...
  }

  getMonthFullName(month: number): string {
    return I18N_VALUES.months[month - 1]; // Nombre completo
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
