<div class="wrap">
  <div class="w-100 btn-wrapper">
    <button class="close" (click)="onClose()">
      <i class="fa-solid fa-cloud-arrow-down"></i>
    </button>
  </div>
  <div class="w-100 mt-3 d-flex flex-wrap justify-content-center content-wrapper">
    <pdf-viewer class="w-pdf-view" [src]="src" [zoom]="zoom_to" [original-size]="false"
      [autoresize]="true"></pdf-viewer>
    <div class="d-flex flex-wrap buttons-wrapper zin-btn">
      <button class="w-100 zin-btn" (click)="zoom_in()">
        <i class="fa-solid fa-magnifying-glass-plus"></i>
      </button>
      <button class="w-100 zout-btn" (click)="zoom_out()">
        <i class="fa-solid fa-magnifying-glass-minus"></i>
      </button>
    </div>
  </div>
</div>
