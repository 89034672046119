import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { RcSolicitudService } from 'src/app/services/recuperacion/rc-solicitud.service';

@Component({
  selector: 'app-recuperacion',
  templateUrl: './recuperacion.component.html',
  styleUrls: ['./recuperacion.component.css']
})
export class RecuperacionComponent implements OnInit {
   public recuperacionForm:FormGroup;

  constructor(
    private _serivice:RcSolicitudService
  ) { }

  ngOnInit(): void {
    this.iniciarFormulario();
  }

  iniciarFormulario(){
    this.recuperacionForm = new FormGroup({
      actual:new FormControl('',Validators.required),
      nueva: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
      confirmar: new FormControl('', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ])),
    })
  }

  changePassword(){
    const  {actual:clave } = this.recuperacionForm.value;
    this._serivice.updatePassword(clave).subscribe(resp=>{
      console.log(resp);
    },error=>{
      console.log(error);
    });
  }

}
