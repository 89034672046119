<div class="container">
  <div class="row">
    <div class="col-md-12">
      <br><br>
      <h3>Proceso de firma finalizado </h3>
      <p style="margin-top: 16px !important;">Muy pronto tu asesor comercial se estará contactando contigo. Te invitamos
        a
        leer nuestras últimas noticias.</p>
      <div style="text-align: center; padding-top: 18px;">
        <img src="../../../../assets/img/monito.png" alt="">
      </div>
      <button-look-feel color="secondary" onclick="window.location='https://www.bayportcolombia.com'">Continuar</button-look-feel>
    </div>
  </div>
</div>
