import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  private utils = new Utils();
  
  constructor(
    private dialogref:MatDialogRef<ModalComponent>
    ) { }
    
    ngOnInit(): void {
    }
    
    fileChange(event){
      let archivos = event.target.files; 
      let arreglo:Array<File> = [];

    let valido = this.validarFormatos(archivos); 

    if(valido.ok && arreglo.length < 10){

      if(archivos[0].type != 'application/pdf'){
        this.utils.convertImageFileToPDF(archivos[0]).then(res=>{
          arreglo.push(res);
        })

      }else{
        arreglo.push(archivos[0]);
      }

      this.dialogref.close(arreglo);

    }else{
      alert(valido.message);
    }

    

  }

  validarFormatos(archivos:File[]){

    for(let i = 0; i < archivos.length;i++){
      let nombreCortado = archivos[i].name.split('.');

      let extension = nombreCortado[nombreCortado.length -1];

      let extesionesValidas = ['PDF','pdf','jpg','JPG','png','PNG','jpeg','JPEG'];

      if( extesionesValidas.indexOf(extension) < 0){
          return {
            ok:false,
            message:`Las extensiones o farmatos validos son: pdf, jpg, png`
          };
      }
    }

    return {
      ok:true,
      message:'Formatos correctos'
    };
    
  }



}
