import { Component, Input } from '@angular/core';
import { MultipleLoadFiles } from 'src/app/models/multipleLoadFiles.interface';

@Component({
  selector: 'app-multiple-load-files',
  templateUrl: './multiple-load-files.component.html',
  styleUrls: ['./multiple-load-files.component.scss']
})
export class MultipleLoadFilesComponent {
  @Input() rules: string[] = []; // reglas para carga de archivos
  @Input() files: MultipleLoadFiles[] = [];
}
