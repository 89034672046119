import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { fadeIn, fadeOut } from 'src/app/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeIn, fadeOut]
})
export class HeaderComponent implements OnInit {
  @Input() color: 'success' | 'warning' | 'error' | 'none' = 'none'; // Por defecto: success
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() title: string = "";         // mensaje
  @Input() text: string = "";         // mensaje
  @Input() user: string = "";
  @Output() logout = new EventEmitter<any>();
  @Output() toogleMenu = new EventEmitter<any>();

  showProfileMenu = false

    constructor(
      private router: Router
    ) {
    }


  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.showProfileMenu = false
    });
  }

  cerrarSesion() {
    this.logout.emit();
  }

  toogleMenuMobile() {
    this.toogleMenu.emit();
  }
}
