import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { IOfferBody, IOfferResponse, IProfilingLiteResponse } from './interfaces';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfertaService {

  refAmmount: number;
  refTerm: number;
  refFee: number;
  messageService: string;
  statusService: number;

  constructor(private http: HttpClient) {}

  changeConditions(body: IOfferBody): Observable<IOfferResponse> {
    return this.http.post<IOfferResponse>(
      `${environment.ApiConecta}seiz/origination/change-conditions`,
      body
    );
  }

  profilingLite(numeroCarpeta: string | number): Observable<IProfilingLiteResponse> {
    return this.http.post<IProfilingLiteResponse>(
      `${environment.ApiConecta}seiz/origination/profiling-lite/${numeroCarpeta}`,
      {}
    );
  }

}
