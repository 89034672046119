<div class="main-container-component">
  <!-- <div class="row">
    <div class="col-md-4">
      <h6 style="color: blanchedalmond;">Volver</h6>
    </div>
    <div class="col-md-8"> -->
      <h1 class="card-header text-start text-md-center ms-5 mx-md-auto" *ngIf="header">
        {{ header }}
      </h1>
    <!-- </div>
  </div> -->

  <div class="card-body center-content">
    <div class="card-body-card center-content">
      <ng-content></ng-content>
    </div>
  </div>

</div>