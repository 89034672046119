import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
// import { PersonalValidators } from '../../validators/personal-validators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-crear-cuenta',
  templateUrl: './crear-cuenta.component.html',
  styleUrls: ['./crear-cuenta.component.css']
})
export class CrearCuentaComponent implements OnInit {

  newAccount: FormGroup;
  // formCreateAccount: FormGroup;
  hide: boolean = true;
  errorLog: String = '';
  showError: boolean = false;
  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private api: AuthService,
    private router: Router
    ) {
      // this.newAccount = this.createFormValidations();
      this.newAccount = new FormGroup({
        user: new FormControl('', [
          Validators.pattern('^\\d+$'),
          Validators.minLength(1),
          Validators.maxLength(15),
          Validators.required,
        ]),
        password: new FormControl('', [
          Validators.pattern('^[a-zA-Z0-9!"#$%&()*+,-./:;<=>?@_`|~ ]+$'),
          Validators.minLength(8),
          Validators.maxLength(33),
          Validators.required,
        ]),

      });
     }

  ngOnInit(): void {
  }

  onLogin() {
    this.api
      .login(
        this.newAccount.controls.user.value,
        this.newAccount.controls.password.value
      )
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error.error.message);
          this.errorLog = error.error.message;
        }
      );
  }

  // private createFormValidations(): FormGroup {
  //   return this.formBuilder.group(
  //     {
  //       user: [null, [Validators.pattern('^\\d+$'),
  //            Validators.minLength(1),
  //            Validators.maxLength(15),
  //            Validators.required,]],
  //       password: [
  //         null,
  //         Validators.compose([
  //           Validators.required,
  //           // check whether the entered password has a number
  //           PersonalValidators.patternValidator(/\d/, {
  //             hasNumber: true
  //           }),
  //           // check whether the entered password has upper case letter
  //           PersonalValidators.patternValidator(/[A-Z]/, {
  //             hasCapitalCase: true
  //           }),
  //           // check whether the entered password has a lower case letter
  //           PersonalValidators.patternValidator(/[a-z]/, {
  //             hasSmallCase: true
  //           }),
  //           // check whether the entered password has a special character
  //           PersonalValidators.patternValidator(
  //             /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  //             {
  //               hasSpecialCharacters: true
  //             }
  //           ),
  //           Validators.minLength(8),
  //         ])
  //       ]
  //     }
  //   );
  // }

}
