import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NotificaService } from 'src/app/services/notificaciones/notifica.service';

@Component({
  selector: 'app-pazysalvo',
  templateUrl: './pazysalvo.component.html',
  styleUrls: ['./pazysalvo.component.scss'],
})
export class PazysalvoComponent {
  constructor(
    private router: Router,
    private serviceNotifica: NotificaService,
    private data: DataService
  ) { }

  public rules = ["El archivo que selecciones no debe superar los <strong>5MB</strong>.", "Puedes subir <strong>PDF, PNG y JPG</strong>."]
  public title = "Certificado de paz y salvo"
  public subtitle = "Da click en el botón cargar para agregar"
  public contract = false
  public secuencia = 16
  public idFolder = 3
  public identificacion = "3.CERTIFICADOS_DE_SALDO"
  public nombreArchivoFinal = "PORTAL-pazysalvo"

  notificarNovedad() {
    let carpeta = this.data.numeroCarpeta ? this.data.numeroCarpeta + "" : ""
    this.serviceNotifica.tipoNovedad(carpeta, "20", "Se realiza la carga de archivos")
      .subscribe(res => {
        this.router.navigate(['pro-solicitud']);
      },error=>{
        console.log(<any>error);
      });
  }
}
