<div>
  <h1>
    <strong> {{ titulo }} </strong>
  </h1>
  <ng-content select="[mensaje]"></ng-content>

  <textarea readonly>
    {{texto}}
   </textarea>
  <ng-content select="[footer]"></ng-content>
</div>
