<div data-backdrop="static" class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <img src="../../../../assets/img/BayportLogo.svg" width="200" height="200">
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <img src="https://c0.klipartz.com/pngpicture/411/273/gratis-png-gatitos-y-gatos-bigotes-gatitos-y-gatos-gatito.png" width="200" height="200">
    <h2>
      Estamos cumpliendo todos los sueños
    </h2>
  </div>
</div>
