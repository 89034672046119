import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/services/dialog.service';
import { MotorService } from 'src/app/services/perfilamiento/motor.service';
import { getFilesByFolder, itemFile } from '../../models/busCarpeta.interface';
import { BuscCarpetaService } from 'src/app/services/perfilamiento/busc-carpeta.service';
import { DataService } from '../../services/data.service';
import { ReconocerService } from 'src/app/services/preOferta/reconocer.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { ResumenAdoService } from '../../services/resumen-ado.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detalle-carpetas',
  templateUrl: './detalle-carpetas.component.html',
  styleUrls: ['./detalle-carpetas.component.scss']
})
export class DetalleCarpetasComponent {
  @ViewChild('generalTemplate', { static: true }) generalTemplate!: TemplateRef<any>;
  @ViewChild('motorTemplate', { static: true }) motorTemplate!: TemplateRef<any>;
  @ViewChild('carteraTemplate', { static: true }) carteraTemplate!: TemplateRef<any>;
  @ViewChild(PdfViewerComponent) pdfViewer?: PdfViewerComponent;

  myTabs: { title: string; content: TemplateRef<any> }[] = [];
  myTabsDetalle: { title: string; content: TemplateRef<any> }[] = [];

  respuesta: string;
  dataSource: any;
  responseCarpeta = 0;
  loading: boolean = false;
  listFiles: itemFile[];
  isLoading = false;
  panelOpenState = false;
  page = 1;
  zoom = 1;
  activeIndex: number | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialogService: DialogService,
    private motorService: MotorService,
    private busService: BuscCarpetaService,
    public data: DataService,
    private reconocerSrvc: ReconocerService,
    private getinfoServ: GetInformacionService,
    private resumenAdoService: ResumenAdoService,
    private location: Location
  ) {
    this.respuesta = this.router.getCurrentNavigation()?.extras.state?.['data']
  }

  ngOnInit() {

    this.myTabs = [
      { title: 'Información general', content: this.generalTemplate },
      { title: 'Motor de decisión', content: this.motorTemplate },
      { title: 'Comprobantes de compras de cartera', content: this.carteraTemplate }
    ];

    console.log(this.motorTemplate)
    this.getMotorDecision()
    this.getComprobantesCartera()

  }
  prueba(){
    alert('pestaña uno')
  }

  getMotorDecision() {
    this.motorService.getMotor(this.respuesta['datos'].numeroCarpeta).subscribe((res) => {
      this.dataSource = res.rows;
    });


  }

  public resetMessage() {
    this.http
      .post(`${environment.ApiConecta}resend/message`, {
        numeroCarpeta: this.respuesta['datos'].numeroCarpeta,
        status: this.respuesta['datos'].codigoEstado,
      })
      .subscribe(
        (http: any) => {
          this.http = http;
          let value = http.url;
          if (http.status === 200) {
            this.dialogService.openDialogUrl(http.message, "Si al solicitante no le llegó el sms o correo, por favor copia y pega la siguiente URL y compártela con tu cliente:", value)
          } else {
            this.dialogService.openDialog(
              'Advertencia',
              `${http.error.message[0].msg}`,
              false
            );
          }
        },
        (err) => {
          this.dialogService.openDialog(
            'Advertencia',
            `${err.error.message}`,
            false
          );
        }
      );
  }

  async getComprobantesCartera() {
    const body: getFilesByFolder = {
      folder: this.respuesta['datos'].numeroCarpeta.toString(),
      satelital: 'buscar',
      originFolder: 4,
    };
    this.loading = true;
    await this.busService
      .obtenerComprobantesPago(body)
      .then((res) => {
        this.listFiles = res;
        console.log({ listFiles: this.listFiles });
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async goStep(data) {
    console.log('******',data['datos'])
    this.isLoading = true;

    this.data.numeroCarpeta = data['datos'].numeroCarpeta;
    this.data.numeroSolicitud = data['datos'].numeroSolicitud;
    this.data.numeroIdentificacion = data['datos'].numeroDocumento;
    this.data.cedula = data['datos'].numeroDocumento;
    this.data.apellido = data['datos'].primerApellido;
    this.data.isResume = true;

    const response = await this.reconocerSrvc
      .reconoce(data['datos'].numeroSolicitud)
      .toPromise();

    this.data.setListadoCelulares(response.celulares);
    this.data.setListadoCorreos(response.correos);

    console.log({ data });

    this.resumenAdoService.getResumenAd(this.data.numeroCarpeta).subscribe(
      async (resp: any) => {
        if (resp.marcaAuditoria === 1) {
          this.dialogService.openDialog(
            'Advertencia',
            'Carpeta se encuentra en validación. Intente mas tarde',
            false
          );
          return;
        }

        switch (data['datos'].codigoEstado) {
          case 0:
            this.router.navigateByUrl('/autorizacioncentral', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 20:
            this.getinfoServ
              .getInfoClient({ carpeta: this.data.numeroCarpeta.toString() })
              .subscribe(async (resp) => {
                this.data.cellphone =
                  resp.data.resultado.infoClient.celular.toString();

                const [tries] = resp.data.resultado.tries.filter(
                  (el) => el.tipoValidacion === 4
                );
                if (tries.intentosActuales === tries.totalIntentos) {
                  this.data.isResume = false;
                }

                this.router.navigateByUrl(
                  '/' + resp.data.resultado.logClient.status_process.viewRoute,
                  {
                    state: { resp },
                  }
                );
              });
            break;
          case 30:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 40:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 100:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 50:
            this.router.navigateByUrl('/rob-analisis', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 150:
            this.router.navigateByUrl('/pre-oferta', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 200:
            this.router.navigateByUrl('/cargadocumentos', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 300:
            if (resp.seizPagaduria === 1) {
              this.router.navigateByUrl('/oferta', {
                state: { data },
              });
              break;
            }
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 600:
            this.router.navigateByUrl('/saldo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 700:
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1300:
            this.router.navigateByUrl('/pazysalvo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1200:
            // this.estado = 'resumen';
            break;
          case 530:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 960:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 970:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 531:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 990:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 980:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 550:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;

          default:
            this.dialogService.openDialog(
              'Búsqueda de cartera',
              `La carpeta se encuentra ${data.estado}`,
              false
            );
            this.isLoading = false;
            break;
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  anteriorPagina() {
    if (this.page > 1) {
      this.page--;
    }
  }

  siguientePagina() {
    if (this.pdfViewer && this.pdfViewer?.pdfViewer.pagesCount > this.page) {
      this.page++;
    }
  }

  masZoom() {
    this.zoom += 0.1;
  }

  menosZoom() {
    this.zoom -= 0.1;
  }

  downloadPDF(file: string, nombreDocumento: string) {
    const link = document.createElement('a');
    link.href = file;
    link.target = '_blank';
    link.download = nombreDocumento;
    link.click();
  }

  toggle(index: number) {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  volverAtras() {
    this.location.back();
  }
}
