import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DataService } from '../../../services/data.service';
import { VideoService } from 'src/app/services/video.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validacion-identidad',
  templateUrl: './validacion-identidad.component.html',
  styleUrls: ['./validacion-identidad.component.css'],
})
export class ValidacionIdentidadComponent implements AfterViewInit {

  @ViewChild('VideoUrlValidacion') videoUrlValidacion: ElementRef;
  @ViewChild('VideoUrlMobile') videoMibile: ElementRef;

  videoUrl = '';
  mimeType = '';
  user = '';

  constructor(
    private http: HttpClient,
    public data: DataService,
    private video: VideoService
  ) { }

  ngAfterViewInit(): void {
    this.getVideo();
  }

  gnURL(): void {
    let documentos = [];
    let splited: any;
    let splited3: any;

    splited = this.data.nombre_contrato.split('/');
    this.data.nombre_contrato = splited[splited.length - 1].split('?')[0];

    // let splited2 = this.data.nombre_pagare.split('/');
    // this.data.nombre_pagare = splited2[splited2.length - 1].split('?')[0];

    if (this.data.pagaduria_url != null) {
      splited3 = this?.data?.pagaduria_url?.split('/');
      this.data.pagaduria_url = splited3[splited3.length - 1].split('?')[0];
    }

    if (this.data.pagaduria_url == null) {
      documentos.push(this.data.nombre_contrato);
    } else if (this.data.pagaduria_url != null) {
      documentos.push(this.data.nombre_contrato);
      const arrDocument = this.data.pagaduria_url.split('.');
      documentos.push(`${arrDocument[0]}.${arrDocument[1]}`);
    }

    this.http
      .post(`${environment.ApiConecta}ado/url`, {
        cedula: this.data.cedula_ado,
        monto: Number(this.data.monto_ado),
        nui: this.data.nui,
        carpeta: this.data.carpeta_ado,
        requiere_firma: this.data.requiere_firma,
        canal: this.data.canal,
        session: this.data.session,
        documentos,
      })
      .subscribe((http: any) => {
        this.http = http;
        window.location.href = http.url;
      });
  }

  getVideo() {
    this.video.getVideoIdentidad().subscribe((data) => {
      this.mimeType = data.video.mime;
      this.videoUrl = data.video.url;
      this.videoUrlValidacion.nativeElement.src = this.videoUrl;
      this.videoMibile.nativeElement.src = this.videoUrl;
    });
  }

}
