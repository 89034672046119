import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from '../../services/perfilamiento/get-informacion.service';

import { DataService } from 'src/app/services/data.service';
import { PerfilamientoService } from 'src/app/services/perfilamiento/perf-inf-personal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registro-correo',
  templateUrl: './registro-correo.component.html',
  styleUrls: ['./registro-correo.component.css'],
})
export class RegistroCorreoComponent implements OnInit {
  public isValidConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public formInfoCorreo: FormGroup;
  loading: boolean = false;
  intentosContinuar = 0;

  constructor(
    private router: Router,
    private dialogServ: DialogService,
    private data: DataService,
    private getinfoServ: GetInformacionService,
    private perfilamientoService: PerfilamientoService
  ) {
    this.formInfoCorreo = new FormGroup({
      correo: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]),
      confirmarCorreo: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]),
    });
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    Swal.fire(
      'Función no permitida',
      'Ingresa manualmente el correo electrónico',
      'warning'
    );
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    Swal.fire(
      'Función no permitida',
      'No es permitido copiar el contenido seleccionado',
      'warning'
    );
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    Swal.fire(
      'Función no permitida',
      'No es permitido cortar el contenido seleccionado',
      'warning'
    );
    e.preventDefault();
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }
    this.data.isResume = false;
  }
  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  modalAuto(message = '') {
    Swal.fire({
      title: 'Correo incorrecto',
      allowOutsideClick: false,
      position: 'center',
      imageUrl: '../../assets/icons/error.svg',
      text: message,
      confirmButtonText: 'Volver a intentar',
      confirmButtonColor: '#009BDC',
    });
  }

  saveEmail() {
    const { correo } = this.formInfoCorreo.value;

    this.getinfoServ
      .saveCascadaMail({
        ind: 2,
        correo: correo,
        carpeta: this.data.numeroCarpeta.toString(),
        cedula: this.data.cedula.toString(),
      })
      .toPromise()
      .then(async (resp) => {
        this.data.isNewEmail = true;
        this.router.navigateByUrl('/' + resp.data.ruta);
      })
      .catch((err) => {
        this.modalAuto(err.error.message);
        this.intentosContinuar = 0;
      })
      .finally(() => {
        this.loading = false;
      });

    return true;
  }

  changeComfirmar(query) {
    const { correo } = this.formInfoCorreo.value;

    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      if (correo == query) {
        this.isValidConfirmar = false;
      } else {
        this.isValidConfirmar = true;
      }
    }, 500);
  }

  confirmacion() {
    this.loading = true;
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;
      const { correo } = this.formInfoCorreo.value;

      Swal.fire({
        allowOutsideClick: false,
        title:
          "<div style='text-align:left;font: normal normal bold 24px/24px Roboto;'>Confirmación</div>",
        html:
          "<div style='text-align:left;font: normal normal normal 16px/22px Roboto;opacity: 1;'>¿Estás seguro que <b>" +
          correo +
          '</b> es el correo electrónico que deseas registrar?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText:
          "<div style='text-align:left'>Confirmar y guardar</div>",
        confirmButtonColor: '#10AEEF',
        cancelButtonText: 'No, ajustar',
        reverseButtons: true,
        didOpen: () => {
          Swal.getCancelButton().setAttribute(
            'style',
            'color: #0FADEF;border: 1px solid #0CAEEF;background: #FFFFFF;'
          );
        },
      }).then((resultado) => {
        if (resultado.value) {
          this.loading = true;
          this.saveEmail();
        } else {
          this.loading = false;
          this.intentosContinuar = 0;
        }
      });
    } catch (error) {
      this.modalAuto(error.error.message);
      this.loading = false;
      this.intentosContinuar = 0;
    }
  }
}
