import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-aceptacion-firma',
  templateUrl: './aceptacion-firma.component.html',
  styleUrls: ['./aceptacion-firma.component.css']
})
export class AceptacionFirmaComponent implements OnInit {

  constructor(
    private router: Router,
    public data: DataService
  ) { }

  ngOnInit(): void {
  }

  evaluaPagaduria(){
    if(!this.data.firmaDigital){
      this.router.navigateByUrl('/firma-doc')
    }else{
      this.router.navigateByUrl('/pro-solicitud')
    }
  }

}
