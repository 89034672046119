<div class="main-c">
    <div mat-dialog-content>

        <div class="contenido">
            <h3>¿Estás seguro que subiste los documentos que necesitas?</h3>
            <p>Una vez enviados debes esperar nuestra respuesta para poder cargar un documento adicional.</p>
            <p>Te recomendamos revisar antes de enviarlos.</p>

            <div class="botones">
                <div class="row">
                    <button (click)="confirmarEnvio()" class="primero">Sí, ya subí lo que necesito</button>
                </div>
                <div class="row">
                    <button mat-dialog-close class="segundo">Ok, revisaré de nuevo</button>
                </div>
            </div>
        </div>
    </div>
</div>
  

