<div class="validacion-cuenta m-3">
  <h1>Validación-identidad</h1>
  <p>
    Antes de empezar te recomendamos leer atentamente las siguientes
    instrucciones.
  </p>
  <div class="row escritorio">
    <div class="col-md-4 col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="content-image">
            <img src="../../../../assets/img/cedula_frontal.png" style="width: 200px;height: 200px;"/>
          </div>
          <div class="linea"></div>
          <p class="title">Tener a la mano la cédula</p>
          <dl class="dl-horizontal">
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Cédula original y en bues estado.</p>
            </div>
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Cédula a color.</p>
            </div>
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Debes central la cédula en el cuadro.</p>
            </div>
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Evitar el uso del flash.</p>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="content-image">
            <div class="embed-responsive embed-responsive-21by9">
              <video #VideoUrlValidacion class="video-content" controls autoplay [muted]="true">
                <source [src]="videoUrl" [type]="mimeType">
              </video>
            </div>
          </div>
          <div class="linea"></div>
          <p class="title">Debes tomar la foto sin lentes</p>
          <dl class="dl-horizontal">
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Sin lentes o gafas de sol.</p>
            </div>
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p>Debe evitar usar sombreros</p>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="content-image">
            <!-- <img src="../../../../assets/img/escribiendo.png" style="width: 200px;height: 200px;"/> -->
            <img src="../../../../assets/img/Iluminacion.png" class="embed-responsive embed-responsive-21by9" style="width: 200px;height: 200px;"/>
          </div>
          <div class="linea"></div>
          <p class="title">Contar con una buena iluminación</p>
          <dl class="dl-horizontal">
            <div class="d-flex">
              <mat-icon style="color: #00C33B;">done</mat-icon>
              <p style="font-size: 10px;">Usar un fondo claro.</p>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div id="carouselExampleIndicators" class="carousel d-sm-none mobile" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>

    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="img">
          <img src="../../../../assets/img/cedula_frontal.png" alt="First slide">
        </div>
        <div class="carousel-caption uno">
          <div class="caption">
            <p class="title">Tener a la mano la cédula</p>
            <dl>
              <div class="d-flex">
                <mat-icon style="color: #00C33B;">done</mat-icon>
                <p>Cédula original y en bues estado.</p>
              </div>
              <div class="d-flex">
                <mat-icon style="color: #00C33B;">done</mat-icon>
                <p>Cédula a color.</p>
              </div>
              <div class="d-flex">
                <mat-icon style="color: #00C33B;">done</mat-icon>
                <p>Debes central la cédula en el cuadro.</p>
              </div>
              <div class="d-flex">
                <mat-icon style="color: #00C33B;">done</mat-icon>
                <p>Evitar el uso del flash.</p>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="img">
            <video #VideoUrlMobile class="video-content" controls autoplay [muted]="true" alt="Second slide" height="250">
              <source [src]="videoUrl" [type]="mimeType">
            </video>
        </div>
        <div class="carousel-caption dos">
          <div class="caption">
            <p class="title">Debes tomar la foto sin lentes</p>
            <dl>
              <div class="d-flex">
                <mat-icon style="color: #00C33B;">done</mat-icon>
                <p>Usar un fondo claro.</p>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="img">
          <img src="../../../../assets/img/Iluminacion.png" alt="Third slide">        </div>
          <div class="carousel-caption tres">
            <div class="caption">
              <p class="title">Contar con una buena iluminación</p>
              <dl>
                <div class="d-flex">
                  <mat-icon style="color: #00C33B;">done</mat-icon>
                  <p>Sin lentes o gafas de sol.</p>
                </div>
                <div class="d-flex">
                  <mat-icon style="color: #00C33B;">done</mat-icon>
                  <p>Debe evitar usar sombreros.</p>
                </div>
              </dl>
            </div>
          </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div class="btn-action">
    <button class="btn btn-primary" (click)="gnURL()">Continuar</button>
  </div>

</div>
