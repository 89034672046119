import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SolicitudInfo } from '../../models/solicitudInfo.interface';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PerfInfSolicitudService {

  private endpoint = environment.ApiConecta+'perfilamiento/informacion/solicitud';

  constructor(
    private http:HttpClient
  ) { }

  savInfSol(solicitudInfo:SolicitudInfo): Observable<any> {
    return this.http.post<SolicitudInfo>(this.endpoint,solicitudInfo);
  }

  getCompraCartera(numeroSolicitud: number): Observable<any> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/obtener/compras-cartera`, {
      numeroSolicitud
    });
  }

  getRefinanciacion(numeroSolicitud: number): Observable<any> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/obtener/refinanciacion`, {
      numeroSolicitud
    });
  }

}
