import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { DialogService } from 'src/app/services/dialog.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { DataService } from '../../services/data.service';
import { DataOfertaService } from '../../services/genOferta/dataOferta.service';

import { Condicion } from 'src/app/models/condicion';
import { Oferta } from 'src/app/models/oferta';

import Swal from 'sweetalert2';
import { DialogModificaComponent } from './dialog-modifica/dialog-modifica.component';
import { DialogRechazaComponent } from './dialog-rechaza/dialog-rechaza.component';

@Component({
  selector: 'app-pre-oferta',
  templateUrl: './pre-oferta.component.html',
  styleUrls: ['./pre-oferta.component.css'],
})
export class PreOfertaComponent implements OnInit {
  formularios: { name: string; open: boolean }[] = [
    { name: 'collapseOne', open: false },
    { name: 'collapselaboral', open: false },
    { name: 'collapseFinanciera', open: false },
    { name: 'collapseSolicitud', open: false },
  ];

  tasas: Array<any> = [];
  ofertas: any;
  tasaSeleccionada: number = 0;
  seguroVoluntario: any = null;
  condicion: any = {};
  montoMaximo: number | string;
  montoMinimo: number | string;
  continuarSinSeguro: boolean = false;
  credito: string = '2';

  isLoaded: boolean = false;
  disabledCheck: boolean = false;
  collapsePoli: string = 'false';

  constructor(
    private geofertaServc: GenOfertaService,
    public dialog: MatDialog,
    public data: DataService,
    public dataOferta: DataOfertaService,
    private dialogSrvc: DialogService,
    private cambioEstadoServ: CambioestadocarpetaService,
    private route: Router
  ) {}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }

    if (!this.dataOferta.tasas) {
      this.solicitarTasa(this.data.numeroCarpeta);
    } else {
      this.tasas = this.dataOferta.tasas;
      this.tasaSeleccionada =
        this.tasaSeleccionada == 0 ? this.tasas[0].tasa : this.tasaSeleccionada;
    }

    if (!this.dataOferta.oferta) {
      this.generarOferta(this.data.numeroCarpeta);
    } else {
      this.ofertas = this.dataOferta.oferta;
      this.montoMaximo = this.ofertas.montoMaximo;
      this.montoMinimo = this.ofertas.monto;
    }

    this.seguroVoluntario = this.dataOferta.seguroVoluntario;

    if (this.dataOferta.ajustarCondiciones) {
      this.dataOferta.ajustarCondiciones = false;
      this.ofertas = null;
      this.cambioCondiciones(false);
    }
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }
  private solicitarTasa(numeroCarpeta: string): void {
    this.geofertaServc.solicitudTasa(numeroCarpeta).subscribe(
      (res) => {
        this.tasas = res.response.rows;
        this.tasas = this.tasas.filter((valorActual, indiceActual, arreglo) => {
          return (
            arreglo.findIndex(
              (valorDelArreglo) =>
                JSON.stringify(valorDelArreglo) === JSON.stringify(valorActual)
            ) === indiceActual
          );
        });

        this.tasaSeleccionada =
          this.tasaSeleccionada == 0
            ? this.tasas[0].tasa
            : this.tasaSeleccionada;

        this.dataOferta.tasas = this.tasas;
      },
      (err) => {
        this.dialogSrvc.openDialog('Advertencia', err.error.message, false);
      }
    );
  }
  public collapse() {
    var elem = document.querySelector('.politicasColl');
    var b = elem.getAttribute('aria-expanded');
    this.collapsePoli = b;
  }

  private generarOferta(numeroCarpeta: string): void {
    if (!this.ofertas) {
      this.geofertaServc.genOferta(numeroCarpeta).subscribe(
        (res) => {
          this.dataOferta.oferta = res;
          this.dataOferta.montoSolicitado = res.monto;

          let segurosIndividuales: any = [];

          if (res.ofertasSeguro.length > 0) {
            res.ofertasSeguro.forEach((oferta: any) => {
              if (oferta.seguroFamiliar.length == 0) {
                segurosIndividuales.push(oferta);
              }
            });
          } else {
            console.log('No hay ofertas de seguro');
          }

          if (!this.seguroVoluntario) {
            //  Ordenar, prima más alta primero
            segurosIndividuales.sort((a: any, b: any) => {
              if (a.primaDiaria < b.primaDiaria) {
                return 1;
              }

              if (a.primaDiaria > b.primaDiaria) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });

            this.seguroVoluntario = segurosIndividuales[0];
            this.dataOferta.seguroVoluntario = this.seguroVoluntario;

            if (this.seguroVoluntario) {
              this.crearPoliza();
            }

            this.cambioCondiciones(false);
          }
        },
        (err) => {
          this.dialogSrvc.openDialog(
            'Advertencia',
            err.error.error.message,
            false
          );
        }
      );
    }
  }

  public cambioCondiciones(
    showConfirmation: boolean,
    retakeSecure: boolean = false
  ): void {
    this.disabledCheck = true;

    let valSeg = 0;

    if (!this.continuarSinSeguro && this.seguroVoluntario) {
      valSeg = this.seguroVoluntario.valorSeguro;
    }

    let condicion: Condicion = {
      monto: Number(this.dataOferta.montoSolicitado),
      tasa: this.tasaSeleccionada,
      plazo: this.dataOferta.oferta.plazo,
      numeroCarpeta: parseInt(this.data.numeroCarpeta),
      valorSeguro: retakeSecure ? 0 : valSeg,
    };

    this.geofertaServc.cambioCondiciones(condicion).subscribe(
      (res) => {
        this.dataOferta.ofertasCambioCondicion = res.ofertas;
        this.ofertas = this.dataOferta.oferta;
        this.ofertas.monto = res.montoSeguro;
        this.montoMinimo = res.montoSeguro;
        this.ofertas.cuota = res.valorCuotaSeguro;

        let nuevaOferta = this.dataOferta.ofertasCambioCondicion.find(
          (oferta: any) => oferta.tasa == this.tasaSeleccionada
        );

        this.ofertas.cuotaMaxima = nuevaOferta.cuotaSugerida;
        this.ofertas.montoMaximo = nuevaOferta.valorCreditoSegerido;
        this.ofertas.plazoMaximo = nuevaOferta.plazo;
        this.ofertas.porcentajeCorretaje = nuevaOferta.porcentajeCorretaje;
        this.ofertas.comisionMillon = nuevaOferta.valorComision;
        this.dataOferta.oferta = this.ofertas;

        this.disabledCheck = false;

        if (showConfirmation) {
          this.dialogSrvc.openDialog(
            'Hecho',
            'Cambio de condiciones realizado con éxito.',
            true
          );
        }
      },
      (err) => {
        this.ofertas = null;
        this.disabledCheck = false;
        this.dialogSrvc.openDialog('Advertencia', err.error.message, false);
      }
    );
  }

  public aceptarOferta(): void {
    this.isLoaded = true;

    /*Traer el monto y plazo solicitado desde el formulario de perfilamiento*/
    let oferta: Oferta = {
      cuota:
        this.credito == '1' ? this.ofertas.cuota : this.ofertas.cuotaMaxima,
      //cuota: this.credito ? this.ofertas.cuota : this.ofertas.cuotaMaxima,
      monto:
        this.credito == '1' ? this.ofertas.monto : this.ofertas.montoMaximo,
      //monto: this.credito ? this.ofertas.monto : this.ofertas.montoMaximo,
      montoSolicitado:
        this.credito == '1' ? this.ofertas.monto : this.ofertas.montoMaximo,
      //montoSolicitado: this.credito ? this.ofertas.monto : this.ofertas.montoMaximo,
      numeroCarpeta: parseInt(this.data.numeroCarpeta),
      plazo:
        this.credito == '1' ? this.ofertas.plazo : this.ofertas.plazoMaximo,
      //plazo: this.credito ? this.ofertas.plazo : this.ofertas.plazoMaximo,
      plazoSolicitado:
        this.credito == '1' ? this.ofertas.plazo : this.ofertas.plazoMaximo,
      //plazoSolicitado: this.credito ? this.ofertas.plazo : this.ofertas.plazoMaximo,
      tasa: this.tasaSeleccionada,
      tipoOferta: this.credito,
      valorSeguro: this.continuarSinSeguro
        ? 0
        : this.seguroVoluntario.valorSeguro,
      codigoPlan: this.continuarSinSeguro
        ? 0
        : this.seguroVoluntario.codigoPlan,
    };

    this.geofertaServc.aceptarOferta(oferta).subscribe(
      (res) => {
        this.cambioEstadoServ
          .cambioEstadocarpeta(
            Number(this.data.numeroCarpeta),
            '200',
            'Cambio de estado aprobado por el Asesor'
          )
          .subscribe(
            (res) => {
              this.cleanData();

              this.isLoaded = false;

              this.route.navigateByUrl('/cargadocumentos', {
                state: {
                  numeroSolicitud: this.data.numeroSolicitud,
                  numeroCarpeta: this.data.numeroCarpeta,
                },
              });
            },
            (err) => {
              const message = err.error.message.split('- ')[1];

              this.isLoaded = false;

              this.mostrarModal(message, err);
            }
          );
      },
      (err) => {
        this.isLoaded = false;
        this.dialogSrvc.openDialog('Advertencia', err.error.message, false);
      }
    );
  }

  public modificaMonto(): void {
    if (this.tasaSeleccionada != 0) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.data = {
        monto: this.ofertas.monto,
        plazo: this.ofertas.plazo,
        cuota: this.ofertas.cuota,
      };

      const dialogRef = this.dialog.open(DialogModificaComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((res) => {
        if (res.updates) {
          this.cambioCondiciones(res.updates);
        }
        /*
        if (res) {
          console.log('Nuevas condiciones',res);

          this.montoMinimo = res.montoMinimo;
          this.ofertas.monto = res.res.montoSeguro;
          this.ofertas.plazo = res.data.plazo,
          this.ofertas.montoSeguro = res.res.montoSeguro;
          this.ofertas.cuotaSeguro = res.res.valorCuotaSeguro;
          this.ofertas.cuota = res.res.valorCuotaSeguro;
          this.ofertas.comisionMillon = res.res.comisionMillon;
          this.dataOferta.oferta = this.ofertas;
          this.dataOferta.ofertasCambioCondicion = res.res.ofertas;

          this.cambiarTasa(this.tasaSeleccionada)
          this.dialogSrvc.openDialog("Hecho", 'Cambio de condiciones realizado con éxito.', true);
        }
        */
      });
    } else {
      alert('debe seleccionar tasa');
    }
  }

  public rechazaOferta(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      numeroCarpeta: this.data.numeroCarpeta,
    };

    const dialogRef = this.dialog.open(DialogRechazaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  public cambiarTasa(tasa: number, id: string, opuesto: string = ''): void {
    if (id === 'flexi1') {
      document.getElementById('flexi0').classList.remove('normal');
    }
    if (id === 'flexi0') {
      document.getElementById('flexi1').classList.remove('normal');
    }
    document.getElementById(id).classList.add('normal');
    this.tasaSeleccionada = tasa;
    this.cambioCondiciones(false);
  }

  private crearPoliza(): void {
    this.geofertaServc
      .checkPoliza(
        this.data.numeroCarpeta,
        Number(this.dataOferta.seguroVoluntario.codigoPlan)
      )
      .subscribe(
        (res) => {
          console.log('poliza creada', res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public irSeguroVoluntario(): void {
    this.route.navigateByUrl('/segurovoluntario', {
      state: {
        back: '/pre-oferta',
      },
    });
  }

  public ajustarCondiciones(): void {
    this.geofertaServc.eliminarPoliza(this.data.numeroCarpeta).subscribe(
      (resp) => {
        if (resp.status === 200) {
          this.cambioEstadoServ
            .cambioEstadocarpeta(
              Number(this.data.numeroCarpeta),
              '40',
              'Cambio de estado aprobado por el Asesor'
            )
            .subscribe(
              () => {
                this.cleanData();

                this.route.navigateByUrl('/formulario-perfilamiento', {
                  state: {
                    numeroSolicitud: this.data.numeroSolicitud,
                    numeroCarpeta: this.data.numeroCarpeta,
                  },
                });
              },
              (error) => {
                console.log(error);
              }
            );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public continuarSinSeguroFunc(): void {
    this.cambioCondiciones(true, true);
    this.onDeletePoliza();
  }

  private onDeletePoliza(): void {
    if (this.continuarSinSeguro) {
      this.geofertaServc.eliminarPoliza(this.data.numeroCarpeta).subscribe();
    } else {
      this.geofertaServc.crearPoliza(this.data.numeroCarpeta, 22).subscribe(
        (res) => {
          this.ofertas = null;
          this.seguroVoluntario = null;
          this.generarOferta(this.data.numeroCarpeta);
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  private cleanData(): void {
    this.dataOferta.tasas = null;
    this.dataOferta.oferta = null;
    this.dataOferta.ofertasCambioCondicion = null;
    this.dataOferta.condicion = null;
    this.dataOferta.seguroVoluntario = null;
    this.dataOferta.ajustarCondiciones = false;
    this.dataOferta.montoSolicitado = '';
  }

  mostrarModal(message = '', err: any) {
    Swal.fire({
      allowOutsideClick: false,
      title: "<div style='text-align:left'>Advertencia</div>",
      html: `<div style="text-align:left">
        ${message}
      </div>`,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0CAEEF',
      didOpen: () => {
        const modalAction = document.querySelector(
          '.swal2-actions'
        ) as HTMLElement;

        modalAction.style.width = '100%';
        modalAction.style.justifyContent = 'end';
        modalAction.style.paddingRight = '1rem';
      },
    }).then((resultado) => {
      if (resultado.value) {
        if (err.error.message.toLowerCase().includes('(checkaudit)')) {
          this.route.navigate(['/home']);
        }
      }
    });
  }
}
