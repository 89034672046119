import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly api = `${environment.ApiConecta}news`;
  newsActive: EventEmitter<{url:string, name: string}> = new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  getSections(): Observable<any> {
    return this.http.get<any>(`${this.api}/sections`);
  }

  getUrlsBySection(body: { tipo_asesor: string, seccion: string }): Observable<any> {
    return this.http.post<any>(`${this.api}/urls-section`, body);
  }

  getUrls(body: { tipo_asesor: string }): Observable<any> {
    return this.http.post<any>(`${this.api}/urls`, body);
  }

}
