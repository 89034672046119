import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.scss'],
})
export class ValidacionComponent {
  errorValidacion = false;
  bloqueado = false;
  tiempoBloqueo = 60000; // Tiempo en milisegundos
  numeroCarpeta: string = '';
  cellphone: string = '';
  cedula: number = 0;
  regValidacion: string = '';
  numeroSolicitud: string = '';
  idTransaccionOTP: string = '';
  code: string = '';
  carpeta: string = '';
  correo: '';
  celular: string = '';
  enviarOtp = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  validarOtp = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  cambioCelular = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  tiempoRestante: number = 60;
  intervalId: any;
  isLoading = false;
  intentosContinuar = 0;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    private getinfoServ: GetInformacionService,
    private cambioEstadoServ: CambioestadocarpetaService,
    public dialogService: DialogService
  ) { }

  ngAfterViewInit(): void {


    if (this.data.isUpdateMobile) {
      this.iniciarTemporizadorBloqueo();
    }

    let state = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );
    this.numeroCarpeta = this.data.numeroCarpeta;
    this.getinfoServ
      .getInfoClient({ carpeta: this.numeroCarpeta.toString() })
      .subscribe(
        (resp) => {
          this.carpeta = resp.data.resultado.infoClient.carpeta;
          this.celular = resp.data.resultado.infoClient.celular;

          this.correo = resp.data.resultado.infoClient.correo;
          this.enviarOtp = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 4
          )[0];
          this.validarOtp = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 2
          )[0];
          this.cambioCelular = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 5
          )[0];

          this.cedula = resp.data.resultado.infoClient.identificacion;
          this.cellphone = resp.data.resultado.infoClient.celular;
          this.numeroSolicitud = this.data.numeroSolicitud;
          resp.data.resultado.tries.forEach((element) => {
            if (element.tipoValidacion == 4) {
              this.idTransaccionOTP = element.intentos;
            }
          });
          if (this.cambioCelular.intentosActuales !== 0) {
            if (!this.data.isResume) {
              this.dialogService.openDialog(
                'Enviar el código',
                `El solicitante recibirá un SMS con el código de verificación al celular <b>${this.cellphone}</b> para iniciar su proceso de realizar la autorización de consulta en centrales de riesgos.<br/><br/><br/>
                <strong class="text-center">Intento ${this.enviarOtp.totalIntentos - this.enviarOtp.intentosActuales + 1} de ${this.enviarOtp.totalIntentos}</strong>`,
                true,
                'Enviar código',
                () => {
                  this.iniciarTemporizadorBloqueo();
                  this.getinfoServ
                    .sendOtpCode({
                      carpeta: this.carpeta,
                      celular: this.celular,
                      ind: 0,
                    })
                    .subscribe(
                      async () => {
                        await this.consultarIntentos();
                      },
                      (error) => {
                        console.log(error.error.message);
                      }
                    );
                }
              )
            }
          }
        },
        (error) => {
          console.log('Falle traer informacion cliente' + error);
        }
      );
  }


  onCodeCompleted(code: string) {
    this.code = code;
  }
  onCodeChanged(code: string) {
    this.code = code;
  }

  async consultarIntentos() {
    const data = await this.getinfoServ
      .getInfoClient({ carpeta: this.numeroCarpeta.toString() })
      .toPromise();

    this.carpeta = data.data.resultado.infoClient.carpeta;
    this.celular = data.data.resultado.infoClient.celular;

    this.enviarOtp = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 4
    )[0];
    this.validarOtp = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 2
    )[0];
    this.cambioCelular = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 5
    )[0];
  }

  async reenviarCodigo() {
    if (this.enviarOtp.intentosActuales === 0) {
      await this.rechazarCarpeta(this.carpeta.toString());
      this.carpetaRechazada('reenvío');
      return;
    }

    let mensaje = `El solicitante recibirá nuevamente un SMS con el código al celular <b>${this.cellphone}</b> para realizar la autorización de consulta en centrales de riesgos.`;
    if (this.enviarOtp.intentosActuales === 1) {
      mensaje = `Te queda solo un reintento más para enviar el mensaje SMS al celular <b>${this.cellphone}</b>. Si deseas registrar un nuevo número celular selecciona la opción “<b>Actualizar Celular</b>” en este momento.`;
    }
    if (this.cambioCelular.intentosActuales === 0 && this.enviarOtp.intentosActuales === 1) {
      mensaje = `Te queda un solo reintento para enviar el mensaje SMS al celular <b>${this.cellphone}</b>, si no coincide el código, la carpeta será rechazada.`;
    }

    this.dialogService.openDialog(
      'Reenviar el código',
      this.enviarOtp.intentosActuales === 1
        ? `${mensaje}<br/><br/><br/><strong class="text-center">Último intento<strong>`
        : `${mensaje}<br/><br/><br/><strong class="text-center">Intento ${this.enviarOtp.totalIntentos - this.enviarOtp.intentosActuales + 1
        } de ${this.enviarOtp.totalIntentos}</strong>`,
      true,
      'Reenviar código',
      () => {
        this.iniciarTemporizadorBloqueo();
        this.getinfoServ
          .sendOtpCode({
            carpeta: this.carpeta,
            celular: this.celular,
            ind: 1,
          })
          .subscribe(
            async () => {
              await this.consultarIntentos();
            },
            (error) => {
              console.log(error.error.message);
            }
          );
      },
      this.enviarOtp.intentosActuales === 1 && this.cambioCelular.intentosActuales != 0,
      'Actualizar celular',
      () => {
        this.getinfoServ
          .saveCascadaPhone({
            ind: 3,
            celular: this.celular.toString(),
            carpeta: this.data.numeroCarpeta.toString(),
            cedula: this.data.cedula.toString(),
          })
          .subscribe(
            (resp) => {
              this.router.navigateByUrl('/' + resp.data.ruta);
            },
            (error) => {
              console.log('Falle guradado de respuesta celular' + error);
            }
          );
      }
    )
  }

  async validatOtp() {
    this.isLoading = true;

    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      let otp = {
        carpeta: this.carpeta.toString(),
        celular: this.cellphone.toString(),
        codigo: this.code,
      };
      const response = await this.getinfoServ.getvalidateOtp(otp).toPromise();

      const isCorrect = response.data.status;

      if (!isCorrect) {
        await this.consultarIntentos();
      }

      if (this.validarOtp.intentosActuales === 0) {
        await this.rechazarCarpeta(this.carpeta.toString());
        this.carpetaRechazada('validación');
        return;
      }

      if (!isCorrect) {
        this.visibilidadMensajeError();
        this.intentosContinuar = 0;
      }

      if (isCorrect) {
        this.data.isResume = false;
        this.data.isNewEmail = false;
        this.data.isNewPhone = false;

        this.router.navigate(['formulario-perfilamiento']);
      }
    } catch (error) {

      this.dialogService.openDialog("Error", 'ocurrio un problema', false)
      this.intentosContinuar = 0;
    } finally {
      this.isLoading = false;
    }
  }

  alertaError() {
    this.dialogService.openDialog(
      'Verificación no exitosa',
      `El código digitado es incorrecto. A continuación, puedes reenviar el código al solicitante nuevamente por medio de SMS al celular ${this.cellphone}.<br/><br/><br/> Intento ${this.enviarOtp.totalIntentos - this.enviarOtp.intentosActuales + 1} de ${this.enviarOtp.totalIntentos}`,
      true,
      'Reenviar código',
      async () => {
        this.code = '';
        await this.consultarIntentos();
        this.getinfoServ
          .sendOtpCode({
            carpeta: this.carpeta,
            celular: this.celular,
            ind: 1,
          })
          .subscribe(
            async () => {
              await this.consultarIntentos();
            },
            (error) => {
              console.log(error.error.message);
            }
          );
      },
      this.validarOtp.totalIntentos - this.validarOtp.intentosActuales == 1,
      'Actualizar celular',
      () => {
        this.router.navigate(['/modificarcelular']);
      }
    )
  }

  carpetaRechazada(tipo = '') {
    this.dialogService.openDialog(
      'Advertencia',
      'Se superó el número de intentos para validar el <b>código</b>. La carpeta ha sido rechazada.',
      false,
      'Ir a inicio',
      () => {
        this.router.navigate(['home']);
      })
  }

  iniciarTemporizadorBloqueo() {
    this.bloqueado = true;
    this.iniciarCronometro();

    setTimeout(() => {
      this.bloqueado = false;
    }, this.tiempoBloqueo);
  }

  iniciarCronometro() {
    this.intervalId = setInterval(() => {
      this.tiempoRestante = Math.max(0, this.tiempoRestante - 1);

      if (this.tiempoRestante === 0) {
        this.detenerCronometro();
      }
    }, 1000);
  }

  detenerCronometro() {
    clearInterval(this.intervalId);
    this.tiempoRestante = 60;
    this.data.isUpdateMobile = false;
  }

  async rechazarCarpeta(carpeta = '') {
    await this.cambioEstadoServ
      .cambioEstadocarpeta(
        Number(this.carpeta),
        '900000',
        'Rechazo por intentos'
      )
      .toPromise();

    await this.getinfoServ.rejectFolder({ carpeta }).toPromise();
  }

  visibilidadMensajeError() {
    this.errorValidacion = true;
    // setTimeout(() => {
    //   this.errorValidacion = false;
    // }, 5000);
  }
}
