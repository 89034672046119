import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';

@Component({
  selector: 'app-firma-doc',
  templateUrl: './firma-doc.component.html',
  styleUrls: ['./firma-doc.component.css'],
})
export class FirmaDocComponent {
  constructor(
    private cambioEstadoServ: CambioestadocarpetaService,
    private router: Router,
    private data: DataService
  ) { }

  public rules = ["El archivo que selecciones no debe superar los <strong>5MB</strong>.", "Puedes subir <strong>PDF, PNG y JPG</strong>."]
  public title = "Documentos contrato"
  public subtitle = "Contrato, autorización de libranza y seguro<br> de accidente, libranza y Libraval"
  public contract = true
  public secuencia = 69
  public idFolder = 2
  public identificacion = "certification"
  public nombreArchivoFinal = "PORTAL-documentosAdicionales"

  cambiarEstado() {
    this.cambioEstadoServ
      .cambioEstadocarpeta(
        Number(this.data.numeroCarpeta),
        '992',
        'Cambio de estado aprobado por el Asesor'
      )
      .subscribe((res) => {
        this.router.navigateByUrl('pro-solicitud');
      },error=>{
        console.log(<any>error);
      });
  }
}
