import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RcActualizaPass } from '../../models/rcActualizaPass.interface';

@Injectable({
  providedIn: 'root',
})
export class RcActualizaPassService {
  private endpoint = environment.ApiConecta;

  constructor(private http: HttpClient) { }

  actualizarPassword(
    canal: string,
    usuario: string,
    contrasena_nueva: string,
    otp: string
  ): Observable<RcActualizaPass> {
    console.log(usuario, contrasena_nueva);

    return this.http.post<RcActualizaPass>(
      this.endpoint + 'account/update-pwd',
      { canal, usuario, contrasena_nueva, otp }
    );
  }

  validationPassword(
    cedula: string,
    claveActual: string,
    claveNueva: string,
    operacion: number
  ): Observable<any> {
    return this.http.post<RcActualizaPass>(this.endpoint + 'validationPass', {
      cedula,
      claveActual,
      claveNueva,
      operacion,
    });
  }
}
