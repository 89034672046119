<button [type]="type" class="boton btn-hover-wave mx-2 my-1"
  [ngClass]="[customClass, color, size, errorAnimationClass, loading ? 'loading' : '', compressed ? 'compressed' : '', block ? 'block': '']"
  [disabled]="disabled && !loading" (click)="onClick()">
  <ng-container *ngIf="!loading">
    <ng-content></ng-content> <!-- Texto o contenido del botón -->
  </ng-container>
  <ng-container *ngIf="loading">
    <span class="spinner"></span> <!-- Spinner -->
  </ng-container>
</button>
