<app-main-container header="Validación de identidad"
  subheader="Autorización manejo de datos personales y centrales de riesgo">
  <div class="p-md-4 pt-3">
    <p>
      Ingresa código de verificación de 6 dígitos enviado al solicitante al número
      celular
      {{ cellphone }}.
    </p>
    <div class="d-inline">
      <code-input class="code-input" id="otp" #input (codeCompleted)="onCodeCompleted($event)"
        (codeChanged)="onCodeChanged($event)" [isCodeHidden]="false" [isNonDigitsCode]="false" [codeLength]="6"
        [isCharsCode]="false">
      </code-input>
    </div>
    <mat-error class="error" *ngIf="errorValidacion" style="color: #f44336;font: 16px/24px;margin-left: -17px;">
      <i class="fa-solid fa-circle-xmark"></i> El código digitado es incorrecto. Verifica el código y regístralo de
      nuevo, tienes un último intento
    </mat-error>
    <div class="row">
      <p style="margin-top: 60px" *ngIf="enviarOtp.intentosActuales != 0">
        ¿No fue recibido el código?
        <a class="link" role="button" (click)="reenviarCodigo()" [ngStyle]="{
          'pointer-events': bloqueado ? 'none' : 'auto',
          color: bloqueado ? '#10AEEF' : '#10AEEF',
          opacity: bloqueado ? '.3' : '1'
        }">
          Reenviar código
          <span *ngIf="tiempoRestante !== 60">en {{ tiempoRestante }}s</span>
        </a>
      </p>
      <p style="margin-top: 60px" *ngIf="enviarOtp.intentosActuales == 0 && enviarOtp.totalIntentos != 0">
        Último intento, los intentos de reenvío fueron superados.
      </p>
    </div>
    <button-look-feel color="secondary" [disabled]="code.length !== 6" [loading]="isLoading"
      (clicked)="validatOtp()">Validar código</button-look-feel>
  </div>
</app-main-container>
