import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../services/data.service';
import { BuscCarpetaService } from '../../../services/perfilamiento/busc-carpeta.service';

@Component({
  selector: 'app-pro-solicitud',
  templateUrl: './pro-solicitud.component.html',
  styleUrls: ['./pro-solicitud.component.scss'],
})
export class ProSolicitudComponent implements OnInit {

  nomClient: string;

  constructor(public data: DataService, public resumen: BuscCarpetaService) { }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    this.nameClient({ numeroCarpeta: this.data.numeroCarpeta });
  }


  public nameClient(data: { numeroCarpeta: string | Number }): void {
    this.resumen.getResultadosBusqueda(data).subscribe(({ rows }) => this.nomClient = rows[0].nombreCliente);
  }



}
