  <!--app-header></app-header-->

  <div class="">
    <div class="cont-form">
      <div class="col-sm-12 col-md-12 col-lg-12">
      <form [formGroup]="recuperacionForm">
        <h1>Cambio de contraseña</h1>
        <div class="d-flex flex-column  ">
            <h4>Contraseña actual</h4>
            <mat-form-field class="example-full-width amb-45" appearance="outline">
              <input
                [type]="showActual ? 'text':'password'"
                matInput
                class="w-100"
                name="actual"
                formControlName="actual"
                maxlength="15"
              />
              <mat-icon matSuffix (click)="showActual = !showActual">{{
                showActual ? "visibility" : "visibility_off"
              }}</mat-icon>
              <mat-error *ngIf="recuperacionForm.controls.actual.hasError('required')">
                La contraseña actual es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
        </div>
        <p class="amb-20">Crea tu nueva contraseña</p>
        <div class="resp-mov">
          <div class="d-flex flex-column nueva">
            <h4>Nueva contraseña</h4>
            <mat-form-field class="example-full-width amb-28" appearance="outline">
              <input
                matInput
                [type]="showPassword ? 'text':'password'"
                class="w-100"
                name="nueva"
                formControlName="nueva"
                maxlength="15"
                (ngModelChange)="showValid($event)"
              />
              <mat-icon matSuffix (click)="showPassword = !showPassword">{{
                  showPassword ? "visibility" : "visibility_off"
                }}</mat-icon>
              <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('required')">
                  La contraseña es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
            <div class="aw-validaciones">
              <div class="validaciones" *ngIf="showV">

                <div class="indicador-box">
                  <div class="indicador" [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('minlength')  ? 'indicador' : 'indicador-selected'">
                    <span class="material-icons afontsize-16">done</span>
                  </div>
                  <span class="indicador-label">Al menos 8 caractéres</span>
                </div>

                <div class="indicador-box">
                  <div class="indicador" [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasCapitalCase')  ? 'indicador' : 'indicador-selected'">
                    <span class="material-icons afontsize-16">done</span>
                  </div>
                  <span class="indicador-label">Al menos 1 mayúscula</span>
                </div>

                <div class="indicador-box" style="margin-right: 32px;">
                  <div class="indicador" [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasNumber')  ? 'indicador' : 'indicador-selected'">
                    <span class="material-icons afontsize-16">done</span>
                  </div>
                  <span class="indicador-label">Al menos 1 número</span>
                </div>

                <div class="indicador-box amr-4">
                  <div class="indicador" [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasSmallCase')  ? 'indicador' : 'indicador-selected'">
                    <span class="material-icons afontsize-16">done</span>
                  </div>
                  <span class="indicador-label">Al menos 1 minúscula</span>
                </div>

                <div class="indicador-box amb-15">
                  <div class="indicador" [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasSpecialCharacters')  ? 'indicador' : 'indicador-selected'">
                    <span class="material-icons afontsize-16">done</span>
                  </div>
                  <span class="indicador-label">Al menos 1 caractér especial (+*%$_)</span>
                </div>

                <div *ngIf="recuperacionForm.invalid && showError" class="create-account-error animate__animated animate__fadeIn">
                  <p class="error">La contraseña ingresada no cumple con los requisitos necesarios. Revisa nuevamente</p>
                </div>

              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
              <h4>Confirmar contraseña</h4>
              <mat-form-field class="example-full-width" appearance="outline">
                <input
                  matInput
                  [type]="showConfirmar ? 'text':'password'"
                  class="w-100"
                  name="confirmar"
                  formControlName="confirmar"
                  maxlength="15"
                  #txtQuery
                  (keyup)="changeComfirmar(txtQuery.value)">

                  <mat-icon matSuffix (click)="showConfirmar = !showConfirmar">{{
                    showConfirmar ? "visibility" : "visibility_off"
                  }}</mat-icon>

                <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('required')">
                  Confirmar contraseña es <strong>requerida</strong>
                </mat-error>
              </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="submit"
            [disabled]="!recuperacionForm.valid" id="recuperar"
            class="btn btn-primary w-100" (click)="changePassword()"
            [ngClass]="recuperacionForm.valid ? 'btn-primary:disabled' : 'btn-primary:active'"
          >
              Cambiar contraseña
          </button>
        </div>
      </form>
      </div>
    </div>
  </div>
