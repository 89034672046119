import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import jwt_decode from "jwt-decode";
import { NewsService } from 'src/app/services/news.service';
import { environment } from "../../../environments/environment";
import { fadeIn } from 'src/app/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeIn]
})
export class HomeComponent implements OnInit {
  @ViewChild('VideoSrc') videoSrc: ElementRef;

  public produccion = environment.production
  user = "";
  user_id: string;
  dashboard: string;
  politicas: string;
  showNews: { url: string, name: string } = { url: '', name: '' };

  constructor(private _newsService: NewsService) {
    this._newsService.newsActive.subscribe(
      news => {
        this.showNews = news;
        console.log('news: ', { news })
      }
    );
  }
  ngOnInit(): void {
      this.nombreUsuario();
  }

  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    this.user_id = decoded['sub'];
    localStorage.setItem('tpcnl', decoded['tpcnl'].toLowerCase());
    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`
    } else {
      this.user = `${this.user[0]}`
    }

  }
}
