<div class="message-simple-c">

  <div class="all-notifications" (click)="back()">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <a role="button">Todas las notificaciones</a></div>
    
  <h3>{{cedula}}-{{nombreCliente}}-{{numeroCarpeta}}</h3>
  <div class='separador'></div>
  <p class="prfo">
    A continuación, encontrarás la acción a ejecutar para continuar con el proceso de crédito.
  </p>

  <p class="prfo">{{ mensaje }}</p>

  <!-- <p class="prfo">2)Tell us about one of the most impactful learning experiences you´ve had (inperson, online, webinar, etc.)</p> -->

  <div class="actions">
    <p class="prfo">
      Agrega tus documentos dando click en
    </p>
    <button (click)="upArchivos()">
      +
    </button>
  </div>
</div>