import { Component, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from '../../services/perfilamiento/get-informacion.service';

@Component({
  selector: 'app-registro-celular',
  templateUrl: './registro-celular.component.html',
  styleUrls: ['./registro-celular.component.scss'],
})
export class RegistroCelularComponent {
  url = '';
  public isValidConfirmar: boolean = false;
  private debounceTime?: NodeJS.Timeout;
  public formInfoTele: FormGroup;
  loading: boolean = false;
  intentosContinuar = 0;

  constructor(
    private router: Router,
    private dialogServ: DialogService,
    private data: DataService,
    private getinfoServ: GetInformacionService,
  ) {
    this.formInfoTele = new FormGroup({
      telefono: new FormControl('', [
        Validators.required, Validators.pattern('3[0-9]{9}'),
      ]),
      confirmarTelefono: new FormControl('', [
        Validators.required, this.celularIgualValidator.bind(this)
      ]),
    });
    this.formInfoTele.get('telefono')?.valueChanges.subscribe(() => {
      this.formInfoTele.get('confirmarTelefono')?.updateValueAndValidity();
    });
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'Ingrese manualmente el número de celular',
      false
    );
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'No es permitido copiar el contenido seleccionado',
      false
    );
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    this.dialogServ.openDialog(
      'Función no permitida',
      'No es permitido cortar el contenido seleccionado',
      false
    );
    e.preventDefault();
  }

  ngAfterViewInit(): void {
    this.url = this.router.url.split('/')[1];
  }

  modalAuto(message = '') {
    this.dialogServ.openDialog('Celular incorrecto', message, false, 'Volver a intentar');
  }

  guardarCelular(): any {
    const { telefono } = this.formInfoTele.value;

    this.data.isNewPhone = true;

    this.getinfoServ
      .saveCascadaPhone({
        ind: 2,
        celular: telefono.toString(),
        carpeta: this.data.numeroCarpeta.toString(),
        cedula: this.data.cedula.toString(),
      })
      .subscribe(
        async (resp) => {
          this.router.navigateByUrl('/' + resp.data.ruta);
        },
        (error) => {
          this.loading = false;
          this.intentosContinuar = 0;
          this.modalAuto(error.error.message);
        }
      );

    return true;
  }

  actualizarCelular(): any {
    this.data.isUpdateMobile = true;

    const { telefono } = this.formInfoTele.value;
    this.getinfoServ
      .changeNumberPhone({
        celular: telefono.toString(),
        carpeta: this.data.numeroCarpeta.toString(),
      })
      .subscribe(
        async () => {
          this.router.navigateByUrl('/validaotp');
        },
        (error) => {
          this.intentosContinuar = 0;
          console.log('Falle guradado de respuesta celular' + error);
        }
      );
    return true;
  }

  confirmacion() {
    this.loading = true;
    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      const { telefono } = this.formInfoTele.value;
      this.dialogServ.openDialog(
        "Confirmación",
        `¿Estás seguro que <strong>${telefono}</strong> es el número de celular que deseas registrar?`,
        true,
        "Si, guardar",
        () => {
          this.loading = true;
          this.url === 'modificarcelular' ? this.actualizarCelular() : this.guardarCelular();
        },
        true,
        "No, ajustar",
        () => {
          this.loading = false;
          this.intentosContinuar = 0;
        });
    } catch (error) {
      this.modalAuto(error.error.message);
      this.loading = false;
      this.intentosContinuar = 0;
    }
  }

  celularIgualValidator(control: FormControl) {
    if (!this.formInfoTele) return null; // Evita errores en la inicialización

    const celular = this.formInfoTele.get('telefono')?.value;
    const confirmarCelular = control.value;

    return celular === confirmarCelular ? null : { celularesNoCoinciden: true };
  }
}
