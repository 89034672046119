import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResumenAdoService {

  private apiurl = `${environment.ApiConecta}ado/resumen-credito`;

  constructor(private http: HttpClient) { }

  getResumenAd(numeroCarpeta: number | string): Observable<Object> {
    return this.http.get(`${this.apiurl}?numeroCarpeta=${numeroCarpeta}`);
  }

  obtenerDocumentFisica(carpeta: any):Observable<any>{
    return this.http.post<any>(`${environment.ApiConecta}obtenerDocumentFisica`,carpeta)
  }

}
