import { Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { ReconocerService } from 'src/app/services/preOferta/reconocer.service';
import { itemFile } from '../../models/busCarpeta.interface';
import { DataService } from '../../services/data.service';
import { ResumenAdoService } from '../../services/resumen-ado.service';


@Component({
  selector: 'app-resultado-carpeta',
  templateUrl: './resultado-carpeta.component.html',
  styleUrls: ['./resultado-carpeta.component.scss']
})
export class ResultadoCarpetaComponent {

  estadoColor: any;
  estadoIcono: SafeHtml;
  svgContainer1: any;

  pageNumber: number = 1;
  page_size: number = 3;
  pageSizeOptions = [3, 5, 10];
  isLoading = false;
  searching: boolean = false;
  estado: string = 'busqueda';
  tipoBusqueda: string = 'cedula';
  entradabusqueda: string = '';
  fechaInicial: any = '';
  fechaFinal: any = '';
  convenio: string = '';
  pagaduria: string = '';
  resultadosBusqueda: any[] = [];
  resultadoDetalle: any;
  page_number: number = 0;
  total_pages: number = 0;
  arrayTotal: any[] = [];
  activePage: boolean = false;
  contPages: number = 1;
  resAdo: any[] = [];
  genOf: any;
  convenios: any;
  tiposContratos: any;
  edoLaboral: any;
  cargos: any;
  pagaduarias: any;

  respuesta: string;

  dataRes: any;

  public filteredConvenio: any;
  public filteredPagaduria: any;

  dataSource: any;
  numeroCarpeta: string = '';
  numeroSolicitud: string = '';
  infoSolicitud: any;
  mostrarBotonConsultaMotor = false;
  mostrarConsultaMotor = false;
  responseCarpeta = 0;

  display: string = 'none';
  listFiles: itemFile[];
  loading: boolean = false;
  fileView: boolean = false;
  fileSelected: itemFile;
  pathFile: string;


  constructor(
    private router: Router,
    public data: DataService,
    public dialog: MatDialog,
    private reconocerSrvc: ReconocerService,
    private resumenAdoService: ResumenAdoService,
    private dialogService: DialogService,
    private getinfoServ: GetInformacionService,
  ) {
    this.respuesta = this.router.getCurrentNavigation()?.extras.state?.['respuesta']
  }

  ngOnInit() {

    this.dataRes = this.respuesta ? JSON.parse(this.respuesta) : [];
    this.totalPages();
  }

  onPageChange(e: PageEvent,): void {
    this.page_size = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }
  detalleCarpeta(datos: string): void {

    let data = { datos }

    this.router.navigate(['/detalle-carpetas'], { state: { data: data } });

  }

  async goStep(data) {
    this.isLoading = true;

    this.data.numeroCarpeta = data.numeroCarpeta;
    this.data.numeroSolicitud = data.numeroSolicitud;
    this.data.numeroIdentificacion = data.numeroDocumento;
    this.data.cedula = data.numeroDocumento;
    this.data.apellido = data.primerApellido;
    this.data.isResume = true;

    const response = await this.reconocerSrvc
      .reconoce(data.numeroSolicitud)
      .toPromise();

    this.data.setListadoCelulares(response.celulares);
    this.data.setListadoCorreos(response.correos);

    console.log({ data });

    this.resumenAdoService.getResumenAd(this.data.numeroCarpeta).subscribe(
      async (resp: any) => {
        if (resp.marcaAuditoria === 1) {
          this.dialogService.openDialog(
            'Advertencia',
            'Carpeta se encuentra en validación. Intente mas tarde',
            false
          );
          return;
        }

        switch (data.codigoEstado) {
          case 0:
            this.router.navigateByUrl('/autorizacioncentral', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 20:
            this.getinfoServ
              .getInfoClient({ carpeta: this.data.numeroCarpeta.toString() })
              .subscribe(async (resp) => {
                this.data.cellphone =
                  resp.data.resultado.infoClient.celular.toString();

                const [tries] = resp.data.resultado.tries.filter(
                  (el) => el.tipoValidacion === 4
                );
                if (tries.intentosActuales === tries.totalIntentos) {
                  this.data.isResume = false;
                }

                this.router.navigateByUrl(
                  '/' + resp.data.resultado.logClient.status_process.viewRoute,
                  {
                    state: { resp },
                  }
                );
              });
            break;
          case 30:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 40:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 100:
            this.router.navigateByUrl('/formulario-perfilamiento', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 50:
            this.router.navigateByUrl('/rob-analisis', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 150:
            this.router.navigateByUrl('/pre-oferta', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 200:
            this.router.navigateByUrl('/cargadocumentos', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 300:
            if (resp.seizPagaduria === 1) {
              this.router.navigateByUrl('/oferta', {
                state: { data },
              });
              break;
            }
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 600:
            this.router.navigateByUrl('/saldo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 700:
            this.router.navigateByUrl('/informacionadicional', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1300:
            this.router.navigateByUrl('/pazysalvo', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 1200:
            this.estado = 'resumen';
            break;
          case 530:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 960:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 970:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 531:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 990:
            this.resumenAdoService.obtenerDocumentFisica(
              {
                "carpeta": this.data.numeroCarpeta
              }
            ).subscribe(resp => {
              if (resp.SOLICITA_DOC_FISICA == 'SI') {
                this.router.navigateByUrl('/firma-doc', {
                  state: { data },
                });
              } else {
                this.dialogService.openDialog(
                  'Advertencia',
                  `Recuperar desde notificaciones, si deseas corregir una devolución`,
                  false
                );
                this.isLoading = false;
              }
            })
            this.isLoading = false;
            break;
          case 980:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;
          case 550:
            this.router.navigateByUrl('/firma-doc', {
              state: { data },
            });
            this.isLoading = false;
            break;

          default:
            this.dialogService.openDialog(
              'Búsqueda de cartera',
              `La carpeta se encuentra ${data.estado}`,
              false
            );
            this.isLoading = false;
            break;
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  private totalPages(): void {
    this.total_pages = this.dataRes.rows.length;
    if (this.total_pages > 1 && this.total_pages > 10) {
      this.arrayTotal = new Array(Math.round(10));
      this.activePage = true;
    } else {
      this.arrayTotal = new Array(Math.round(this.total_pages));
    }
  }
}
