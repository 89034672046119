import { EventEmitter, Input } from '@angular/core';
import { Component, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { DialogService } from 'src/app/services/dialog.service';
@Component({
  selector: 'app-simple-message',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss']
})
export class SimpleMessageComponent {
  public arreglo:Array<File> = [];
  @Input() nombreCliente:string = '';
  @Input() numeroCarpeta:string = '';
  @Input() mensaje:string = '';
  @Input() cedula:string = '';
  @Output() regresar:EventEmitter<boolean>= new EventEmitter<boolean>();
  @Output() datosCapturados:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dialog:MatDialog,
    private dialogService: DialogService
  ) { }

  back(){
    this.regresar.emit(true);
  }

  upArchivos(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      midato:''
    };

    const dialogRef = this.dialog.open(ModalComponent,dialogConfig);

    dialogRef.beforeClosed().subscribe( data=>{
      if(this.arreglo.length > 9){
        this.dialogService.openDialog('Opss','Solo se permiten 10, como maximo de archivos', false)
        return
      }

      if(data.length === 1 || data !== ''){
        this.arreglo.push(data);
      }

      this.datosCapturados.emit(this.arreglo);
    });

  }

}
