
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    private apiurl=`${environment.ApiConecta}resumen`
    constructor(private http: HttpClient) {

    }

  async getResultadosBusqueda(params:any):Promise<any>{
    return await this.http.get(this.apiurl, {params}).toPromise()
  }
}
