<div class="cont">
<h1><strong>Preguntas de seguridad</strong></h1>
<p style="max-width: 660px;"> Ya puedes cerrar está página o continuar hacía nuestro sitio para conocer las últimas noticias que tenemos para ti.</p>

<button mat-raised-button (click)="isOptional = !isOptional" hidden>
  {{!isOptional ? 'Enable optional steps' : 'Disable optional steps'}}
</button>

<mat-horizontal-stepper linear #stepper >
  <mat-step *ngFor="let form of formPreguntas let i= index" [stepControl]="form">
    <form [formGroup]="form">
      <ng-template matStepLabel><strong>Pregunta {{i+1}} de 4</strong></ng-template>
        <span class="t-preg"><strong>Pregunta {{i+1}} de 4</strong></span>
        <mat-radio-group formControlName="idRespuesta" aria-label="Select an option" class="example-radio-group" >
          <mat-label class="line-preg">{{i+1}}. {{preguntas.Pregunta[i].texto | capitalize}}</mat-label>
          <mat-radio-button style="margin-right: 10px;" [value]="respuesta.id" *ngFor="let respuesta of preguntas.Pregunta[i].Respuesta" class="example-radio-button">{{respuesta.texto | capitalize}}</mat-radio-button>
        </mat-radio-group>
        <span style="color: red;" *ngIf="form.controls.idRespuesta.hasError('required')">Seleccione una opción para continuar</span>
      <div class="t-end">
        <button class="btn-cam-rag" [disabled]="form.controls.idRespuesta.hasError('required')" mat-button matStepperNext (click)="enviar(i+1)">Siguiente pregunta</button>
      </div>
    </form>
  </mat-step>
<!--   <mat-step [stepControl]="firstquest">
    <form [formGroup]="firstquest">
      <ng-template matStepLabel><strong>Pregunta 1 de 4</strong></ng-template>
        <span class="t-preg"><strong>Pregunta 1 de 4</strong></span>
        <mat-radio-group aria-label="Select an option" class="example-radio-group" >
          <mat-label class="line-preg">1. ¿Desempeñas o has desempeñado durante el último año algún cargo público destacado a nivel federal, estatal, municipal en <strong>México</strong> o en el extranjero?</mat-label>
          <mat-radio-button checked value="1" class="example-radio-button">Respuesta 1</mat-radio-button>
          <mat-radio-button value="2" class="example-radio-button">Respuesta 2</mat-radio-button>
          <mat-radio-button value="3" class="example-radio-button">Respuesta 3</mat-radio-button>
          <mat-radio-button value="4" class="example-radio-button">Respuesta 4</mat-radio-button>
        </mat-radio-group>


      <div class="t-end">
        <button class="btn-cam-rag" mat-button matStepperNext>Siguiente pregunta</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondquest" [optional]="isOptional">
    <form [formGroup]="secondquest">
      <ng-template matStepLabel><strong>Pregunta 2 de 4</strong></ng-template>
      <span class="t-preg"><strong>Pregunta 2 de 4</strong></span>
      <mat-radio-group aria-label="Select an option" class="example-radio-group" >
        <mat-label class="line-preg">2. ¿Desempeñas o has desempeñado durante el último año algún cargo público destacado a nivel federal, estatal, municipal en <strong>México</strong> o en el extranjero?</mat-label>
        <mat-radio-button checked value="1" class="example-radio-button">Respuesta 1</mat-radio-button>
        <mat-radio-button value="2" class="example-radio-button">Respuesta 2</mat-radio-button>
        <mat-radio-button value="3" class="example-radio-button">Respuesta 3</mat-radio-button>
        <mat-radio-button value="4" class="example-radio-button">Respuesta 4</mat-radio-button>
      </mat-radio-group>
      <div class="t-end">
        <button class="btn-white" mat-button matStepperPrevious>Pregunta anterior</button>
        <button class="btn-cam-rag" mat-button matStepperNext>Siguiente pregunta</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondquest" [optional]="isOptional">
    <form [formGroup]="secondquest">
      <ng-template matStepLabel><strong>Pregunta 3 de 4</strong></ng-template>
      <span class="t-preg"><strong>Pregunta 3 de 4</strong></span>
      <mat-radio-group aria-label="Select an option" class="example-radio-group" >
        <mat-label class="line-preg">3. ¿Desempeñas o has desempeñado durante el último año algún cargo público destacado a nivel federal, estatal, municipal en <strong>México</strong> o en el extranjero?</mat-label>
        <mat-radio-button checked value="1" class="example-radio-button">Respuesta 1</mat-radio-button>
        <mat-radio-button value="2" class="example-radio-button">Respuesta 2</mat-radio-button>
        <mat-radio-button value="3" class="example-radio-button">Respuesta 3</mat-radio-button>
        <mat-radio-button value="4" class="example-radio-button">Respuesta 4</mat-radio-button>
      </mat-radio-group>
      <div class="t-end">
        <button class="btn-white" mat-button matStepperPrevious>Pregunta anterior</button>
        <button class="btn-cam-rag" mat-button matStepperNext>Siguiente pregunta</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <form [formGroup]="secondquest">
      <ng-template matStepLabel><strong>Pregunta 4 de 4</strong></ng-template>
      <span class="t-preg"><strong>Pregunta 4 de 4</strong></span>
      <mat-radio-group aria-label="Select an option" class="example-radio-group" >
        <mat-label class="line-preg">4. ¿Desempeñas o has desempeñado durante el último año algún cargo público destacado a nivel federal, estatal, municipal en <strong>México</strong> o en el extranjero?</mat-label>
        <mat-radio-button checked value="1" class="example-radio-button">Respuesta 1</mat-radio-button>
        <mat-radio-button value="2" class="example-radio-button">Respuesta 2</mat-radio-button>
        <mat-radio-button value="3" class="example-radio-button">Respuesta 3</mat-radio-button>
        <mat-radio-button value="4" class="example-radio-button">Respuesta 4</mat-radio-button>
      </mat-radio-group>
      <div class="t-end">
        <button class="btn-white" mat-button matStepperPrevious>Pregunta anterior</button>
        <button class="btn-cam-rag" mat-button [routerLink]="['/validTerm']">Continuar</button>
      </div>
    </form>
  </mat-step> -->
</mat-horizontal-stepper>

<!-- <button class="btn-cam-rag" mat-button >
  <strong>Continuar Solicitud</strong></button>
</div> -->
