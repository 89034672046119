import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Video, VideoIdn } from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private endpoint = environment.ApiPortal;

  private urlVI = "https://clientsettings.co.prod.bayport-digital.com/ado-identidad";

  constructor(
    private http: HttpClient,
    handler: HttpBackend) {
      this.http = new HttpClient(handler);
   }

  getVideo(): Observable<Video>{
    return this.http.get<Video>(this.endpoint);
  }

  //  getVideoIdentidad(){
  //    return this.http.get('https://clientsettings.co.prod.bayport-digital.com/ado-identidad');
  //  }


   getVideoIdentidad(): Observable<VideoIdn>{
     return this.http.get<VideoIdn>(this.urlVI);
   }

}
