<app-main-container
  header="Carga de documentos"
  subheader="Los siguientes documentos son indispensables para continuar con la solicitud de crédito"
>
  <mat-stepper labelPosition="bottom" [linear]="false" #stepper>
    <mat-step [stepControl]="cedulaForm">
      <form [formGroup]="cedulaForm">
        <ng-template matStepLabel style="width: 2rem"
          >Cédula de ciudadanía</ng-template
        >
        <p class="step__subtitle">
          <img src="./../../../assets/icons/check-blue.svg" alt="" />
          El archivo que selecciones no debe superar <strong>5MB</strong>. <br/>
          <img src="./../../../assets/icons/check-blue.svg" alt="" />
          Puedes subir <strong>PDF</strong>, <strong>PNG</strong>, y
          <strong>JPG</strong>. <br/>
          <img src="./../../../assets/icons/check-blue.svg" alt="" />
          Verifica que la cédula se vea completa en la imagen, incluyendo sus
          bordes.
        </p>
        <div
          class ="d-md-flex justify-content-center"
        >
          <app-card-file-upload
            [iconoCentral]="'./../../../assets/icons/address-card-solid.svg'"
            titulo="Cédula ciudadanía (frente)"
            (fileChange)="
              fileChange(
                $event,
                cedula[0],
                'Cedula-ciudadania-anverso',
                'cedulaFrente',
                cedulaForm
              )
            "
            [archivoCargado]="cedula[0]?.status"
            (clearFile)="eliminarArchivo(cedula[0])"
          ></app-card-file-upload>
          <app-card-file-upload
            [iconoCentral]="'./../../../assets/icons/cedula-reverso.svg'"
            titulo="Cédula ciudadanía (reverso)"
            (fileChange)="
              fileChange(
                $event,
                cedula[1],
                'Cedula-ciudadania-reverso',
                'cedulaReverso',
                cedulaForm
              )
            "
            [archivoCargado]="cedula[1]?.status"
            (clearFile)="eliminarArchivo(cedula[1])"
          ></app-card-file-upload>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 2rem">
          <button
            class="button__steps"
            [disabled]="cedulaForm.invalid"
            mat-button
            matStepperNext
          >
            Guardar y continuar
            <img
              src="./../../../assets/icons/flecha-izquierda.svg"
              style="transform: rotate(180deg)"
            />
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="desprendiblesForm">
      <form [formGroup]="desprendiblesForm">
        <ng-template matStepLabel>Desprendibles de pago</ng-template>
        <p class="step__subtitle">
          <img src="./../../../assets/icons/check-blue.svg" alt="" />
          El archivo que selecciones no debe superar <strong>5MB</strong>.
          <img src="./../../../assets/icons/check-blue.svg" alt="" />
          Puedes subir <strong>PDF</strong>, <strong>PNG</strong> y
          <strong>JPG</strong>.
        </p>
        <div
         class="d-md-flex justify-content-center"
        >
          <app-card-file-upload
            iconoCentral="./../../../assets/icons/subir.svg"
            titulo="Desprendible de pago último mes"
            (fileChange)="
              fileChange(
                $event,
                desprendible[0],
                'Desprendible-reciente',
                'desprendibleReciente',
                desprendiblesForm
              )
            "
            [archivoCargado]="desprendible[0]?.status"
            (clearFile)="eliminarArchivo(desprendible[0])"
          ></app-card-file-upload>
          <app-card-file-upload
            iconoCentral="
              ./../../../assets/icons/subir.svg
            "
            titulo="Desprendible pago mes anterior"
            (fileChange)="
              fileChange(
                $event,
                desprendible[1],
                'Desprendible-anterior',
                'desprendibleAnterior',
                desprendiblesForm
              )
            "
            [archivoCargado]="desprendible[1]?.status"
            (clearFile)="eliminarArchivo(desprendible[1])"
          ></app-card-file-upload>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
          "
        >
          <button mat-button matStepperPrevious class="button__steps">
            <img src="./../../../assets/icons/flecha-izquierda.svg" />
            Volver
          </button>
          <button
            class="button__steps"
            [disabled]="desprendiblesForm.invalid"
            mat-button
            matStepperNext
          >
            Guardar y continuar
            <img
              src="./../../../assets/icons/flecha-izquierda.svg"
              style="transform: rotate(180deg)"
            />
          </button>
        </div>
      </form>
    </mat-step>
    <div *ngIf="otrosObligatorios.length !== 0">
      <mat-step>
        <ng-template matStepLabel>Otros documentos obligatorios</ng-template>
        <p class="step__subtitle">
          El archivo que selecciones no debe superar 5MB. Puedes subir PDF, PNG
          y JPG.
        </p>
        <div class="card-file" *ngFor="let obligatorios of otrosObligatorios">
          <app-card-file-upload
            iconoCentral="
                      ./../../../assets/icons/subir.svg
                    "
            [titulo]="obligatorios.nombre"
            (fileChange)="fileChange($event, obligatorios, obligatorios.nombre)"
            [archivoCargado]="obligatorios.status"
            (clearFile)="eliminarArchivo(obligatorios)"
          ></app-card-file-upload>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
          "
        >
          <button mat-button matStepperPrevious class="button__steps">
            <img src="./../../../assets/icons/flecha-izquierda.svg" />
            Volver
          </button>
          <button class="button__steps" mat-button matStepperNext>
            Guardar y continuar
            <img
              src="./../../../assets/icons/flecha-izquierda.svg"
              style="transform: rotate(180deg)"
            />
          </button>
        </div>
      </mat-step>
    </div>
    <mat-step>
      <ng-template matStepLabel>Otros documentos adicionales</ng-template>
      <p class="step__subtitle">
        <img src="./../../../assets/icons/check-blue.svg" alt="" />
        El archivo que selecciones no debe superar <strong>5MB</strong>.
        <img src="./../../../assets/icons/check-blue.svg" alt="" />
        Puedes subir <strong>PDF</strong>, <strong>PNG</strong> y
        <strong>JPG</strong>.
      </p>
      <div
        style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
          gap: 1rem;
          justify-items: center;
        "
      >
        <div *ngFor="let adicional of otrosAdicionales; let i = index">
          <app-card-file-upload
            iconoCentral="./../../../assets/icons/subir.svg"
            [titulo]="adicional.nombre"
            (fileChange)="fileChange($event, adicional)"
            [archivoCargado]="adicional.status"
            (clearFile)="eliminarArchivo(adicional, true)"
          ></app-card-file-upload>
        </div>
      </div>
      <section class="center">
        <h3 style="font-size: 15px; text-align: center">
          ¿Necesitas cargar documentos adicionales?
        </h3>
        <app-card-file (dataSubmit)="addAdditionalFile($event)"></app-card-file>
      </section>
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;
          gap: 1.5rem;
        "
      >
        <div
          *ngFor="let document of additionalDocuments; index as i"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 330px;
          "
        >
          <p class="text__additional">{{ document.fileName }}</p>
          <button class="button__additional" (click)="removeAdditionalFile(i)">
            <img src="./../../../assets/icons/eliminar.svg" alt="" />
          </button>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2rem;
        "
      >
        <button mat-button matStepperPrevious class="button__steps">
          <img src="./../../../assets/icons/flecha-izquierda.svg" />
          Volver
        </button>
        <button class="button__steps button__fin" (click)="cargaSteps(stepper)">
          Guardar y enviar
          <img
            src="./../../../assets/icons/flecha-izquierda.svg"
            style="transform: rotate(180deg)"
          />
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Completo</ng-template>
      <div
        style="display: flex; flex-direction: column; align-items: center"
        *ngIf="isUploadingFile"
      >
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h3 style="text-align: center; font-size: 20px; font-weight: bold">
          Estamos procesando los documentos
        </h3>
        <p style="text-align: center; font-size: 16px">
          Espera unos minutos. No cierres ni refresques esta ventana.
        </p>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 4rem 0;
        "
        *ngIf="isUploadingError"
      >
        <img
          src="./../../../assets/icons/error-carga.svg"
          alt=""
          style="
            filter: drop-shadow(0 0 50px rgba(228, 0, 70, 0.9));
            z-index: 99;
          "
        />
        <h3
          style="
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            margin-top: 2rem !important;
          "
        >
          {{ tituloError }}
        </h3>
        <div *ngIf="!isListaError">
          <p style="text-align: center; font-size: 13px !important">
            {{ mensajeError }}
          </p>
        </div>
        <div *ngIf="isListaError">
          <p style="text-align: center; font-size: 13px !important">
            Revisa que todos los documentos:
          </p>
          <p
            style="
              display: flex;
              flex-direction: column;
              text-align: center;
              font-size: 13px !important;
            "
          >
            1. Sean legibles 2. Que no estén invertidos 3. Estén en la categoría
            correctas
          </p>
        </div>
        <button
          class="button__steps button__fin"
          (click)="resetErrorDocument(stepper)"
          style="margin-top: 6rem"
        >
          Volver al inicio
        </button>
      </div>
      <div
        style="display: flex; flex-direction: column; align-items: center"
        *ngIf="isUploadingSuccess"
      >
        <img
          src="./../../../assets/icons/completado.svg"
          alt=""
          style="
            background: transparent
              radial-gradient(
                closest-side at 50% 50%,
                var(--unnamed-color-fa6792) 0%,
                #fa679246 75%,
                #fa679200 100%
              )
              0% 0% no-repeat padding-box;
          "
        />
        <h3 style="text-align: center; font-size: 20px; font-weight: bold">
          Carga de documentos completada exitosamente
        </h3>
        <button class="button__steps button__fin" (click)="redirectSeiz()">
          Continuar
        </button>
      </div>
      <div
        style="display: flex; flex-direction: column; align-items: center"
        *ngIf="isTimeout"
      >
        <img
          src="./../../../assets/icons/inactividad.svg"
          alt=""
          style="
            background: transparent
              radial-gradient(
                closest-side at 50% 50%,
                #ffda77 0%,
                #ffda7745 75%,
                #ffda7700 100%
              )
              0% 0% no-repeat padding-box;
          "
        />
        <h3 style="text-align: center; font-size: 20px; font-weight: bold">
          Seguimos procesando la documentación
        </h3>
        <p>Para continuar, da clic en el botón</p>
        <button class="button__steps button__fin" (click)="resendService()">
          Guardar y continuar
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</app-main-container>
