<div class="alert alert-primary d-flex align-items-center px-0 py-2 my-2"
  [ngClass]="[color, customClass, compressed ? 'compressed' : '']" role="alert">
  <img *ngIf="color==='success'" src="./../../../assets/icons/alerta-check.svg" class="ms-2" style="width: 11px;" />
  <img *ngIf="color==='warning'" src="./../../../assets/icons/alerta-advertencia.svg" class="ms-2"
    style="width: 11px;" />
  <img *ngIf="color==='info'" src="./../../../assets/icons/alerta-info.svg" class="ms-2" style="width: 11px;" />
  <div class="mx-2 texto">
    <ng-content></ng-content> <!-- Texto o contenido del botón -->
  </div>
</div>
