// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { ComponentsV2Module } from './components/components.v2.module';

// Prime
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

// Pages
import { AppV2Component } from './app.v2.component';
import { AutoCentralComponent } from './pages/auth/auto-central/auto-central.component';
import { IniciarSesionComponent } from './pages/auth/iniciar-sesion/iniciar-sesion.component';
import { RecuperarContrasenaComponent } from './pages/auth/recuperar-contrasena/recuperar-contrasena.component';
import { CambioContrasenaComponent } from './pages/home/cambio-contrasena/cambio-contrasena.component';
import { HomeComponent } from './pages/home/home.component';
import { DebesSaberComponent } from './pages/home/debes-saber/debes-saber.component';

@NgModule({
    declarations: [
        AppV2Component,
        RecuperarContrasenaComponent,
        IniciarSesionComponent,
        CambioContrasenaComponent,
        AutoCentralComponent,
        HomeComponent,
        DebesSaberComponent,
    ],
    imports: [
        // Angular
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // Custom
        ComponentsV2Module,
        // Prime
        InputTextModule,
        ButtonModule,
        PasswordModule
    ]
})
export class AppV2Module { }
