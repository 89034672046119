import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Identidad } from 'src/app/models/identidad';
import { environment } from 'src/environments/environment';
import {Otp} from 'src/app/models/Otp'
import { EvidenteRespuesta } from 'src/app/models/EvidenteRespuestas';

@Injectable({
  providedIn: 'root'
})
export class EvidenteOtpExpService {


  private endpoint = environment.ApiConecta;

  constructor(private http:HttpClient) { }

  validarIdentidad(identidad:Identidad):Observable<any>{
    return this.http.post<Identidad>(this.endpoint+'validate/identity',identidad);
  }

  SolicitarOtp(cedula:number,regValidacion:string,cellphone:string):Observable<any>{
    return this.http.post(this.endpoint+'messaging/otp/result',{cedula,regValidacion,cellphone});
  }

  validarOtp(otp:Otp):Observable<any>{
    return this.http.post(this.endpoint+'check/otp',otp);
  }

  
  solicitarPreguntas(cedula:string, regValidacion:string):Observable<any>{
    return this.http.post(this.endpoint+'messaging/questions/result',{cedula,regValidacion});
  }

  ValidarPreguntas(respuestas:EvidenteRespuesta):Observable<any>{
    return this.http.post(this.endpoint+'check/questions',respuestas);
  }
}
