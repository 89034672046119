import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-login-tb',
  templateUrl: './header-login-tb.component.html',
  styleUrls: ['./header-login-tb.component.css']
})
export class HeaderLoginTbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
