import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class ReconocerService {
  private endpoint = environment.ApiConecta + 'perfilamiento/reconocer/';
  private endpoint2 = environment.ApiConecta + '/perfilamiento/adicional/informacion/personal/reconocer/';

  constructor(private http: HttpClient) {}

  reconoce(numero_solicitud: number): Observable<any> {
    return this.http.post(this.endpoint + `${numero_solicitud}`, {});
  }

  reconoceGet(numero_solicitud: number): Observable<any> {
    return this.http.get(this.endpoint2 + `${numero_solicitud}`, {});
  }
}
