<main class="w-screen h-screen flex relative overflow-x-hidden">
  <app-menubar
    [isOpen]="openSisebar"
    (toggleOpen)="toggleSidebar()"
    [class]="openSisebar ? 'w-full inline' : 'w-0 hidden'"
    class="shadow-8 overflow-x-hidden text-white bg-blue-gradient-left-top md:hidden"
  ></app-menubar>

  <div
    class="overflow-x-hidden text-white w-0 md:w-min bg-blue-gradient-left-top"
  >
    <aside class="w-sidebar h-full flex flex-column justify-content-between">
      <figure
        class="w-full z-5 px-4 sm:px-0 mt-3 sm:mt-5 flex align-items-center justify-content-between sm:justify-content-center"
      >
        <img
          src="../../../assets/img/logo-2.svg"
          alt="logo bayport"
          class="w-3rem sm:hidden"
        />
        <img
          src="../../../assets/img/logo.png"
          alt="logo bayport"
          class="w-3rem hidden sm:inline"
        />
      </figure>
      <menu
        class="max-menu-height mx-4 mb-10 px-4 border-round-md bg-blue-gradient-top text-sm menu"
      >
        <a
          routerLink="/home"
          class="font-omnes hover-effect w-full pt-4 block text-white no-underline"
        >
          Inicio
        </a>
        <a
          routerLink="/busqueda-carpetas"
          class="font-omnes hover-effect w-full pt-3 block text-white no-underline"
        >
          Mis créditos
        </a>
        <a
          routerLink="/reporteria"
          class="font-omnes hover-effect w-full pt-3 block text-white no-underline"
        >
          Mis reportes
        </a>
        <a
          routerLink="/capacitacion"
          class="font-omnes hover-effect w-full pt-3 block text-white no-underline"
        >
          Tus cursos
        </a>
        <details
          #details1
          (click)="toggleDetails(details2)"
          class="w-full pt-3 block text-sm text-white"
        >
          <summary
            class="flex justify-content-between text-sm"
            style="font-size: 16px !important"
          >
            <span class="hover-effect font-omnes">Mis trámites</span>
            <i class="fa fa-arrow-right"></i>
          </summary>
          <hr class="w-full bg-white" />
          <a
            routerLink="/mis-tramites/referencia-comercial"
            class="font-omnes hover-effect pl-2 pb-2 block text-sky-light"
          >
            Certificaciones
          </a>
          <!-- <a routerLink="/mis-tramites/actualizacion-cuenta"
            class="font-omnes hover-effect pl-2 py-2 block text-sky-light">
            Actualización de cuenta
          </a> -->
          <a
            routerLink="/mis-tramites/carga-documentos"
            class="font-omnes hover-effect pl-2 py-2 block text-sky-light"
          >
            Carga de documentos
          </a>
          <a
            routerLink="/mis-tramites/consulta-documentos"
            class="font-omnes hover-effect pl-2 pt-2 block text-sky-light"
          >
            Consulta de documentos
          </a>
        </details>
        <details
          #details2
          (click)="toggleDetails(details1)"
          class="w-full pt-3 pb-4 block text-sm text-white"
        >
          <summary
            class="flex justify-content-between text-sm"
            style="font-size: 16px !important"
          >
            <span class="hover-effect font-omnes">Debes saber</span>
            <i class="fa fa-arrow-right"></i>
          </summary>
          <hr class="w-full bg-white" />
          <a
            routerLink="/home/debes-saber"
            class="font-omnes hover-effect pl-2 pb-2 block text-white-alpha-80"
          >
            Home
          </a>
          <a
            routerLink="/home/debes-saber/politicas"
            class="font-omnes hover-effect pl-2 py-2 block text-sky-light"
          >
            Políticas de producto
          </a>
          <a
            routerLink="/home/debes-saber/megafono"
            class="font-omnes hover-effect pl-2 py-2 block text-sky-light"
          >
            Megáfono
          </a>
          <a
            routerLink="/home/debes-saber/calendario"
            class="font-omnes hover-effect pl-2 pt-2 block text-sky-light"
          >
            Calendario comercial
          </a>
        </details>
      </menu>
    </aside>
  </div>

  <article
    class="local-shadow w-auto flex-1 flex flex-column relative bg-blue-gradient-bottom overflow-x-hidden"
  >
    <figure class="icon-clic">
      <img
        src="../../../assets/img/logo-2.svg"
        alt="logo bayport"
        class="w-3rem sm:hidden"
      />
      <img
        src="../../../assets/img/logo.png"
        alt="logo bayport"
        class="w-3rem hidden sm:inline"
      />
    </figure>
    <header
      class="w-auto h-min pt-3 px-4 flex justify-content-end md:justify-content-end sticky top-0 right-0"
    >
      <nav
        class="px-3 py-2 flex gap-2 text-white border-round-3xl shadow-7 bg-blue-dark"
      >
        <a
          routerLink="/autorizacionTransferencia"
          class="font-omnes-semibold text-sky-light px-4 py-2 hidden md:inline border-1 border-round-3xl border-sky no-underline credito"
        >
          Nuevo crédito
        </a>
        <div class="md:hidden px-3 py-2 border-1 border-sky border-round-3xl">
          <i class="fa fa-usd text-sky-light" aria-hidden="true"></i>
        </div>
        <a
          routerLink="/notificaciones"
          class="px-3 py-2 flex gap-2 align-items-center text-white border-1 border-sky border-round-3xl"
          style="padding: 6px 17px 6px 17px !important"
        >
          <img src="../../../../assets/icons/campana.svg" />
        </a>
        <div
          class="hidden sm:inline px-3 py-2 border-1 border-sky border-round-3xl dropdown"
          style="
            padding: 6px 17px 6px 17px !important;
            cursor: pointer !important;
          "
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="../../../../assets/icons/user.svg"
            aria-hidden="true"
            data-bs-offset="15,20"
          />
          <div
            class="dropdown-menu sesion"
            aria-labelledby="dropdownMenuOffset"
          >
            <div
              style="color: #fff; font-size: 22px; padding-bottom: 5px"
              align="center"
              class="font-omnes-semibold"
            >
              ¡Hola, {{ user }}!
            </div>
            <div
              class="btn-group buttonGroup"
              role="group"
              aria-label="Basic outlined example"
            >
              <button
                type="button"
                routerLink="/mi-perfil"
                class="btn btn-outline-primary btnSesion perfil font-omnes-medium text-sky-light px-3"
              >
                <span>Mi perfil</span>
              </button>
              <button
                type="button"
                (click)="serviceAuth.logout()"
                class="btn btn-outline-primary btnSesion cerrar font-omnes-medium text-sky-light px-3"
              >
                <i class="fas fa-sign-out"></i> <span>Cerrar sesión</span>
              </button>
            </div>
          </div>
        </div>

        <div
          class="h-min px-3 py-2 lg:hidden border-round-md border-none border-1 border-sky border-round-3xl"
          (click)="toggleSidebar(true)"
        >
          <i class="fa fa-bars text-sky-light"></i>
        </div>
      </nav>
    </header>

    <router-outlet></router-outlet>
  </article>
</main>
