import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderLoginTbComponent } from './header-login-tb/header-login-tb.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { HeaderTabletComponent } from './header-tablet/header-tablet.component';
import { InfoComponent } from './info/info.component';
import { LoginHeaderMbComponent } from './login-header-mb/login-header-mb.component';

import { MaterialModule } from '../material.module';
import { LoadingComponent } from './loading/loading.component';
import { StepperComponent } from './stepper/stepper.component';
import { AdvertenciasComponent } from './advertencias/advertencias.component';
import { ButtonComponent } from './button/button.component';
import { LoadFileCardComponent } from './load-file-card/load-file-card.component';
import { CardDottedLineComponent } from './card-dotted-line/card-dotted-line.component';
import { ProgressComponent } from './progress/progress.component';
import { HyperlinkIconComponent } from './hyperlink-icon/hyperlink-icon.component';
import { FileUploadPhysicalDocsComponent } from './file-upload-physical-docs/file-upload-physical-docs.component';

@NgModule({
  declarations: [
    HeaderTabletComponent,
    HeaderMobileComponent,
    LoginHeaderMbComponent,
    HeaderLoginTbComponent,
    InfoComponent,
    LoadingComponent,
    StepperComponent,
    LoadFileCardComponent,
    ButtonComponent,
    AdvertenciasComponent,
    CardDottedLineComponent,
    ProgressComponent,
    HyperlinkIconComponent,
    FileUploadPhysicalDocsComponent
  ],
  imports: [CommonModule, RouterModule, MaterialModule],
  exports: [
    HeaderTabletComponent,
    HeaderMobileComponent,
    LoginHeaderMbComponent,
    HeaderLoginTbComponent,
    LoadingComponent,
    ButtonComponent,
    AdvertenciasComponent,
    CardDottedLineComponent,
    LoadFileCardComponent,
    ProgressComponent,
    HyperlinkIconComponent,
    FileUploadPhysicalDocsComponent
  ],
})
export class ComponentsModule {}
