<app-main-container header="Validación de identidad" subheader="Datos del solicitante del crédito">
  <div class="contenido">
    <div class="row">
      <p>
        Para la validación de identidad del solicitante, como corredor/asesor y en calidad del responsable de la
        información, deberás seleccionar el correo electrónico que confirma el solicitante:
      </p>
    </div>
    <br>
    <div class="row">
      <mat-radio-group [(ngModel)]="mail" aria-labelledby="example-radio-group-label" class="telefono-group">
        <mat-radio-button class="telefono-button" *ngFor="let correo of correos" [value]="correo.email">
          {{ correo.email }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <button-look-feel *ngIf="havemails" [color]="'secondary'" [disabled]="mail == ''" [loading]="loading" (clicked)="savemail()">Continuar</button-look-feel>
  </div>
</app-main-container>
