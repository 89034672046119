import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PersonalInfo } from 'src/app/models/personalinfo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PerfilamientoService {
  private endpoint = `${environment.ApiConecta}perfilamiento/informacion/personal`;
  private endpointTipos =
    environment.ApiConecta + 'parametros/tipos-identificacion';

  constructor(private http: HttpClient) {}

  guardarPerfil(personalinfo: PersonalInfo): Observable<any> {
    return this.http.get(this.endpointTipos).pipe(
      map((res: any) => {
        const tiposIdentificacion = res.rows.reduce((acc, el) => {
          acc[el.id] = el.codigoTipoIdentificacion;
          return acc;
        }, {});

        personalinfo.tipoIdentificacion =
          tiposIdentificacion[personalinfo.tipoIdentificacion] ??
          personalinfo.tipoIdentificacion;

        let pinfo: any = {};
        Object.assign(pinfo, personalinfo);
        return pinfo;
      }),
      switchMap((pinfo: any) => {
        return this.http.post<PersonalInfo>(this.endpoint, pinfo);
      })
    );
  }
}
