<div class="nuevo m-3">
  <div class="nuevo__content">
    <div class="archivo__cargado" *ngIf="archivoCargado">
      <button class="archivo__limpiar" (click)="onClearFile()">
        <img src="./../../../assets/icons/close-doc.svg" alt="" />
      </button>
      <img
        src="./../../../assets/icons/archivo-cargado.svg"
        style="width: 42px"
        alt=""
      />
      <p>{{ this.nombreArchivo }}</p>
    </div>
    <div *ngIf="!archivoCargado">
      <div style="display: grid; place-content: center; margin-top: 20px">
        <img src="{{ iconoCentral }}" alt="" />
      </div>
      <h3 class="title" style="margin-top: 14px !important">
        {{ titulo }}
      </h3>
      <p
        style="
          font-size: 11px !important;
          text-align: center;
          margin-top: 0.5rem !important;
          text-wrap: pretty;
        "
      >
        {{ texto }}
      </p>
      <label class="button__file">
        <div class="row">
          <img src="./../../../assets/icons/lupa-azul.svg" alt="" style="width: 20%;" />
          Selecciona un archivo
        </div>
        <input
          type="file"
          style="display: none"
          (change)="onFileChange($event)"
          accept=".png, .jpg, .pdf"
        />
      </label>
      <div class="error" *ngIf="isError">
        <img src="./../../../assets/icons/advertencia.svg" alt="" />
        <p class="error__text">
          El archivo supera el límite, comprímelo e intenta otra vez
        </p>
      </div>
    </div>
  </div>
</div>
