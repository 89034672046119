import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CambioFlujoService {
  private endpoint = environment.ApiConecta;

  constructor(private http:HttpClient) { }

  cambiarFlujo(numeroSolicitud:string):Observable<any>{
    return this.http.post(this.endpoint+'perfilamiento/cambio-flujo',{numeroSolicitud});
  }
}
