import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RcActualizaPassService } from '../../services/recuperacion/rc-actualiza-pass.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map} from 'rxjs/operators';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  hide: boolean = true;
  showError: boolean = false;
  errorLog: String = '';
  matcher = new MyErrorStateMatcher();
  numuser="";
  otp="";
  constructor(
    private api: RcActualizaPassService,
    private router: Router,
    public activatedRoute: ActivatedRoute
    ) {
      let state = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state))
  
      
      state.subscribe(x=>{
        console.log(x);
        this.otp=x.otp;
        this.numuser=x.numuser;
      })
    this.changePasswordForm = new FormGroup({
      actual: new FormControl('', [
        Validators.required,
      ]),
      nueva: new FormControl('', [
        Validators.pattern('^[a-zA-Z0-9!"#$%&()*+,-./:;<=>?@_`|~ ]+$'),
        Validators.minLength(8),
        Validators.maxLength(33),
        Validators.required,
      ]),
      confirmar: new FormControl('', [
        Validators.pattern('^[a-zA-Z0-9!"#$%&()*+,-./:;<=>?@_`|~ ]+$'),
        Validators.minLength(8),
        Validators.maxLength(33),
        Validators.required,
      ]),

    });
   }

  ngOnInit(): void {
  }

  actRc() {

    var responses:any;
    this.api
      .actualizarPassword(
        'ConectaFrontEnd',
        this.numuser,
        this.changePasswordForm.controls.password.value,
        this.otp
      )
      .subscribe(
        (data:any) => {
          console.log(data);
          this.errorLog = data.message;
          alert(this.errorLog)
          responses = data;
          if(responses.code==200){
            this.router.navigate(['/']);
          }
      else{
      this.errorLog = responses.message;
      }
        },
        (error) => {
          console.log(error);
          
          console.log(error.error);
          this.errorLog = error.error.message;
        }
      );
  }

}
