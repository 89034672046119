<main class="bg-login w-full h-full relative overflow-auto">
  <figure class="icon-clic">
    <img src="../../../assets/img/logo-2.svg" alt="logo bayport" class="w-4rem mb-2 sm:hidden" />
    <img src="../../../assets/img/logo.png" alt="logo bayport" class="w-3rem hidden sm:block" />
  </figure>
  <article class="w-full h-full grid">
    <div class="bg-blue-gradient m-auto sm:w-4 px-6 sm:px-4 sm:py-5 sm:border-1 border-round border-sky-200">
      <h3 class=" mb-2 text-2xl font-bold text-white" style="font-size: 20px !important;">
        Información enviada
      </h3>
      <p class="pb-4 text-sm text-pretty text-white fw-semibold" style="font-size: 16px !important;">
        En tu correo recibirás la información para ingresar a tu
        cuenta.
      </p>
      <div class="w-full pb-3">
        <button-look-feel [block]="true" color="secondary" routerLink="/" customClass="py-2">Iniciar sesión</button-look-feel>
      </div>
      <div class="olvide-pas flex justify-content-center mt-2">
        <a routerLink="/recuperar-contrasena" class="text-xs text-sky-200 hover:text-underline fw-semibold" style="font-size: 13px !important;">
          ¿No recibiste el correo con la información?
        </a>
      </div>
      <div class="flex justify-content-center mt-2">
        <a routerLink="/" class="flecha-izquierda text-sky-200 fw-semibold" style="font-size: 16px !important;">
          <i class=" palabra-animada fas fa-arrow-left"></i>
          Volver
        </a>
      </div>
    </div>
  </article>
</main>
