<mat-drawer-container
  *ngIf="url != '/valid-ado-term'; else elseBlock"
  class="example-container cont menu_"
>
  <mat-drawer
    #drawer
    [mode]="screenWidth < 1025 ? 'over' : 'side'"
    [opened]="screenWidth < 1025 ? false : true"
  >
    <div id="mostrador">
      <div class="logo" id="logo">
        <img
          style="cursor: pointer"
          src="../../../assets/img/ClickOficinaVirtual.png"
          alt="logo"
        />
      </div>

      <div class="info-sidebar">
        <!-- <div class="imagen-persona">
            <img
              src="../../../assets/img/BYP_Avatar_Neutro.png"
              class="img-fluid"
              alt="img-persona"
            />
          </div>
          <div class="info-persona">
            <span>Ana Sofia</span>
            <br />
            <span>Ramírez Téllez</span>
            <br />
            <div class="perfcomp">
              <span>Perfil completado al 70%</span>
            </div>
          </div> -->

        <mat-list>
          <mat-list-item
            #home
            class="list-m"
            routerLinkActive="bay-active"
            [routerLink]="['/home']"
            (click)="showNews({ url: '' }, home)"
          >
            <mat-icon mat-list-icon>home</mat-icon>
            <div class="menu-title" mat-line>Inicio</div>
            <span class="material-icons alright">arrow_right</span>
          </mat-list-item>
          <!-- <mat-list-item class="list-m" [routerLink]="['/notificaciones']">
            <mat-icon mat-list-icon>notifications</mat-icon>
            <div class="menu-title" mat-line>Notificaciones</div>
            <span class="material-icons alright">arrow_right</span>
          </mat-list-item> -->
          <!-- <mat-list-item class="list-m" [routerLink]="['/content']">
            <mat-icon mat-list-icon>person</mat-icon>
            <div class="menu-title" mat-line>Mi cuenta</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item> -->
          <mat-list-item
            class="list-m"
            routerLinkActive="bay-active"
            [routerLink]="['/busqueda-carpetas']"
            (click)="clearRouterLinkActive()"
          >
            <mat-icon mat-list-icon>monetization_on</mat-icon>
            <div class="menu-title" mat-line>Mis créditos</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>
          <mat-list-item
            class="list-m"
            routerLinkActive="bay-active"
            [routerLink]="['/reporteria']"
            (click)="clearRouterLinkActive()"
          >
            <mat-icon mat-list-icon>sticky_note_2</mat-icon>
            <div class="menu-title" mat-line>Mis reportes</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>
          <mat-list-item
            class="list-m"
            routerLinkActive="bay-active"
            [routerLink]="['/', 'capacitacion']"
            (click)="clearRouterLinkActive()"
          >
            <mat-icon mat-list-icon>school</mat-icon>
            <div class="menu-title" mat-line>Tus cursos</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <mat-list-item
            class="list-m"
            routerLinkActive="bay-active"
            [routerLink]="['/mi-perfil']"
            (click)="clearRouterLinkActive()"
          >
            <mat-icon mat-list-icon>person</mat-icon>
            <div class="menu-title" mat-line>Mi Perfil</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <mat-list-item
            class="list-m"
            data-toggle="collapse"
            data-target="#sub-menu"
            id="misTramitesHead"
            (click)="clearRouterLinkActive()"
          >
            <mat-icon mat-list-icon>insert_drive_file</mat-icon>
            <div class="menu-title" mat-line>Mis trámites</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <div id="sub-menu" class="collapse" aria-labelledby="misTramitesHead">
            <div class="card-body">
              <mat-list>
                <mat-list-item
                  class="list-m sub-menu-item"
                  routerLinkActive="bay-active"
                  [routerLink]="['/', 'mis-tramites', 'referencia-comercial']"
                  (click)="clearRouterLinkActive()"
                >
                  <!-- <mat-icon mat-list-icon>sticky_note_2</mat-icon> -->
                  <div class="menu-title" mat-line>Certificaciones</div>
                  <span class="material-icons">arrow_right</span>
                </mat-list-item>

                <mat-list-item
                  class="list-m sub-menu-item"
                  routerLinkActive="bay-active"
                  [routerLink]="['/', 'mis-tramites', 'carga-documentos']"
                  (click)="clearRouterLinkActive()"
                >
                  <!-- <mat-icon mat-list-icon>sticky_note_2</mat-icon> -->
                  <div class="menu-title" mat-line>Carga de documentos</div>
                  <span class="material-icons">arrow_right</span>
                </mat-list-item>
                <mat-list-item
                  class="list-m sub-menu-item"
                  routerLinkActive="bay-active"
                  [routerLink]="['/', 'mis-tramites', 'consulta-documentos']"
                  (click)="clearRouterLinkActive()"
                >
                  <!-- <mat-icon mat-list-icon>sticky_note_2</mat-icon> -->
                  <div class="menu-title" mat-line>Consulta de documentos</div>
                  <span class="material-icons">arrow_right</span>
                </mat-list-item>
              </mat-list>
            </div>
          </div>

          <mat-list-item
            [disabled]="newsUrls.length <= 0"
            class="list-m"
            data-toggle="collapse"
            data-target="#sub-menu_noticias"
            id="noticiasHead"
            #news
            (click)="showNews({ url: '' }, news)"
          >
            <div class="menu-title d-flex align-items-center" mat-line>
              <span class="icon">
                <img [src]="icon_dbs" /> </span
              >Debes saber
            </div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <div
            id="sub-menu_noticias"
            class="collapse"
            aria-labelledby="noticiasBody"
            *ngIf="newsUrls.length > 0"
          >
            <div class="card-body">
              <mat-list>
                <mat-list-item
                  #item_
                  class="list-m sub-menu-item"
                  (click)="showNews(item.urls[0], item_)"
                  *ngFor="let item of newsUrls"
                >
                  <!-- <mat-icon mat-list-icon>sticky_note_2</mat-icon> -->
                  <div class="menu-title" mat-line>{{ item.description }}</div>
                  <span class="material-icons">arrow_right</span>
                </mat-list-item>
              </mat-list>
            </div>
          </div>

          <!-- <mat-list-item class="list-m" routerLinkActive="bay-active" [routerLink]="['/content']">
            <mat-icon mat-list-icon>calculate</mat-icon>
            <div class="menu-title" mat-line>Simulador</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <mat-list-item class="list-m" routerLinkActive="bay-active" [routerLink]="['/content']">
            <mat-icon mat-list-icon>message</mat-icon>
            <div class="menu-title" mat-line>Convenios</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item>

          <mat-list-item class="list-m" routerLinkActive="bay-active">
            <mat-icon mat-list-icon>place</mat-icon>
            <div class="menu-title" mat-line>Centro de ayuda</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item> -->
          <!-- <mat-list-item class="list-m" [routerLink]="['/content']">
            <mat-icon mat-list-icon>help</mat-icon>
            <div class="menu-title" mat-line>Centro de ayuda</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item> -->
          <!-- <mat-list-item class="list-m" [routerLink]="['/content']">
            <mat-icon mat-list-icon>place</mat-icon>
            <div class="menu-title" mat-line>Tour de bienvenida</div>
            <span class="material-icons">arrow_right</span>
          </mat-list-item> -->
        </mat-list>

        <!-- <div class="cerrar-sesion">
          <mat-list>
            <mat-list-item class="list-m" (click)="authService.logout()">
              <mat-icon mat-list-icon>power_settings_new</mat-icon>
              <div class="menu-title" mat-line>Cerrar sesión</div>
            </mat-list-item>
          </mat-list>
        </div> -->
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content" style="min-height: 100vh">
      <app-header-mobile>
        <div menuResponsive class="menu" (click)="drawer.toggle()">
          <span class="material-icons top-menu">menu</span>
          <span>Menú</span>
        </div>
      </app-header-mobile>

      <app-header-tablet>
        <div menuResponsive class="menu" (click)="drawer.toggle()">
          <span class="material-icons">menu</span>
          <span class="menu-desc">Menú</span>
        </div>
      </app-header-tablet>
      <app-header></app-header>
      <ng-content select="[header]"> </ng-content>
      <router-outlet></router-outlet>
    </div>
    <app-footer class="d-none d-lg-block"></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #elseBlock>
  <router-outlet></router-outlet>
</ng-template>
