import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { DocsContratoComponent } from './docs-contrato/docs-contrato.component';
import { DocsPagareComponent } from './docs-pagare/docs-pagare.component';
import { LoaderComponent } from './loader/loader.component';
import { DocsPagaduriaComponent } from './docs-pagaduria/docs-pagaduria.component';
import { DialogFirmaDocumentosComponent } from '../../../dialogs/dialog-firma-documentos/dialog-firma-documentos.component';

import { DataService } from '../../../services/data.service';
import { AdoServiceService } from '../../../services/ado-service.service';
import { BuscCarpetaService } from 'src/app/services/perfilamiento/busc-carpeta.service';
import { DialogService } from '../../../services/dialog.service';

import { environment } from 'src/environments/environment';
import { PdfViewDialogComponent } from '@modules/shared/components/pdf-view-dialog/pdf-view-dialog.component';

@Component({
  selector: 'app-f-docs',
  templateUrl: './f-docs.component.html',
  styleUrls: ['./f-docs.component.css'],
})
export class FDocsComponent implements OnInit {


  public existeDocumentoPagaduria: string;
  public existeAuditoria: string;
  loading: boolean = false;
  public activeSpinner: boolean = true;
  public errorSpinner: boolean = true;
  public contrato_url: string = '';
  public pagare_url: string = '';
  private b64Pdf: string;

  constructor(
    public dialog: MatDialog,
    private dialogService: DialogService,
    private http: HttpClient,
    public data: DataService,
    private router: Router,
    public adoServ: AdoServiceService,
    public busqueda: BuscCarpetaService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.adoServ.numeroCarpeta = params.carpeta;
      this.adoServ.cedula = params.cedula;
      this.data.cedula_ado = params.cedula;
      this.data.carpeta_ado = params.carpeta;
    });
  }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(DialogFirmaDocumentosComponent, {
      disableClose: false,
    });

    dialogRef.beforeClosed().subscribe(() => {
      this.docsContrato();

      this.existeDocumentoPagaduria = this.router.parseUrl(
        this.router.url
      ).queryParams['pagaduria'];

      this.existeAuditoria = this.router.parseUrl(
        this.router.url
      ).queryParams['auditoria'];
    });
  }

  public onRedirectIdentValidate(): void {
    if (this.existeAuditoria === '0') {
      this.activeSpinner = true;
      this.busqueda.montoCarpeta(Number(this.adoServ.numeroCarpeta)).subscribe(({ monto }) => {
        this.adoServ.monto = Number(monto);
        this.data.monto_ado = Number(monto);
        this.router.navigate(['/validacion-identidad']);
      },
      (error) => {
        this.activeSpinner = false;
        this.errorSpinner = true;
        let messageError = error.error.message
          ? error.error.message
          : 'No se pudo obtener el monto de la carpeta';

        this.dialogService.openDialog('Advertencia', messageError, true);
      });
    } else if (this.existeAuditoria === '1') {
      Swal.fire('', 'Por el momento tú solicitud se encuentra en Auditoria, por favor inténtalo más tarde', 'warning');
    }
  }

  getContrato() {
    this.showPdf(this.data.contrato_url);
  }

  getPagare() {
    this.showPdf(this.data.pagare_url);
  }

  getPagaduria() {
    this.showPdf(this.data.pagaduria_url);
  }

  public docsContrato() {
    this.http
      .post(`${environment.ApiConecta}generacion/documentos/ADO`, {
        numeroCarpeta: this.adoServ.numeroCarpeta,
      })
      .subscribe(
        (http: any) => {
          this.http = http;

          this.data.contrato_url = http.contrato_url;
          this.data.pagare_url = http.pagare_url;
          this.data.pagaduria_url = http.contrato_url2 ? http.contrato_url2 : null;

          let splited = http.contrato_url.split('/');
          this.data.nombre_contrato = splited[splited.length - 1].split('?')[0];

          let splited2 = http.pagare_url.split('/');
          this.data.nombre_pagare = splited2[splited2.length - 1].split('?')[0];

          this.activeSpinner = false;
          this.errorSpinner = false;
        },
        (error) => {
          this.activeSpinner = false;
          this.errorSpinner = true;
          let messageError = error.error.message
            ? error.error.message
            : 'Tus documentos no se generaron correctamente, inténtalo más tarde';

          this.dialogService.openDialog('Advertencia', messageError, true);
        }
      );
  }

  loader() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      numeroCarpeta: this.adoServ.numeroCarpeta,
    };
    const dialogRef = this.dialog.open(LoaderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  public async showPdf(url: string){
    this.activeSpinner = true;
    let blobFile = await this.convertUrlToBlob(url);
    let b64File = await this.convertBlobToData(blobFile);
    this.activeSpinner = false;
    this. b64Pdf = String(b64File).split(',')[1];
    const dialogConfig: MatDialogConfig = {
      width: '100%',
      maxWidth: '500px',
      height:'fit-content ',
      panelClass: 'no-padding',
      data:{
        document_base64: this.b64Pdf
      }
    };
    this.dialog.open(PdfViewDialogComponent, dialogConfig);
  }

  async convertUrlToBlob(url: string) {
    var myRequest = new Request(url);
    return await fetch(myRequest)
      .then((response) => response.blob())
      .then(function (myBlob) {
        return myBlob;
      });
  }

  convertBlobToData = (blob: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
}
