import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleft',
  templateUrl: './cleft.component.html',
  styleUrls: ['./cleft.component.css']
})
export class CleftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
