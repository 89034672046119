import { Component, OnInit, Input } from '@angular/core';
import { BuscCarpetaService } from '../../../services/perfilamiento/busc-carpeta.service';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
import { ResumenAdoService } from '../../../services/resumen-ado.service';
import { ResendMessageService } from '../../../services/resend-message.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenOfertaService } from '../../../services/genOferta/gen-oferta.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resumen-credito',
  templateUrl: './resumen-credito.component.html',
  styleUrls: ['./resumen-credito.component.css'],
})
export class ResumenCreditoComponent implements OnInit {
  @Input() datosCarpeta: any;

  resAdo: any;
  genOf: any;

  constructor(
    public resumenAdo: ResumenAdoService,
    public resend: ResendMessageService,
    // private router: Router,
    private getofrtaServ: GenOfertaService,
    private http: HttpClient,
    public data: DataService
  ) {}

  ngOnInit(): void {
    this.resCarp(this.data);
  }

  public resCarp(data): void {
    this.resumenAdo.getResumenAd(data.numeroCarpeta).subscribe((res) => {
      this.resAdo = res;
      console.log(':: ResumenAdo, res', res);
    });
  }

  public resOferta(data): void {
    this.getofrtaServ.genOferta(data).subscribe((res) => {
      console.log('::OFERTA', res);
      this.genOf = res;
      console.log(':: Gen Ofert', res);
    });
  }

  public resetMessage() {
    this.http
      .post(`${environment.ApiConecta}resend/message`, {
        numeroCarpeta: this.data.numeroCarpeta,
        status: this.datosCarpeta.codigoEstado,
      })
      .subscribe((http: any) => {
        this.http = http;
        let value=http.url;
        console.log(http);
        if (http.status === 200) {
          Swal.fire({
            confirmButtonColor: '#009BDC',
            html:`<b>${http.message}</b><br>Si a tu cliente no le llegó el sms o correo, por favor copia y pega la siguiente URL y compártela con tu cliente:<br>
            <textarea id="clipboard-example-textarea" style="width: 24rem; height: 9rem;">${value}</textarea>`,
            titleText: 'OK',
            icon: 'success',
          });
        } else {
          Swal.fire('Advertencia', `${http.message}`, 'error');
        }
      },
      (err) => {
        Swal.fire('Advertencia', `${err.error.message}`, 'error');
      });
  }




}
