<aside class="w-full h-full flex flex-column justify-content-between">
  <header class="z-5">
    <figure
      class="w-full px-4 lg:px-0 mt-3 lg:mt-5 flex align-items-center justify-content-between lg:justify-content-center"
    >
      <img
        src="../../../assets/img/logo-2.svg"
        alt="logo bayport"
        class="w-3rem"
      />
      <div class="lg:hidden h-min flex">
        <nav
          class="w-11rem py-2 flex gap-2 justify-content-center border-round-3xl shadow-7 bg-blue-dark"
        >
          <div class="px-3 py-2 border-1 border-sky border-round-3xl">
            <i class="fa fa-usd text-sky-light" aria-hidden="true"></i>
          </div>
          <div
            class="px-3 py-2 flex gap-2 align-items-center border-1 border-sky border-round-3xl"
          >
            <i class="fa fa-bell text-sky-light" aria-hidden="true"></i>
          </div>
          <div
            class="h-min px-3 py-2 lg:hidden border-round-md border-none border-1 border-sky border-round-3xl"
            (click)="toggleSidebar(false)"
          >
            <i class="fa fa-bars text-sky-light" *ngIf="!isOpen; elseIcon"></i>
            <i class="fa fa-x text-sky-light" #elseIcon></i>
          </div>
        </nav>
      </div>
    </figure>
    <div class="flex justify-content-end mt-4 mr-4">
      <a
        routerLink="/mi-perfil"
        class="w-11rem flex justify-content-center text-sky-light text-base border-round-3xl py-2 border-sky"
      >
        Mi perfil
      </a>
    </div>
  </header>
  <menu
    class="max-menu-height mx-4 mb-10 px-4 py-2 border-round-md bg-blue-gradient-top text-lg lg:text-base"
  >
    <a
      routerLink="/home"
      class="hover-effect w-full py-3 block text-white no-underline"
    >
      Inicio
    </a>
    <a
      routerLink="/busqueda-carpetas"
      class="hover-effect w-full py-3 block text-white no-underline"
    >
      Mis créditos
    </a>
    <a
      routerLink="/reporteria"
      class="hover-effect w-full py-3 block text-white no-underline"
    >
      Mis reportes
    </a>
    <a
      routerLink="/capacitacion"
      class="hover-effect w-full py-3 block text-white no-underline"
    >
      Tus cursos
    </a>
    <details
      #detail1
      (click)="toggleDetails(detail2)"
      class="w-full py-3 block text-sm text-white"
    >
      <summary class="hover-effect flex justify-content-between text-lg">
        Mis trámites <i class="fa fa-arrow-right"></i>
      </summary>
      <hr class="w-full bg-white" />
      <a
        routerLink="/mis-tramites/referencia-comercial"
        class="hover-effect pl-2 pb-2 block text-sky-light"
      >
        Certificaciones
      </a>
      <!-- <a routerLink="/mis-tramites/actualizacion-cuenta" class="hover-effect pl-2 py-2 block text-sky-light">
        Actualización de cuenta
      </a> -->
      <a
        routerLink="/mis-tramites/carga-documentos"
        class="hover-effect pl-2 py-2 block text-sky-light"
      >
        Carga de documentos
      </a>
      <a
        routerLink="/mis-tramites/consulta-documentos"
        class="hover-effect pl-2 pt-2 block text-sky-light"
      >
        Consulta de documentos
      </a>
    </details>
    <details
      #detail2
      (click)="toggleDetails(detail1)"
      class="w-full py-3 block text-sm text-white"
    >
      <summary class="hover-effect flex justify-content-between text-lg">
        Debes saber <i class="fa fa-arrow-right"></i>
      </summary>
      <hr class="w-full bg-white" />
      <a
        routerLink="/home/debes-saber"
        class="hover-effect pl-2 pb-2 block text-white-alpha-80"
      >
        Home
      </a>
      <a
        routerLink="/home/debes-saber/politicas"
        class="hover-effect pl-2 py-2 block text-sky-light"
      >
        Políticas de producto
      </a>
      <a
        routerLink="/home/debes-saber/megafono"
        class="hover-effect pl-2 py-2 block text-sky-light"
      >
        Megáfono
      </a>
      <a
        routerLink="/home/debes-saber/calendario"
        class="hover-effect pl-2 pt-2 block text-sky-light"
      >
        Calendario comercial
      </a>
    </details>
    <hr class="w-full lg:hidden bg-cyan-400" />
    <div
      (click)="serviceAuth.logout()"
      class="flex gap-2 w-full py-3 text-sky-light text-base lg:hidden align-items-center justify-content-center text-center border-round-3xl border-sky"
    >
      <i class="fas fa-sign-out"></i>
      Cerrar Sesión
    </div>
  </menu>
</aside>
