// Angular
import { Component } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';

// Services
import { AuthService } from 'src/app/services/auth/auth.service';
import { RcActualizaPassService } from 'src/app/services/recuperacion/rc-actualiza-pass.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: 'app-iniciar-sesion',
    templateUrl: './iniciar-sesion.component.html',
    styleUrls: ['./iniciar-sesion.component.scss']
})
export class IniciarSesionComponent {
    loginForm: UntypedFormGroup;
    matcher = new MyErrorStateMatcher();

    hide: boolean = true;
    loading: boolean = false;

    errorLog: string | null = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private api: AuthService,
        private router: Router,
        private apiPass: RcActualizaPassService,
    ) {
        this.loginForm = this.formBuilder.group({
            user: [
                null,
                [
                    Validators.pattern('^\\d+$'),
                    Validators.minLength(1),
                    Validators.maxLength(15),
                    Validators.required,
                ],
            ],
            password: [
                null,
                [
                    Validators.minLength(7),
                    Validators.maxLength(33),
                    Validators.required,
                ],
            ],
        });
    }

    get userValue() {
        return this.loginForm.get('user');
    }

    get passwordValue() {
        return this.loginForm.get('password');
    }

    public onLogin(): void {
        const { user, password } = this.loginForm.value;

        localStorage.setItem('user', user)

        if (user && password) {
            this.loading = true;

            this.api.login(user, password).subscribe((data: any) => {
                if (data.code == 200) {
                    this.loading = false;
                    this.router.navigate(['home']);
                } else {
                    this.errorLog = data.message;
                    this.loading = false;
                }
            }, (errorAuth) => {
                this.loading = false;

                if (errorAuth.error.code === 401) {
                    this.loading = true;
                    this.apiPass.validationPassword(user, password, '_', 1).subscribe((data: any) => {
                        if (data.faError == -1) {
                            this.router.navigate(['expired-password']);
                        } else {
                            this.loading = false;
                            this.errorLog = errorAuth.error.error
                                ? data.faDescripcion
                                : 'Error desconocido';
                        }
                    }, (errorValidation) => {
                        if (errorValidation) {
                            this.errorLog = errorValidation.error.message;
                        } else {
                            this.errorLog = errorAuth.error.message;
                        }
                    });
                } else {
                    this.errorLog = errorAuth.error.message;
                }
            });

        } else {
            this.errorLog = 'Debe ingresar las credenciales';
        }
    }
}
