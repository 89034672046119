import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Login } from '../../models/login.module';

//declare var e: any;
//declare var grecaptcha: any;

@Component({
  selector: 'app-cright',
  templateUrl: './cright.component.html',
  styleUrls: ['./cright.component.css'],
})
export class CrightComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean = true;
  errorLog: String = '';

  constructor(private formBuilder: FormBuilder, private api: AuthService) {
    this.loginForm = new FormGroup({
      user: new FormControl('', [
        Validators.pattern(' / ^ [0-9] $ /'),
        Validators.minLength(1),
        Validators.maxLength(15),
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.pattern(
          '^(?=.*d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])S$'
        ),
        Validators.minLength(8),
        Validators.maxLength(30),
        Validators.required,
      ]),
    });
  }

  ngOnInit(): void {}

  // Recapcha ::.
  // onLogin() {
  //e.preventDefault();
  // grecaptcha.ready(function () {
  // grecaptcha.execute('6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP', { action: 'submit' }).then(function (token: any) {
  // Add your logic to submit to your backend server here.
  // console.log(token)
  //loginCallback()
  // });
  //});
  //}

  loginCallback() {
    this.api
      .login(
        this.loginForm.controls.email.value,
        this.loginForm.controls.password.value
      )
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error.error.message);
          this.errorLog = error.error.message;
        }
      );
  }
}
