import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificaService{
  private endpoint:string = environment.ApiConecta;

  constructor(
    private http:HttpClient
  ) { }

  countNotification(){
    let total = 0;
    return this.http.get(this.endpoint + 'alerta/carpeta/usuario',{}).subscribe( (res:any) =>{
      total = res.count;
    })

    return total;
  }

  getNotificaciones():Observable<any>{
    return this.http.get(this.endpoint + 'alerta/carpeta/usuario',{});
  }

  cambioEstado(numeroCarpeta,estadoNuevo,observacion):Observable<any>{
    return this.http.post(this.endpoint + 'cambio-estado',{ 
      numeroCarpeta,
      estadoNuevo,
      observacion 
    });
  }

  tipoNovedad(numeroCarpeta,tipoNovedad:any,observaciones):Observable<any>{
    return this.http.post(this.endpoint + 'notificacion/novedades',{
      numeroCarpeta,
      tipoNovedad,
      observaciones,
    });
  }

  deleteNovedad(numeroCarpeta):Observable<any>{
    return this.http.delete(this.endpoint + `alerta/eliminacion/${numeroCarpeta}`);
  }

}
