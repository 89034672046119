import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.css']
})
export class HeaderMobileComponent implements OnInit {

  constructor(
    private router:Router,
    private activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  rout(){
    this.router.navigate(['/autorizacionTransferencia']);
  }

}
