<nav class="nav" style="margin-left: 2%; padding-top: 100px;">
    <a class="nav-link " routerLink="/busqueda-carpetas">
        <h6>Búsqueda de carpetas</h6>
    </a>
    <img src="../../../assets/icons/next.svg" class="page-link">
    <a class="nav-link">
        <h6>Resultado de la búsqueda</h6>
    </a>
</nav>
<div class="retroceder" routerLink="/busqueda-carpetas"><i class="fa-solid fa-arrow-left"></i> Volver</div>
<app-container header="Resultado de la búsqueda" style="background: transparent !important;">
    <div class="row">
        <div class="col-md-4" *ngFor="let result of dataRes.rows | paginator : page_size : pageNumber">
            <app-cards>
                <div class="row" style="text-align: center;">
                    <div class="col-md-6 border-info border-right-1">
                        <h6 class="labelT">Fecha</h6>
                        <h5 style="display: inline-block;">
                            <strong>{{ result.fechaCreacion | date: "dd/MM/YYYY" }}</strong>
                        </h5>
                    </div>
                    <div class="col-md-6">
                        <h6 class="labelT">Número de Carpeta</h6>
                        <h5>
                            <strong>{{ result.numeroCarpeta }}</strong>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="alert  " role="alert"
                        [style]="{'background': result.semaforo.TBC_COLOR, 'width':'100%', 'border-radius':'0%','margin-left':'0%'}">
                        <div class="row">
                            <div class="col-md-1 icono" [innerHtml]="result.semaforo.ICON | safeHtml"
                                style="padding-left: 0%"></div>
                            <div class="col-md-11" style="padding: 0%">
                                <div class="row">
                                    <div
                                        style="color: #181D40;text-align: left; font-size: 11px;display: inline-block;">
                                        Estado actual de la carpeta
                                    </div>
                                </div>
                                <div class="row">
                                    <div style="color: #181D40;text-align: left; font-size: 16px;display: inline-block;"
                                        class="fw-semibold">
                                        {{ result.estado }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding: 0%">
                    <div class="col-md-6">
                        <div class="labelT  ">Pagaduria</div>
                        <div class="value fw-semibold">
                            {{ result.pagaduria }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="labelT  ">Monto</div>
                        <div class="value fw-semibold">
                            {{ result.montoSolicitado | currency }}
                        </div>
                    </div>
                </div><br>
                <div class="row" style="padding: 0%">
                    <div class="col-md-6">
                        <div class="labelT  ">Valor Cuota</div>
                        <div class="value fw-semibold">
                            {{ result.valorCuota | currency }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="labelT  ">Tasa de interes (1)</div>
                        <div class="value fw-semibold">
                            {{ result.tasaAprobada }}%
                        </div>
                    </div>
                </div><br>
                <div class="row" style="padding: 0%">
                    <div class="col-md-6">
                        <div class="labelT  ">Plazo (2)</div>
                        <div class="value fw-semibold">
                            {{ result.plazoSolicitado }} meses
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="labelT  ">Seguro Aplicado (3)</div>
                        <div class="value fw-semibold">
                            {{ result.planSeguroVoluntario | seguroVoluntario }}
                        </div>
                    </div>
                </div><br>
                <div class="row" style="padding: 0%">
                    <div class="col-md-6">
                        <div class="labelT  ">Sucursal</div>
                        <div class="value fw-semibold">
                            {{ result.nombreSucursal | sucursal }}
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-6">
                        <button-look-feel [block]="true" size="small" color="outline" style="font-size: 10px;"
                             (clicked)="detalleCarpeta(result)">
                            Ver Detalles
                        </button-look-feel>
                    </div>
                    <div class="col-md-6">
                        <button-look-feel [block]="true" size="small" [loading]="isLoading" customClass="px-0"
                            color="secondary" (click)="goStep(result)">
                            Retomar carpeta
                        </button-look-feel>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>
    <br>
    <app-pagination [totalItems]="total_pages" [itemsPerPage]="page_size" [pageSizeOptions]="pageSizeOptions"
        (pageChange)="onPageChange($event)">
    </app-pagination>
</app-container>