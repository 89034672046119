<!-- <div class="card shadow-sm" style="width: 18rem; border-radius: 0">
  <div style="padding: 0" class="card-body">
    <label id="fecha" class="card-title"><strong>Fecha: </strong>{{ datosCarpeta.fechaCreacion | date: "dd-MM-YYYY"
      }}</label>
    <div class="p-2">
      <div class="row">
        <div class="col-7">
          <label style="width: 100%">Monto</label>
          <label class="value">{{
            datosCarpeta.montoSolicitado | currency
            }}</label>
        </div>
        <div class="col-5">
          <label>Valor Cuota</label>
          <label class="value">{{ datosCarpeta.valorCuota | currency }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Tasa de interes (1)</label>
          <label class="value">{{ datosCarpeta.tasaAprobada }}%</label>
        </div>
        <div class="col-5">
          <label>Plazo (2)</label>
          <label class="value">{{ datosCarpeta.plazoSolicitado }} meses</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Seguro Aplicado (3)</label>
          <label class="value">{{
            datosCarpeta.planSeguroVoluntario | seguroVoluntario
            }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Estado de Carpeta</label>
          <label class="value">{{ datosCarpeta.estado }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Número de Carpeta</label>
          <label class="value">{{ datosCarpeta.numeroCarpeta }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Pagaduria</label><br />
          <label class="value">{{ datosCarpeta.pagaduria }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <label>Sucursal</label> <br />
          <label class="value">{{
            datosCarpeta.nombreSucursal | sucursal
            }}</label>
        </div>
      </div>

      <ng-content select="[footer]"> </ng-content>
    </div>
  </div>
</div> -->
<br>
<div class="card shadow p-0  bg-body rounded" style="width: 340px;">
  <div class="row" style="text-align: center;">
    <div class="col-md-6 border-info border-right-1">
      <label class="labelT font-omnes" style="padding-top: 3%;">Fecha</label><br>
      <label style="display: inline-block; font-size: 18px;" class="font-omnes-semibold"><strong>{{ datosCarpeta.fechaCreacion | date:
          "dd-MM-YYYY"}}</strong></label>
    </div>
    <div class="col-md-6">
      <label class="labelT font-omnes" style="padding-top: 3%;">Número de Carpeta</label>
      <!-- <label><strong>{{ datosCarpeta.semoforo }}</strong></label> -->
      <label class="font-omnes-semibold" style="font-size: 18px;"><strong>{{ datosCarpeta.numeroCarpeta }}</strong></label>
    </div>
  </div>
  <div class="row">
    <div class="alert font-omnes" role="alert"
      [style]="{'background': estadoColor, 'width':'100%', 'border-radius':'0%'}">
      <div class="row">
        <div class="col-md-2 icono" [innerHTML]="estadoIcono"></div>
        <div class="col-md-10">
          <div class="row">
            <div style="color: #181D40;text-align: left; font-size: 11px;display: inline-block;" class="font-omnes">
              Estado actual de la carpeta
            </div>
          </div>
          <div class="row">
            <div style="color: #181D40;text-align: left; font-size: 16px;display: inline-block;"
              class="font-omnes-medium">
              {{ datosCarpeta.estado }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="labelT font-omnes">Pagaduria</div>
      <div class="value font-omnes-medium">{{ datosCarpeta.pagaduria }}</div>
    </div>
    <div class="col-md-6">
      <div class="labelT font-omnes">Monto</div>
      <div class="value font-omnes-medium">
        {{ datosCarpeta.montoSolicitado | currency }}
      </div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT font-omnes">Valor Cuota</div>
      <div class="value font-omnes-medium">{{ datosCarpeta.valorCuota | currency }}</div>
    </div>
    <div class="col-md-6">
      <div class="labelT font-omnes">Tasa de interes (1)</div>
      <div class="value font-omnes-medium">{{ datosCarpeta.tasaAprobada }}%</div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT font-omnes">Plazo (2)</div>
      <div class="value font-omnes-medium">{{ datosCarpeta.plazoSolicitado }} meses</div>
    </div>
    <div class="col-md-6">
      <div class="labelT font-omnes">Seguro Aplicado (3)</div>
      <div class="value font-omnes-medium">
        {{ datosCarpeta.planSeguroVoluntario | seguroVoluntario }}
      </div>
    </div>
  </div><br>
  <div class="row">
    <div class="col-md-6">
      <div class="labelT font-omnes">Sucursal</div>
      <div class="value font-omnes-medium">
        {{ datosCarpeta.nombreSucursal | sucursal }}
      </div>
    </div>
  </div>
  <br>
  <ng-content select="[footer]"> </ng-content>
  <br>
</div>