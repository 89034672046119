import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  GetCatalogBody,
  GetDocsByAdviserBody,
  GetDocumentBody,
  UploadDocsBody,
} from 'src/app/models/tramites/formalities.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormalitiesService {
  private readonly api = environment.ApiConecta + 'documentation';
  public getDocs: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  public uploadDocs(body: UploadDocsBody): Observable<any> {
    return this.http.post<any>(`${this.api}/upload-document`, body);
  }

  public uploadBankAccount(body: any): Observable<any> {
    return this.http.post<any>(`${this.api}/bank-account`, body);
  }

  public getDocument(body: GetDocumentBody): Observable<any> {
    return this.http.post<any>(`${this.api}`, body);
  }

  public getDocsByAdviser(body: GetDocsByAdviserBody): Observable<any> {
    return this.http.post<any>(`${this.api}/by-adviser`, body);
  }

  public getDocsByDocumentId(id: number): Observable<any> {
    return this.http.get(`${this.api}/by-adviser/${id}`);
  }

  public getCatalog(body: GetCatalogBody): Observable<any> {
    return this.http.post(`${this.api}/catalog`, body);
  }
}
