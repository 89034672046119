<div class="card">
  <div class="card__content">
    <div
      *ngIf="!uploadedFile"
      style="
        display: grid;
        grid-template-columns: 1fr max-content;
        width: 100%;
        align-content: center;
      "
    >
      <section>
        <h4 cl ass="card__title">Documentos adicionales</h4>
        <p class="card__text">Da click en el botón agregar para cargar</p>
      </section>
      <section style="display: flex; justify-content: center; align-items: end">
        <label>
          <p class="card__button" style="color: #10aeef !important">Agregar</p>
          <input
            type="file"
            style="display: none"
            (change)="onFileChange($event)"
          />
        </label>
      </section>
    </div>
    <div *ngIf="uploadedFile" style="width: 100%">
      <div style="display: flex; flex-direction: column">
        <section>
          <h4 class="card__title">Documentos adicionales</h4>
          <p class="card__text">{{ fileName }}</p>
        </section>
        <section
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <section>
            <h4 class="card__title">Nombre de archivo</h4>
            <input
              type="text"
              placeholder="*nombre del archivo"
              class="card__input"
              [(ngModel)]="newFileName"
              [ngClass]="{ 'input-error': isInputInvalid }"
            />
            <p class="error-message" *ngIf="isInputInvalid">
              Debes asignar un nombre al archivo
            </p>
          </section>
          <section>
            <button class="card__button-disabled" (click)="onSaveFileChange()">
              Guardar
            </button>
          </section>
        </section>
      </div>
    </div>
  </div>
</div>
