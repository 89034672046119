import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { FormalitiesService } from '@modules/mis-tramites/services/formalities.service';
import { CatalogParamsService } from '@modules/shared/services/catalog-params.service';
import { UserService } from '@modules/shared/services/user.service';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomValidators } from 'src/app/proveiders/CustomValidators';
import { AdicionalesService } from 'src/app/services/catalogos/adicionales.service';
import { LlmsService } from 'src/app/services/llms.service';
import Swal from 'sweetalert2';
import { MiPerfilService } from '../../services/mi-perfil/mi-perfil.service';

@Component({
  selector: 'app-mi-perfil-page',
  templateUrl: './mi-perfil-page.component.html',
  styleUrls: ['./mi-perfil-page.component.scss'],
})
export class MiPerfilPageComponent implements OnInit, OnDestroy {
  @ViewChild('iconIBP') ElIconIBP: MatIcon;
  @ViewChild('iconIP') ElIconIP: MatIcon;
  @ViewChild('iconSB') ElIconSB: MatIcon;
  @ViewChild('iconIB') EliconIB: MatIcon;
  @ViewChild('iconIF') EliconIF: MatIcon;

  public infoBayport: any = '';
  public infoPersonal: any = '';
  public infoSocial: any = '';
  public infoBancaria: any = '';
  public infoFinanciera: any = '';
  public disabled = true;
  user = '';

  public startDate = new Date(1950, 1, 1);

  public errorCel1: boolean = false;
  public errorCel2: boolean = false;
  public errorCel3: boolean = false;
  public errorCel4: boolean = false;

  public errordescOtIn1: boolean = false;
  public errordescOtIn2: boolean = false;
  public errordescOtIn3: boolean = false;

  public estados = [
    { value: 1, name: 'ACTIVO' },
    { value: 2, name: 'INACTIVO' },
  ];

  filteredOptionsCiudad: Observable<Array<any>>;
  filteredOptionsExpedition: any;
  filteredOptionsDep: Observable<Array<any>>;

  public formsCompletos: boolean = false;

  public bayportInfoForm: UntypedFormGroup = new UntypedFormGroup({
    executiveCode: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    executiveType: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    creationDate: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    state: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    regional: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    branch: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    commertialCoordinator: new UntypedFormControl(
      { value: '', disabled: true },
      [Validators.required]
    ),
    bayportEmail: new UntypedFormControl({ value: '', disabled: false }, [
      Validators.compose([Validators.required, Validators.email]),
    ]),
    channelType: new UntypedFormControl({ value: 1, disabled: true }, [
      Validators.required,
    ]),
    salesChannel: new UntypedFormControl({ value: 1, disabled: true }, [
      Validators.required,
    ]),
  });

  public formInfoPersonal: UntypedFormGroup = new UntypedFormGroup({
    celular: new UntypedFormControl(
      {
        value: '',
        disabled: false,
      },
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        CustomValidators.patternValidator(/^3/i, { numberThree: true }),
        Validators.pattern(/^[0-9]+$/i),
      ]
    ),
    ciudad: new UntypedFormControl(
      {
        value: '',
        disabled: false,
      },
      [
        Validators.required,
        Validators.pattern(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i),
      ]
    ),
    correoElectronico: new UntypedFormControl(
      {
        value: '',
        disabled: false,
      },
      [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
        ),
      ]
    ),
    departamento: new UntypedFormControl(
      {
        value: '',
        disabled: false,
      },
      [
        Validators.required,
        Validators.pattern(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i),
      ]
    ),
    direccion: new UntypedFormControl({ value: 'hola', disabled: false }, [
      Validators.required,
      Validators.maxLength(50),
    ]),
    estadoCivil: new UntypedFormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    estudiosRealizados: new UntypedFormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    fechaExpedicion: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    fechaNacimiento: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    genero: new UntypedFormControl({ value: 1, disabled: true }, [
      Validators.required,
    ]),
    lugarExpedicion: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    lugarNacimiento: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    numeroDocumento: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),

    primerApellido: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    primerNombre: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    segundoApellido: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    segundoNombre: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    tipoVivienda: new UntypedFormControl({ value: '', disabled: false }, [
      Validators.required,
    ]),
    typeDoc: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  public socialBenefitsInfoForm: UntypedFormGroup = new UntypedFormGroup({
    eps: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i),
    ]),
    afp: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i),
    ]),
    arl: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i),
    ]),
  });

  public formInfoBancaria: UntypedFormGroup = new UntypedFormGroup({
    banco: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    tipoCuenta: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    numeroCuenta: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  public formInfoFinanciera: UntypedFormGroup = new UntypedFormGroup({
    totalActivos: new UntypedFormControl('', [
      // Validators.required,
      Validators.maxLength(12),
      Validators.minLength(1),
      CustomValidators.patternValidator(/^[0-9]+$/i, { onlyNumber: true }),
    ]),
    totalPasivos: new UntypedFormControl('', [
      // Validators.required,
      Validators.maxLength(12),
      Validators.minLength(1),
      CustomValidators.patternValidator(/^[0-9]+$/i, { onlyNumber: true }),
    ]),
    gastos: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(12),
      Validators.minLength(1),
      CustomValidators.patternValidator(/^[0-9]+$/i, { onlyNumber: true }),
    ]),
    ingresos: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(12),
      Validators.minLength(1),
      CustomValidators.patternValidator(/^[0-9]+$/i, { onlyNumber: true }),
    ]),
    otrosIngresos: new UntypedFormControl('', [
      Validators.maxLength(12),
      Validators.minLength(1),
      CustomValidators.patternValidator(/^[0-9]+$/i, { onlyNumber: true }),
    ]),
    descripcionOtrosIngresos: new UntypedFormControl('', [
      // Validators.required,
      Validators.maxLength(150),
      Validators.minLength(5),
      CustomValidators.patternValidator(/^[a-zA-Zá-úÁ-Úä-üÄ-Ü\,-\.-\ ]*$/i, {
        onlyLetters: true,
      }),
    ]),
    personasAcargo: new UntypedFormControl(
      {
        value: 0,
        disabled: false,
      },
      [
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern(/^[0-9]+$/i),
      ]
    ),
  });
  identificationType: Array<{
    codigoTipoIdentificacion: string;
    id: string;
    nombre: string;
  }> = [];
  executiveType: Array<{
    secuencia: number;
    indTipoEjecutivo: number;
    descripcion: string;
  }> = [];
  regional: Array<{ codigoRegional: number; nombre: string }> = [];
  sucursales: Array<any> = [];
  channelType: Array<{ codCnlVta: number; nomCnlVta: string }> = [];
  salesChannel: Array<{ codigoTipo: number; nombreTipo: string }> = [];
  cities: Array<any> = [];
  edoCivil: Array<{ codigo: number; nombre: string }> = [];
  genders: Array<{ codigo: number; nombre: string }> = [];
  scholarship: Array<{ codigoEstudio: number; nombreEstudio: string }> = [];
  departments: Array<any> = [];
  citiesPer: Array<any> = [];
  banks: Array<any> = [];
  accountTypes: Array<any> = [];
  codDepto: number;
  codMunic: number;
  eps: Array<any> = [];
  arl: Array<any> = [];
  vivienda: Array<any> = [];

  codigoAsesor: any;
  tipoEjec: any;
  estado: any;
  fechaCrea: any;
  tipoEjecDes: any;
  regionalId: any;
  regionalNombre: any;
  sucursalId: any;
  sucursalNombre: any;
  canalId: any;
  canalNombre: any;
  canalVentaId: any;
  canalVentaNombre: any;
  nombreRefiere: any;

  isLoading: boolean = false;

  typeUser: string;

  tipoDocumento: any;

  isUpdate = false;

  numeroDocumento = '';
  fechaExpedicion = '';
  lugarExpedicion = '';
  primerNombre = '';
  segundoNombre = '';
  primerApellido = '';
  segundoApellido = '';
  fechaNacimiento: '';
  lugarNacimiento: '';
  departamento: '';
  ciudad: '';
  direccion: '';
  tipoVivienda: '';
  newEps: '';
  afp: '';
  newArl: '';
  totalActivos: '';
  totalPasivos: '';
  gastosMensuales: '';
  ingresosMensuales: '';
  otrosIngresosMensuales: '';
  personasCargo: '';

  items = ['Femenino', 'Masculino'];
  activeIndex = 0;
  banco = '';
  tipoCuenta = '';
  numeroCuenta = '';
  archivo64 = '';

  constructor(
    private render: Renderer2,
    private miPerfilService: MiPerfilService,
    private _formBuilder: UntypedFormBuilder,
    private _paramsService: CatalogParamsService,
    private _additionalsServices: AdicionalesService,
    private _llmsService: LlmsService,
    private _router: Router,
    private _formalitiesService: FormalitiesService,
    private _userService: UserService
  ) {
    this.typeUser = localStorage.getItem('tpcnl');
    this.bayportInfoForm = this._formBuilder.group({
      executiveCode: ['', Validators.required],
      executiveType: ['', Validators.required],
      creationDate: ['', Validators.required],
      state: [1, Validators.required],
      regional: [1, Validators.required],
      branch: [1, Validators.required],
      commertialCoordinator: [1, Validators.required],
      bayportEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(
            this.typeUser === 'freelance'
              ? /^([a-zA-Z0-9_\-.]+)@([a-zA-Z_\-.]+)\.([a-zA-Z]{2,5})$/
              : /^([a-zA-Z0-9_\-.]+)@bayport\.([a-zA-Z_\-.]{2,7})$/i
          ),
        ]),
      ],
      channelType: [1, Validators.required],
      salesChannel: [1, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getCatalogs();
    await this.getInfoBayport();
    await this.getInfoPersonal();
    await this.getInfoSocial();
    this.getInfoBancaria();
    await this.getInfoFinanciera();
    this.nombreUsuario();
  }
  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`;
    } else {
      this.user = `${this.user[0]}`;
    }
  }

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  private async getCatalogs(): Promise<void> {
    this.getIdentificationType();
    this.getExecType();
    this.getRegional();
    this.getSucursales();
    this.getChannelType();
    await this.getSalesChannel();
    this.getCities();
    this.getMaritalStatus();
    this.getGenders();
    this.getScholarship();
    this.getDepartments();
    this.getVivienda();
    await this.getBanks();
    this.getAccountType();
    this.getEPS();
    this.getARL();
  }

  private getIdentificationType(): void {
    this._paramsService.getIdentifacationType().subscribe(
      (res) => {
        this.identificationType = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getExecType(): void {
    this._paramsService.getExecutiveType().subscribe(
      (res) => {
        this.executiveType = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getRegional(): void {
    this._paramsService.getRegional().subscribe(
      (res) => {
        this.regional = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getSucursales(): void {
    this._additionalsServices.getSucursales().subscribe(
      (res) => {
        this.sucursales = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getChannelType(): void {
    this._paramsService.getChannelType().subscribe(
      (res) => {
        this.channelType = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private async getSalesChannel(): Promise<void> {
    try {
      const res = await this._paramsService.getSalesChannel().toPromise();
      this.salesChannel = res.rows;
    } catch (error) {
      console.error(error.error.message);
    }
  }

  private getCities(): void {
    this._additionalsServices.getAllCiudades().subscribe((res) => {
      this.cities = res.rows;
      //this.filteredOptionsCiudad = this.cities.slice();
      this.filteredOptionsExpedition = this.cities.slice();
    });
  }

  private getCitiesById(codDep: number): void {
    this._additionalsServices.getCiudades(codDep).subscribe((res) => {
      this.citiesPer = this.filteredOptionsCiudad = res.rows.slice();
      this.citiesPer = res.rows;
      this.filteredOptionsCiudad =
        this.formInfoPersonal.controls.ciudad.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterCities(value))
        );
    });
  }

  private debounceTime?: NodeJS.Timeout;
  public valueCities: string;

  public citiesChange(query: string = '', fieldNumber: number): void {
    if (this.debounceTime) clearTimeout(this.debounceTime);
    this.debounceTime = setTimeout(() => {
      this._additionalsServices.getCuidadesPorLike(query).subscribe((res) => {
        if (query === '' && fieldNumber === 1) {
          this.valueCities = '';
          return;
        } else if (query === '' && fieldNumber === 2) {
        }

        if (fieldNumber === 1) {
          this.filteredOptionsCiudad = res.rows;
        } else if (fieldNumber === 2) {
          this.filteredOptionsExpedition = res.rows;
        }
      });
    }, 100);
  }

  private getGenders(): void {
    this._paramsService.getGenders().subscribe(
      (res) => {
        this.genders = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getMaritalStatus(): void {
    this._additionalsServices.getEstadoCivil().subscribe(
      (res) => {
        this.edoCivil = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getScholarship(): void {
    this._paramsService.getScholarship().subscribe(
      (res) => {
        this.scholarship = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getDepartments(): void {
    this._additionalsServices.getDepartamentos().subscribe(
      (res) => {
        this.departments = res.rows;
        this.filteredOptionsDep =
          this.formInfoPersonal.controls.departamento.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterDepartments(value))
          );
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getVivienda(): void {
    this._additionalsServices.getTipoVivienda().subscribe(
      (res) => {
        var tipo = res.sort((a, b) =>
          a.descripcion.localeCompare(b.descripcion)
        );
        this.vivienda = tipo;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private async getBanks(): Promise<void> {
    try {
      const res = await this._paramsService.getBanks().toPromise();
      this.banks = res.rows;
    } catch (error) {
      console.error(error.error.message);
    }
  }

  private getAccountType(): void {
    this._additionalsServices.getTipoCuenta().subscribe(
      (res) => {
        this.accountTypes = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getEPS(): void {
    this._additionalsServices.getEps().subscribe(
      (res) => {
        this.eps = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private getARL(): void {
    this._additionalsServices.getArp().subscribe(
      (res) => {
        this.arl = res.rows;
      },
      (err) => {
        console.error(err.error.message);
      }
    );
  }

  private _filterDepartments(value: string): Array<string> {
    const filterValue = value.toLowerCase();
    return this.departments.filter((option) => {
      return option.nomDepto.toLowerCase().includes(filterValue);
    });
  }

  private _filterCities(value: string): Array<string> {
    const filterValue = value.toLowerCase();
    return this.citiesPer.filter((option) => {
      return option.nomMunic.toLowerCase().includes(filterValue);
    });
  }

  setDeptoValue(evt: any): void {
    this.departments.every((item) => {
      if (
        item.nomDepto.toLowerCase() === evt.target.value.toLowerCase() ||
        item.codDepto === evt.target.value
      ) {
        this.formInfoPersonal.controls.departamento.setValue(
          item.nomDepto.toUpperCase()
        );
        return false;
      }
      return true;
    });
  }

  getCityName = (cod: string) => {
    let cityName: string;
    this.cities.find((item) => {
      if (item.codMunic === cod) {
        cityName = item.nomMunic;
      }
    });
    return cityName;
  };

  setCityValue(evt: any): void {
    /*let i_split = String(this.cities.length / 1000).split('.');
    let _i = Number(i_split[0]);
    let a = 0;

    for (let i = 0; i <= _i; i++) {
      a = (i) * 1000 + 1;
      this.cities.slice(i === 0 ? 0 : a, (i + 1) * 1000).every(item => {
        if (item.codMunic === evt.target.value) {
          this.formInfoPersonal.controls.ciudad.setValue(item.nomMunic.toUpperCase());
          this.isLoading = false;
          return false;
        }
        return true;
      });
      if (!this.isLoading)
        break;
    }*/

    this.citiesPer.every((item) => {
      if (
        item.nomMunic.toLowerCase() === evt.target.value.toLowerCase() ||
        item.codMunic === evt.target.value
      ) {
        this.formInfoPersonal.controls.ciudad.setValue(
          item.nomMunic.toUpperCase()
        );
        return false;
      }
      return true;
    });
  }

  setDeptoOnClick(value: any): void {
    this.codDepto = value;
    this.formInfoPersonal.controls.ciudad.setValue('');
    this.getCitiesById(value);
  }

  setCityOnClick(value: any): void {
    this.codMunic = value;
  }

  /**
   * Aplica la clase flip a el elemento recibido y elimina la clase de los elementos que no la requieren.
   * @param el Referencia al icono al que se le quiere aplicar la clase flip.
   */
  onClick(el: any): void {
    const { _elementRef } = el;
    const { nativeElement } = _elementRef;
    const iconsList: Array<ElementRef> = [
      this.ElIconIBP._elementRef,
      this.ElIconIP._elementRef,
      this.ElIconSB._elementRef,
      this.EliconIB._elementRef,
      this.EliconIF._elementRef,
    ];
    iconsList.forEach((item: ElementRef) => {
      if (
        Array.from(item.nativeElement.classList).includes('flip') &&
        _elementRef != item
      ) {
        this.render.removeClass(item.nativeElement, 'flip');
      }
    });
    if (Array.from(nativeElement.classList).includes('flip')) {
      this.render.removeClass(nativeElement, 'flip');
    } else {
      this.render.addClass(nativeElement, 'flip');
    }
  }

  async getInfoBayport() {
    const resp = await this.miPerfilService.getInfoBayport().toPromise();

    this.infoBayport = resp;
    this.bayportInfoForm.controls.executiveCode.setValue(resp.codigoAsesor);
    this.codigoAsesor = resp.codigoAsesor;
    this.bayportInfoForm.controls.executiveCode.disable();
    this.bayportInfoForm.controls.executiveType.setValue(resp.tipoEjecutivo);
    this.tipoEjec = resp.tipoEjecutivo;
    this.executiveType.forEach((item) => {
      if (item.indTipoEjecutivo == this.tipoEjec) {
        this.tipoEjecDes = item.descripcion;
      }
    });

    this.bayportInfoForm.controls.executiveType.disable();
    this.bayportInfoForm.controls.creationDate.setValue(
      new Date(resp.fechaCreacion)
    );
    this.fechaCrea = resp.fechaCreacion;

    this.bayportInfoForm.controls.creationDate.disable();
    this.bayportInfoForm.controls.state.setValue(resp.estado);
    this.estado = resp.estado;
    this.bayportInfoForm.controls.state.disable();
    this.bayportInfoForm.controls.regional.setValue(resp.regional);
    this.regionalId = resp.regional;
    this.regional.forEach((item) => {
      if (item.codigoRegional == this.regionalId) {
        this.regionalNombre = item.nombre;
      }
    });
    this.bayportInfoForm.controls.regional.disable();
    this.bayportInfoForm.controls.branch.setValue(resp.sucursal);
    this.sucursalId = resp.sucursal;
    this.sucursales.forEach((item) => {
      if (item.codigoSucursal == this.sucursalId) {
        this.sucursalNombre = item.nombreSucursal;
      }
    });
    this.bayportInfoForm.controls.branch.disable();
    this.bayportInfoForm.controls.commertialCoordinator.setValue(
      resp.coordinadorComercial
    );
    this.nombreRefiere = resp.coordinadorComercial;

    this.bayportInfoForm.controls.commertialCoordinator.disable();
    this.bayportInfoForm.controls.bayportEmail.setValue(resp.correo);
    this.bayportInfoForm.controls.channelType.setValue(resp.tipoCanal);
    this.canalId = resp.tipoCanal;
    this.channelType.forEach((item) => {
      if (item.codCnlVta == this.canalId) {
        this.canalNombre = item.nomCnlVta;
      }
    });

    this.bayportInfoForm.controls.channelType.disable();
    this.bayportInfoForm.controls.salesChannel.setValue(resp.canalVenta);
    this.canalVentaId = resp.canalVenta;
    this.salesChannel.forEach((item) => {
      if (item.codigoTipo == this.canalVentaId) {
        this.canalVentaNombre = item.nombreTipo;
      }
    });

    this.bayportInfoForm.controls.salesChannel.disable();
  }

  async getInfoPersonal() {
    this.isLoading = false;
    const resp = await this.miPerfilService.getInfoPersonal().toPromise();

    this.infoPersonal = resp;
    this.getCitiesById(resp.departamento);
    this.formInfoPersonal.controls.typeDoc.setValue(String(resp.tipoDocumento));
    this.formInfoPersonal.controls.numeroDocumento.setValue(
      resp.numeroDocumento
    );
    this.formInfoPersonal.controls.primerNombre.setValue(resp.primerNombre);
    this.formInfoPersonal.controls.segundoNombre.setValue(resp.segundoNombre);
    this.formInfoPersonal.controls.primerApellido.setValue(resp.primerApellido);
    this.formInfoPersonal.controls.segundoApellido.setValue(
      resp.segundoApellido
    );
    this.formInfoPersonal.controls.lugarExpedicion.setValue(
      resp.lugarExpedicionDocumento
    );
    this.formInfoPersonal.controls.fechaExpedicion.setValue(
      new Date(resp.fechaExpedicionDocumento)
    );
    this.formInfoPersonal.controls.lugarNacimiento.setValue(
      String(resp.lugarNacimiento)
    );
    this.formInfoPersonal.controls.fechaNacimiento.setValue(
      new Date(resp.fechaNacimiento)
    );
    this.formInfoPersonal.controls.genero.setValue(resp.genero);
    this.activeIndex = resp.genero == 2 ? 0 : 1;
    this.formInfoPersonal.controls.estadoCivil.setValue(resp.estadoCivil);
    this.formInfoPersonal.controls.estudiosRealizados.setValue(
      resp.estudiosRealizados
    );
    this.formInfoFinanciera.controls.personasAcargo.setValue(
      resp.personasACargo
    );
    this.formInfoPersonal.controls.tipoVivienda.setValue(resp.tipoVivienda);
    this.formInfoPersonal.controls.direccion.setValue(resp.direccion);
    this.setCityOnClick(resp.ciudad);
    this.setDeptoOnClick(resp.departamento);
    setTimeout(() => {
      this.setDeptoValue({ target: { value: String(resp.departamento) } });
      this.setCityValue({ target: { value: String(resp.ciudad) } });
      this.citiesChange(this.getCityName(resp.lugarExpedicionDocumento), 2);
    }, 3000);
    this.formInfoPersonal.controls.celular.setValue(resp.celular);
    this.formInfoPersonal.controls.correoElectronico.setValue(resp.correo);

    const [ciudad] = this.cities.filter(
      (el) => el.codMunic == resp.lugarNacimiento
    );

    this.numeroDocumento = resp.numeroDocumento;
    this.fechaExpedicion = resp.fechaExpedicionDocumento;
    this.primerNombre = resp.primerNombre;
    this.segundoNombre = resp.segundoNombre;
    this.primerApellido = resp.primerApellido;
    this.segundoApellido = resp.segundoApellido;
    this.fechaNacimiento = resp.fechaNacimiento;
    this.lugarNacimiento = ciudad.filteredName;
    this.departamento = resp.departamento;
    this.ciudad = resp.ciudad;
    this.direccion = resp.direccion;
    this.tipoVivienda = resp.tipoVivienda;
    this.personasCargo = resp.personasACargo;

    console.log({
      lugar: this.cities.filter((el) => el.codMunic == resp.lugarNacimiento),
    });

    this.isLoading = false;
  }

  async getInfoSocial() {
    const resp = await this.miPerfilService
      .getInfoPrestacionesSociales()
      .toPromise();

    this.infoSocial = resp;
    this.socialBenefitsInfoForm.controls.eps.setValue(resp.eps);
    this.socialBenefitsInfoForm.controls.afp.setValue(resp.afp);
    this.socialBenefitsInfoForm.controls.arl.setValue(resp.arl);
  }

  getInfoBancaria() {
    this.miPerfilService.getInfoBancaria().subscribe((resp) => {
      const { nombre } = this.banks.find((el) => el.codigo == resp.banco);

      this.banco = nombre;

      this.tipoCuenta = resp.tipoCuenta == 1 ? 'Ahorros' : 'Corriente';
      this.numeroCuenta = resp.numeroCuenta;

      this.formInfoBancaria.controls.banco.setValue(nombre);
      this.formInfoBancaria.controls.tipoCuenta.setValue(
        resp.tipoCuenta == 1 ? 'Ahorros' : 'Corriente'
      );
      this.formInfoBancaria.controls.numeroCuenta.setValue(resp.numeroCuenta);
    });
  }

  async getInfoFinanciera() {
    const resp = await this.miPerfilService.getInfoFinanciera().toPromise();

    this.infoFinanciera = resp;
    this.formInfoFinanciera.controls.totalActivos.setValue(
      this.infoFinanciera.totalActivos
    );
    this.formInfoFinanciera.controls.totalPasivos.setValue(
      this.infoFinanciera.totalPasivos
    );
    this.formInfoFinanciera.controls.gastos.setValue(
      this.infoFinanciera.gastos
    );
    this.formInfoFinanciera.controls.ingresos.setValue(
      this.infoFinanciera.ingresos
    );
    this.formInfoFinanciera.controls.otrosIngresos.setValue(
      this.infoFinanciera.otrosIngresos
    );
    this.formInfoFinanciera.controls.descripcionOtrosIngresos.setValue(
      this.infoFinanciera.descripcionOtrosIngresos
    );

    this.totalActivos = resp.totalActivos;
    this.totalPasivos = resp.totalPasivos;
    this.gastosMensuales = resp.gastos;
    this.ingresosMensuales = resp.ingresos;
    this.otrosIngresosMensuales = resp.otrosIngresos;
  }

  onSubmitBP(): void {
    let bodyB = {
      codigoAsesor: this.bayportInfoForm.controls.executiveCode.value,
      tipoEjecutivo: this.bayportInfoForm.controls.executiveType.value,
      fechaCreacion: this.bayportInfoForm.controls.creationDate.value,
      estado: this.bayportInfoForm.controls.state.value,
      regional: this.bayportInfoForm.controls.regional.value,
      sucursal: String(this.bayportInfoForm.controls.branch.value),
      coordinadorComercial:
        this.bayportInfoForm.controls.commertialCoordinator.value,
      correo: this.bayportInfoForm.controls.bayportEmail.value,
      tipoCanal: this.bayportInfoForm.controls.channelType.value,
      canalVenta: this.bayportInfoForm.controls.salesChannel.value,
    };
    this.miPerfilService.updateInfoBayport(bodyB).subscribe((resp) => {
      let response = resp;
      if ((response.status = 200)) {
        Swal.fire({
          title: 'Información actualizada',
          text: 'Tu Información ha sido actualizada exitosamente',
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Información no actualizada',
          text: 'Tu información no se ha podido actualizar',
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          icon: 'error',
        });
      }
    });
  }

  onSubmitPersonal() {
    let bodyP = {
      tipoVivienda: Number(this.formInfoPersonal.controls.tipoVivienda.value),
      segundoNombre: this.formInfoPersonal.controls.segundoNombre.value,
      fechaExpedicionDocumento:
        this.formInfoPersonal.controls.fechaExpedicion.value,
      lugarExpedicionDocumento: String(
        this.formInfoPersonal.controls.lugarExpedicion.value
      ),
      primerNombre: this.formInfoPersonal.controls.primerNombre.value,
      primerApellido: this.formInfoPersonal.controls.primerApellido.value,
      fechaNacimiento: this.formInfoPersonal.controls.fechaNacimiento.value,
      estudiosRealizados: Number(
        this.formInfoPersonal.controls.estudiosRealizados.value
      ),
      direccion: this.formInfoPersonal.controls.direccion.value,
      segundoApellido: this.formInfoPersonal.controls.segundoApellido.value,
      estadoCivil: Number(this.formInfoPersonal.controls.estadoCivil.value),
      tipoDocumento: Number(this.formInfoPersonal.controls.typeDoc.value),
      ciudad: Number(this.codMunic),
      genero: Number(this.formInfoPersonal.controls.genero.value),
      // correo: this.formInfoPersonal.controls.correoElectronico.value,
      departamento: Number(this.codDepto),
      // celular: String(this.formInfoPersonal.controls.celular.value),
      estrato: 0,
      numeroDocumento: String(
        this.formInfoPersonal.controls.numeroDocumento.value
      ),
      personasACargo: Number(
        this.formInfoFinanciera.controls.personasAcargo.value
      ),
      telefono: 0,
      lugarNacimiento: Number(
        this.formInfoPersonal.controls.lugarNacimiento.value
      ),
      codigoAsesor: Number(this.bayportInfoForm.controls.executiveCode.value),
    };
    this.miPerfilService.updateInfoPersonal(bodyP).subscribe(
      (resp) => {
        if (resp.status == 200) {
          Swal.fire({
            title: 'Información actualizada',
            text: 'Tu Información ha sido actualizada exitosamente',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#36A0E3',
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Información no actualizada',
            text: 'Tu información no se ha podido actualizar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#36A0E3',
            icon: 'error',
          });
        }
      },
      (err) => {
        console.error(err.error.message);
        Swal.fire({
          title: `ERROR`,
          text: `code: ${err.error.code} - ${err.error.message}`,
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          icon: 'error',
        });
      }
    );
  }

  onSubmitSB(): void {
    let body = {
      usuario: this.infoPersonal.numeroDocumento,
      eps: this.socialBenefitsInfoForm.controls.eps.value,
      afp: this.socialBenefitsInfoForm.controls.afp.value,
      arl: this.socialBenefitsInfoForm.controls.arl.value,
    };
    this.miPerfilService
      .updateInfoPrestacionesSociales(body)
      .subscribe((resp) => {
        let response = resp;
        if ((response.status = 200)) {
          Swal.fire({
            title: 'Información actualizada',
            text: 'Tu Información ha sido actualizada exitosamente',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#36A0E3',
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Información no actualizada',
            text: 'Tu información no se ha podido actualizar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#36A0E3',
            icon: 'error',
          });
        }
      });
  }

  onSubmitFinanciera() {
    let bodyF = {
      ingresos: this.formInfoFinanciera.controls.ingresosMensuales.value,
      totalPasivos: this.formInfoFinanciera.controls.totalPasivos.value,
      totalActivos: this.formInfoFinanciera.controls.totalActivos.value,
      otrosIngresos:
        this.formInfoFinanciera.controls.otrosIngresosMensuales.value,
      descripcionOtrosIngresos:
        this.formInfoFinanciera.controls.descripcionOtrosIngresos.value,
      gastos: this.formInfoFinanciera.controls.gastosMensuales.value,
    };
    this.miPerfilService.updateInfoFinanciera(bodyF).subscribe((resp) => {
      let response = resp;
      if ((response.status = 200)) {
        Swal.fire({
          title: 'Información actualizada',
          text: 'Tu Información ha sido actualizada exitosamente',
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Información no actualizada',
          text: 'Tu información no se ha podido actualizar',
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          icon: 'error',
        });
      }
    });
  }

  onSubmitCompleto() {
    if (
      this.formInfoFinanciera.value.otrosIngresos === null ||
      this.formInfoFinanciera.value.otrosIngresos === 0
    ) {
      this.formInfoFinanciera.value.otrosIngresos = '0';
      this.formInfoFinanciera.value.descripcionOtrosIngresos = '';
    }
    this.miPerfilService
      .updateInfoFinanciera(this.formInfoFinanciera.value)
      .subscribe(
        (res) => {
          if ((res.status = 200)) {
            Swal.fire({
              title: 'Información actualizada',
              text: 'Tu Información ha sido actualizada exitosamente',
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#36A0E3',
              icon: 'success',
            });
            return;
          }
          Swal.fire({
            title: 'Información no actualizada',
            text: 'Tu información no se ha podido actualizar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#36A0E3',
            icon: 'error',
          });
        },
        (err) => {
          console.error(err.error.message);
        }
      );
  }

  public validateFechCreacion(): void {
    const { creationDate } = this.bayportInfoForm.value;
    const anioini = new Date(creationDate).getFullYear();
    const anioactual = new Date().getFullYear();
    const fech = anioactual - anioini >= 18 && anioactual - anioini <= 80;
  }

  errors(id: string, action: string): void {
    let alert;
    if (id === 'cel') {
      alert = document.getElementById('alertErrorCel');
      if (alert) alert.style.display = action;
    } else if (id === 'descripcionOtrosIngresos') {
      alert = document.getElementById('alertErrordescOtIn');
      if (alert) alert.style.display = action;
    }

    if (
      this.formInfoPersonal.controls.celular.hasError('required') &&
      this.formInfoPersonal.controls.celular.touched
    ) {
      this.errorCel1 = true;
      this.errorCel2 = false;
      this.errorCel3 = false;
      this.errorCel4 = false;
    } else if (
      this.formInfoPersonal.controls.celular.hasError('numberThree') &&
      this.formInfoPersonal.controls.celular.touched
    ) {
      this.errorCel1 = false;
      this.errorCel2 = true;
      this.errorCel3 = false;
      this.errorCel4 = false;
    } else if (
      this.formInfoPersonal.controls.celular.hasError('minlength') &&
      this.formInfoPersonal.controls.celular.touched
    ) {
      this.errorCel1 = false;
      this.errorCel2 = false;
      this.errorCel3 = true;
      this.errorCel4 = false;
    } else if (
      this.formInfoPersonal.controls.celular.hasError('pattern') &&
      this.formInfoPersonal.controls.celular.touched
    ) {
      this.errorCel1 = false;
      this.errorCel2 = false;
      this.errorCel3 = false;
      this.errorCel4 = true;
    } else {
      this.errorCel1 = false;
      this.errorCel2 = false;
      this.errorCel3 = false;
      this.errorCel4 = false;
    }

    if (
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.hasError(
        'required'
      ) &&
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.touched
    ) {
      this.errordescOtIn1 = true;
      this.errordescOtIn2 = false;
      this.errordescOtIn3 = false;
    } else if (
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.hasError(
        'onlyLetters'
      ) &&
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.touched
    ) {
      this.errordescOtIn1 = false;
      this.errordescOtIn2 = true;
      this.errordescOtIn3 = false;
    } else if (
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.hasError(
        'minlength'
      ) &&
      this.formInfoFinanciera.controls.descripcionOtrosIngresos.touched
    ) {
      this.errordescOtIn1 = false;
      this.errordescOtIn2 = false;
      this.errordescOtIn3 = true;
    } else {
      this.errordescOtIn1 = false;
      this.errordescOtIn2 = false;
      this.errordescOtIn3 = false;
    }
  }

  onSetError(id: string): void {
    this.errors(id, 'flex');
  }

  async onFileChange(e) {
    const file = e.target.files[0];
    const base64 = await this.convertFileToBase64(file);
    this.archivo64 = base64 as string;

    this.showModal({ title: 'Procesando documento' });
    const { numeroDocumento, primerNombre } = await this.miPerfilService
      .getInfoPersonal()
      .toPromise();

    const promp = `Con base al siguiente texto crea un json el cual tenga las siguientes nombreBanco: el nombre debe ser devuelto con base al siguiente array["BANCAMIA S.A.","BANCIEN","BANCO AGRARIO","BANCO AV VILLAS","BANCO CAJA SOCIAL","BANCO DE BOGOTA","BANCO DE LA REPUBLICA","BANCO DE OCCIDENTE","BANCO FALABELLA S.A","BANCO FINANDINA SA","BANCO GNB SUDAMERIS","BANCO PICHINCHA SA","BANCO POPULAR","BANCO W  SA","BANCOLDEX","BANCOLOMBIA","BANCOOMEVA","BBVA","nombre": "CITIBANK","COBROACTIVO SAS","COLPATRIA","COOPCENTRAL","COOPERATIVA FINANCIERA CONFIAR","COOPERATIVA FINANCIERA COTRAFA","COOPERATIVA FINANCIERA DE ANTIOQUIA CFA","CREDICOOP","DAVIVIENDA","FINANCIERA JURISCOOP","FONDO DE INVERSION COLECTIVA EFECTIVO A LA VISTA","HBSC COLOMBIA S.A.","ITAU CORPBANCA COLOMBIA S A","LATAM CREDIT COLOMBIA SA","MIBANCO","NU BANK","PIBANK","SERINCOOP"], fechaExpedicion: el cual debe traer la fecha de expedicion del documento en formato yyyy-mm-dd, numeroCedula: de la persona, nombrePersona: nombre de la persona, tipoCuenta: tipo de cuenta si es ahorros o corriente, numeroCuenta: numero de cuenta, estadoCuenta:estado de cuenta donde se validar si en alguna parte del documento dice inactiva, embargada o cancelada en caso de que no el estado de la cuenta es activo`;

    await this._llmsService
      .processDocument({ file: base64, promp })
      .toPromise();

    let respuesta = await this._llmsService.validateDocument().toPromise();
    const interval = setInterval(async () => {
      if (respuesta) {
        const {
          fechaExpedicion,
          estadoCuenta,
          numeroCedula,
          nombrePersona,
          nombreBanco,
          tipoCuenta,
          numeroCuenta,
        } = respuesta;

        const fechaExp = new Date(fechaExpedicion);
        const fechaActual = new Date();

        if (fechaExp instanceof Date && fechaActual instanceof Date) {
          const diferenciaMilisegundos =
            fechaActual.getTime() - fechaExp.getTime();

          const diferenciaDias = Math.floor(
            diferenciaMilisegundos / (1000 * 60 * 60 * 24)
          );

          if (diferenciaDias > 30) {
            clearInterval(interval);
            await this.showModal({
              title: 'Documento no vigente',
              isError: true,
              isShowConfirm: true,
            });
            return;
          }
        } else {
          console.error('Una de las fechas no es válida.');
        }

        if (numeroCedula != numeroDocumento) {
          clearInterval(interval);
          await this.showModal({
            title:
              'El número de identificación del documento no corresponde al del asesor',
            isError: true,
            isShowConfirm: true,
          });
          return;
        }

        if (!nombrePersona.toLowerCase().includes(primerNombre.toLowerCase())) {
          clearInterval(interval);
          await this.showModal({
            title:
              'El nombre del titular del documento no corresponde al del asesor',
            isError: true,
            isShowConfirm: true,
          });
          return;
        }

        if (estadoCuenta.toLowerCase() !== 'activo') {
          clearInterval(interval);
          await this.showModal({
            title: 'El estado de la cuenta no permite la actualización',
            isError: true,
            isShowConfirm: true,
          });
          return;
        }

        // Actualizar los valores si todo es válido
        this.formInfoBancaria.controls.banco.setValue(nombreBanco);
        this.formInfoBancaria.controls.tipoCuenta.setValue(tipoCuenta);
        this.formInfoBancaria.controls.numeroCuenta.setValue(numeroCuenta);

        // Detener el intervalo al completar la operación
        clearInterval(interval);
        Swal.close();
        return;
      }

      // Obtener una nueva respuesta
      respuesta = await this._llmsService.validateDocument().toPromise();
    }, 5000);
  }

  setActive(index: number): void {
    this.activeIndex = index;
  }

  setFormatDate(date = '') {
    const newDate = new Date(date);
    const day =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
    const month =
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  actualizarInformacionBancaria() {
    this.isUpdate = true;
  }

  async showModal({
    title = '',
    isError = false,
    isShowConfirm = false,
    warning = false,
  }) {
    const result = await Swal.fire({
      allowOutsideClick: false,
      title,
      icon: isError ? 'error' : 'info',
      showConfirmButton: isShowConfirm,
    });
    if (result.isConfirmed) {
      if (warning) {
        return;
      }
      this._router.navigateByUrl('/home');
    }
  }

  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // Archivo leído exitosamente
      reader.onerror = () => reject(new Error('Error al leer el archivo.'));
      reader.readAsDataURL(file); // Leer el archivo como Data URL
    });
  }

  upload() {
    const userInfo = this._userService.parseJwt();

    const bank = this.banks.find((el) => el.nombre == this.banco);
    console.log({ bank, banks: this.banks, banco: this.banco });

    const body = {
      entidad_financiera: bank.codigo,
      tipo_cuenta: this.tipoCuenta === 'Ahorros' ? '1' : '2',
      numero_cuenta: this.numeroCuenta,
      documento_base64: this.archivo64,
      num_identificacion_asesor: userInfo['sub'],
      token_usuario: JSON.parse(localStorage.getItem('credentials'))[
        'access_token'
      ],
    };

    this._formalitiesService.uploadBankAccount(body).subscribe(
      (res) => {
        if (res.CODE !== 200) {
          alert(
            `⚠️ Ocurrio un error inesperado, por favor intentalo de nuevo. (code: ${res.CODE})`
          );
          return;
        }
        Swal.fire({
          text: ' Cuenta actualizada correctamente',
          confirmButtonText: 'Continuar',
          confirmButtonColor: '#36A0E3',
          showCloseButton: true,
          allowOutsideClick: false,
          icon: 'success',
        }).then(() => {});
      },
      (err) => {
        alert(
          `⚠️ Ocurrio un error inesperado, por favor intentalo de nuevo. (code: ${err.status})`
        );
      }
    );
  }
}
