<div class="main-container-component d-flex align-items-start flex-column">
  <h1 class="main-container-header text-start text-md-center ms-5 mx-md-auto" *ngIf="header">
    {{ header }}
  </h1>
  <p class="main-container-subheader text-start text-md-center ms-5 mx-md-auto" *ngIf="subheader">
    {{ subheader }}
  </p>
  <div class="main-container-body d-flex align-items-start flex-column flex-grow-1 center-content">
    <div class="main-container-body-card center-content">
      <div class="main-container-body-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
