import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EdoClienteService {

  private endpoint = environment.ApiConecta+"perfilamiento/estado-cliente"

  constructor(private http:HttpClient) { }

  getEdoCliente(numeroIdentificacion:number):Observable<any>{
    return this.http.post(this.endpoint,{numeroIdentificacion});
  }
}
