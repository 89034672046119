<!--app-header></app-header-->
<!-- <div>
<iframe
class="frame"
src="https://app.powerbi.com/view?r=eyJrIjoiZDBmM2UyNDItMWRkMC00NzhhLTkwY2EtNGRkODEzOGQzMzc5IiwidCI6ImQ0MTk5MTU1LTc3ZTctNDY0OC05N2YxLTY2ODNkMThiMjZkYiIsImMiOjR9&pageName=ReportSectionad8e3a26ac7b3a567b34"
frameborder="0"
allowFullScreen="false"></iframe>
</div> -->

<div class="mobile">
  <iframe
  class="frame"
  src="https://app.powerbi.com/view?r=eyJrIjoiZDBmM2UyNDItMWRkMC00NzhhLTkwY2EtNGRkODEzOGQzMzc5IiwidCI6ImQ0MTk5MTU1LTc3ZTctNDY0OC05N2YxLTY2ODNkMThiMjZkYiIsImMiOjR9&pageName=ReportSectionad8e3a26ac7b3a567b34"
  frameborder="0"
  allowFullScreen="true"></iframe>
</div>

<div class="desk">
  <iframe
  class="frame"
  src="https://app.powerbi.com/view?r=eyJrIjoiZWUwYTY4MTAtNDMzMC00ZDU2LWFjYTgtNjY0YzY0ZWU4NzQ2IiwidCI6ImQ0MTk5MTU1LTc3ZTctNDY0OC05N2YxLTY2ODNkMThiMjZkYiIsImMiOjR9"
  frameborder="0"
  allowFullScreen="true"></iframe>
</div>
