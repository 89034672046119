import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asegurado } from 'src/app/models/asegurado';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AseguradoAdicionalService {
  private endpoint:string = environment.ApiConecta+'perfilamiento/asegurado-adicional';

  constructor(private http:HttpClient, private fechap:DatePipe) { }

  guardarAsegurdos(asegurados:Asegurado[],numeroCarpeta:string):Observable<any>{
    asegurados.forEach(asegurado=>{
      asegurado.fechaNacimiento=this.fechap.transform(asegurado.fechaNacimiento,'dd-MM-yyyy');
    })
    let body = {asegurados,numeroCarpeta}
    console.log('Asegurados Body', body);

    return this.http.post(this.endpoint, body);
  }
}
