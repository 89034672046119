<article class="w-full h-full relative text-center">
  <section class="z-5 py-6 w-full h-min absolute">
    <article class="bg-opaque relative py-6 border-0 sm:border-1 border-round-md border-sky">
      <div class="z-2 p-4 absolute top-0 left-50 -translate-y-50	border-circle bg-lime">
        <i class="fa fa-check text-gray-900 text-3xl font-bold"></i>
      </div>
      <header class="font-omnes-semibold text-xl font-bold mt-2 mb-6 min-w-fit">
        ¡Creaste una nueva contraseña!
      </header>
      <section class="w-full mb-4">
        <a routerLink="/home"
          class="font-omnes-semibold font-bold text-lg text-gray-900 px-8 py-2 border-round-md bg-sky-200">
          Ir al inicio
        </a>
      </section>
      <footer>
        <a routerLink="/home/cambio-contrasena" class="py-2 text-sky-200">
          <i class="fa fa-arrow-left"></i>
          Volver a mi perfil
        </a>
      </footer>
    </article>
  </section>
  <div class="z-1 circle middle absolute"></div>
</article>