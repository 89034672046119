import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-terminos-autorizacion',
  templateUrl: './terminos-autorizacion.component.html',
  styleUrls: ['./terminos-autorizacion.component.scss']
})
export class TerminosAutorizacionComponent implements OnInit {
  @Input() titulo:any;
  @Input() texto:any;
  constructor() { }

  ngOnInit(): void {
  }

}
