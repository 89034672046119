<div class="p-4">
  <div id="tituloP">Validación de identidad</div>
  <div id="tituloS">
    Autorización manejo de datos personales y centrales de riesgo
  </div>
  <p>
    Ingresa código de verificación de 6 dígitos enviado al solicitante al número
    celular
    {{ cellphone }}.
  </p>
  <div class="d-inline">
    <code-input class="code-input" id="otp" #input (codeCompleted)="onCodeCompleted($event)"
      (codeChanged)="onCodeChanged($event)" [isCodeHidden]="false" [isNonDigitsCode]="false" [codeLength]="6"
      [isCharsCode]="false">
    </code-input>
  </div>

  <mat-error class="error" *ngIf="errorValidacion" style="color: #f44336;font: 16px/24px;margin-left: -17px;">
    <i class="fa-solid fa-circle-xmark"></i> El código digitado es incorrecto. Verifica el código y regístralo de
    nuevo, tienes un último intento
  </mat-error>

  <br /><br />
  <!-- <p style="font-size: 15px;">El código vence en <strong>59 segundos</strong></p> -->
  <div class="row">
    <p style="margin-top: 60px" *ngIf="enviarOtp.intentosActuales != 0">
      ¿No fue recibido el código?
      <a class="link" role="button" (click)="reenviarCodigo()" [ngStyle]="{
          'pointer-events': bloqueado ? 'none' : 'auto',
          color: bloqueado ? '#10AEEF' : '#10AEEF',
          opacity: bloqueado ? '.3' : '1'
        }">
        Reenviar código
        <span *ngIf="tiempoRestante !== 60">en {{ tiempoRestante }}s</span>
      </a>
    </p>
    <p style="margin-top: 60px" *ngIf="enviarOtp.intentosActuales == 0 && enviarOtp.totalIntentos != 0">
      Último intento, los intentos de reenvío fueron superados.
    </p>
  </div>
  <button class="continuar btn-dc" mat-button [disabled]="code.length !== 6 || isLoading" (click)="validatOtp()">
    <mat-icon *ngIf="isLoading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-icon>
    Validar código
  </button>
</div>
