import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdleModalComponent } from '@modules/shared/components/idle-modal/idle-modal.component';
import { DialogService } from '@modules/shared/services/dialog.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'front-portal-agentes';
  private subList: Array<Subscription> = [];
  private idleActive: boolean = false;
  private dialog: any;
  private TIMER_ID: any;

  constructor(
    private router: Router,
    private _idleService: Idle,
    private _dialogService: DialogService
  ) {
    _idleService.setIdle(540);
    _idleService.setTimeout(60);
    _idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIdleActivation(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    this.idleSubs();
    this.idleReset();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (localStorage.getItem('credentials')) {
          const refresh = localStorage.getItem('refrescar');
          if (!refresh) {
            localStorage.setItem('refrescar', '0');
            // location.reload();
          }
          if (refresh == '0') {
            localStorage.setItem('refrescar', '1');
            // location.reload();
          }
        } else {
          const scriptElement = document.getElementById(
            'hubspot-messages-iframe-container'
          );
          if (scriptElement) {
            scriptElement.remove();
          }
        }
      }
    });
  }

  private checkIdleActivation(url: string): void {
    const excludedRoutes = ['/iniciar-sesion', '/recup-pass'];
    if (excludedRoutes.includes(url)) {
      this._idleService.stop();
    } else {
      this.idleReset();
    }
  }

  private idleReset(): void {
    this._idleService.watch();
  }

  private idleSubs(): void {
    this.subList.push(
      this._dialogService.idleActive.subscribe((res: any) => {
        this.idleActive = res;
        if (!res) clearInterval(this.TIMER_ID);
      })
    );
    this.subList.push(
      this._dialogService.idleActiveSession.subscribe((res: any) => {
        if (!res) {
          this.logout();
          return;
        }
        this.idleReset();
      })
    );
    this.subList.push(
      this._idleService.onTimeoutWarning.subscribe((res: any) => {
        if (res === 60 && !this.idleActive) {
          this.dialog = this._dialogService.openDialog(IdleModalComponent, {
            disableClose: true,
            width: '100%',
            maxWidth: '320px',
          });
          let timeout = res;
          this.TIMER_ID = setInterval(() => {
            timeout -= 1;
            this._dialogService.idleTimeOut.emit(timeout);
            if (timeout === 0) {
              this.logout();
            }
          }, 1000);
        }
      })
    );
  }

  private logout(): void {
    localStorage.clear();
    this._dialogService.closeDialog(this.dialog);
    clearInterval(this.TIMER_ID);
    this.router.navigate(['/', 'iniciar-sesion']);
  }
}
