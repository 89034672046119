import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VigenteFondeo {
  constructor(private http: HttpClient) {}

  validacionFondeadores(body: any): Observable<any> {
    const url = `${environment.ApiConecta}validacion-vigente-fondeadores`;
    return this.http.post(url, body);
  }

  validacionCreditos(body: any): Observable<any> {
    const url = `${environment.ApiConecta}validacion-vigente-creditos`;
    return this.http.post(url, body);
  }
}
