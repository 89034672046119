import { Component, OnInit } from '@angular/core';
import { RcValidaOTPService } from '../../services/recuperacion/rc-valida-otp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { RcEnvioOTPService } from 'src/app/services/recuperacion/rc-envio-otp.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-valid-account',
  templateUrl: './valid-account.component.html',
  styleUrls: ['./valid-account.component.css']
})
export class ValidAccountComponent implements OnInit {
  usuario:any;
  otpForm: FormGroup;
  hide: boolean = true;
  errorLog: String = '';
  matcher = new MyErrorStateMatcher();
  code=""
  numuser=""
  constructor(
    private formBuilder: FormBuilder,
    private api: RcValidaOTPService,
    private router: Router,
    private enviarOtp:RcEnvioOTPService,
    public activatedRoute: ActivatedRoute

  ) {
    let state = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state))


    state.subscribe(x=>{
      this.usuario = x.usuario;
      //console.log(x);
      this.numuser=x.numuser;
    })

    this.otpForm = new FormGroup({
      user: new FormControl('', [
        Validators.pattern('^\\d+$'),
        Validators.minLength(1),
        Validators.maxLength(15),
        Validators.required,
      ]),
      otp: new FormControl('', [
        Validators.pattern('^[a-zA-Z0-9!"#$%&()*+,-./:;<=>?@_`|~ ]+$'),
        Validators.minLength(8),
        Validators.maxLength(33),
        Validators.required,
      ]),
    });
   }

   onCodeCompleted(code: string) {
     this.code=code;
  }

  ngOnInit(): void {
  }

  valOtp() {
    var responses:any;
    this.api
      .ValidaOtp(
        'ConectaFrontEnd',
        this.numuser,
        this.code
      )
      .subscribe(
        (data) => {
          //console.log(data);
          responses = data;
              if(responses.code==200){
                this.router.navigateByUrl('/change-password',{state:{numuser:this.numuser,otp:this.code}})
                //this.router.navigate(['change-password']);
              }
          else{
          this.errorLog = responses.message;
          }
        },
        (error) => {
          console.log(error.error.message);
          this.errorLog = error.error.message;
        }
      );
  }

  reenviar(){
    this.enviarOtp.solicitarOtp('ConectaFrontEnd',this.numuser,'3')
    .subscribe((responses:any)=>{
      if(responses.code==200){
        alert('El codigo fue enviado correctamente');
      }
    })
  }

}
