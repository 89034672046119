import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from 'src/app/services/busqueda/busqueda.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { DataService } from '../../services/data.service';
import { Documento } from 'src/app/models/documento';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';
import { MultipleLoadFiles } from 'src/app/models/multipleLoadFiles.interface';
import { StepperComponent } from 'src/app/components/stepper/stepper.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Utils } from 'src/app/utils/utils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-carga-documentos',
  templateUrl: './carga-documentos.component.html',
  styleUrls: ['./carga-documentos.component.scss'],
})
export class CargaDocumentosComponent implements OnInit {
  // titulos steps y reglas para cada step
  public steps: string[] = [];
  public rules = []
  public rules1step = []
  // archivos solicitados en cada step
  public files1step: MultipleLoadFiles[] = []
  public files2step: MultipleLoadFiles[] = []
  public files3step: MultipleLoadFiles[] = []
  public files4step: MultipleLoadFiles[] = []
  // flujo alterno de seiz
  public flujoAlternativo = false;
  // informacion de la carpeta
  private dataCarpeta: any;
  // texto ultimo step
  public contenido = ""
  // step actual
  public step = 0
  // config documentos adicionales
  public step4adicionalsConfig: { title: string; subtitle: string; step4AdicionalDocuments: Documento[]; secuencia: number; };
  // config etapa final
  public finalResultConfig: { titulo: string; contenido: string; color: 'success' | 'warning' | 'error' | 'loading'; buttonText: string; } = {
    titulo: '',
    contenido: '',
    color: 'warning',
    buttonText: ''
  }

  private readonly utils = new Utils();
  // referencia al stepper
  @ViewChild(StepperComponent) stepper!: StepperComponent;
  public documentosParaSerCargados: Documento[] = [];


  constructor(
    public data: DataService,
    private readonly docSolSrv: DocSolicitudService,
    private readonly router: Router,
    private readonly cambioEstadoServ: CambioestadocarpetaService,
    private readonly serviceCarga: ArchivoCargaService,
    private readonly serviceDoc: DocSolicitudService,
    private readonly dialogService: DialogService,
    private readonly datepipe: DatePipe,
    private readonly _busquedaService: UploadService
  ) { }

  // AL iniciar

  async ngOnInit() {
    this.getDocumentos();
    const folderData = await this._busquedaService.getResultadosBusqueda({
      numeroCarpeta: this.data.numeroCarpeta,
    });
    this.dataCarpeta = folderData.rows[0];
  }

  // Getters

  get valido(): boolean {
    if (this.step == 0) {
      return this.files1step.every(file => !file.required || file.file.status === true);
    }
    if (this.step == 1) {
      return this.files2step.every(file => !file.required || file.file.status === true);
    }
    if (this.step == 2) {
      if (this.files3step.length > 0) {
        return this.files3step.every(file => !file.required || file.file.status === true);
      } else {
        return this.files4step.every(file => !file.required || file.file.status === true);
      }
    }
    if (this.step == 3) {
      if (this.files3step.length > 0) {
        return this.files4step.every(file => !file.required || file.file.status === true);
      }
    }
    return false
  }

  get isUploadingFile(): boolean {
    if (this.documentosParaSerCargados.length > 0) {
      return this.documentosParaSerCargados.every(item => item.upload);
    }
    return false
  }

  // Metodos

  private getDocumentos(): void {
    this.dialogService.openDialogLoadingService()
    this.docSolSrv
      .getDocumentosSolicitud(this.data.numeroCarpeta)
      .subscribe((res) => {
        this.files3step = [];
        res.rows.forEach((docs: any) => {
          if (
            (docs.faseDigitalizacion == 1 && docs.indObligatorio == 1) ||
            docs.indRobotSeiz == 1
          ) {
            if (
              docs.nombreDocumento != 'DESPRENDIBLES DE PAGO' &&
              docs.nombreDocumento != 'Desprendibles de pago (Ultimo mes)'
            ) {
              this.files3step.push({
                titulo: docs.nombreDocumento,
                required: true,
                file: {
                  status: false,
                  secuencia: docs.secuenciaDocumento
                }
              })
            }
          }
        });
        this.createSteps(this.files3step.length > 0)
        this.addRequestedDocuments()
        this.dialogService.closeDialog()
      }, (error) => {
        this.dialogService.closeDialog()
      });
  }

  async cargaSteps() {
    this.stepper.disableStepperClicks(true);
    this.finalResultConfig = {
      titulo: "Estamos procesando los documentos",
      contenido: "Espera unos minutos. No cierres ni refresques esta ventana.",
      color: "loading",
      buttonText: ""
    }
    try {
      // bandera que indica que todo se subio
      if (!this.isUploadingFile) {
        // crea arreglo de todos los documentos que toca subir a aws
        this.documentosParaSerCargados = [
          ...this.files1step.filter(item => item.file.status).map(item => item.file),
          ...this.files2step.filter(item => item.file.status).map(item => item.file),
          ...this.files3step.filter(item => item.file.status).map(item => item.file),
        ];
        // crea el pdf unificado de todos los documentos adicionales
        let pdfAdicionalFinal = await this.crearPdfDocumentosAdicionales()
        if (pdfAdicionalFinal) {
          this.documentosParaSerCargados.push(pdfAdicionalFinal)
        }
        // ciclo que carga los documentos
        for (const doc of this.documentosParaSerCargados) {
          if (!doc.upload) {
            doc.nombre = `PORTAL-AGENTES-${doc.nombre}`;
            const carpeta = `${this.data.numeroCarpeta}`;
            const identificacion = 'certification';
            const respuestaArchivo = await this.serviceCarga
              .cargandoArchivo(carpeta, identificacion, doc.nombre)
              .toPromise();
            let form = new FormData();
            Object.keys(respuestaArchivo.fields).forEach((key) =>
              form.append(key, respuestaArchivo.fields[key])
            );
            form.append('Content-Type', 'application/pdf');
            form.append('file', doc.archivo);

            const responseFetch = await fetch(respuestaArchivo.url, {
              method: 'POST',
              body: form,
            });
            let aws = await this.serviceDoc
              .uploadAwsRoute(carpeta, respuestaArchivo.fields.key, doc.secuencia)
              .toPromise();
            if (responseFetch.ok && respuestaArchivo.url && aws.status == 200) {
              doc.upload = true
            } else {
              this.finalResultConfig.color = 'warning'
              this.finalResultConfig.titulo = 'Seguimos procesando la documentación';
              this.finalResultConfig.contenido = 'Para continuar, da clic en el botón';
              this.finalResultConfig.buttonText = 'Enviar y continuar'
              return;
            }
          }
        }
      }
      this.cambioEstado();
    } catch (error) {
      console.log({ error: error });
    }
  }

  async crearPdfDocumentosAdicionales(): Promise<Documento> {
    // logica para los documentos adicionales que no son obligatorios para crear un unico pdf
    let otrosDocumentosAdicionales: Documento[] = this.files4step.filter(item => item.file.status).map(item => item.file);
    let masDocumentosAdicionales: Documento[] = this.step4adicionalsConfig.step4AdicionalDocuments;
    let todosDocumentosAdicionales: Documento[] = [...otrosDocumentosAdicionales, ...masDocumentosAdicionales];

    if (todosDocumentosAdicionales.length > 0) {
      todosDocumentosAdicionales = todosDocumentosAdicionales.filter((doc: any) => {
        if (doc.status && !doc.upload) return doc;
      });
      if (todosDocumentosAdicionales.length > 1) {
        let pdfBytes = await this.utils.appendPDF(
          todosDocumentosAdicionales.map((doc) => doc.archivo)
        );
        let timestamp: string = this.datepipe.transform(
          new Date(),
          'yyyyMMddHHmmss'
        );

        let name = `documentosAdicionales-${timestamp}.pdf`;
        let pdfFile = new File([new Blob([pdfBytes as ArrayBuffer])], name, {
          type: 'application/pdf',
          lastModified: new Date().getTime(),
        });
        let pdfUnificado: Documento = {
          status: true,
          nombre: name,
          upload: false,
          archivo: pdfFile,
          secuencia: 64,
        };
        return pdfUnificado
      } else {
        return todosDocumentosAdicionales[0]
      }
    }
    return null
  }

  createSteps(step3: boolean) {
    this.rules1step = [
      "El archivo que selecciones no debe superar los <strong>5MB</strong>.",
      "Puedes subir <strong>PDF, PNG y JPG</strong>.", "Verifica que la cédula se vea completa en la imagen, incluyendo sus bordes."
    ]
    this.rules = [
      "El archivo que selecciones no debe superar los <strong>5MB</strong>.",
      "Puedes subir <strong>PDF, PNG y JPG</strong>."
    ]
    this.steps = [
      'Cédula de ciudadanía',
      'Desprendibles <br/> de pago',
      'Otros documentos adicionales',
      '¡Listo!'
    ];
    if (step3) {
      this.steps.splice(2, 0, 'Otros documentos obligatorios');
    }
  }

  addRequestedDocuments() {
    this.files1step = [
      {
        iconoCentral: "assets/icons/address-card-solid.svg",
        titulo: "Cédula ciudadanía (frente)",
        iconoLoading: "assets/icons/archivo-imagen.svg",
        iconoLoaded: "assets/icons/archivo-imagen.svg",
        required: true,
        file: {
          status: false,
          secuencia: 2
        }
      },
      {
        iconoCentral: "assets/icons/cedula-reverso.svg",
        titulo: "Cédula ciudadanía (reverso)",
        iconoLoading: "assets/icons/archivo-imagen.svg",
        iconoLoaded: "assets/icons/archivo-imagen.svg",
        required: true,
        file: {
          status: false,
          secuencia: 61
        }
      }
    ]
    this.files2step = [
      {
        titulo: "Desprendible de pago último mes",
        required: true,
        file: {
          status: false,
          secuencia: 4
        }
      },
      {
        titulo: "Desprendible pago mes anterior",
        required: true,
        file: {
          status: false,
          secuencia: 62
        }
      }
    ]
    this.files4step = [
      {
        titulo: "Huellas de consulta",
        texto: "Carga el soporte de validación para garantizar que el cliente no tenga endeudamiento simultáneo.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
      {
        titulo: "Aclaraciones del proceso",
        texto: "Carga los documentos que soporten la terminación o archivo de las demandas en centrales de riesgo.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
      {
        titulo: "Resolución",
        texto: "Carga la resolución del cliente en la que se valide el tipo de pensión y fecha en la que se otorgó.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
      {
        titulo: "Líneas de crédito",
        texto: "Carga el soporte de validación del tipo de crédito, garantizando que no es una libranza.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
      {
        titulo: "Plan de pagos",
        texto: "Carga el soporte donde se evidencien las cuotas a par con otras entidades.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
      {
        titulo: "Desprendible adicional",
        texto: "Carga desprendibles para validar otro tipo de descuentos.",
        required: false,
        file: {
          status: false,
          secuencia: 64
        }
      },
    ]
    this.step4adicionalsConfig = {
      title: "Documentos adicionales",
      subtitle: "Da click en el botón cargar para agregar",
      step4AdicionalDocuments: [],
      secuencia: 5
    }
  }

  redirectSeiz() {
    if (this.flujoAlternativo) {
      this.router.navigateByUrl('oferta', {
        state: this.dataCarpeta,
      });
    } else {
      this.router.navigateByUrl('informacionadicional', {
        state: this.dataCarpeta,
      });
    }
  }

  cambioStep(step: number) {
    this.step = step
    if (step == this.steps.length - 1) {
      this.dialogService.openDialog(
        '¿Estás seguro de enviar?',
        'Una vez enviada la documentación no podrás editarla',
        false,
        "Sí, enviar",
        () => {
          this.cargaSteps()
        },
        true,
        "Regresar",
        () => {
          this.stepper.previousStep()
        }
      );
    }
  }

  cerrar() {
    if (this.finalResultConfig.color == 'error') {
      this.stepper.previousStep()
    }
    if (this.finalResultConfig.color == 'warning') {
      this.cargaSteps()
    }
    if (this.finalResultConfig.color == 'success') {
      this.redirectSeiz()
    }

  }

  cambioEstado() {
    this.changeState()
      .then((res) => {
        this.flujoAlternativo = res.flujoAlterno == 1;
        this.finalResultConfig.color = 'success'
        this.finalResultConfig.titulo = 'Carga de documentos completada exitosamente';
        this.finalResultConfig.contenido = null;
        this.finalResultConfig.buttonText = 'Continuar'
      })
      .catch((err) => {
        this.erroresSeiz(err)
      });
  }

  private changeState(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cambioEstadoServ
        .cambioEstadocarpeta(
          parseInt(this.data.numeroCarpeta),
          '300',
          'Cambio de estado aprobado por el Asesor'
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  erroresSeiz(err) {
    this.stepper.disableStepperClicks(false);
    this.finalResultConfig.contenido = err.error.message;
    // Casos de error
    if (err.error.code === 409 || err.error.estadoNuevo === 300) {
      this.documentosParaSerCargados = []
      this.finalResultConfig.color = 'error'
      this.finalResultConfig.titulo = 'No pudimos leer uno o más de los documentos';
      this.finalResultConfig.contenido = 'Revisa que todos los documentos:<br/><br/>1. Sean legibles<br/>2. Que no estén invertidos<br/>3. Estén en la categoría correcta';
      this.finalResultConfig.buttonText = 'Volver al inicio'
      if (err.error.message.includes(' - El documento que estás cargando no está vigente.')) {
        this.finalResultConfig.titulo = 'La vigencia de uno de los documentos no es válida';
        this.finalResultConfig.contenido = 'Alguno de los documentos que subiste, no cumple con los tiempos establecidos';
      }
      if (err.error.message.includes(' - No es posible continuar con el proceso. Según el desprendible no tiene capacidad. Valida la información con tu cliente.')) {
        this.finalResultConfig.titulo = 'No es posible continuar con la solicitud';
        this.finalResultConfig.contenido = 'Según el desprendible el solicitante no cuenta con capacidad.<br/>Valida la información con tu cliente para una nueva solicitud.';
      }
      if (
        err.error.message.includes(' - El cliente no es sujeto de crédito por Número de afiliación inválido')) {
        this.finalResultConfig.titulo = 'El solicitante no es sujeto de crédito';
        this.finalResultConfig.contenido = 'El número de afiliación de Colpensiones es inválido.';
      }
      if (err.error.message.includes(' - El documento no corresponde a la pagaduría que se está radicando')
        || err.error.message.includes('Cambio de estado aprobado por el Asesor')) {
        this.finalResultConfig.titulo = 'Revisa los documentos cargados';
        this.finalResultConfig.contenido = 'Alguno de los documentos no coincide con la pagaduría del solicitante';
      }
      if (err.error.message.includes(' - Tipo de mesada pensional no válida')) {
        this.finalResultConfig.titulo = 'No es posible continuar con la solicitud';
        this.finalResultConfig.contenido = 'El tipo de mesada pensional del solicitante no le permite ser sujeto de crédito';
      }
    }
    // Casos de timeout
    if (err.error?.isTrusted || err.status == 0) {
      this.stepper.disableStepperClicks(true);
      this.finalResultConfig.color = 'warning'
      this.finalResultConfig.titulo = 'Seguimos procesando la documentación';
      this.finalResultConfig.contenido = 'Para continuar, da clic en el botón';
      this.finalResultConfig.buttonText = 'Enviar y continuar'
      return;
    }

  }
}
