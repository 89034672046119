import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/models/user';
import { LoginIn } from '../../models/login.interface';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiLog = environment.ApiConecta + 'login';
  private usuarioData: BehaviorSubject<User | null>;

  constructor(private http: HttpClient, private router: Router) {
    let user: any = localStorage.getItem('credentials');
    user = JSON.parse(user);
    this.usuarioData = new BehaviorSubject(user);
  }

  public get usuario(): User | null {
    return this.usuarioData.value;
  }

  login(usuario: string, contrasena: string): Observable<LoginIn> {
    return this.http
      .post<LoginIn>(this.apiLog, { usuario, contrasena }, {})
      .pipe(
        map((res: any) => {
          if (res.Authorization) {
            let credentials: User = {
              usuario,
              access_token: res.Authorization,
            };
            this.usuarioData.next(credentials);
            localStorage.setItem('credentials', JSON.stringify(credentials));
          }
          return res;
        })
      );
  }

  logout(): void {
    localStorage.removeItem('credentials');
    localStorage.removeItem('tpcnl');
    localStorage.removeItem('refrescar');
    this.router.navigateByUrl('/iniciar-sesion');
    this.usuarioData.next(null);
  }
}
