import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NotificaService } from 'src/app/services/notificaciones/notifica.service';
@Component({
  selector: 'app-saldo',
  templateUrl: './saldo.component.html',
  styleUrls: ['./saldo.component.css']
})
export class SaldoComponent {
  constructor(
    private serviceNotifica: NotificaService,
    private router: Router,
    private data: DataService
  ) { }

  public rules = [
    "El archivo que selecciones no debe superar los <strong>5MB</strong>.",
    "Puedes subir <strong>PDF, PNG y JPG</strong>.",
    "Verifica que incluya los siguientes <strong>datos</strong>: nombre, cédula, número de obligación, valor a pagar <br/>y medios de pago; además, valida que coincidan en el <strong>motor de decisión.</strong>",
    "Los certificados con código de barras deben ser <strong>originales</strong> y <strong>legibles</strong>.",
    "Los certificados deben estar <strong>vigentes</strong>."
  ]
  public title = "Certificado de saldo"
  public subtitle = "Da click en el botón cargar para agregar"
  public contract = false
  public secuencia = 14
  public idFolder = 3
  public identificacion = "3.CERTIFICADOS_DE_SALDO"
  public nombreArchivoFinal = "PORTAL-saldo"

  notificarNovedad() {
    let carpeta = this.data.numeroCarpeta ? this.data.numeroCarpeta + "" : ""
    this.serviceNotifica.tipoNovedad(carpeta, "18", "Se realiza la carga de archivos").subscribe(res => {
      this.router.navigate(["pro-solicitud"]);
    }, error => {
      console.log(<any>error);
    });
  }
}
