<menu>
  <a routerLink="/home" routerLinkActive="active">Inicio</a><br/>
  <a routerLink="/busqueda-carpetas" routerLinkActive="active">Mis créditos</a><br/>
  <a routerLink="/reporteria" routerLinkActive="active">Mis reportes</a><br/>
  <a routerLink="/capacitacion" routerLinkActive="active">Tus cursos</a><br/>
  <details #details1 (click)="toggleDetails(details2)">
    <summary>
      <span>Mis trámites</span>
      <i class="fa fa-arrow-right"></i>
    </summary>
    <hr/>
    <a routerLink="/mis-tramites/referencia-comercial" routerLinkActive="active">Certificaciones</a><br/>
    <a routerLink="/mis-tramites/carga-documentos" routerLinkActive="active">Carga de documentos</a><br/>
    <a routerLink="/mis-tramites/consulta-documentos" routerLinkActive="active">Consulta de documentos</a><br/>
  </details>
  <details #details2 (click)="toggleDetails(details1)">
    <summary>
      <span>Debes saber</span>
      <i class="fa fa-arrow-right"></i>
    </summary>
    <hr/>
    <a routerLink="/debes-saber">Home</a><br/>
    <a routerLink="/debes-saber/politicas" routerLinkActive="active">Políticas de producto</a><br/>
    <a routerLink="/debes-saber/megafono" routerLinkActive="active">Megáfono</a><br/>
    <a routerLink="/debes-saber/calendario" routerLinkActive="active">Calendario comercial</a><br/>
  </details>
</menu>
