import jspdf from 'jspdf';
import { PDFDocument } from 'pdf-lib';

export class Utils {
  public async convertImageFileToPDF(imageFile: File): Promise<File> {
    const doc = new jspdf({ compress: true });
    const nombre = imageFile.name.split('.')[0];

    // Obtener la imagen en base64
    const base64Image = await this.getBase64(imageFile);

    // Crear una imagen para obtener sus dimensiones
    const img = new Image();
    img.src = base64Image as string;

    return new Promise((resolve) => {
      img.onload = () => {
        // Obtener las dimensiones de la imagen
        const imgWidth = img.width;
        const imgHeight = img.height;

        // Ajustar las dimensiones de la imagen en el PDF
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        const width = imgWidth * ratio;
        const height = imgHeight * ratio;

        doc.addImage(base64Image as string, 'JPEG', 0, 0, width, height);
        const pdfBlob = doc.output('blob');
        const pdfFile = new File([pdfBlob], `${nombre}.pdf`);
        resolve(pdfFile);
      };
    });
  }

  public async appendPDF(pdfs: File[]) {
    const pdfDoc = await PDFDocument.create();
    await Promise.all(
      pdfs.map((pdf) => {
        return new Promise(async (resolve) => {
          //  Convertir PDF en un arreglo de bytes
          let pdfBuffer = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsArrayBuffer(pdf);
          });

          const bytes = new Uint8Array(pdfBuffer as ArrayBuffer);

          const firstDonorPdfDoc = await PDFDocument.load(bytes);

          let cont = 0;
          await Promise.all(
            firstDonorPdfDoc.getPages().map((page) => {
              return new Promise(async (resolve) => {
                const [firstDonorPage] = await pdfDoc.copyPages(
                  firstDonorPdfDoc,
                  [cont++]
                );
                pdfDoc.addPage(firstDonorPage);
                resolve(null);
              });
            })
          );
          resolve(null);
        });
      })
    );

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  }

  /**
   *
   * @param file archivo en formato image
   * @returns promesa para la generacion de pdf
   */
  convertImage(file: File): Promise<any> {
    var promise = new Promise((resolve) => {
      let imageData: any = '';
      let documentoConvertido: any = '';
      let doc = new jspdf({ compress: true });

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {};
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          //console.log(img_height, img_width);
        };
        imageData = reader.result;
      };

      setTimeout(() => {
        doc.addImage(imageData, 'JPG', 10, 10, 180, 150);
        documentoConvertido = doc.output();
        let fileConvert: File = new File([documentoConvertido], file.name, {
          type: 'application/pdf',
        });
        documentoConvertido = fileConvert;
        resolve(documentoConvertido);
      }, 1000);
    });

    return promise;
  }

  /**
   *
   * @param base64 base 64 de una imagen
   * @returns promesa con el archivo en formato pdf
   */
  convertBase64toPdf(base64: string): Promise<any> {
    let doc = new jspdf({ compress: true });
    var promise = new Promise((resolve, reject) => {
      doc.addImage(base64, 'JPG', 10, 10, 180, 150);
      let documentoConvertido: any = doc.output();
      let blob: Blob = new Blob([documentoConvertido], {
        type: 'application/pdf',
      });
      documentoConvertido = blob;
      resolve(documentoConvertido);
    });

    return promise;
  }

  private getBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsDataURL(file);
    });
  }
}
