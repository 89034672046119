import { Injectable, Injector } from '@angular/core';
import { DialogComponent } from '../components/dialog/dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private modalService: NgbModal) {}

  openDialog(
    titulo: string,
    mensaje: string,
    success: boolean,
    next: () => any = () => {}
  ) {
    const modalRef = this.modalService.open(DialogComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'custom-backdrop',
      windowClass: 'custom-modal'
    });

    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensaje = mensaje;
    modalRef.componentInstance.success = success;
    modalRef.closed.subscribe(() => {
      next();
    });
  }
}
