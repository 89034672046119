import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../../services/data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AdoServiceService } from '../../../../services/ado-service.service';

@Component({
  selector: 'app-docs-pagare',
  templateUrl: './docs-pagare.component.html',
  styleUrls: ['./docs-pagare.component.css'],
})
export class DocsPagareComponent implements OnInit {
  public b64Pdf;
  public url: string = '';

  constructor(
    private http: HttpClient,
    public data: DataService, // public url: string = 'cadenaurl'
    public adoServ: AdoServiceService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  async ngOnInit() {
    this.showContract(this.data.pagare_url)
  }

  public async showContract(url: string){
    let blobFile = await this.convertUrlToBlob(url);
    let b64File = await this.convertBlobToData(blobFile);
    this.b64Pdf = b64File;
  }

  async convertUrlToBlob(url: string) {
    var blob = new Blob();
    var myRequest = new Request(url);
    return await fetch(myRequest)
      .then((response) => response.blob())
      .then(function (myBlob) {
        console.log(myBlob);
        return myBlob;
      });
  }

  convertBlobToData = (blob: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
}
