<div class="dialog-change-ammount">
  <div class="d-flex justify-content-end dialog-close">
    <span class="material-icons" mat-dialog-close>cancel</span>
  </div>
  <h1>Modifica el monto y plazo</h1>
  <mat-dialog-content>
    <form [formGroup]="dialogForm">
      <div class="row">
        <div class="d-flex ammount">
          <strong>Monto</strong>
          <mat-form-field class="w-100" appearance="outline">
            <input formControlName="ammount" matInput type="tel" currencyMask />
          </mat-form-field>
        </div>

        <div>
          <strong>Plazo</strong>
          <mat-form-field class="w-100" appearance="outline">
            <input formControlName="term" matInput type="tel" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div class="d-flex justify-content-center">
    <button
      mat-dialog-close
      [disabled]="dialogForm.invalid"
      [disableRipple]="true"
      mat-raised-button
      class="btn-change-ammount"
      (click)="changeData()"
    >
      Modificar
    </button>
  </div>
</div>
