import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// import { MatDialogRef } from '@angular/material/dialog';

// import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

// import { Subject, Observable } from 'rxjs';

// import { DialogModificaComponent } from 'src/app/pwa/pre-oferta/dialog-modifica/dialog-modifica.component';
import { DataService } from '../../../../../services/data.service';

// @Component({
//   selector: 'app-camera',
//   templateUrl: './camera.component.html',
//   styleUrls: ['./camera.component.scss']
// })
// export class CameraComponent {

//   @Output() public pictureTaken = new EventEmitter<WebcamImage>();
//   @Output() imageBase64 = new EventEmitter<string>();

//   // toggle webcam on/off
//   public showWebcam = true;
//   public allowCameraSwitch = true;
//   public multipleWebcamsAvailable = false;
//   public deviceId: string;
//   public videoOptions: MediaTrackConstraints = {
//     // width: {ideal: 1024},
//     // height: {ideal: 576}
//   };
//   public errors: WebcamInitError[] = [];

//   // webcam snapshot trigger
//   private trigger: Subject<void> = new Subject<void>();
//   // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
//   private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

//   constructor(private dialogRef: MatDialogRef<DialogModificaComponent>){}

//   public ngOnInit(): void {
//     WebcamUtil.getAvailableVideoInputs()
//       .then((mediaDevices: MediaDeviceInfo[]) => {
//         this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
//       });
//   }

//   public triggerSnapshot(): void {
//     this.trigger.next();
//   }

//   public toggleWebcam(): void {
//     this.showWebcam = !this.showWebcam;
//   }

//   public handleInitError(error: WebcamInitError): void {
//     this.errors.push(error);
//   }

//   public showNextWebcam(directionOrDeviceId: boolean|string): void {
//     // true => move forward through devices
//     // false => move backwards through devices
//     // string => move to device with given deviceId
//     this.nextWebcam.next(directionOrDeviceId);
//   }

//   public handleImage(webcamImage: WebcamImage): void {
//     this.dialogRef.close({ data:  webcamImage.imageAsDataUrl })
//     this.pictureTaken.emit(webcamImage);
//   }

//   public cameraWasSwitched(deviceId: string): void {
//     console.log('active device: ' + deviceId);
//     this.deviceId = deviceId;
//   }

//   public get triggerObservable(): Observable<void> {
//     return this.trigger.asObservable();
//   }

//   public get nextWebcamObservable(): Observable<boolean|string> {
//     return this.nextWebcam.asObservable();
//   }

// }

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {

  @Output() imageBase64 = new EventEmitter<string>();

  videNode: any;
  stream: any;
  foto: any;

  constructor(private _dataService: DataService) {}

  ngOnInit(): void {
    this.videNode = document.getElementById('player');
    this.encender();
  }

  private encender(): void {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: 300,
          height: 300,
          facingMode: 'user'
        },
      })
      .then((stream) => {
        this.videNode.srcObject = stream;
        this.stream = stream;
      });
  }

  private apagar(): void {
    this.videNode.pause();

    if (this.stream) {
      this.stream.getTracks()[0].stop();
    }
  }

  public tomarFoto(): void {
    let canvas = document.createElement('canvas');

    canvas.setAttribute('width', '300');
    canvas.setAttribute('height', '300');

    let context = canvas.getContext('2d');

    context.drawImage(this.videNode, 0, 0, canvas.width, canvas.height);

    this.foto = context.canvas.toDataURL();

    canvas = null;
    context = null;

    this.apagar();

    this._dataService.base64 = this.foto;
  }

}
