<!-- <div>
<iframe
class="frame"
src="https://app.powerbi.com/view?r=eyJrIjoiZDBmM2UyNDItMWRkMC00NzhhLTkwY2EtNGRkODEzOGQzMzc5IiwidCI6ImQ0MTk5MTU1LTc3ZTctNDY0OC05N2YxLTY2ODNkMThiMjZkYiIsImMiOjR9&pageName=ReportSectionad8e3a26ac7b3a567b34"
frameborder="0"
allowFullScreen="false"></iframe>
</div> -->

<app-main-container header="Mis reportes" subheader="Conoce el detalle de tu desempeño comercial">

<div class="mobile">
  <iframe
  class="frame"
  [src]="'https://lookerstudio.google.com/embed/reporting/1f447826-9f08-45e9-a2d4-78ea86b146c1/page/p_jqgvmfss6c?params=%7B%22ds0.id_asesor%22:%22'+user_id+'%22,%22ds79.id_asesor%22:%22'+user_id+'%22%7D' | safeUrl"
  frameborder="0"
  allowFullScreen="true"></iframe>
</div>

  <div class="desk">
    <iframe class="frame"
      [src]="'https://lookerstudio.google.com/embed/reporting/1f447826-9f08-45e9-a2d4-78ea86b146c1/page/p_jqgvmfss6c?params=%7B%22ds0.id_asesor%22:%22'+user_id+'%22,%22ds79.id_asesor%22:%22'+user_id+'%22%7D' | safeUrl"
      frameborder="0" allowFullScreen="false" style="height: 1150px;"></iframe>
  </div>
</app-main-container>
