<div class="contenido" style="padding-bottom: 1rem;">
  <div id="tituloP">Validación de identidad</div>
  <div id="tituloS">Actualización de información del solicitante</div>
  <p>
    Solicita la siguiente información para continuar proceso de autorización de tratamiento de datos y validación de
    identidad.
  </p>
  <form class="form" [formGroup]="formInfoCorreo" style="margin-left: -17px;">
    <div class="row">
      <div class="inp-wrap col-4 col-sm-4 col-md-4 col-lg-4 mb-err-inp">
        <mat-label>Correo electrónico</mat-label>
        <mat-form-field style="width: 103%" appearance="outline">
          <input matInput formControlName="correo" ngModel email="true" type="email"
            (keyup)="changeComfirmar(txtQuery.value)" />
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
          formInfoCorreo.controls.correo.hasError(
                      'required'
                    )
                  ">
            El correo electrónico es obligatorio
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
          formInfoCorreo.controls.correo.hasError(
                      'pattern'
                    )
                  ">
            El formato de correo electrónico es incorrecto
          </mat-error>
        </mat-form-field>
      </div>
      <div class="inp-wrap col-4 col-sm-4 col-md-4 col-lg-4 mb-err-inp">
        <mat-label>Confirmar correo electrónico</mat-label>
        <mat-form-field style="width: 109%" appearance="outline">
          <input matInput formControlName="confirmarCorreo" ngModel email="true" type="email" #txtQuery
            (keyup)="changeComfirmar(txtQuery.value)" />
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
          formInfoCorreo.controls.confirmarCorreo.hasError(
                      'required'
                    )
                  ">
            Confirmar el correo electrónico es obligatorio
          </mat-error>
          <mat-error style="color: #f44336;margin-left: -7px;" class="error" *ngIf="
          formInfoCorreo.controls.confirmarCorreo.hasError(
                      'pattern'
                    )
                  ">
            El formato de correo electrónico es incorrecto
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="alert" *ngIf="isValidConfirmar">
    <img src="../../../assets/icons/errorClic.svg" alt="error clic" />
    No coinciden los correos ingresados
  </div>
  <br>
  <div id="autorizacion">
    Al ingresar los datos personales del solicitante, como asesor/corredor declaras que cuentas con la autorización para
    transferir estos datos personales a Bayport y cuentas con los consentimientos necesarios para realizar el
    tratamiento de datos personales, en calidad de responsable de acuerdo con las obligaciones establecidas para dicho
    rol por la <b>Ley 1581 de 2012</b> y sus decretos reglamentarios.
  </div><br><br><br><br>
  <button class="continuar" (click)="confirmacion()" [disabled]="!formInfoCorreo.valid || isValidConfirmar || loading"
    mat-button>
    <mat-icon *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-icon>
    Guardar
  </button>
</div>