<div class="content">
  <div class="cont-form">
    <h1>Cambiar contraseña</h1>
    <p>¡Parece que es tu primer ingrreso a nuestro Portal de Agentes! Ingresa tu correo corporativo. Si tu correo parab cambiar contraseña no funcina
      <a class="olvide-pass" [routerLink]="['/recup-pass']"
      >haz clic aquí.</a
    ></p>
    <form [formGroup]="chPassForm">
      <mat-label>Correo electrónico</mat-label>
      <br />
      <mat-form-field class="example-full-width">
        <input
          matInput
          name="password"
          formControlName="password"
          maxlength="15"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="chPassForm.controls.user.hasError('required')">
          El usuario es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="chPassForm.controls.user.hasError('pattern')">
          El usuario solo acepta <strong>números</strong>
        </mat-error>
        <mat-error *ngIf="chPassForm.controls.user.hasError('minlength')">
          Minimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="chPassForm.controls.user.hasError('maxlength')">
          Maximo 15 caracteres
        </mat-error>
      </mat-form-field>

      <p>{{ errorLog }}</p>
    </form>
    <p></p>

    <!--<button type="button" class="btn btn-primary" (click)="loginCallback()">Recuperar contraseña</button>-->
    <button type="button" class="btn btn-primary">Cambiar contraseña</button>

    <!--<button class="g-recaptcha"
        data-sitekey="6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP"
        data-callback='onSubmit'
        data-action='submit'>Submit</button>-->
  </div>
</div>
