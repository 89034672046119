import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  constructor(
    private _router:Router,
    private dialog:MatDialogRef<InfoComponent>
  ) { }

  ngOnInit(): void {
  }

  aceptar(){
    this.dialog.close();
  }


}
