import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  currentYear = 0;

  constructor() {}

  ngOnInit(): void {
    const date = new Date();

    this.currentYear = date.getFullYear();
  }
}
