<div class="modal-dialog-centered">
  <div class="modal-content p-4">
    <h4 class="me-auto">{{titulo}}</h4>
    <p class="mt-1" [innerHTML]="mensaje"></p>
    <div class="d-flex url-container mt-3" [ngClass]="[copied?'copiado':'no-copiado']">
      <p class="ms-3" [innerHTML]="url"></p>
      <button class="copy-button" (click)="copyToClipboard()">Copiar vínculo<i
          class="fa-regular fa-copy ms-2"></i></button>
    </div>
    <div class="d-flex align-items-center flex-column">
      <app-advertencias [hidden]="!copied" [color]="alertConfig.color" class="w-100" [compressed]="true">{{alertConfig.mensaje}}</app-advertencias>
      <app-hyperlink-icon [icon]="'arrow'" iconPosition="start" class="mt-3" (clicked)="cerrar()">Volver</app-hyperlink-icon>
    </div>
  </div>
</div>
