import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LlmsService {
  private endpointBase = `${environment.ApiConecta}llms`;

  constructor(private http: HttpClient) {}

  processDocument(data: any) {
    return this.http.post(`${this.endpointBase}/process-document`, data);
  }

  validateDocument(): Observable<any> {
    return this.http.get(`${this.endpointBase}/validate-document`);
  }
}
