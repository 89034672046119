import { AbstractControl } from "@angular/forms";

/**
 * Clase para metodos que evaluan el contenido de algun formulario
 */
export class FormChecks {
    static checkMayus(control: AbstractControl): { [key: string]: any } | null {
        const inputValue: string = control.value;
        return /.*[A-Z].*/.test(inputValue) ? null : { notMayus: true };
    }

    static checkMinus(control: AbstractControl): { [key: string]: any } | null {
        const inputValue: string = control.value;
        return /.*[a-z].*/.test(inputValue) ? null : { notMinus: true };
    }

    static checkNumber(control: AbstractControl): { [key: string]: any } | null {
        const inputValue: string = control.value;
        return /\d/.test(inputValue) ? null : { notNumber: true };
    }

    static checkSpecialCharacter(control: AbstractControl): { [key: string]: any } | null {
        const inputValue: string = control.value;
        return /.*[\W_].*/.test(inputValue) ? null : { notSpecialCharacter: true };
    }

    static checkIfEqual(control: AbstractControl): { [key: string]: any } | null {
        const input1Value = control.get('new-password').value;
        const input2Value = control.get('confirm-password').value;
        return input1Value === input2Value ? null : { notEqual: true };
    }
}
