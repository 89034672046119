import { Component, ViewChild, Input,TemplateRef, ElementRef, AfterViewInit   } from '@angular/core';

@Component({
  selector: 'app-tabs-detalle',
  templateUrl: './tabs-detalle.component.html',
  styleUrls: ['./tabs-detalle.component.scss']
})
export class TabsDetalleComponent {
  @ViewChild('tabsContainer') tabsContainer!: ElementRef;
  @Input() tabs?: { title: string; content: TemplateRef<any> }[] = [];
  @Input() defaultTabIndex: number = 0; // Índice de la pestaña activa por defecto

  activeTab: number = 0;

  ngAfterViewInit() {
    const container = this.tabsContainer.nativeElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    container.addEventListener('mousedown', (e: MouseEvent) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener('mouseleave', () => {
      isDown = false;
    });

    container.addEventListener('mouseup', () => {
      isDown = false;
    });

    container.addEventListener('mousemove', (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2; // Ajusta la velocidad del desplazamiento
      container.scrollLeft = scrollLeft - walk;
    });
  }

  ngOnInit() {
    this.activeTab = this.defaultTabIndex;
  }

  selectTab(index: number) {
    this.activeTab = index;
  }

}
