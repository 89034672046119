import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild('news') news: ElementRef;
  @ViewChild('home') home: ElementRef;
  @ViewChildren('item_') item_: QueryList<ElementRef>;

  private subList: Array<Subscription> = [];
  private TIMER_ID: any;

  public screenWidth: number = 0;
  newsUrls: Array<any> = [];

  constructor(
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private _newsService: NewsService,
    private renderer: Renderer2
  ) {
    if (!localStorage.getItem('tpcnl')) {
      setTimeout(() => this.getUrls(), 500);
    } else {
      this.getUrls();
    }

    // set screenWidth on page load
    this.screenWidth = window.innerWidth;

    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
      this.cdr.detectChanges();
      if (this.screenWidth > 1025) {
        this.drawer.open();
      } else {
        this.drawer.close();
      }
    };
  }
  url: string = this.router.url;

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subList.forEach((item) => item.unsubscribe());
    clearInterval(this.TIMER_ID);
  }

  private getUrls(): void {
    this._newsService
      .getUrls({ tipo_asesor: localStorage.getItem('tpcnl') })
      .subscribe(
        (res) => {
          try {
            if (Number(res.CODE) !== 200) {
              console.error('ERROR_GET_NEWS_URLS: ', res.CODE);
              return;
            }
            const { response } = res.data;
            this.newsUrls = response;
          } catch (e) {
            console.error('ERROR_GET_NEWS_URLS');
          }
        },
        (err) => {
          console.error('ERROR_GET_NEWS_URLS: ', err);
        }
      );
  }
  icon_dbs: string = 'assets/icons/idbs.svg';
  showNews(url_: any, el: any) {
    const { _element } = el;
    const { url, name } = url_;
    this.item_.forEach((item) => {
      this.renderer.removeClass(item['_element'].nativeElement, 'bay-active');
    });
    this.renderer.removeClass(
      this.home['_element'].nativeElement,
      'bay-active'
    );
    this.renderer.addClass(_element.nativeElement, 'bay-active');
    if (_element !== this.home['_element']) {
      this.renderer.addClass(this.news['_element'].nativeElement, 'bay-active');
      this.icon_dbs = 'assets/icons/idbs_w.svg';
      this.router.navigate(['/', 'home']).then(() => {
        this.renderer.removeClass(
          this.home['_element'].nativeElement,
          'bay-active'
        );
        this._newsService.newsActive.emit({ url, name });
      });
      return;
    }
    this._newsService.newsActive.emit({ url, name });
    this.renderer.removeClass(
      this.news['_element'].nativeElement,
      'bay-active'
    );
    this.icon_dbs = 'assets/icons/idbs.svg';
  }

  clearRouterLinkActive(): void {
    this.icon_dbs = 'assets/icons/idbs.svg';
    this.item_.forEach((item) => {
      this.renderer.removeClass(item['_element'].nativeElement, 'bay-active');
    });
    this.renderer.removeClass(
      this.news['_element'].nativeElement,
      'bay-active'
    );
  }
}
