import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatepickerFormatService extends NgbDateParserFormatter {
  // Convierte NgbDateStruct a string con formato dd/mm/yyyy
  override format(date: NgbDateStruct | null): string {
    return date ? `${this.pad(date.day)}/${this.pad(date.month)}/${date.year}` : '';
  }

  // Convierte string dd/mm/yyyy a NgbDateStruct
  override parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const parts = value.split('/');
    return parts.length === 3
      ? { day: +parts[0], month: +parts[1], year: +parts[2] }
      : null;
  }

  // Agrega un 0 a los números menores de 10
  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
