import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RcValidaOtp } from '../../models/rcValidaOTP.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RcValidaOTPService {

  private endpoint = environment.ApiConecta+'validate/email/otp';

  constructor(
    private http:HttpClient
  ) { }

  ValidaOtp(canal: string, usuario: string, otp: string): Observable<RcValidaOtp>{
console.log(canal,usuario,otp);

    return this.http.post<RcValidaOtp>(this.endpoint, {canal,usuario,otp});
  }

}
