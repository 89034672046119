import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { IInfoLaboralBody } from '../../models/info-adicional/infoLaboral';
import { IInfoFinancieraBody } from '../../models/info-adicional/infoFinanciera';
import { IInfoDesembolsoBody } from '../../models/info-adicional/infoDesembolso';
import { IInfoSegurabilidadBody } from '../../models/info-adicional/infoSegurabilidad';
import { IInfoPersonalBody } from '../../models/info-adicional/infoPersonal';

import { environment } from 'src/environments/environment';
import { GetAEI, GetAFI, GetAPI, GetDI } from 'src/app/models/perfilamiento/additional-information.model';

@Injectable({
  providedIn: 'root'
})
export class InfoAdicionalService {
  private readonly api = environment.ApiConecta+`perfilamiento/adicional/`;
  constructor(private http: HttpClient) { }

  getReconocimiento(numeroSolicitud: number): Observable<Object> {
    return this.http.get(`${environment.ApiConecta}perfilamiento/adicional/informacion/personal/reconocer/${numeroSolicitud}`);
  }

  getDataDireccion(codigo: number): Observable<Object> {
    return this.http.get(`${environment.ApiConecta}parametros/direccion/${codigo}`);
  }

  postInfoPersonalAdicional(body: IInfoPersonalBody): Observable<Object> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/adicional/informacion/personal`, body);
  }

  postInfoLaboralAdicional(body: IInfoLaboralBody): Observable<Object> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/adicional/informacion/laboral`, body);
  }

  postInfoFinancieraAdicional(body: IInfoFinancieraBody): Observable<Object> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/adicional/informacion/financiera`, body);
  }

  postInfoDesembolsoAdicional(body: IInfoDesembolsoBody): Observable<Object> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/adicional/desembolso`, body);
  }

  postInfoSegurabilidadAdicional(body: IInfoSegurabilidadBody): Observable<Object> {
    return this.http.post(`${environment.ApiConecta}perfilamiento/adicional/asegurabilidad`, body);
  }

  getPersonalInformation(carpeta:any): Observable<GetAPI> {
    return this.http.get<GetAPI>(`${this.api}informacion/personal/${carpeta}`);
  }

  getEmploymentInformation(carpeta: any): Observable<GetAEI> {
    return this.http.get<GetAEI>(`${this.api}informacion/laboral/${carpeta}`);
  }

  getDisbrusementInformation(carpeta: any): Observable<GetDI> {
    return this.http.get<GetDI>(`${this.api}desembolso/${carpeta}`);
  }

  getFinancialInformation(carpeta: any): Observable<GetAFI> {
    return this.http.get<GetAFI>(`${this.api}financiera/${carpeta}`);
  }
}
