<div class="card">
  <div *ngIf="paso1">
    <h4 class="title">{{ titulo }}</h4>
  </div>

  <div class="cont-card" *ngIf="paso1">
    <span style="font-size: 18px">
      {{ text }}
    </span>
    <div
      style="display: flex; align-items: center; gap: 0.2rem"
      *ngIf="isComision"
    >
      <p style="font-size: 14px !important; padding: 0; margin: 0 !important">
        Última actualización al mes de
      </p>
      <span style="font-size: 14px; font-weight: bold">
        {{ mesCargado }}
      </span>
    </div>
    <ng-container
      [ngTemplateOutlet]="onLoad"
      *ngIf="type === 'load'"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="onCommon"
      *ngIf="type === 'common'"
    ></ng-container>
  </div>

  <div *ngIf="paso2">
    <div class="cont-card">
      <img id="tarjeta" src="assets/img/tarjeta.PNG" alt="tarjeta" />
      <div style="margin-top: 10px">
        <span style="font-size: 16px">Archivo cargado exitosamente.</span>
      </div>
    </div>
    <div class="cont-card">
      <a
        class="elimiar cont-card"
        (click)="eliminarArchivo()"
        role="button"
        style="font-size: 16px"
        >Eliminar archivo y cargar otro</a
      >
    </div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #onLoad>
  <div class="btn-select-card">
    <label for="update-account" class="h-100 d-flex align-items-center">
      <mat-icon
        aria-hidden="false"
        aria-label="Example home icon"
        style="padding: 0px 24px 0px 8px"
        >attach_file
      </mat-icon>
      <span style="font-size: 16px"> Cargar desde una carpeta</span>
    </label>
    <input
      id="update-account"
      accept="{{ allowedFormats }}"
      type="file"
      class="bayport-file"
      style="display: none"
      (change)="onAddFile($event)"
    />
  </div>
  <div class="bc-cam" *ngIf="enableCamera">
    <button
      class="btn-cam-rag-card cont-card d-flex align-items-center"
      mat-button
    >
      <span class="material-icons" style="padding: 0px 4px 0px 8px">
        camera_alt
      </span>
      <span> Cargar usando la cámara </span>
    </button>
  </div>
</ng-template>

<ng-template #onCommon>
  <button
    class="btn-select-card d-flex justify-content-center align-items-center"
    (click)="viewp()"
  >
    <mat-icon class="mr-2"> visibility </mat-icon>
    <span style="font-size: 16px">Ver</span>
  </button>
  <button
    class="btn-select-card additional d-flex justify-content-center align-items-center"
    (click)="onDownload()"
  >
    <img src="../../../../../assets/icons/dowload.svg" alt="" />
    <span style="font-size: 16px; padding-left: 0.5rem">Descargar</span>
  </button>
</ng-template>
