import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../../../services/data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AdoServiceService } from '../../../../services/ado-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-docs-contrato',
  templateUrl: './docs-contrato.component.html',
  styleUrls: ['./docs-contrato.component.css'],
})
export class DocsContratoComponent implements OnInit {
  public b64Pdf;
  public url: string = '';

  constructor(
    public adoServ: AdoServiceService,
    @Inject (MAT_DIALOG_DATA) public data: any
  ) {

  }

  async ngOnInit() {
    console.log(this.data.contrato_url);
    this.showContract(this.data.contrato_url)
  }


  public async showContract(url: string){
    let blobFile = await this.convertUrlToBlob(url);
    let b64File = await this.convertBlobToData(blobFile);
    this.b64Pdf = `data:/application/pdf;base64,`+String(b64File).split(',')[1];
    console.log("🚀 ~ file: docs-contrato.component.ts:35 ~ DocsContratoComponent ~ showContract ~ this.b64Pdf:", this.b64Pdf)
    
  }

  async convertUrlToBlob(url: string) {
    var myRequest = new Request(url);
    return await fetch(myRequest)
      .then((response) => response.blob())
      .then(function (myBlob) {
        console.log(myBlob);
        return myBlob;
      });
  }

  convertBlobToData = (blob: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
}
