<div class="alert-container">
  <h2 mat-dialog-title>
    Firma de documentos
  </h2>
  <mat-dialog-content class="mat-typography">
    <p>Revisa los documentos para continuar con tu validación de identidad</p>
  </mat-dialog-content>
  <button
    style="width: 100%;"
    class="cerrar"
    mat-flat-button
    mat-dialog-close
  >Iniciar validación</button>
</div>
