<!--app-header></app-header-->
<div class="contenido mobile">
  <h3>Firma de documentos</h3>
  <p style="margin-top: 16px !important;">Antes de empezar te recomendamos leer atentamente las siguientes indicaciones:
  </p>
  <mat-card>
    <div class="ref">
      <a (click)="getContrato()">Documentos contrato</a>
    </div>
    <p>
      Aquí puedes consultar el contrato, autorización de libranza, seguro de accidentes, libranza y libraval.
    </p>
  </mat-card>
  <mat-card>
    <div class="ref">
      <a (click)="getPagare()">Documentos pagaré</a>
    </div>
    <p>
      Consulta el documento de pagaré que respalda tu solicitud de crédito.
    </p>
  </mat-card>
  <mat-card *ngIf="existeDocumentoPagaduria == '1'">
    <div class="ref">
      <a (click)="getPagaduria()">Documentos pagaduría</a>
    </div>
    <p>
      Consulta el documento autorización de libranza, autorización de descuento por nómina y cédula
      de cuidadanía.
    </p>
  </mat-card>

  <p style="margin-top: 40px !important;">Tu aceptación es equivalente a la firma en el acuerdo, según lo establecido
    por la Ley No. 527 de 1999, mediante la cual se define y regula el acceso y uso de los mensajes de datos. No es
    necesario imprimir o descargar los documentos.</p>
  <div class="modal" *ngIf="activeSpinner">
    <img src="../../../../assets/img/spinner.gif" style="height:120px;" />
  </div>
  <button
    [disabled]="!activeSpinner && errorSpinner"
    type="button"
    class="btn btn-primary"
    style="display: flex; align-items: center; justify-content: center;"
    (click)="onRedirectIdentValidate()"
  >
    Continuar
  </button>
</div>
