<div class="alert-container">
    <div class="close-wrapper col-12">
        <div class="close" (click)="continue()">
          <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
    <br>
    <h2 class="title">Tu sesión expirará pronto </h2>
    <mat-dialog-content class="mat-typography">
        <p>
            No hemos detectado actividad en BayportClic, por lo que tu sesión cerrará en <strong>{{timeOut}} segundos.</strong>
        </p>
        <p>
            ¿Qué deseas hacer?
        </p>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons-wrapper d-flex">
        <div class="cancel-btn col-12">
            <button-look-feel color="secondary" (clicked)="logout()">Cerrar sesión</button-look-feel>
        </div>
        <div class="continue-btn col-12">
          <button-look-feel color="outline" (clicked)="continue()">Mantener sesión abierta</button-look-feel>
        </div>
    </mat-dialog-actions>
</div>
