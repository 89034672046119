import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CargosService {

  private endpoint = environment.ApiConecta+'libranza/pagadurias/cargos/';

  constructor(private http:HttpClient) { }

  getCargos(codigoPagaduria:string):Observable<any>{
    return this.http.get(this.endpoint+codigoPagaduria,{});
  }
}
