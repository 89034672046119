<div class="card">
  <div class="card__content">
    <div class="archivo__cargado" *ngIf="archivoCargado">
      <img
        src="./../../../assets/icons/archivo-cargado-blanco.svg"
        style="width: 42px"
        alt=""
      />
      <p style="color: #ffffff !important">{{ this.nombreArchivo }}</p>
    </div>
    <div *ngIf="!archivoCargado">
      <div style="display: grid; place-content: center; margin-top: 20px">
        <img src="{{ iconoCentral }}" alt="" />
      </div>
      <h3 class="title" style="margin-top: 14px !important">
        Certificación bancaria
      </h3>
      <h6 class="subtitle">Arrastra y suelta tus archivos aquí</h6>
      <label class="button__file">
        <div class="row">
          <img
            src="./../../../assets/icons/lupa-azul.svg"
            alt=""
            style="width: 38px"
          />
          Selecciona o arrastra un archivo
        </div>
        <input
          type="file"
          style="display: none"
          (change)="onFileChange($event)"
          accept=".png, .jpg, .pdf"
        />
      </label>
      <div class="error" *ngIf="isError">
        <img src="./../../../assets/icons/advertencia.svg" alt="" />
        <p class="error__text">
          El archivo supera el límite, comprímelo e intenta otra vez
        </p>
      </div>
      <p class="text__description">
        El archivo que selecciones no debe superar 5MB. Debe estar en formato
        PDF. El documento no debe tener más de 30 días de expedido.
      </p>
    </div>
  </div>
</div>
