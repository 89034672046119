<div class="cont-valid">

<div class="head">
  <img class="img-log" src="../../assets/img/BayportLogo.svg">
</div>

<div >
  <img class="img-rsp" src="../../assets/img/Vag5974.png">
</div>

<div class="bar-azul">
  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni minima vel debitis neque autem velit assumenda minus omnis at. Fuga distinctio velit quas, nemo ad labore sequi. Voluptates, earum labore.
</div>

<div class="c-val">
  otro texto
</div>
</div>
