import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiPerfilService {

  private endpoint = environment.ApiConecta

  constructor(
    private http: HttpClient,
  ) { }

  // GETS

  getInfoBayport(): Observable<any> {
    return this.http.get(`${this.endpoint}profile/bayport-information`);
  }

  getInfoPersonal(): Observable<any> {
    return this.http.get(`${this.endpoint}profile/personal-information`);
  }

  getInfoPrestacionesSociales(): Observable<any> {
    return this.http.get(`${this.endpoint}profile/social-information`);
  }

  getInfoBancaria(): Observable<any> {
    return this.http.get(`${this.endpoint}profile/banking-information`);
  }

  getInfoFinanciera(): Observable<any> {
    return this.http.get(`${this.endpoint}profile/financial-information`);
  }

  // PUTS

  updateInfoBayport(infoBayport: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}profile/update/bayport-information`, infoBayport);
  }

  updateInfoPersonal(infoPersonal: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}profile/update/personal-information`, infoPersonal);
  }

  updateInfoPrestacionesSociales(infoSocial: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}profile/update/social-information`, infoSocial);
  }

  updateInfoFinanciera(infoFinanciera: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}profile/update/financial-information`, infoFinanciera);
  }
}
