import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComfirModelComponent } from '../comfir-model/comfir-model.component';
import Swal from 'sweetalert2';
import { Utils } from 'src/app/utils/utils';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';
import { UploadService } from 'src/app/services/cargaArchivos/upload.service';
import { NotificaService } from 'src/app/services/notificaciones/notifica.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { RSA_NO_PADDING } from 'constants';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.css']
})
export class SendComponent implements OnInit {
  private utils = new Utils();
  @Input() listaPDF:any;
  @Input() notificacion:any;
  @Output() isDeleted:EventEmitter<boolean> = new EventEmitter<boolean>();

  public observaciones = '';
  constructor(
    private dialog:MatDialog,
    private serviceCarga:ArchivoCargaService,
    private serviceUpload:UploadService,
    private serviceNotifica:NotificaService,
    private serviceDoc:DocSolicitudService
  ) { }

  ngOnInit(): void {
    // this.isDeleted.emit(false);
  }

  deletePDF(index){
    this.listaPDF.splice(index,1);
    console.log(this.listaPDF);
  }

  async enviar(){
    const { numeroCarpeta,codigoEstadoActualCarpeta } = this.notificacion;

    if(codigoEstadoActualCarpeta == "1100" || codigoEstadoActualCarpeta == "400" || codigoEstadoActualCarpeta == "500"){
      Swal.fire('Opps','El estado actual de la carpeta no permite continuar, intenta en un momento', 'warning');
      this.listaPDF = [];
      return
    }

    

    if( codigoEstadoActualCarpeta == "600" || codigoEstadoActualCarpeta == "700" ||
    codigoEstadoActualCarpeta == "990" || codigoEstadoActualCarpeta == "1300" || codigoEstadoActualCarpeta == "550" ){
      if(this.observaciones == ''){
        Swal.fire('Opps','Favor de escribir tus observaciones','warning');
        return
      }
    }

    const dialogRef = this.dialog.open(ComfirModelComponent);
    const identificacion = "8.recuperaciones";

    dialogRef.beforeClosed().subscribe(data => {
      if(data.ok){
        if(this.listaPDF.length >= 1){
          this.convertirPDF().then(res => {

            //TODO -> Cambiar por asyc/await
            this.metodoCargaArchivo(numeroCarpeta,identificacion,res);
            
            //this.getCambioEstado();
            //this.eliminarNotificacion();
          });
        }else{
            this.getCambioEstado();
            this.eliminarNotificacion();
        }
      }
    });
  }

  //Peticion servicio para obtener data del buket
  metodoCargaArchivo(carpeta,identificacion,file){
    this.serviceCarga.cargandoArchivo(carpeta,identificacion,file.name).subscribe(
      respuesta=>{
        this.uploadFileToBuket(file,respuesta);
      },
      error=>{
        console.log("No hay respuesta del BUCKET");
        console.log(<any>error);
      }
    );
  }

  async uploadFileToBuket(file,data){
    try {
      const { numeroCarpeta } = this.notificacion;

      let form = new FormData();
      Object.keys(data.fields).forEach(key => form.append(key, data.fields[key]));
      form.append('Content-Type', file.type);
      form.append('file', file);

      fetch(data.url, { method: 'POST', body: form }).then( res=>{
        if(res.ok){

          //carga de archivo
          this.serviceDoc.uploadAwsRoute(numeroCarpeta,data.fields.key,68, 8).subscribe(
            res=>{
              console.log(res);
              Swal.fire({
                imageUrl:'../../../../../assets/img/palomita.png',
                text:"Archivo cargado correctamente",
                confirmButtonColor:'#009BDC'
              });
            }
          );

          //TODO -> Cambiar por asyc/await
          this.getCambioEstado();
          this.eliminarNotificacion();

        }else{
          Swal.fire('Opps','Error al cargar el archivo, intenta nuevamente por favor','error');
        }
      });

    } catch (error) {
      console.log(error);

    }
  }


  getCambioEstado(){

    let estados = {
      '0':'20','20':'30','30':'40',"40":'50',"50":'100','100':'150','150':'200','200':'300','300':'700','800':'400',
      '1200':'530','530':'531','960':'970','970':'980','980':'990','1280':'1230'
    }

    const { numeroCarpeta,codigoEstadoActualCarpeta} = this.notificacion;

    let msj = "Cambio de estado aprobado por asesor";

    switch(codigoEstadoActualCarpeta){
      case "600":
        this.cambioNovedad(numeroCarpeta,"18",this.observaciones);
        break;
      case "700":
        this.cambioEstado(Number(numeroCarpeta),"800",msj);
        this.cambioNovedad(numeroCarpeta,"1",this.observaciones);
        break;
      case "990":
        this.cambioEstado(Number(numeroCarpeta),"550",msj);
        this.cambioNovedad(numeroCarpeta,"1",this.observaciones);
        break;
      case "1300":
        this.cambioNovedad(numeroCarpeta,"18",this.observaciones);
        break;
      case "550":
        this.cambioNovedad(numeroCarpeta,"1",this.observaciones);
        break;
      default:
        this.cambioEstado(Number(numeroCarpeta),estados[codigoEstadoActualCarpeta],msj);
    }

  }

  // cambio de estado
  cambioEstado(numeroCarpeta,estadoNuevo,observacion){
    try {
      this.serviceNotifica.cambioEstado(numeroCarpeta,estadoNuevo,observacion).subscribe(
        respuesta=>{
          console.log(respuesta);
          Swal.fire('Genial !!','Estado Carpeta cambiado correctamente','success');
        },
        error=>{
          console.log(<any>error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  cambioNovedad(carpeta,Novedad,observaciones){
    this.serviceNotifica.tipoNovedad(carpeta,Novedad,observaciones).subscribe(
      respuesta=>{
        console.log(respuesta);
        Swal.fire('Genial !!','Aplicación de novedad realizada con éxito','success');
      },
      error=>{
        console.log(<any>error);
      }
    );
  }

  eliminarNotificacion(){
    const {numeroCarpeta } = this.notificacion;
    this.serviceNotifica.deleteNovedad(numeroCarpeta).subscribe(
      respuesta=>{
        this.isDeleted.emit(true);
        this.observaciones = "";
        this.listaPDF = [];

      },
      error=>{
        console.log(<any>error);
        console.log("La notificación no fue eliminada correctamente");
      }
    )
  }

  //Convierte a un solo pdf
  async convertirPDF(){

    let pdfBytes = await this.utils.appendPDF(this.listaPDF.map(element => element[0] ));
    let timestamp = Date.parse( new Date().toString() );
    let name = `DOCUMENTOS-RECUPERACION-${timestamp}.pdf`;
    let pdfFile = new File([new Blob([pdfBytes as ArrayBuffer])],name, {type:"application/pdf", lastModified:new Date().getTime()})
    return pdfFile;
  }

}
