<div>
  <div class="t-dialog">
    <span><strong>Autorización terminada</strong></span>
  </div>
  <div class="txt-dialog">
<mat-dialog-content class="mat-typography">
  <p><strong>Andrés</strong>, el proceso ha sido aceptado, continúa con tu asesor Juan Andrés Uribe para recibir tu crédito con nosotros  .</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="finalizar" mat-button mat-dialog-close [routerLink]="['/validacion-terminada']">Finalizar</button>
</mat-dialog-actions>
</div>
</div>
