<div class="dialog-change-ammount">
  <h3 class="m-2">Modifica el monto y plazo</h3>
    <form [formGroup]="dialogForm">
      <div class="row">
        <div class="form-field">
          <label for="ammount" class="form-label">Monto</label>
          <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Monto" id="ammount"
            formControlName="ammount" autocomplete="off">
          <div class="form-error"
            *ngIf="dialogForm.controls.ammount.hasError('required') && !dialogForm.controls.ammount.pristine">
            Monto es obligatorio
          </div>
          <div class="form-error" *ngIf="dialogForm.controls.ammount.hasError('min')">
            Ingresar un valor igual o superior a 0.
          </div>
        </div>
        <div class="form-field">
          <label for="term" class="form-label">Plazo</label>
          <input type="number" inputmode="numeric" class="form-control" placeholder="*Plazo" id="term"
            autocomplete="off" formControlName="term">
          <div class="form-error"
            *ngIf="dialogForm.controls.term.hasError('required') && !dialogForm.controls.term.pristine">
            Plazo es obligatorio
          </div>
          <div class="form-error" *ngIf="dialogForm.controls.term.hasError('min')">
            Ingresa un valor igual o superior a dos cifras.
          </div>
          <div class="form-error" *ngIf="dialogForm.controls.term.hasError('max')">
            Ingresa un valor igual o inferior a tres cifras.
          </div>
        </div>
      </div>
    </form>
  <div class="mx-2">
    <button-look-feel [block]="true" color="secondary" (clicked)="changeData()" [disabled]="dialogForm.invalid">Modificar</button-look-feel>
  </div>
</div>
