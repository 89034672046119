<nav *ngIf="totalPages > 1" aria-label="Page navigation">
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage === 1">
            <!-- <button class="page-link" (click)="changePage(currentPage - 1)">Previous</button> -->
            <!-- <i class="bi bi-chevron-left"></i> --> 
             <img src="../../../assets/icons/previous.svg" class="page-link rotate" (click)="changePage(currentPage - 1)">
        </li>
        <li *ngFor="let page of pages" class="page-item" [class.active]="page === currentPage">
            <button class="page-link" (click)="changePage(page)">{{ page }}</button>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
            <!-- <button class="page-link" (click)="changePage(currentPage + 1)">Next</button> -->
            <img src="../../../assets/icons/next.svg" class="page-link" (click)="changePage(currentPage + 1)">
        </li>
    </ul>
</nav>