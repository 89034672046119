import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  idleTimeOut: EventEmitter<number> = new EventEmitter();
  idleActive: EventEmitter<boolean> = new EventEmitter();
  idleActiveSession: EventEmitter<boolean> = new EventEmitter();

  isLoaded: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private matDialog: MatDialog 
  ) { }

  openDialog(dialog:any, configs:MatDialogConfig): MatDialogRef<any> {
    return this.matDialog.open(dialog, configs);
  }
  closeDialog(dialog: MatDialogRef<any>): void {
    dialog.close()
  }
}
