<main class="fondo-principal w-100 h-screen flex relative overflow-hidden position-relative ">
  <aside [hidden]="openSisebar" class="d-md-flex">
    <app-layout (cerrarSesion)="cerrarSesion()"></app-layout>
  </aside>
  <article #scrollContainer (scroll)="onScroll($event)" class="w-100">
    <div class="header me-md-3">
      <app-header color="none" [title]="user_id" [text]="user" [user]="user" (logout)="cerrarSesion()" (toogleMenu)="toggleSidebar()"></app-header>
    </div>
    <router-outlet></router-outlet>
    <div class="footer d-block d-md-none">
      <app-footer></app-footer>
    </div>
  </article>
  <div class="circle-blue"></div>
</main>
