import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.css']
})
export class NotificacionComponent implements OnInit {
  @Input() mensaje:string = '';
  @Input() fecha:string = '';
  @Input() cedula:string = '';
  @Input() nombre:string = '';
  @Input() carpeta:string = '';

  constructor() { }

  ngOnInit(): void {
    let diaActual = moment(new Date).subtract(10,'days').calendar();
    let fechaDinamica = moment(this.fecha).subtract(10,'days').calendar();

    if(diaActual == fechaDinamica){
      this.fecha = moment(this.fecha).format('h:mm:ss');
    }else{
      this.fecha = fechaDinamica;
    }
    // this.fecha = moment(this.fecha).subtract(15,'minutes').toString();
    this.mensaje = this.mensaje.split(':')[1];

  }

}
