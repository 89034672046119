import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Oferta } from 'src/app/models/oferta';
import { Condicion } from 'src/app/models/condicion';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenOfertaService {

  private endpoint = environment.ApiConecta + 'oferta/';

  constructor(private http: HttpClient) {}

  solicitudTasa(numeroCarpeta: string): Observable<any> {
    return this.http.post(this.endpoint + 'tasa', { numeroCarpeta });
  }

  genOferta(numeroCarpeta: string): Observable<any> {
    return this.http.post(this.endpoint + 'generacion', { numeroCarpeta });
  }

  getOfertaSeguro(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'seguro', { numeroSolicitud });
  }

  aceptarOferta(oferta: Oferta): Observable<any> {
    return this.http.post(this.endpoint + 'aceptacion', oferta);
  }

  cambioCondiciones(condicion: Condicion): Observable<any> {
    return this.http.post<Condicion>(
      this.endpoint + 'cambio-condiciones',
      condicion
    );
  }

  rechazarOferta(
    numeroCarpeta: string,
    motivoRechazo: string,
    observacion: string
  ): Observable<any> {
    return this.http.post(
      environment.ApiConecta + 'perfilamiento/rechazo-solicitud',
      { numeroCarpeta, motivoRechazo, observacion }
    );
  }

  crearPoliza(numeroCarpeta: string, tipoPoliza: number): Observable<any> {
    return this.http.post(environment.ApiConecta + '/polizas/pre-creacion', {
      numeroCarpeta,
      tipoPoliza,
    });
  }

  eliminarPoliza(numeroCarpeta: string): Observable<any> {
    return this.http.post(environment.ApiConecta + 'polizas/eliminacion', {
      numeroCarpeta,
    });
  }

  checkPoliza(numeroCarpeta: string, tipoPoliza: number): Observable<any> {
    return this.http.post(environment.ApiConecta + '/polizas/check-poliza', {
      numeroCarpeta,
      tipoPoliza,
    });
  }

  saveEvidente(carpeta: string, cedula: string, nomDoc: string){
    return this.http.post(environment.ApiConecta + 'expirian/save', {
      carpeta,
      cedula,
      nomDoc
    });
  }
}
