import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchivoCargaService{
  private endpoint:string = environment.ApiConecta;

  constructor(
    private http:HttpClient
  ) { }

  cargandoArchivo(numeroCarpeta,identificacion,nombreArchivo):Observable<any>{
    return this.http.post(this.endpoint+'document/bucket/key',{ 
      numeroCarpeta,
      identificacion,
      nombreArchivo 
    });
  }

  isUpload(
      numeroCarpeta,
      rutaAws,
      secuenciaDocumento
    ):Observable<any>{
    return this.http.post(this.endpoint + 'document/upload',{
      numeroCarpeta,
      rutaAws,
      secuenciaDocumento
    })
  }





}