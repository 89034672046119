<!--app-header></app-header-->


<mat-horizontal-stepper>
  <mat-step label="Análisis de solicitud del crédito" state="phone">
    <h1>Cargue de cédula de ciudadanía</h1>
    <p>Para continuar con el proceso, debe subir las cédulas de ciudadanía de tu cliente al 150% en los siguientes formatos: PDF, PNG, JPG, así de fácil.</p>
    <div class="row">
      <div class="col-md-3"><h2>Nombre:</h2></div>
      <div class="col"><h2>Andrés Ramírez</h2></div>
    </div>
    <div class="row">
      <div class="col-md-3"><h2>Monto:</h2></div>
      <div class="col"><h2>$ 100.000</h2></div>
    </div>
    <div class="row">
      <div class="col-md-3"><h2>Número de carpeta:</h2></div>
      <div class="col"><h2>345.0340.231</h2></div>
    </div>
    <div>
      <div class="row mt-3">
      <div class="col-lg-5 col-md-6 col-sm-12">
        <mat-card class="example-card">
          <h2>Cédula cara frontal</h2>
          <div class="no-upload" #ref1 id="upload-1">
            <p>
              Puedes subir PDF, PNG, JPG o utilizar tu teléfono <br class="no-responsive"> móvil para tomar
              una fotografía
            </p>
            <div class="botones">
              <div class="div-file">
                <label for="bayport-file"> <span class="material-icons">
                  attach_file
                  </span>
                  Cargar desde una carpeta</label>
                <input (change)="selectFile($event,'anverso')" accept="image/png, image/jpeg, .pdf" type="file" id="bayport-file"
                  value="Cargar desde una carpeta">
              </div>
              <button class="btn-cam" mat-button (click)="openDialog()">
                <span class="material-icons">
                  camera_alt
                  </span>
                  Cargar usando la cámara</button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="col-lg-5 col-md-6 col-sm-12">
        <mat-card class="example-card">
          <h2>Cédula cara posterior</h2>
          <div class="no-upload" #ref1 id="upload-1">
            <p>
              Puedes subir PDF, PNG, JPG o utilizar tu teléfono <br class="no-responsive"> móvil para tomar
              una fotografía
            </p>
            <div class="botones">
              <div class="div-file">
                <label for="bayport-file">
                  <span class="material-icons">
                    attach_file
                    </span>
                    Cargar desde una carpeta</label>
                <input (change)="selectFile($event,'reverso')" accept="image/png, image/jpeg, .pdf" type="file" id="bayport-file"
                  value="Cargar desde una carpeta">
              </div>
              <button class="btn-cam" mat-button (click)="openDialogProgres()" >
                <span class="material-icons">
                camera_alt
                </span>
                Cargar usando la cámara
                </button>
            </div>
          </div>

          <!-- <div class="ok-upload animate__animated animate__fadeIn" >
            <div class="tarjeta">
              <img src="../../../assets/img/tarjeta.svg" alt="tarjeta">
            </div>
            <span>Archivo cargado exitosamente.</span>
            <a class="cursor">Eliminar archivo y cargar otro</a>
          </div> -->
        </mat-card>
      </div>

    </div>

    <div class="row">
      <div class="col t-end">
        <button  mat-flat-button class="btn-dash" matStepperNext>Guardar y continuar</button>
      </div>
    </div>
    </div>
  </mat-step>
  <mat-step label="Pre-oferta de crédito" state="chat">
    <p>Socialize with each other.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step label="Validación de información">
    <p>You're welcome.</p>
  </mat-step>
  <mat-step label="Desembolso del crédito">
    <p>You're welcome.</p>
  </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="phone">
    <mat-icon>call_end</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="chat">
    <mat-icon>forum</mat-icon>
  </ng-template>
</mat-horizontal-stepper>





