<nav class="nav" style="margin-left: 2%;
  padding-top: 100px;">
  <a class="nav-link " routerLink="/busqueda-carpetas">
    <h6>Búsqueda de carpetas</h6>
  </a>
  <img src="../../../assets/icons/next.svg" class="page-link" >
  <a class="nav-link" (click)="volverAtras()" style="cursor: pointer;">
    <h6>Resultado de la búsqueda</h6>
  </a>
  <img src="../../../assets/icons/next.svg" class="page-link">
  <a class="nav-link active" aria-current="page" >
    <h6>Resumen del crédito</h6>
  </a>
</nav>
<div class="retroceder" (click)="volverAtras()"><i class="fa-solid fa-arrow-left"></i> Volver</div>
<app-container-resumen header="Resumen del crédito">
  <div class="row" style="text-align: center;padding-top: 0%;">
    <div class="col-md-3 border-info border-right-1">
      <h6>Nombre:</h6>
      <h5 style="display: inline-block">
        {{ respuesta['datos'].nombreCliente}}
      </h5>
    </div>
    <div class="col-md-3 border-info border-right-1">
      <h6>Número de carpeta</h6>
      <h5 style="display: inline-block">
        {{ respuesta['datos'].numeroCarpeta}}
      </h5>
    </div>
    <div class="col-md-3 border-info border-right-1">
      <h6>Monto</h6>
      <h5 style="display: inline-block">
        {{ respuesta['datos'].montoAprobado | currency}}
      </h5>
    </div>
    <div class="col-md-3" [style]="{
        background: respuesta['datos'].semaforo['TBC_COLOR'],
        width: '25%',
        'border-radius': '0 4% 0 0'
      }">
      <div [innerHtml]="respuesta['datos'].semaforo.ICON | safeHtml" class="icono"></div>
      <h6>Estado actual de la carpeta</h6>
      <h5>
        {{ respuesta['datos'].estado }}
      </h5>
    </div>
  </div>
  <br>
  <app-tabs-detalle [tabs]="myTabs" [defaultTabIndex]="0"></app-tabs-detalle>
  <ng-template #generalTemplate>
    <table class="table table-striped" style="margin-bottom: 0rem !important; border-radius: 4px !important;">
      <tbody>
        <tr>
          <td>
            <strong>N.M.V:tasa de interés corriente nóminal mensual
              vencido</strong><br />
            Nominal mensual vencido
          </td>
          <td>
            {{ respuesta['datos'].tasaAprobadanominal }}%
          </td>
          <td><strong>Comisión por millón</strong></td>
          <td>
            {{ respuesta['datos'].valorMillon | currency }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>E.A: Tasa de interés corriente efectiva anual</strong><br />
            Efectivo anual
          </td>
          <td>
            {{ respuesta['datos'].tasaAprobadaefectiva }}%
          </td>
          <td><strong>Monto</strong></td>
          <td>
            {{ respuesta['datos'].montoAprobadoAnalista | currency }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>N.M.V: tasa de interés máxima legal permitida</strong><br />
            Nominal mensual vencido
          </td>
          <td>
            {{ respuesta['datos'].tasaUsuraMv }}%
          </td>
          <td><strong>Plazo</strong></td>
          <td>
            {{ respuesta['datos'].plazoAprobadoAnalista }} Meses
          </td>
        </tr>
        <tr>
          <td><strong>Seguro de vida deudores</strong></td>
          <td>
            {{ respuesta['datos'].seguroVida }}%
          </td>
          <td><strong>Cuota</strong></td>
          <td>
            {{ respuesta['datos'].cuotaAprobadaAnalista | currency }}
          </td>
        </tr>
        <tr>
          <td><strong>Plan Poliza</strong></td>
          <td>
            {{
            respuesta['datos'].planSeguroVoluntario | seguroVoluntario
            }}
          </td>
          <td>
            <strong>Estudio de crédito y administración</strong>
          </td>
          <td>
            {{ respuesta['datos'].estudioCredito }}%
          </td>
        </tr>
        <tr>
          <td><strong>Valor total asegurado</strong></td>
          <td>
            {{ respuesta['datos'].polizaValorPlan | currency }}
          </td>
          <td><strong>Pagaduría</strong></td>
          <td>
            {{ respuesta['datos'].pagaduria }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
  <ng-template #motorTemplate>
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let dato of dataSource; let i = index">
          <td>
            <strong>{{ dato.NOMBREPOLITICA }}</strong>
          </td>
          <td style="border-left: none !important">
            {{ dato.RESTRICCION }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
  <ng-template #carteraTemplate>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item" *ngFor="let file of listFiles; let i = index">
        <h2 class="accordion-header">
          <button class="accordion-button" [class.collapsed]="activeIndex !== i" (click)="toggle(i)">
            {{ file.fileName }}
          </button>
        </h2>
        <div [class.show]="activeIndex === i" class="accordion-collapse collapse" [class.show]="activeIndex === i">
          <div class="accordion-body">
            <section style="width: 100%; background-color: #dbdbdb">
              <section style="
                display: flex;
                align-items: center;
                justify-content: end;
                width: 100%;
                padding: 1rem;
                gap: 2rem;
                box-shadow: 0px 3px 15px #00000029;
                height: 61px;
              ">
                <div style="display: flex; gap: 0.5rem">
                  <img src="./../../../assets/icons/siguiente-pagina.svg" alt="" style="transform: rotate(180deg)"
                    (click)="anteriorPagina()" />
                  <img src="./../../../assets/icons/siguiente-pagina.svg" alt="" (click)="siguientePagina()" />
                  <p style="
                    padding: 0 !important;
                    margin: 0 !important;
                    font-size: 14px !important;
                  ">
                    Page {{ page }} /
                    {{ pdfViewer?.pdfViewer?.pagesCount }}
                  </p>
                </div>
                <div style="display: flex; gap: 0.5rem">
                  <img src="./../../../assets/icons/mas-zoom.svg" alt="" (click)="masZoom()" />
                  <img src="./../../../assets/icons/menos-zoom.svg" alt="" (click)="menosZoom()" />
                  <p style="
                    padding: 0 !important;
                    margin: 0 !important;
                    font-size: 14px !important;
                  ">
                    Zoom 50%
                  </p>
                </div>
                <div>
                  <img src="./../../../assets/icons/descargar.svg" alt="" (click)="
                    downloadPDF(
                      'data:application/pdf;base64,' + file.fileBase64,
                      file.fileName
                    )
                  " />
                </div>
              </section>
              <div style="padding: 3rem">
                <pdf-viewer [src]="'data:application/pdf;base64,' + file.fileBase64" [render-text]="true"
                  [original-size]="false" [show-all]="false" [page]="page" [zoom]="zoom"
                  style="width: 100%; height: 500px"></pdf-viewer>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="d-flex justify-content-evenly align-items-center mt-5" >
    <div>
      ¿El solicitante no recibió el mensaje o correo para la firma de
      documentos?
      <a (click)="resetMessage()" style="font-weight: 900; cursor: pointer; color: #009bdc !important">
        Haz clic aquí
      </a>
    </div>
    <button-look-feel color="secondary" (click)="goStep(respuesta)">Retomar
      carpeta</button-look-feel>
  </div><br>
</app-container-resumen>