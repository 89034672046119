<!--app-header></app-header-->
<mat-horizontal-stepper>
  <mat-step label="Análisis de solicitud del crédito" state="phone"> </mat-step>
  <mat-step label="Pre-oferta de crédito" state="chat"> </mat-step>
  <mat-step label="Validación de información"> </mat-step>
  <mat-step label="Desembolso del crédito"> </mat-step>

  <!-- Icon overrides. -->
  <ng-template matStepperIcon="phone">
    <mat-icon>call_end</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="chat">
    <mat-icon>forum</mat-icon>
  </ng-template>
</mat-horizontal-stepper>

<div id="encabezado">
  <h1 class="font-omnes" style="font-weight: bold; margin-top: 34px; font-size: 20px">
    Formulario de perfilamiento
  </h1>
  <p style="font-size: 20px; line-height: 120%">
    Completa la información de tu cliente en cada uno de los campos solicitados
    a continuación.
  </p>
</div>

<div id="accordion">
  <div class="card formulario">
    <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseOne"
      id="headingOne">
      <h2 class="mb-0 acordion-title">Información Personal</h2>
      <div class="collapsable-button">
        <mat-icon *ngIf="!formularios[0].open" aria-hidden="false" style="font-size: 30px">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="formularios[0].open" aria-hidden="false" style="font-size: 30px">arrow_drop_up</mat-icon>
      </div>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
      <div class="card-body">
        <form [formGroup]="personalInfo">
          <ng-template matStepLabel>Información Personal</ng-template>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Primer nombre</mat-label>
              <mat-form-field appearance="outline">
                <input matInput required formControlName="primerNombre" style="text-transform: uppercase" />
                <mat-error class="error" *ngIf="
                    personalInfo.controls.primerNombre.hasError('required')
                  ">
                  Primer nombre es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Segundo nombre
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="segundoNombre" style="text-transform: uppercase" />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Primer apellido</mat-label>
              <mat-form-field appearance="outline">
                <input matInput required formControlName="primerApellido" style="text-transform: uppercase" />
                <mat-error class="error" *ngIf="
                    personalInfo.controls.primerApellido.hasError('required')
                  ">
                  Primer apellido es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Segundo apellido
                <span style="font-weight: normal">(opcional)</span>
              </mat-label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="segundoApellido" style="text-transform: uppercase" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <mat-label>Fecha de nacimiento</mat-label>
              <mat-form-field style="margin-right: 30px" appearance="outline">
                <input matInput [matDatepicker]="picker" (dateChange)="validateFechNac()" #fechaNacimiento
                  formControlName="fechaNacimiento" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <div style="margin-bottom: 5px">
                <mat-label style="margin-bottom: 10px">Género</mat-label>
              </div>
              <div class="radio-group">
                <div *ngFor="let id of generos">
                  <input type="radio" [id]="'codigo_' + id.codigo" name="geneross" [value]="id.codigo"
                    formControlName="genero" />
                  <label [htmlFor]="'codigo_' + id.codigo">{{
                    id.nombre
                    }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div style="margin-right: 30px; margin-bottom: 20px">
              <div>
                <mat-label style="margin-bottom: 5px">Tipo de documento</mat-label>
              </div>
              <div class="radio-group">
                <div *ngFor="let id of tiposId">
                  <input type="radio" [id]="'tipo_' + id.id" name="options" [value]="id.codigoTipoIdentificacion"
                    formControlName="tipoIdentificacion" />
                  <label [htmlFor]="'tipo_' + id.id">{{ id.nombre }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Número de documento</mat-label>
              <mat-form-field appearance="outline">
                <input matInput required formControlName="numeroIdentificacion" type="number" />
                <mat-error class="error" *ngIf="
                    personalInfo.controls.numeroIdentificacion.hasError(
                      'required'
                    )
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Correo electrónico</mat-label>
              <mat-form-field appearance="outline">
                <input matInput formControlName="correo" />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Teléfono celular</mat-label>
              <mat-form-field appearance="outline">
                <input matInput required formControlName="celular" />
                <mat-error class="error" *ngIf="personalInfo.controls.celular.hasError('pattern')">
                  Ingrese un formato valido
                </mat-error>
                <mat-error class="error" *ngIf="personalInfo.controls.celular.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Número de carpeta</mat-label>
              <mat-form-field appearance="outline">
                <input type="number" matInput required formControlName="numeroCarpeta" />
                <mat-error class="error" *ngIf="
                    personalInfo.controls.numeroCarpeta.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="justify-right">
            <p class="condicion">
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
              aceptando que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button class="continue" data-toggle="collapse" data-target="#collapselaboral"
              (click)="guardarInformacionPersonal()" [disabled]="
                isLoaded ||
                personalInfo.invalid ||
                personalInfo.get('genero').value === 0
              " mat-button>
              <mat-icon *ngIf="isLoaded" style="margin-right: 5px">
                <mat-spinner diameter="25"></mat-spinner>
              </mat-icon>
              Guardar y Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
    <p *ngIf="!formularios[0].open" class="descripcion">
      Ingresa la información referente a nombres, apellidos, documento de
      identidad, datos de contacto, entre otros.
    </p>
  </div>

  <div class="card formulario">
    <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapselaboral"
      id="headinglaboral">
      <h2 class="mb-0 acordion-title">Información Laboral</h2>
      <div class="collapsable-button">
        <mat-icon *ngIf="!formularios[1].open" aria-hidden="false" style="font-size: 30px">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="formularios[1].open" aria-hidden="false" style="font-size: 30px">arrow_drop_up</mat-icon>
      </div>
    </div>

    <div id="collapselaboral" class="collapse" aria-labelledby="headinglaboral" data-bs-parent="#accordion">
      <div class="card-body">
        <form [formGroup]="laboralInfo">
          <ng-template matStepLabel>Información Laboral</ng-template>

          <div class="row">
            <div class="col-md-3">
              <mat-label>Convenio</mat-label><br>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="codigoConvenio" required>
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="convenios"
                    (filteredReturn)="filteredConvenio = $event"></mat-select-filter>
                  <mat-option (click)="getPagadurias(item.codigo)" *ngFor="let item of filteredConvenio"
                    [value]="item.codigo">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoConvenio.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-label>Pagaduría</mat-label><br>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="codigoPagaduria" required>
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="pagaduarias"
                    (filteredReturn)="filteredPagaduria = $event"></mat-select-filter>
                  <mat-option (click)="
                      getTiposContrato(item.codigoPagaduria);
                      getCargos(item.codigoPagaduria);
                      getEdoLab(item.codigoPagaduria);
                      busquedaCreditosPorPagaduria(
                        item.codigoPagaduria,
                        this.data.cedula
                      )
                    " *ngFor="let item of filteredPagaduria" [value]="item.codigoPagaduria">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoPagaduria.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Estado laboral</mat-label>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="codigoEstadoLaboral" required>
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'descripcion'" [array]="edoLaboral"
                    (filteredReturn)="filteredEdoLab = $event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredEdoLab" (click)="selectEstadoLaboral(item.codigo)"
                    [value]="item.codigo">
                    {{ item.descripcion }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoEstadoLaboral.hasError(
                      'required'
                    )
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-label>Tipo de contrato</mat-label>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="codigoTipoContrato" required
                  [(value)]="tipoContratoModel">
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="tiposContratos"
                    (filteredReturn)="filteredTiposContrato = $event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredTiposContrato" [value]="item.codigoContrato">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoTipoContrato.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Rango / Cargo</mat-label>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="codigoCargo" required>
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="cargos"
                    (filteredReturn)="filteredCargos = $event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredCargos" [value]="item.codigo">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="laboralInfo.controls.codigoCargo.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-label>Fecha de ingreso Pagaduría </mat-label>
              <mat-form-field appearance="outline">
                <input type="date" matInput required formControlName="fechaIngreso" />
                <mat-error class="error" *ngIf="laboralInfo.controls.fechaIngreso.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="justify-right">
            <p class="condicion">
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
              aceptando que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button [disabled]="isLoaded || laboralInfo.invalid" class="continue" data-toggle="collapse"
              data-target="#collapseFinanciera" (click)="guardarInformacionLaboral()" mat-button>
              <mat-icon *ngIf="isLoaded" style="margin-right: 5px">
                <mat-spinner diameter="25"></mat-spinner>
              </mat-icon>
              Guardar y Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
    <p class="descripcion" *ngIf="!formularios[1].open">
      Ingresa la información referente al empleador, tipo de contrato, cargo,
      entre otros.
    </p>
  </div>

  <div class="card formulario">
    <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseFinanciera"
      id="headingFinanciera">
      <h2 class="mb-0 acordion-title">Información Financiera</h2>
      <div class="collapsable-button">
        <mat-icon *ngIf="!formularios[2].open" aria-hidden="false" style="font-size: 30px">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="formularios[2].open" aria-hidden="false" style="font-size: 30px">arrow_drop_up</mat-icon>
      </div>
    </div>

    <div id="collapseFinanciera" class="collapse" aria-labelledby="headingFinanciera" data-bs-parent="#accordion">
      <div class="card-body">
        <div class="infofinaciera">
          <span>Ingresos</span>
        </div>
        <form [formGroup]="financieraInfo">
          <ng-template matStepLabel>Información Financiera</ng-template>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Salario</mat-label><br>
              <mat-form-field appearance="outline">
                <input type="tel" value="0" matInput required name="salario" formControlName="salario"
                  autocomplete="off" currencyMask onkeypress="return isNumberKey(event)" [ngModel]="salario" />
                <!-- Add ng-model -->
                <!-- <p>{{ salario | miles}}</p> -->

                <mat-error class="error" *ngIf="financieraInfo.controls.salario.hasError('min')">
                  Ingresar un valor igual o superior al salario mínimo.
                </mat-error>
                <mat-error class="error" *ngIf="financieraInfo.controls.salario.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Otros ingresos
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="otrosIngresos" name="otrosIngresos"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
              </mat-form-field>
            </div>
          </div>
          <p style="font-size: 20px; margin-top: 0px; margin-bottom: 44px">
            <strong>Nota:</strong> Incluye los ingresos que Bayport tiene en
            cuenta para el cálculo de capacidad.
          </p>
          <div style="margin-bottom: 15px" class="infofinaciera">
            <span>Egresos</span>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Salud</mat-label><br>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" required formControlName="salud" name="salud" value="1" min="1"
                  autocomplete="off" currencyMask onkeypress="return isNumberKey(event)" />
                <mat-error class="error" *ngIf="financieraInfo.controls.salario.hasError('min')">
                  Ingrese un numero mayor a 0
                </mat-error>
                <mat-error class="error" *ngIf="financieraInfo.controls.salud.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Pensión
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="pension" name="pension"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
                <mat-error class="error" *ngIf="financieraInfo.controls.pension.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Retefuente
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="retefuente" name="retefuente"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Fondo solidaridad
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="fondoSolidaridad" name="fondoSolidaridad"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Caprovimpo
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="caproimpo" name="caproimpo"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-label>Otros descuentos
                <span style="font-weight: normal">(opcional)</span></mat-label>
              <mat-form-field appearance="outline">
                <input type="tel" matInput value="0" formControlName="otrosDescuentos" name="otrosDescuentos"
                  onkeypress="return isNumberKey(event)" autocomplete="off" currencyMask />
              </mat-form-field>
            </div>
          </div>
          <p style="font-size: 20px; margin-bottom: 44px">
            <strong>Caprovimpo:</strong> Sólo registrar si es obligatorio, en
            caso que sea voluntario no aplica.
          </p>
          <div class="justify-right">
            <p class="condicion">
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
              aceptando que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button class="continue" data-toggle="collapse" data-target="#collapseSolicitud"
              (click)="guardarInformacionFinanciera()"
              [disabled]="!codigoSolicitud || isLoaded || !financieraInfo.valid" mat-button>
              <mat-icon *ngIf="isLoaded" style="margin-right: 5px">
                <mat-spinner diameter="25"></mat-spinner>
              </mat-icon>
              Guardar y Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
    <p class="descripcion" *ngIf="!formularios[2].open">
      Ingresa la información relacionada con los ingresos y egresos de tu
      cliente.
    </p>
  </div>

  <div class="card formulario">
    <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseSolicitud"
      id="headingSolicitud">
      <h2 class="mb-0 acordion-title">Información Solicitud</h2>
      <div class="collapsable-button">
        <mat-icon *ngIf="!formularios[3].open" aria-hidden="false" style="font-size: 30px">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="formularios[3].open" aria-hidden="false" style="font-size: 30px">arrow_drop_up</mat-icon>
      </div>
    </div>

    <div id="collapseSolicitud" class="collapse" aria-labelledby="headingSolicitud" data-bs-parent="#accordion">
      <div class="card-body">
        <form [formGroup]="solicitudInfo">
          <ng-template matStepLabel>Información solicitud</ng-template>
          <div class="row">
            <div class="col-md-3">
              <mat-label>Tipo de crédito</mat-label>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="tipoCredito" required
                  [(value)]="tipoCreditoModel">
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="tipos_credito"
                    (filteredReturn)="filteredTiposCredito = $event"></mat-select-filter>
                  <mat-option *ngFor="let item of filteredTiposCredito" (click)="selecTipoCredito(item.nombre)"
                    [value]="item.codigoTipoCredito">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    solicitudInfo.controls.tipoCredito.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-label>Línea de crédito</mat-label>
              <mat-form-field appearance="outline">
                <mat-select placeholder="Selecciona ..." formControlName="lineaCredito" required
                  [(value)]="lineaCredito">
                  <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="lineascredito"
                    (filteredReturn)="filteredLineaCredito = $event"></mat-select-filter>
                  <mat-option (click)="cambiarLineaCredito(item.nombre)" *ngFor="let item of filteredLineaCredito"
                    [value]="item.codigo">
                    {{ item.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error class="error" *ngIf="
                    solicitudInfo.controls.lineaCredito.hasError('required')
                  ">
                  Este campo es obligatorio
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-label>Plazo</mat-label>
              <div (click)="alertaPlazo()">
                <mat-form-field appearance="outline">
                  <input type="number" min="1" matInput formControlName="plazo" required [value]="plazoModel" />
                  <mat-error class="error" *ngIf="solicitudInfo.controls.plazo.hasError('required')">
                    Este campo es obligatorio
                  </mat-error>
                  <mat-error class="error" *ngIf="solicitudInfo.controls.plazo.hasError('min')">
                    Este campo debe ser mayor a cero
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <mat-label>Monto</mat-label><br>
              <mat-form-field appearance="outline">
                <input type="tel" matInput required formControlName="monto" name="monto" autocomplete="off" currencyMask
                  value="0" />
                <mat-error class="error" *ngIf="solicitudInfo.controls.monto.hasError('required')">
                  Este campo es obligatorio
                </mat-error>
                <mat-error class="error" *ngIf="solicitudInfo.controls.monto.hasError('min')">
                  Este campo debe ser mayor a cero
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <!--Aqui acaba el formulario-->
        <div>
          <p>
            Nota: Los montos y valores diligenciados serán tomados en pesos
            colombianos (COP)
          </p>
          <p *ngIf="compraCartera">
            Recuerda que ante la compañía podrás gestionar mínimo una (1) compra
            de cartera y máximo seis (6)
          </p>
        </div>

        <div *ngIf="isRefinanciacion && !isNewCredito">
          <div class="row separador" style="margin-top: 10px">
            <div class="row libre__inversion" *ngFor="let libre of formLibreInversion; let i = index">
              <div class="delete" *ngIf="i > 0">
                <a (click)="eliminarRefinanciacion(formLibreInversion, i)" style="cursor: pointer">Eliminar</a>
                <mat-icon>delete_forever</mat-icon>
              </div>
              <mat-label>Refinanciación {{ i + 1 }}</mat-label>
              <div class="campos">
                <div class="inp-wrap">
                  <mat-label>Número de crédito</mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Selecciona ..." [(ngModel)]="libres[i].libreNumeroCredito.value"
                      [required]="isRefinanciacion && !isNewCredito">
                      <mat-option *ngFor="let credito of creditosRefinanciacion" [value]="credito.numeroCredito"
                        (click)="
                          seleccionarNumeroCredito(
                            credito.numeroCredito,
                            credito,
                            i
                          )
                        ">
                        {{ credito.numeroCredito }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="inp-wrap">
                  <mat-label>Pagaduría</mat-label>
                  <mat-form-field appearance="outline">
                    <input type="text" matInput [(ngModel)]="libres[i].librePagaduria.value"
                      value="libres.librePagaduria.value" name="librePagaduria" disabled />
                  </mat-form-field>
                </div>
                <div class="inp-wrap">
                  <mat-label>Valor cuota</mat-label>
                  <mat-form-field appearance="outline">
                    <input type="text" matInput [ngModel]="libres[i].libreValorCuota.value"
                      value="libres.libreValorCuota.value" currencyMask disabled />
                  </mat-form-field>
                </div>
                <div class="inp-wrap">
                  <mat-label>Saldo</mat-label>
                  <mat-form-field appearance="outline">
                    <input type="text" matInput [ngModel]="libres[i].libreSaldo.value"
                      value="libres.libreValorCuota.value" currencyMask disabled />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <button role="button" style="border-radius: 100px; width: 25%" (click)="addRefinanciacion()" [ngClass]="
                  contFormLibreInversion == 6 || !variosCreditos
                    ? 'ocultar'
                    : ''
                ">
                Agregar refinanciacion adicional
              </button>
            </div>
          </div>
        </div>

        <!-- <div class="row separador" style="margin-top: 10px" *ngIf="lineacredito == 'COMPRA CARTERA'"> -->
        <div class="row separador" style="margin-top: 10px" *ngIf="compraCartera == true">
          <div *ngFor="let cartera of datosCompraCartera; let i = index" class="row libre__inversion">
            <mat-label style="font-size: 24px">
              Compra de Cartera {{ i + 1 }}</mat-label>

            <div class="row">
              <div class="delete inp-wrap col-1 col-sm-1 col-md-1 col-lg-1 mb-err-inp" style="margin-top: 10px"
                *ngIf="i != 0">
                <a (click)="quitarCartera(i)" style="cursor: pointer">Eliminar</a>
                <mat-icon>delete_forever</mat-icon>
              </div>
              <div class="col-md-3">
                <mat-label>Entidad financiera (max. 6)</mat-label>
                <mat-form-field style="margin-bottom: 8px" appearance="outline" [class]="
                    cartera.entidadCompra.valid ? '' : 'mat-form-field-invalid'
                  ">
                  <mat-select placeholder="Selecciona ..." minlength="1" [(ngModel)]="cartera.entidadCompra.value"
                    required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="entidadesFinanc[i]"
                      (filteredReturn)="filteredEntidadesFinanc[i] = $event"></mat-select-filter>
                    <mat-option (click)="validarCarterasExistentes(item.codigo)"
                      *ngFor="let item of filteredEntidadesFinanc[i]" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="error" *ngIf="!cartera.entidadCompra.valid">Este campo es obligatorio
                </span>
              </div>

              <div class="col-md-3">
                <mat-label>Valor cuota</mat-label>
                <mat-form-field style="margin-bottom: 8px" appearance="outline" [class]="
                    cartera.valorCuota.valid ? '' : 'mat-form-field-invalid'
                  ">
                  <!-- <span style="font-size: 18px" matPrefix>$ &nbsp;</span> -->
                  <input (keyup)="validMontos(i, 'valorCuota')" [(ngModel)]="cartera.valorCuota.value" min="1"
                    type="tel" matInput autocomplete="off" currencyMask value="0" />
                  <mat-error class="error" *ngIf="cartera.valorCuota.value === ''">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
                <span class="error" *ngIf="!cartera.valorCuota.valid">Este campo es obligatorio
                </span>
              </div>

              <div class="col-md-3">
                <mat-label>Monto compra cartera</mat-label>
                <mat-form-field style="margin-bottom: 8px" appearance="outline"
                  [class]="cartera.monto.valid ? '' : 'mat-form-field-invalid'">
                  <!-- <span style="font-size: 18px" matPrefix>$ &nbsp;</span> -->
                  <input (keyup)="validMontos(i, 'monto')" [(ngModel)]="cartera.monto.value" min="1" type="tel" matInput
                    autocomplete="off" currencyMask value="0" />
                </mat-form-field>
                <span class="error" *ngIf="!cartera.monto.valid">Este campo es obligatorio
                </span>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <mat-label>¿Opera en desprendible?</mat-label>
                </div>
                <div style="height: 50px; display: flex; align-items: center">
                  <mat-slide-toggle [(ngModel)]="cartera.desprendible" size="large"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="lineacredito == 'COMPRA CARTERA'" class="row">
            <p style="font-size: 20px; margin-top: 25px; margin-bottom: 44px">
              <strong>Nota:</strong> recuerda que por política podrás realizar
              mínimo 1 compra de cartera y maximo 6.
            </p>
          </div>
        </div>

        <div *ngIf="lineacredito == 'COMPRA CARTERA'" class="row">
          <!-- <div style="align-items: center; display: flex; width: 235px"> -->
          <div>
            <button (click)="guardarCompra()" id="addentidad" role="button"
              style="border-radius: 100px; font-size: 14px">
              Agregar entidad adicional
            </button>
          </div>
        </div>
        <div class="justify-right" *ngIf="
            !formularios[0].saved ||
            !formularios[1].saved ||
            !formularios[2].saved
          ">
          <p class="condicion">
            No olvide <b>Guardar</b> los cambios de:
            <span *ngFor="let item of validateForms()">
              <b>{{ item }}</b>,
            </span>
            para poder continuar con el perfilamiento.
          </p>
        </div>
        <div *ngElse class="justify-right">
          <p class="condicion">
            Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás aceptando
            que la información es correcta
          </p>
        </div>
        <div class="justify-right">
          <button class="continue" (click)="guardarInformacionSolicitud()" mat-button [disabled]="
              isLoaded ||
              solicitudInfo.invalid ||
              (isRefinanciacion &&
                !isNewCredito &&
                refinancialArr.length == 0) ||
              !formularios[0].saved ||
              !formularios[1].saved ||
              !formularios[2].saved
            ">
            <mat-icon *ngIf="isLoaded" style="margin-right: 5px"><mat-spinner diameter="25"> </mat-spinner></mat-icon>
            Guardar y Continuar
          </button>
        </div>
      </div>
    </div>
    <p class="descripcion" *ngIf="!formularios[3].open">
      Ingresa la información relacionada con el tipo de producto, monto, plazo,
      entre otros.
    </p>

    <div *ngIf="validarMsgFormulario()" style="
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        margin-top: 20px;
      ">
      <p class="descripcion" style="color: red; width: 959px; text-align: right; line-height: 1.3">
        Aún tienes campos sin completar. Puedes guardar y volver luego, pero
        recuerda que necesitas tener la información completa para radicar el
        crédito.
      </p>
    </div>
  </div>
</div>