import { Injectable } from '@angular/core';

import { Condicion } from '../models/condicion';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  public numeroCarpeta: string;
  public numero: string;
  public apellido: string;
  public apellidot: string;
  public cedula: number;
  public cedulat: number;
  public numeroSolicitud: string;
  public cellphone: string;
  public regValidacion: string;
  public celulares: string;
  public idTransaccionOTP: string;
  public f: string;
  public nombre: String;
  public nombres: string;
  public fechaExpedicion: string;
  public numeroIdentificacion: number;
  public infoSolicitud: any;
  public credito: string;
  public montoMaximo: string;
  public montoMinimo: string;
  public caminoOtp: string;
  public nui = '0';
  public requiere_firma = true;
  public canal = 'portal-agentes';
  public session = 'session';
  public contrato_url: string;
  public pagare_url: string;
  public condicion: Condicion;
  public seguro: any;
  public ofertas: any;
  public genero?: number;
  public base64: string;
  public codReconocer: string;
  public isRedable: boolean;
  public firmaDigital: number;
  public pagadurias: any[];
  public nombre_contrato: string;
  public nombre_pagare: string;
  public cedula_ado: string;
  public carpeta_ado: string;
  public monto_ado: number;
  public estado: string;
  public pagaduria_url: string;
  pagaduriaRef: string;
  public celularesReconocer: any[];
  private listadoCelulares: any[];
  private listadoCorreos: any[];
  public isNewPhone: boolean;
  public isNewEmail: boolean;
  public isResume: boolean;
  public isUpdateMobile: boolean;
  public valorMontoInicial = '';
  public isUpdateSeguro = false;
  // @Output () listadoCelulares : EventEmitter <any> = new EventEmitter()

  public setters() {
    this.numeroCarpeta = null;
    this.numero = null;
    this.apellido = null;
    this.apellidot = null;
    this.cedula = null;
    this.cedulat = null;
    this.numeroSolicitud = null;
    this.cellphone = null;
    this.regValidacion = null;
    this.celulares = null;
    this.idTransaccionOTP = null;
    this.f = null;
    this.nombre = null;
    this.nombres = null;
    this.fechaExpedicion = null;
    this.numeroIdentificacion = null;
    this.infoSolicitud = null;
    this.credito = null;
    this.montoMaximo = null;
    this.montoMinimo = null;
    this.caminoOtp = null;
    this.nui = '0';
    this.requiere_firma = null;
    this.canal = 'portal-agentes';
    this.session = 'session';
    this.contrato_url = null;
    this.pagare_url = null;
    this.condicion = null;
    this.seguro = null;
    this.ofertas = null;
    this.genero = null;
    this.base64 = null;
    this.codReconocer = null;
    this.isRedable = null;
    this.firmaDigital = null;
    this.pagadurias = null;
    this.nombre_contrato = null;
    this.nombre_pagare = null;
    this.cedula_ado = null;
    this.carpeta_ado = null;
    this.celularesReconocer = null;
    this.isNewPhone = false;
    this.isNewEmail = false;
    this.isResume = false;
    this.isUpdateMobile = false;
    this.valorMontoInicial = '';
    this.isUpdateSeguro = false;
    // this.pagaduriaRef='716';
  }

  public setListadoCelulares(celulares: any) {
    this.listadoCelulares = celulares;
  }
  public getListadoCelulares(): any[] {
    return this.listadoCelulares;
  }

  public setListadoCorreos(correos: any) {
    this.listadoCorreos = correos;
  }
  public getListadoCorreos(): any[] {
    return this.listadoCorreos;
  }

  public setResetValueInitial() {
    this.valorMontoInicial = '';
    this.isUpdateSeguro = false;
  }
}
