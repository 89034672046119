import { Component, ContentChildren, QueryList, AfterContentInit, TemplateRef, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements AfterContentInit {

  // trata de no modificar mucho este componente ya que es un step generico que se usa en muchas partes

  @ContentChildren(TemplateRef) stepContentsQuery!: QueryList<TemplateRef<any>>; // Detecta los elementos hijos proyectados
  stepContents: TemplateRef<any>[] = []; // Lista de plantillas proyectadas

  @Input() steps: string[] = []; // Títulos de los pasos
  @Input() sucessStep: boolean = false; // mostrar step de finalizacion con chulito
  @Input() completeStep: boolean = false; // estado de completo de la etapa
  @Output() changeStep = new EventEmitter<number>(); // utilizalo para notificar un cambio de step
  buttonBackVisibility = false;
  buttonNextVisibility = true;
  currentStep: number = 0; // Paso actual
  maxStepReached: number = 0; // Máximo paso alcanzado
  disabled: boolean = false; // deshabilita los clics de la barra del stepper

  ngAfterContentInit(): void {
    // Inicializa las plantillas y los títulos de los pasos
    this.stepContents = this.stepContentsQuery.toArray().filter(template => {
      const context = {}; // Define el contexto necesario aquí si aplica
      const view = template.createEmbeddedView(context);
      view.detectChanges();
      const hasContent = view.rootNodes.some(node => node.nodeType === 1 || node.nodeType === 3); // Revisa si hay nodos de elemento o texto
      view.destroy();
      return hasContent;
    });
  }

  get progress(): number {
    return (this.maxStepReached / (this.steps.length - 1)) * 100;
  }

  getStepPosition(index: number): number {
    return (index / (this.steps.length - 1)) * 100;
  }


  // Avanza al siguiente step
  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
      // Actualiza el progreso máximo si avanza a un paso nuevo
      if (this.currentStep > this.maxStepReached) {
        this.maxStepReached = this.currentStep;
      }
      this.buttonsVisibility(this.currentStep)
      this.changeStep.emit(this.currentStep);
    }
  }

  // Regresa al paso anterior (sin afectar el progreso)
  previousStep(): void {
    this.buttonBackVisibility = false;
    this.buttonNextVisibility = true;
    if (this.currentStep > 0) {
      if (this.maxStepReached === this.steps.length - 1 && this.sucessStep) {
        this.maxStepReached--
      }
      this.currentStep--;
      this.buttonsVisibility(this.currentStep)
      this.changeStep.emit(this.currentStep);
    }
  }

  // Ir a un paso específico (solo si está completado o es el actual)
  goToStep(stepIndex: number): void {
    // Evita clics en el último paso
    if (stepIndex === this.steps.length - 1 && this.sucessStep) {
      return;
    }
    // Permite navegar solo a pasos completados o activos
    if ((stepIndex <= this.maxStepReached && this.completeStep) || (stepIndex <= this.maxStepReached && !this.completeStep && stepIndex < this.currentStep)) {
      if (this.maxStepReached === this.steps.length - 1 && this.sucessStep) {
        this.maxStepReached--
      }
      this.currentStep = stepIndex;
      this.buttonsVisibility(this.currentStep)
      this.changeStep.emit(this.currentStep);
    }
  }

  buttonsVisibility(currentStep) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Para un desplazamiento suave
    });
    this.buttonBackVisibility = false
    this.buttonNextVisibility = false
    if (this.sucessStep) {
      if (currentStep < this.steps.length - 1) {
        this.buttonBackVisibility = true
        this.buttonNextVisibility = true
      }
      if (currentStep == this.steps.length - 1) {
        this.buttonNextVisibility = false
      }
    } else {
      if (currentStep < this.steps.length) {
        this.buttonBackVisibility = true
        this.buttonNextVisibility = true
      }
      if (currentStep == this.steps.length) {
        this.buttonNextVisibility = false
      }
    }
    if (currentStep == 0) {
      this.buttonBackVisibility = false
    }
  }

  disableStepperClicks(status: boolean) {
    this.disabled = status
  }
}
