<div class="h-100 d-flex align-items-start flex-column z-3">
  <div class="mb-auto w-100 z-3">
    <figure class="mt-4 mb-0 text-md-center">
      <img src="../../../assets/img/logo.svg" alt="logo bayport" />
    </figure>
    <button-look-feel class="d-flex d-md-none cerrarSesion" color="outline" size="small" routerLink="/mi-perfil" >Mi perfil</button-look-feel>
  </div>
  <app-menu class="me-auto z-3"></app-menu>
  <hr class="d-block d-md-none w-100 menu-divider" />
  <button-look-feel class="d-block d-md-none w-100 px-5 cerrarSesion" color="outline" [block]="true" (clicked)="cerrar()"><i aria-hidden="true" class="fa fa-sign-out me-1"></i> Cerrar sesión</button-look-feel>
  <app-footer class="w-100 px-2 d-none d-md-block"></app-footer>
</div>
<div class="circle-blue d-none d-md-block"></div>
