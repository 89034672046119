import { Component, OnInit, Input } from '@angular/core';
import jwt_decode from "jwt-decode";
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificaService } from 'src/app/services/notificaciones/notifica.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  @Input() isBusqueda: boolean;
  public totalNotificaciones:number = 0;
  user = '';


  constructor(
    public authService:AuthService,
    public _notificaciones:NotificaService
  ) { }

  ngOnInit(): void {
    
    this.nombreUsuario();
    
    this._notificaciones.getNotificaciones().subscribe(res =>{
      localStorage.setItem('notificacion',JSON.stringify(res.count) );
    })

    this.totalNotificaciones =parseInt( localStorage.getItem('notificacion') );
  }

  nombreUsuario(){
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'];
  }

}
