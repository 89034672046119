<div class="not-cont">
    <div class="header-not">
        <span class="title">{{ cedula}}-{{nombre}} - {{carpeta}}</span>
        <div class="detail">
            <span>{{ fecha }}</span>
            <i class="fa-solid fa-chevron-right"></i>
        </div>
    </div>
    <span class="texto-notificacion">
        {{ mensaje }}
    </span>
</div>
