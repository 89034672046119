import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-central',
  templateUrl: './auto-central.component.html',
  styleUrls: ['./auto-central.component.css']
})
export class AutoCentralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
