import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

  @Input() titulo!: string;
  @Input() mensaje!: string;
  @Input() success!: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  handleContinuar() {
    this.activeModal.close();
  }
}
