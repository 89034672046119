<div class="main-c">
    <div class="header-title">
      <h1 class="dialog-title" mat-dialog-title>Carga documentos</h1>
      <div class="close-dialog" mat-dialog-close><mat-icon class="close-icon">close</mat-icon> </div>
    </div>
  
    <div class="contenido">
        <label class="file-upload-ad">
            Seleccionar documentos
            <input type="file" accept=".png, .jpg, .pdf" (change)="fileChange($event)"/>
        </label>
    </div>
  
  </div>
  
