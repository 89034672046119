<div class="documentos">
  <div class="header">
    <div class="header-logo">
      <img src="../../../../assets/img/BayportLogo.svg" alt="logo">
      <div class="circle"></div>
    </div>
    <div class="banner">
      <div class="item">
        <span>Firma de</span>
        <br>
        <span>Documentos</span>
      </div>
    </div>
  </div>

  <div class="info-credito">
    <div class="fecha">
      <span style="color: #8c8c8c;">Julio 23 de 2020</span>
    </div>
    <div class="no-credito">
      <span style="color: #111e3a;">No. de crédito: <strong>123456789</strong></span>
    </div>
  </div>

  <main class="info-contenido">
    <h1>¡Hola!</h1>
    <h2>José Jacinto Góngora</h2>
    <p>En <strong>Bayport</strong> trabajamos por ofrecer el mejor servicio y brindar información oportuna, <strong>por lo que te enviamos adjuntas las condiciones del Crédito de Libranza adquirido con nosotros.</strong></p>

    <div class="servicios">
      <div class="servicio">
        <mat-icon>account_balance</mat-icon>
        <span>Monto aprobado</span>
        <span class="text-blue">$4.170.000</span>
      </div>
      <div class="servicio">
        <mat-icon>folder</mat-icon>
        <span>Línea de crédito</span>
        <span class="text-blue">Libre inversión</span>
      </div>
      <div class="servicio">
        <mat-icon>local_atm</mat-icon>
        <span>Cuota</span>
        <span class="text-blue">$129.917,14	</span>
      </div>
      <div class="servicio">
        <mat-icon>event</mat-icon>
        <span>Plazo</span>
        <span class="text-blue">75 meses</span>
      </div>
    </div>

    <div class="btn-action">
      <button [routerLink]="['/f-docs']" >Firmar documentos</button>
    </div>
  </main>

  <hr>

  <div class="redes">
    <p class="text-center">Siguenos en:</p>
    <div class="items-redes">
      <div class="red">
        <a href="/" target="_blank">
          <mat-icon>link</mat-icon>
        </a>
      </div>
      <div class="red">
        <a href="https://www.facebook.com/" target="_blank">
          <mat-icon>facebook</mat-icon>
        </a>
      </div>
      <div class="red">
        <a href="https://www.facebook.com/" target="_blank">
          <mat-icon>facebook</mat-icon>
        </a>
      </div>
      <div class="red">
        <a href="https://www.facebook.com/" target="_blank">
          <mat-icon>facebook</mat-icon>
        </a>
      </div>
      <div class="red">
        <a href="https://www.facebook.com/" target="_blank">
          <mat-icon>facebook</mat-icon>
        </a>
      </div>
    </div>
  </div>

  <footer>
    <p class="text-center">©BAYPORT, todos los derechos reservados</p>
  </footer>
</div>
