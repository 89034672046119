<main class="bg-login w-screen h-screen relative overflow-auto">
  <figure class="icon-clic hidden md:block">
    <img src="../../../assets/img/logo.png" alt="logo bayport" class="w-3rem" />
  </figure>
  <article class="w-full h-full absolute top-0 left-0 grid">
    <div class="relative bg-blue-gradient m-auto w-11 sm:w-5 lg:w-4 p-5 sm:border-1 border-round border-sky-200" style="background: transparent linear-gradient(180deg, var(--unnamed-color-00405f) 0%, var(--unnamed-color-0f113e) 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid var(--unnamed-color-81daff);
    background: transparent linear-gradient(180deg, #00405F 0%, #0F113E 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid #81DAFF;
    border-radius: 4px;">
      <figure class="md:hidden icon-clic-2">
        <img src="../../../assets/img/logo-2.svg" alt="logo bayport" class="w-4rem mb-2" />
      </figure>
      <div class="cont-form">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <form [formGroup]="recuperacionForm">
            <h2 class=" mb-2 font-bold text-xl text-white">Contraseña caducada</h2>
            <p class="mb-4 text-sm text-white fw-semibold" style="font-size: 16px !important;">
              Tu contraseña actual ha caducado. Por favor, registra una nueva contraseña
            </p>
            <div class="row">
              <div class="col-md-12">
                <div class="font-bold text-white">
                  <label>Contraseña actual</label>
                  <p-password formControlName="actual" type="text" placeholder="*contraseña" required
                    [toggleMask]="true" [feedback]="false" inputStyleClass="max-w-none w-full"
                    styleClass="max-w-none w-full"></p-password>
                </div>
                <br>
              </div>
              <hr style="color: #A7AAF5;">
              <div class="col-md-12">
                <div class="font-bold text-white nueva">
                  <label>Nueva contraseña</label>
                  <p-password formControlName="nueva" type="text" placeholder="*contraseña" required [toggleMask]="true"
                    [feedback]="false" inputStyleClass="max-w-none w-full" styleClass="max-w-none w-full"
                    (ngModelChange)="showValid($event)"></p-password>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('minlength') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp; Mínimo 8 caracteres
                      </span>
                    </div>
                    <div class="col-md-6">
                      <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notMayus') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 letra mayúscula
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notMinus') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 letra minúscula
                      </span>
                    </div>
                    <div class="col-md-6">
                      <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notNumber') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 número
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="mb-2 block fw-semibold" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notSpecialCharacter') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 un caracter especial (+*%$)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="font-bold text-white">
                  <label>Confirmar contraseña</label>
                  <p-password formControlName="confirmar" type="text" placeholder="*contraseña" required
                  [toggleMask]="true" [feedback]="false" inputStyleClass="max-w-none w-full"
                  styleClass="max-w-none w-full" #txtQuery (keyup)="changeComfirmar(txtQuery.value)"></p-password>
                </div>
              </div>
            </div><br>
            <div>
              <button-look-feel [type]="'submit'" (clicked)="changePassword()" [block]="true" color="secondary" [disabled]="!recuperacionForm.valid" customClass="py-2">Cambiar contraseña</button-look-feel>
            </div>
          </form>
        </div>
      </div>
    </div>
  </article>
</main>
