<div class="header-tablet">

  <!-- <div class="menu">
      <span class="material-icons">menu</span>
      <span class="menu-desc">Menú</span>
    </div> -->
  <!-- <div class="menu" (click)="showMenuTablet()">
      <span class="material-icons">cancel</span>
      <span class="menu-desc">Cerrar</span>
    </div> -->
  <div class="logo">
    <img src="../../../assets/img/BayportLogo.svg" alt="logo" />
  </div>
  <div class="nuevo-credito">
    <ng-content select="[menuResponsive]"></ng-content>
  </div>

  <!-- <div class="nuevo-credito opt-no-border"></div> -->
</div>
<div class="back">
  <div class="text">
    <h1>
      ¡Bienvenido a tu oficina virtual! Acá
      podrás gestionar las solicitudes de 
      crédito de tus usuarios
    </h1>
  </div>
</div>