import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        let token: any = (localStorage.getItem('credentials'));
        token = JSON.parse(token);
        if (token) {
            if(req.url.substring(0,10)!='https://s3'){
                let jwt = token.access_token;
                req = req.clone({
                    setHeaders: {
                        auth: `${jwt}`,
                        Authorization: `Bearer ${jwt}`,
                        token: jwt,
                        usuario: token.usuario
                    }
                });
            }
        }
        return next.handle(req);
    }

}
