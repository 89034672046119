<div class="send-container" [ngClass]="{'scroll':listaPDF != '' }">

  <div class="busc-container">
    <div class="fondo-busc">
      <input class="busqueda" placeholder="Escribe tus observaciones..." [(ngModel)]="observaciones" type="text"
        required>
    </div>
    <i (click)="enviar()" class="fa-regular fa-paper-plane"></i>
  </div>
  <div class="pdfs" *ngIf="listaPDF != '' ">
    <div *ngFor="let pdf of listaPDF; let i = index" class="d-flex m-2">
        <i class="fa-regular fa-file-pdf me-2" style="font-size: 35px;"></i>
        <div>
          <p class="m-0">{{pdf[0].name}}</p>
          <small (click)="deletePDF(i)" style="color: brown;">( Eliminar )</small>
        </div>
    </div>
  </div>
</div>