import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'button-look-feel',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnChanges{
  // Propiedades de personalización
  @Input() type: 'submit' | 'reset' | 'button' = 'button'; // Por defecto: primary
  @Input() color: 'primary' | 'secondary' | 'outline' = 'primary'; // Por defecto: primary
  @Input() size: 'small' | 'medium' | 'large' = 'medium'; // Por defecto: medium
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() block: boolean = false;              // Estado de boton en bloque
  @Input() disabled: boolean = false;           // Estado de deshabilitado
  @Input() loading: boolean = false;            // Estado de carga
  @Input() compressed: boolean = false;         // boton comprimido
  @Input() error = false; // Recibe el estado de error desde el padre

  // Evento de clic
  @Output() clicked = new EventEmitter<void>();
  errorAnimationClass = "";

  ngOnChanges(changes: SimpleChanges) {
    if (changes['error'] && changes['error'].currentValue === true) {
      this.triggerErrorAnimation();
    }
  }

  onClick() {
    if (!this.disabled) {
      this.clicked.emit(); // Emitir evento si no está deshabilitado
    }
  }

  triggerErrorAnimation() {
    this.errorAnimationClass = ''; // Elimina la clase temporalmente
    setTimeout(() => {
      this.errorAnimationClass = 'shake-horizontal'; // Vuelve a aplicarla
    }, 800); // Espera unos milisegundos para forzar el cambio
  }

}
