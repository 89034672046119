import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalItems = 0; // Número total de elementos
  @Input() itemsPerPage = 10; // Número de elementos por página
  @Input() currentPage = 1; // Página actual

  @Output() pageChange = new EventEmitter<PageEvent>();

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;

      // Emitiendo un objeto con la estructura de PageEvent
      const event: PageEvent = {
        pageIndex: page - 1,  // Ajuste porque pageIndex es basado en 0
        pageSize: this.itemsPerPage,
        length: this.totalItems
      };

      this.pageChange.emit(event);
    }
  }
}
