<app-main-container header="Validación de identidad" subheader="Actualización de información del solicitante">
  <div class="contenido" style="padding-bottom: 1rem">
    <p>
      Solicita la siguiente información para continuar proceso de autorización de tratamiento de datos y validación de
      identidad.
    </p>
    <form class="form" [formGroup]="formInfoTele">
      <div class="row">
        <div class="col-md-6 form-field">
          <label for="telefono" class="form-label">Teléfono celular</label>
          <input type="number" inputmode="numeric" class="form-control" placeholder="*Teléfono celular" id="telefono"
            formControlName="telefono" autocomplete="off">
          <div class="form-error" *ngIf="formInfoTele.controls.telefono.hasError('required') && !formInfoTele.controls.telefono.pristine">
            El teléfono celular es obligatorio
          </div>
          <div class="form-error" *ngIf="formInfoTele.controls.telefono.hasError('pattern')">
            El formato del celular es incorrecto
          </div>
        </div>
        <div class="col-md-6 form-field">
          <label for="confirmarTelefono" class="form-label">Confirmar teléfono celular</label>
          <input type="number" inputmode="numeric" class="form-control" placeholder="*Confirmar teléfono celular"
            id="confirmarTelefono" formControlName="confirmarTelefono" autocomplete="off">
          <div class="form-error"
            *ngIf="formInfoTele.controls.confirmarTelefono.hasError('celularesNoCoinciden') && !formInfoTele.controls.confirmarTelefono.pristine">
            No coinciden los teléfonos celulares ingresados
          </div>
          <div class="form-error" *ngIf="formInfoTele.controls.confirmarTelefono.hasError('required') && !formInfoTele.controls.confirmarTelefono.pristine">
            La confirmacion del teléfono celular es obligatoria
          </div>
        </div>
      </div>
    </form>
    <br>
    <p>
      Al ingresar los datos personales del solicitante, como asesor/corredor declaras que cuentas con la autorización
      para
      transferir estos datos personales a Bayport y cuentas con los consentimientos necesarios para
      realizar el tratamiento de datos personales, en calidad de responsable de
      acuerdo con las obligaciones establecidas para dicho rol por la
      <b>Ley 1581 de 2012</b> y sus decretos reglamentarios.
    </p>
    <button-look-feel color="secondary" (clicked)="confirmacion()" [disabled]="!formInfoTele.valid || isValidConfirmar"
      [loading]="loading">Guardar</button-look-feel>
  </div>
</app-main-container>
