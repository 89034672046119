import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-card-file',
  templateUrl: './card-file.component.html',
  standalone: true,
  styleUrls: ['./card-file.component.css'],
  imports: [CommonModule, FormsModule],
})
export class CardFileComponent {
  fileName = '';
  uploadedFile = false;
  selectedFile: File;
  newFileName = '';
  isInputInvalid = false;

  @Output() dataSubmit = new EventEmitter<{ file: File; fileName: string }>();

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.uploadedFile = true;
  }

  onSaveFileChange() {
    this.isInputInvalid =
      this.newFileName.trim() === '' ? true : this.newFileName.length < 3;
    if (this.isInputInvalid) {
      return;
    }

    this.dataSubmit.emit({
      file: this.selectedFile,
      fileName: this.newFileName,
    });

    this.newFileName = '';
    this.uploadedFile = false;
  }
}
