import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-advertencias',
  templateUrl: './advertencias.component.html',
  styleUrls: ['./advertencias.component.css']
})
export class AdvertenciasComponent {
  @Input() color: 'success' | 'warning' | 'info' = 'success'; // Por defecto: success
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() compressed: boolean = false;         // alerta comprimido
  @Input() text: string = "";         // mensaje
}
