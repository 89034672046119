import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AceptacionCentralesService {
  private endpoint = environment.ApiConecta+'aceptacion-consulta';

  constructor(private http:HttpClient) { }

  aceptarConsulta(numeroCarpeta:number,confirmacion:number):Observable<any>{
    return this.http.post(this.endpoint,{numeroCarpeta,confirmacion});
  }
}
