import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-minicard',
  templateUrl: './minicard.component.html',
  styleUrls: ['./minicard.component.css']
})
export class MinicardComponent implements OnInit {

  @Input() title:string;
  @Input() message:string;
  @Input() index:number;
  @Input() status:boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
