import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { MatDialog } from '@angular/material/dialog';

import jspdf from 'jspdf';

import { DeviceDetectorService } from 'ngx-device-detector';

import { UploadService } from 'src/app/services/busqueda/busqueda.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { DataService } from '../../services/data.service';

import { Documento } from 'src/app/models/documento';
import { Utils } from 'src/app/utils/utils';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Buffer } from 'buffer';
import { ControlTamañoService } from 'src/app/services/controlTamaño/controlTamaño.service';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';
import { CameraComponent } from '../pre-autorizacion/formato-acr/components/camera/camera.component';
import { DialogUploadComponent } from './components/dialog-upload/dialog-upload.component';

@Component({
  selector: 'app-carga-documentos',
  templateUrl: './carga-documentos.component.html',
  styleUrls: ['./carga-documentos.component.scss'],
})
export class CargaDocumentosComponent implements OnInit {
  public contador = 0;
  private utils = new Utils();
  public loading = false;
  public cedula: Documento[] = [
    { status: false, secuencia: 2 },
    { status: false, secuencia: 61 },
  ];
  public desprendible: Documento[] = [
    { status: false, secuencia: 4 },
    { status: false, secuencia: 62 },
  ];
  public otrosObligatorios: Documento[] = [];
  public otrosAdicionales: Documento[] = [
    {
      status: false,
      texto:
        'Carga el soporte de validación para garantizar que el cliente no tenga endeudamiento simultáneo.',
      nombre: 'Huellas de consulta',
      secuencia: 64,
    },
    {
      status: false,
      texto:
        'Carga los documentos que soporten la terminación o archivo definitivo de las demandas en centrales de riesgo.',
      nombre: 'Aclaraciones del proceso',
      secuencia: 64,
    },
    {
      status: false,
      texto:
        'Carga la resolución de pension del cliente, en la que se valide el tipo de pensión y fecha en la que se otorgó.',
      nombre: 'Resolución',
      secuencia: 64,
    },
    {
      status: false,
      texto:
        'Carga el soporte de validación del tipo de crédito garantizando que no es una libranza.',
      nombre: 'Líneas de crédito',
      secuencia: 64,
    },
    {
      status: false,
      texto:
        'Carga el soporte donde se evidencien las cuotas a par con otras entidades.',
      nombre: 'Plan de pagos',
      secuencia: 64,
    },
    {
      status: false,
      texto: 'Carga el desprendible para validar otro tipo de descuentos.',
      nombre: 'Desprendible adicional',
      secuencia: 64,
    },
  ];
  masAdicionals: Documento[] = [];

  private imageData: any = '';
  private documentoConvertido = null;

  private dataCarpeta: any;

  enabledButton = true;
  isOtherRequired = false;

  cedulaForm: FormGroup;
  desprendiblesForm: FormGroup;
  isLoading = false;

  currentStep = 1; // Variable para controlar el paso actual
  steps = [
    { title: 'Cédula de ciudadanía', completed: false },
    { title: 'Desprendibles de pago', completed: false },
    { title: 'Otros documentos obligatorios', completed: false },
    { title: 'Otros documentos adicionales', completed: false },
    { title: 'Completo', completed: false },
  ];
  currentProgress = 0;
  additionalDocuments = [];

  isUploadingFile = true;
  isUploadingError = false;
  isUploadingSuccess = false;

  flujoAlternativo = false;
  mensajeError = '';
  isListaError = false;
  tituloError = '';
  isTimeout = false;

  constructor(
    private dialog: MatDialog,
    private docSolSrv: DocSolicitudService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    private dialogServ: DialogService,
    public data: DataService,
    private cambioEstadoServ: CambioestadocarpetaService,
    private datepipe: DatePipe,
    private serviceCarga: ArchivoCargaService,
    private serviceDoc: DocSolicitudService,
    private _busquedaService: UploadService,
    private controlTamañoService: ControlTamañoService,
    private _formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.cedulaForm = this._formBuilder.group({
      cedulaFrente: [null, Validators.required],
      cedulaReverso: [null, Validators.required],
    });
    this.desprendiblesForm = this._formBuilder.group({
      desprendibleReciente: [null, Validators.required],
      desprendibleAnterior: [null, Validators.required],
    });

    this.modalWarning(
      'Cargar los documentos en el orden que se presenta en la pantalla.'
    );
    this.getDocumentos();
    /**
     * Si no hay información de la carpeta, la consulta.
     */
    const folderData = await this._busquedaService.getResultadosBusqueda({
      numeroCarpeta: this.data.numeroCarpeta,
    });
    this.dataCarpeta = folderData.rows[0];
  }

  private getDocumentos(): void {
    this.docSolSrv
      .getDocumentosSolicitud(this.data.numeroCarpeta)
      .subscribe((res) => {
        this.otrosObligatorios = [];

        res.rows.forEach((docs: any) => {
          if (
            (docs.faseDigitalizacion == 1 && docs.indObligatorio == 1) ||
            docs.indRobotSeiz == 1
          ) {
            if (
              docs.nombreDocumento != 'DESPRENDIBLES DE PAGO' &&
              docs.nombreDocumento != 'Desprendibles de pago (Ultimo mes)'
            ) {
              this.isOtherRequired = true;
              this.otrosObligatorios.push({
                status: false,
                secuencia: docs.secuenciaDocumento,
                nombre: docs.nombreDocumento,
              });
            }
          }
        });
      });
  }

  public async fileChange(
    event: any,
    doc: Documento,
    nombre?: string,
    controlName?: string,
    formGroup?: FormGroup
  ): Promise<void> {
    this.showModal();

    const sizeInMB = event.target.files[0].size / (1024 * 1024);

    if (sizeInMB >= 5) {
      Swal.fire({
        title: 'Advertencia',
        allowOutsideClick: false,
        position: 'center',
        imageUrl: '../../assets/icons/error.svg',
        html: `
          <p style="text-align: left;text-wrap: balance;">
            El archivo no fue cargado porque supera el tamaño límite. Asegúrate que el archivo no exceda los <b>5 MB</b> y vuelve a cargarlo.
          </p>
        `,
        confirmButtonText: 'OK',
        confirmButtonColor: '#009BDC',
      });
      event.target.files = null;
      return;
    }

    let timestamp: string = this.datepipe.transform(
      new Date(),
      'yyyyMMddHHmmss'
    );

    doc.status = true;
    doc.nombre = nombre ? nombre : doc.nombre;
    doc.nombre = `${doc.nombre}-${timestamp}.pdf`;
    doc.upload = false;
    doc.archivo = event.target.files[0];

    if (event.target.files[0].type != 'application/pdf') {
      const filePdf = await this.utils.convertImageFileToPDF(doc.archivo);
      doc.archivo = filePdf;
    }

    if (sizeInMB >= 2) {
      const fileBase64 = (await this.fileToBase64(doc.archivo)) as string;
      try {
        const response = await this.controlTamañoService
          .documentCompressor(fileBase64)
          .toPromise();

        const buffer = Buffer.from(response.data.documento, 'base64');

        const file = new File([buffer], nombre ? nombre : doc.nombre, {
          type: 'application/pdf',
        });
        doc.status = true;
        doc.nombre = nombre ? nombre : doc.nombre;
        doc.nombre = `${doc.nombre}-${timestamp}.pdf`;
        doc.upload = false;
        doc.archivo = file;
      } catch (error) {
        console.log({ error });
      } finally {
        Swal.close();
      }
    }

    if (controlName) {
      formGroup.get(controlName).setValue(doc);
    }

    this.contador++;
    Swal.close();
  }

  public eliminarArchivo(documento: Documento, nombre?: boolean): void {
    this.contador--;
    documento.status = false;
    documento.upload = false;
    (documento.nombre = nombre ? documento.nombre : ''),
      (documento.archivo = null);
  }

  public deleteDymamic(index: number): void {
    this.masAdicionals.splice(index, 1);
  }

  public openDialogFile(): void {
    const dialogRef = this.dialog.open(DialogUploadComponent);

    dialogRef.afterClosed().subscribe((data: Documento) => {
      if (data) {
        this.masAdicionals.push(data);
      }
    });
  }

  public openCamera(documento: Documento, nombre: string): void {
    let timestamp: string = this.datepipe.transform(
      new Date(),
      'yyyyMMddHHmmss'
    );
    const dialogCamera = this.dialog.open(CameraComponent);

    dialogCamera.afterClosed().subscribe(async ({ data }) => {
      const base64 = data;
      const imageName = `${nombre}.jpeg`;
      const imageBlob = this.dataURItoBlob(base64);
      const imageFile = new File([imageBlob], imageName, {
        type: 'image/jpeg',
      });

      documento.status = true;
      documento.upload = false;
      documento.nombre = nombre;
      documento.nombre = `${documento.nombre}-${timestamp}.pdf`;
      documento.archivo = imageFile;

      await this.convertirImagen(imageFile);
    });
  }

  private async convertirImagen(file: any): Promise<void> {
    let doc = new jspdf({ compress: true });

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {};
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (rs) => {
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];

        //console.log(img_height, img_width);
      };

      this.imageData = reader.result;
    };

    await setTimeout(() => {
      doc.addImage(this.imageData, 'JPEG', 10, 10, 180, 150);
      //doc.save('test.pdf')
      this.documentoConvertido = doc.output();

      this.blobToFile(this.documentoConvertido, file.name);
      //this.getUrl("80038506",'pdf');
    }, 1000);
  }

  private blobToFile(theBlob: any, filename: string): void {
    // let blob: Blob = new Blob([theBlob], { type: 'application/pdf' });
    let file: File = new File([theBlob], filename, { type: 'application/pdf' });
    this.documentoConvertido = file;
    // this.getUrl();
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(btoa(dataURI));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: 'image/jpeg' });

    return blob;
  }

  private validarCompletos(documentos: Documento[], validar: boolean): boolean {
    let valid = true;

    if (!validar) {
      documentos.forEach((doc) => {
        if (!doc.status) {
          valid = false;
        }
      });
    }

    return valid;
  }

  private validarDocumentos() {
    this.changeState()
      .then((res) => {
        this.flujoAlternativo = res.flujoAlterno == 1;
        this.isUploadingError = false;
        this.isUploadingFile = false;
        this.isTimeout = false;
        this.isUploadingSuccess = true;
      })
      .catch((err) => {
        this.cedula = [
          { status: false, secuencia: 2 },
          { status: false, secuencia: 61 },
        ];
        this.desprendible = [
          { status: false, secuencia: 4 },
          { status: false, secuencia: 62 },
        ];
        this.otrosObligatorios = [];
        this.otrosAdicionales = [
          {
            status: false,
            texto:
              'Carga el soporte de validación para garantizar que el cliente no tenga endeudamiento simultáneo.',
            nombre: 'Huellas de consulta',
            secuencia: 64,
          },
          {
            status: false,
            texto:
              'Carga los documentos que soporten la terminación o archivo definitivo de las demandas en centrales de riesgo.',
            nombre: 'Aclaraciones del proceso',
            secuencia: 64,
          },
          {
            status: false,
            texto:
              'Carga la resolución de pension del cliente, en la que se valide el tipo de pensión y fecha en la que se otorgó.',
            nombre: 'Resolución',
            secuencia: 64,
          },
          {
            status: false,
            texto:
              'Carga el soporte de validación del tipo de crédito garantizando que no es una libranza.',
            nombre: 'Líneas de crédito',
            secuencia: 64,
          },
          {
            status: false,
            texto:
              'Carga el soporte donde se evidencien las cuotas a par con otras entidades.',
            nombre: 'Plan de pagos',
            secuencia: 64,
          },
          {
            status: false,
            texto:
              'Carga el desprendible para validar otro tipo de descuentos.',
            nombre: 'Desprendible adicional',
            secuencia: 64,
          },
        ];
        this.masAdicionals = [];

        this.additionalDocuments = [];

        this.mensajeError = err.error.message;
        if (err.error?.isTrusted) {
          this.isTimeout = true;
          this.isUploadingFile = false;
          return;
        }
        if (err.status == 0) {
          this.isTimeout = true;
          this.isUploadingFile = false;
          return;
        }

        if (err.error.code === 409 || err.error.estadoNuevo === 300) {
          if (
            err.error.message.includes(
              ' - El documento no corresponde a la pagaduría que se está radicando'
            ) ||
            err.error.message.includes(
              'Cambio de estado aprobado por el Asesor'
            )
          ) {
            this.mensajeError =
              'Alguno de los documentos no coincide con la pagaduría del solicitante';
            this.tituloError = 'Revisa los documentos cargados';
          } else if (
            err.error.message.includes(
              ' - El documento que estás cargando no está vigente.'
            )
          ) {
            this.mensajeError =
              'Alguno de los documentos que subiste, no cumple con los tiempos establecidos';
            this.tituloError =
              'La vigencia de uno de los documentos no es válida';
          } else if (
            err.error.message.includes(
              ' - No es posible continuar con el proceso. Según el desprendible no tiene capacidad. Valida la información con tu cliente.'
            )
          ) {
            this.mensajeError =
              'Según el desprendible el solicitante no cuenta con capacidad. Valida la información con tu cliente para una nueva solicitud.';
            this.tituloError = 'No es posible continuar con la solicitud';
          } else if (
            err.error.message.includes(' - Tipo de mesada pensional no válida')
          ) {
            this.tituloError = 'No es posible continuar con la solicitud';
            this.mensajeError =
              'El tipo de mesada pensional del solicitante no le permite ser sujeto de crédito';
          } else if (
            err.error.message.includes(
              ' - El cliente no es sujeto de crédito por Número de afiliación inválido'
            )
          ) {
            this.tituloError = 'El solicitante no es sujeto de crédito';
            this.mensajeError =
              'El número de afiliación de Colpensiones es inválido.';
          } else {
            this.isListaError = true;
          }
        }
        this.isUploadingError = true;
        this.isUploadingFile = false;
        this.isUploadingSuccess = false;
      });
  }

  private changeState(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cambioEstadoServ
        .cambioEstadocarpeta(
          parseInt(this.data.numeroCarpeta),
          '300',
          'Cambio de estado aprobado por el Asesor'
        )
        .subscribe(
          (res) => {
            resolve(res);
            this.loading = false;
          },
          (error) => {
            reject(error);
            this.loading = false;
          }
        );
    });
  }

  modalWarning(message = '') {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: 'Advertencia',
      html: message,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#10AEEF',
      didOpen: () => {
        const modalTitle = document.querySelector(
          '.swal2-title'
        ) as HTMLElement;
        const modalContainer = document.querySelector(
          '.swal2-html-container'
        ) as HTMLElement;
        const modalAction = document.querySelector(
          '.swal2-actions'
        ) as HTMLElement;

        modalTitle.style.textAlign = 'left';
        modalContainer.style.textAlign = 'left';
        modalAction.style.width = '100%';
        modalAction.style.justifyContent = 'end';
        modalAction.style.paddingRight = '1rem';
      },
    });
  }

  async fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.toString().split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  showModal() {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: 'Advertencia',
      html: `El archivo seleccionado se esta cargando.`,
      showConfirmButton: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#10AEEF',
    });
  }

  async cargaSteps(stepper: MatStepper) {
    try {
      if (!(await this.modalFinished())) {
        return;
      }
      stepper.next();

      let documentAdicionales = this.otrosAdicionales;
      let masAdicionals = this.masAdicionals;
      let newDocuments = [...documentAdicionales, ...masAdicionals];

      if (newDocuments.length !== 0) {
        let tmpDocs = [];
        documentAdicionales = documentAdicionales.filter((doc: any) => {
          if (doc.status && !doc.upload) return doc;
        });
        if (documentAdicionales.length > 1) {
          let pdfBytes = await this.utils.appendPDF(
            documentAdicionales.map((doc) => doc.archivo)
          );
          let timestamp: string = this.datepipe.transform(
            new Date(),
            'yyyyMMddHHmmss'
          );

          let name = `documentosAdicionales-${timestamp}.pdf`;
          let pdfFile = new File([new Blob([pdfBytes as ArrayBuffer])], name, {
            type: 'application/pdf',
            lastModified: new Date().getTime(),
          });
          let pdfUnificado: Documento = {
            status: true,
            nombre: name,
            upload: false,
            archivo: pdfFile,
            secuencia: 64,
          };
          tmpDocs.push(pdfUnificado);
        } else {
          documentAdicionales.forEach((doc) => {
            tmpDocs.push(doc);
          });
        }

        if (masAdicionals.length > 1) {
          let timestamp: string = this.datepipe.transform(
            new Date(),
            'yyyyMMddHHmmss'
          );
          let pdfBytesAdicionales = await this.utils.appendPDF(
            masAdicionals.map((doc) => doc.archivo)
          );
          let nameAdcionales = `Mas-documentos-adicionales-${timestamp}.pdf`;
          let pdfFileAdicionales = new File(
            [new Blob([pdfBytesAdicionales as ArrayBuffer])],
            nameAdcionales,
            { type: 'application/pdf', lastModified: new Date().getTime() }
          );
          let pdfUnificadoAdicionaless: Documento = {
            status: true,
            nombre: nameAdcionales,
            upload: false,
            archivo: pdfFileAdicionales,
            secuencia: 5,
          };

          tmpDocs.push(pdfUnificadoAdicionaless);
        } else {
          masAdicionals.forEach((doc) => {
            tmpDocs.push(doc);
          });
        }
        newDocuments = tmpDocs;
      }

      const initDocument = [
        ...this.cedula,
        ...this.desprendible,
        ...this.otrosObligatorios,
        ...newDocuments,
      ];

      for (const doc of initDocument) {
        doc.nombre = `PORTAL-AGENTES-${doc.nombre}`;
        const carpeta = `${this.data.numeroCarpeta}`;
        const identificacion = 'certification';
        const respuestaArchivo = await this.serviceCarga
          .cargandoArchivo(carpeta, identificacion, doc.nombre)
          .toPromise();

        let form = new FormData();
        Object.keys(respuestaArchivo.fields).forEach((key) =>
          form.append(key, respuestaArchivo.fields[key])
        );
        form.append('Content-Type', 'application/pdf');
        form.append('file', doc.archivo);

        const responseFetch = await fetch(respuestaArchivo.url, {
          method: 'POST',
          body: form,
        });

        if (!responseFetch.ok) {
          this.isUploadingError = true;
          this.isUploadingFile = false;
          this.isUploadingSuccess = false;
          return;
        }

        //carga de archivo
        await this.serviceDoc
          .uploadAwsRoute(carpeta, respuestaArchivo.fields.key, doc.secuencia)
          .toPromise();
      }

      this.validarDocumentos();
    } catch (error) {
      console.log({ error: error });
      this.mensajeError = error.error.message;
      this.isUploadingError = true;
      this.isUploadingFile = false;
      this.isListaError = false;
      this.isUploadingSuccess = false;

      this.cedula = [
        { status: false, secuencia: 2 },
        { status: false, secuencia: 61 },
      ];
      this.desprendible = [
        { status: false, secuencia: 4 },
        { status: false, secuencia: 62 },
      ];
      this.otrosObligatorios = [];
      this.otrosAdicionales = [
        {
          status: false,
          texto:
            'Carga el soporte de validación para garantizar que el cliente no tenga endeudamiento simultáneo.',
          nombre: 'Huellas de consulta',
          secuencia: 64,
        },
        {
          status: false,
          texto:
            'Carga los documentos que soporten la terminación o archivo definitivo de las demandas en centrales de riesgo.',
          nombre: 'Aclaraciones del proceso',
          secuencia: 64,
        },
        {
          status: false,
          texto:
            'Carga la resolución de pension del cliente, en la que se valide el tipo de pensión y fecha en la que se otorgó.',
          nombre: 'Resolución',
          secuencia: 64,
        },
        {
          status: false,
          texto:
            'Carga el soporte de validación del tipo de crédito garantizando que no es una libranza.',
          nombre: 'Líneas de crédito',
          secuencia: 64,
        },
        {
          status: false,
          texto:
            'Carga el soporte donde se evidencien las cuotas a par con otras entidades.',
          nombre: 'Plan de pagos',
          secuencia: 64,
        },
        {
          status: false,
          texto: 'Carga el desprendible para validar otro tipo de descuentos.',
          nombre: 'Desprendible adicional',
          secuencia: 64,
        },
      ];
      this.masAdicionals = [];

      this.additionalDocuments = [];
    }
  }

  addAdditionalFile(data: any) {
    const type = data.file.name.split('.').at(-1);
    data.fileName = `${data.fileName}.${type}`;
    this.additionalDocuments.push(data);
  }

  removeAdditionalFile(index: number) {
    this.additionalDocuments.splice(index, 1);
  }

  async modalFinished() {
    let isContined = false;

    const result = await Swal.fire({
      allowOutsideClick: false,
      title: '¿Estás seguro de enviar?',
      text: 'Una vez enviada la documentación no podrás editarla',
      showCancelButton: true,
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: `Regresar`,
      didOpen: () => {
        Swal.getCancelButton().setAttribute(
          'style',
          'color: #10AEEF;border: 1px solid #0CAEEF;background:transparent'
        );
        Swal.getConfirmButton().setAttribute(
          'style',
          'color: #0F113E;border: 1px solid #10AEEF;background:#10AEEF'
        );
      },
    });
    if (result.isConfirmed) {
      isContined = true;
    }
    if (result.isDenied) {
      isContined = false;
    }

    return isContined;
  }

  resetErrorDocument(stepper: MatStepper) {
    stepper.reset();
    this.isUploadingError = false;
    this.isUploadingFile = true;
    this.isUploadingSuccess = false;
  }

  redirectSeiz() {
    if (this.flujoAlternativo) {
      this.router.navigateByUrl('seiz/oferta', {
        state: this.dataCarpeta,
      });
    } else {
      this.router.navigateByUrl('informacionadicional', {
        state: this.dataCarpeta,
      });
    }
  }

  resetValuesUploading() {
    this.isUploadingFile = true;
    this.isUploadingError = false;
    this.isUploadingSuccess = false;
    this.isTimeout = false;
    this.mensajeError = '';
  }

  resendService() {
    this.isUploadingFile = true;
    this.isTimeout = false;
    this.validarDocumentos();
  }
}
