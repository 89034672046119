import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UploadService } from 'src/app/services/cargaArchivos/upload.service';
import { CogQrComponent } from './cog-qr/cog-qr.component';
import { ProgressCedComponent } from './progress-ced/progress-ced.component';
import { DatePipe } from '@angular/common'
import Swal from 'sweetalert2';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'app-carga-cedula',
  templateUrl: './carga-cedula.component.html',
  styleUrls: ['./carga-cedula.component.css']
})
export class CargaCedulaComponent implements OnInit {
  selectedFiles:any;
  constructor(
      public dialog: MatDialog,
      private uploadaws:UploadService,
      public datepipe: DatePipe, 
      private serviceCarga:ArchivoCargaService,
      private serviceDoc:DocSolicitudService,
      public data: DataService,
    ) { }

  openDialog() {
    const dialogRef = this.dialog.open(CogQrComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogProgres(){

    const dialogRef = this.dialog.open(ProgressCedComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private upload(tipo:string) {
    const file = this.selectedFiles.item(0);
    let filextension = file.name.split('.').pop();
    let tstamp= this.datepipe.transform(new Date(), 'yyyyMMddHHmmss');
    let name = `PORTARFRONT-cedula_ciudadania-${tipo}-${tstamp}.${filextension}`;

    const identificacion = "identification";
    const carpeta = `${ this.data.numeroCarpeta}`;
    this.serviceCarga.cargandoArchivo(carpeta,identificacion,name).subscribe(
      respuesta=>{
        let form = new FormData();
        Object.keys(respuesta.fields).forEach(key => form.append(key, respuesta.fields[key]));
        form.append('Content-Type', "application/pdf");
        form.append('file', file);
  
        fetch(respuesta.url, { method: 'POST', body: form }).then( res=>{
          if(res.ok){
          }else{
            Swal.fire('Opps','Error al cargar el archivo, intenta nuevamente por favor','error');
          }
        });
      },error=>{
        console.log("No hay respuesta del BUCKET");
        console.log(<any>error);
      }
    );

    }
    
    selectFile(event:any,tipo:string) {
    this.selectedFiles = event.target.files;
    this.upload(tipo);
    }

  ngOnInit(): void {

  }

}
