import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { PerfilamientoService } from 'src/app/services/perfilamiento/perf-inf-personal.service';
import Swal from 'sweetalert2';
import { DialogAutoTermComponent } from '../dialog-auto-term/dialog-auto-term.component';

@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.css'],
})
export class ValidacionComponent implements OnInit {
  errorValidacion = false;
  bloqueado = false;
  tiempoBloqueo = 60000; // Tiempo en milisegundos
  numeroCarpeta: string = '';
  cellphone: string = '';
  cedula: number = 0;
  regValidacion: string = '';
  numeroSolicitud: string = '';
  idTransaccionOTP: string = '';
  code: string = '';
  carpeta: string = '';
  correo: '';
  celular: string = '';
  enviarOtp = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  validarOtp = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  cambioCelular = {
    totalIntentos: 0,
    intentosActuales: 0,
  };
  tiempoRestante: number = 60;
  intervalId: any;
  isLoading = false;
  intentosContinuar = 0;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    private getinfoServ: GetInformacionService,
    private cambioEstadoServ: CambioestadocarpetaService,
    private perfilamientoService: PerfilamientoService
  ) { }

  openDialog() {
    const dialogRef = this.dialog.open(DialogAutoTermComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }

    if (this.data.isUpdateMobile) {
      this.iniciarTemporizadorBloqueo();
    }

    let state = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );
    this.numeroCarpeta = this.data.numeroCarpeta;
    this.getinfoServ
      .getInfoClient({ carpeta: this.numeroCarpeta.toString() })
      .subscribe(
        (resp) => {
          this.carpeta = resp.data.resultado.infoClient.carpeta;
          this.celular = resp.data.resultado.infoClient.celular;

          this.correo = resp.data.resultado.infoClient.correo;
          this.enviarOtp = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 4
          )[0];
          this.validarOtp = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 2
          )[0];
          this.cambioCelular = resp.data.resultado.tries.filter(
            (el) => el.tipoValidacion === 5
          )[0];

          this.cedula = resp.data.resultado.infoClient.identificacion;
          this.cellphone = resp.data.resultado.infoClient.celular;
          this.numeroSolicitud = this.data.numeroSolicitud;
          resp.data.resultado.tries.forEach((element) => {
            if (element.tipoValidacion == 4) {
              this.idTransaccionOTP = element.intentos;
            }
          });
          if (this.cambioCelular.intentosActuales !== 0) {
            if (!this.data.isResume) {
              Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                imageUrl: '../../assets/icons/mensajeCel.png',
                title: 'Enviar el código',
                html:
                  'El solicitante recibirá un SMS con el código de verificación al celular <b>' +
                  this.cellphone +
                  '</b> para iniciar su proceso de realizar la autorización de consulta en centrales de riesgos.',
                showConfirmButton: true,
                confirmButtonText: 'Enviar código',
                confirmButtonColor: '#10AEEF',
                footer: `<font color="#10AEEF">Intento ${this.enviarOtp.totalIntentos -
                  this.enviarOtp.intentosActuales +
                  1
                  } de ${this.enviarOtp.totalIntentos}</font>`,
                didOpen: () => {
                  if (this.data.isNewPhone || this.data.isNewEmail) {
                    const newElement = document.createElement('div');
                    const modal = document.querySelector(
                      '.swal2-modal'
                    ) as HTMLElement;
                    modal.style.position = 'relative';

                    newElement.style.position = 'absolute';
                    newElement.style.top = '-3.2rem';
                    newElement.style.width = '100%';
                    newElement.innerHTML = `
                    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </symbol>
                      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                      </symbol>
                      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </symbol>
                    </svg>
                    <div class="alert alert-success" role="alert" style="background:#E2FFC4;border: 1px solid #BCD5A6;color: var(--unnamed-color-0f113e);font-size: 16px/24px;">
                    <svg class="bi flex-shrink-0 me-2" width="14" height="14" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> &nbsp;La información fue guardada exitosamente. 
                    </div>
                    `;
                    modal.appendChild(newElement);
                  }
                },
              }).then(async (resultado) => {
                if (resultado.value) {
                  this.iniciarTemporizadorBloqueo();

                  this.getinfoServ
                    .sendOtpCode({
                      carpeta: this.carpeta,
                      celular: this.celular,
                      ind: 0,
                    })
                    .subscribe(
                      async () => {
                        await this.consultarIntentos();
                      },
                      (error) => {
                        console.log(error.error.message);
                      }
                    );
                }
              });
            }
          }
        },
        (error) => {
          console.log('Falle traer informacion cliente' + error);
        }
      );
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  onCodeCompleted(code: string) {
    this.code = code;
  }
  onCodeChanged(code: string) {
    this.code = code;
  }

  async consultarIntentos() {
    const data = await this.getinfoServ
      .getInfoClient({ carpeta: this.numeroCarpeta.toString() })
      .toPromise();

    this.carpeta = data.data.resultado.infoClient.carpeta;
    this.celular = data.data.resultado.infoClient.celular;

    this.enviarOtp = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 4
    )[0];
    this.validarOtp = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 2
    )[0];
    this.cambioCelular = data.data.resultado.tries.filter(
      (el) => el.tipoValidacion === 5
    )[0];
  }

  async reenviarCodigo() {
    if (this.enviarOtp.intentosActuales === 0) {
      await this.rechazarCarpeta(this.carpeta.toString());
      this.carpetaRechazada('reenvío');
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      imageUrl: '../../assets/icons/mensajeCel.png',
      title: 'Reenviar el código',
      html: ' <div id="texto"></div>',
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Reenviar código',
      confirmButtonColor: '#10AEEF',
      cancelButtonText: 'Actualizar celular',
      cancelButtonColor: 'border: 1px solid #0CAEEF; background: #FFFFFF',
      footer:
        this.enviarOtp.intentosActuales === 1
          ? `<font color="#f44336">Último intento</font>`
          : `<font color="#10AEEF">Intento ${this.enviarOtp.totalIntentos - this.enviarOtp.intentosActuales + 1
          } de ${this.enviarOtp.totalIntentos}</font>`,
      didOpen: async () => {
        const isDisabled = this.enviarOtp.intentosActuales === 1;
        const sinIntentos = this.cambioCelular.intentosActuales === 0;

        let mensaje = `Te queda solo un reintento más para enviar el mensaje SMS al celular <b>${this.cellphone}</b>. Si deseas registrar un nuevo número celular selecciona la opción “<b>Actualizar Celular</b>” en este momento.`;

        if (sinIntentos) {
          mensaje = `Te queda un solo reintento para enviar el mensaje SMS al celular <b>${this.cellphone}</b>, si no coincide el código, la carpeta será rechazada.`;
        }

        if (this.cambioCelular.intentosActuales === 0) {
          Swal.getCancelButton().hidden = true;
        }

        if (isDisabled) {
          Swal.getCancelButton().disabled = false;
          Swal.getCancelButton().setAttribute(
            'style',
            'color: #0FADEF;border: 1px solid #0CAEEF;background: #FFFFFF;'
          );
          const $texto = Swal.getHtmlContainer().querySelector('#texto');
          $texto.innerHTML = mensaje;
        } else {
          Swal.getCancelButton().disabled = true;
          Swal.getCancelButton().setAttribute(
            'style',
            'color: #0FADEF;border: 1px solid #0CAEEF;background: #FFFFFF;'
          );
          const $texto = Swal.getHtmlContainer().querySelector('#texto');
          $texto.innerHTML =
            'El solicitante recibirá nuevamente un SMS con el código al celular <b>' +
            this.cellphone +
            '</b> para realizar la autorización de consulta en centrales de riesgos.';
        }
      },
    }).then(async (resultado) => {
      if (resultado.value) {
        this.iniciarTemporizadorBloqueo();

        this.getinfoServ
          .sendOtpCode({
            carpeta: this.carpeta,
            celular: this.celular,
            ind: 1,
          })
          .subscribe(
            async () => {
              await this.consultarIntentos();
            },
            (error) => {
              console.log(error.error.message);
            }
          );
      } else if (resultado.dismiss === Swal.DismissReason.cancel) {
        this.getinfoServ
          .saveCascadaPhone({
            ind: 3,
            celular: this.celular.toString(),
            carpeta: this.data.numeroCarpeta.toString(),
            cedula: this.data.cedula.toString(),
          })
          .subscribe(
            (resp) => {
              this.router.navigateByUrl('/' + resp.data.ruta);
            },
            (error) => {
              console.log('Falle guradado de respuesta celular' + error);
            }
          );
      }
    });
  }

  async validatOtp() {
    this.isLoading = true;

    try {
      if (this.intentosContinuar != 0) {
        return;
      }
      this.intentosContinuar = 1;

      let otp = {
        carpeta: this.carpeta.toString(),
        celular: this.cellphone.toString(),
        codigo: this.code,
      };
      const response = await this.getinfoServ.getvalidateOtp(otp).toPromise();

      const isCorrect = response.data.status;

      if (!isCorrect) {
        await this.consultarIntentos();
      }

      if (this.validarOtp.intentosActuales === 0) {
        await this.rechazarCarpeta(this.carpeta.toString());
        this.carpetaRechazada('validación');
        return;
      }

      if (!isCorrect) {
        this.visibilidadMensajeError();
        this.intentosContinuar = 0;
      }

      if (isCorrect) {
        this.data.isResume = false;
        this.data.isNewEmail = false;
        this.data.isNewPhone = false;

        this.router.navigate(['formulario-perfilamiento']);
      }
    } catch (error) {
      console.log(error);
      alert('ocurrio un problema');
      this.intentosContinuar = 0;
    } finally {
      this.isLoading = false;
    }
  }

  alertaError() {
    Swal.fire({
      allowOutsideClick: false,
      imageUrl: '../../assets/icons/error.svg',
      title: 'Verificación no exitosa',
      text:
        'El código digitado es incorrecto. A continuación, puedes reenviar el código al solicitante nuevamente por medio de SMS al celular ' +
        this.cellphone +
        '.',
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Reenviar código',
      confirmButtonColor: '#10AEEF',
      cancelButtonText: 'Actualizar celular',
      cancelButtonColor: 'border: 1px solid #0CAEEF; background: #FFFFFF',
      footer: `<font color="#10AEEF">Intento ${this.enviarOtp.totalIntentos - this.enviarOtp.intentosActuales + 1
        } de ${this.enviarOtp.totalIntentos}</font>`,
      didOpen: () => {
        Swal.getCancelButton().disabled =
          this.validarOtp.totalIntentos - this.validarOtp.intentosActuales == 1;
        Swal.getCancelButton().setAttribute(
          'style',
          'color: #0FADEF;border: 1px solid #0CAEEF;background: #FFFFFF;'
        );
      },
    }).then(async (resultado) => {
      this.code = '';

      if (resultado.value) {
        await this.consultarIntentos();

        this.getinfoServ
          .sendOtpCode({
            carpeta: this.carpeta,
            celular: this.celular,
            ind: 1,
          })
          .subscribe(
            async () => {
              await this.consultarIntentos();
            },
            (error) => {
              console.log(error.error.message);
            }
          );
      } else if (resultado.dismiss === Swal.DismissReason.cancel) {
        this.router.navigate(['/modificarcelular']);
      }
    });
  }

  carpetaRechazada(tipo = '') {
    Swal.fire({
      allowOutsideClick: false,
      title: 'Advertencia',
      html: '<p id="texto"></p',
      showConfirmButton: true,
      confirmButtonText: 'Ir a inicio',
      confirmButtonColor: '#10AEEF',
      didOpen: () => {
        const texto = Swal.getHtmlContainer().querySelector('#texto');
        texto.innerHTML = `
          Se superó el número de intentos para validar el <b>código</b>. La carpeta ha sido rechazada.
        `;
      },
    }).then(async (resultado) => {
      if (resultado.value) {
        this.router.navigate(['home']);
      }
    });
  }

  iniciarTemporizadorBloqueo() {
    this.bloqueado = true;
    this.iniciarCronometro();

    setTimeout(() => {
      this.bloqueado = false;
    }, this.tiempoBloqueo);
  }

  iniciarCronometro() {
    this.intervalId = setInterval(() => {
      this.tiempoRestante = Math.max(0, this.tiempoRestante - 1);

      if (this.tiempoRestante === 0) {
        this.detenerCronometro();
      }
    }, 1000);
  }

  detenerCronometro() {
    clearInterval(this.intervalId);
    this.tiempoRestante = 60;
    this.data.isUpdateMobile = false;
  }

  async rechazarCarpeta(carpeta = '') {
    await this.cambioEstadoServ
      .cambioEstadocarpeta(
        Number(this.carpeta),
        '900000',
        'Rechazo por intentos'
      )
      .toPromise();

    await this.getinfoServ.rejectFolder({ carpeta }).toPromise();
  }

  visibilidadMensajeError() {
    this.errorValidacion = true;
    // setTimeout(() => {
    //   this.errorValidacion = false;
    // }, 5000);
  }
}
