import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(texto:string): string {
    texto = texto.toLowerCase();
    texto = texto.charAt(0).toUpperCase() + texto.slice(1);
    return texto;
  }
}
