import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { map } from 'rxjs/operators';

import { MotorService } from 'src/app/services/perfilamiento/motor.service';
import { DataService } from '../../services/data.service';

import { DialogImportComponent } from './dialog-import/dialog-import.component';

export interface Motor {
  nombrePolitica: string;
  restriccion: string;
}

@Component({
  selector: 'app-rob-analisis',
  templateUrl: './rob-analisis.component.html',
  styleUrls: ['./rob-analisis.component.scss'],
})
export class RobAnalisisComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name'];
  dataSource: any;
  numeroCarpeta: string = '';
  numeroSolicitud: string = '';
  infoSolicitud: any;

  constructor(
    public dialog: MatDialog,
    private motorService: MotorService,
    private router: Router,
    public data: DataService,
    private activatedRoute: ActivatedRoute
  ) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogImportComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    let state = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    state.subscribe((data: any) => {
      if (this.data.numeroCarpeta) {
        this.numeroSolicitud = this.data.numeroSolicitud;
        this.infoSolicitud = this.data.infoSolicitud;
        this.numeroCarpeta = this.data.numeroCarpeta;
      }
    });

    this.getMotorDecision(this.data.numeroCarpeta);
  }

  getMotorDecision(numeroCarpeta) {
    this.motorService.getMotor(numeroCarpeta).subscribe((res) => {
      this.dataSource = res.rows;
    });
  }

  continuar() {
    this.router.navigateByUrl('/pre-oferta', {
      state: {
        numeroSolicitud: this.data.numeroSolicitud,
        infoSolicitud: this.data.infoSolicitud,
        numeroCarpeta: this.data.numeroCarpeta,
      },
    });
  }
}
