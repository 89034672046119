import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  @Output() cerrarSesion = new EventEmitter<any>();

  cerrar(){
    this.cerrarSesion.emit()
  }

}
