import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-load-file-card',
  templateUrl: './load-file-card.component.html',
  styleUrls: ['./load-file-card.component.css']
})
export class LoadFileCardComponent implements OnInit {

  @Output() eliminarArchivo = new EventEmitter<void>();
  @Input() loading: boolean = false;            // Estado de carga
  @Input() customClass: string = '';            // Clases personalizadas
  @Input() fileName: string = '';
  @Input() fileSize: string = '';

  progress: number = 0

  ngOnInit() {
    let start = 0;
    const timer = setInterval(() => {
      start += 5;
      this.progress = Math.min(start, 100); // Asegura que no supere 100
      if (start >= 100) {
        this.loading = false
        clearInterval(timer);
      }
    }, 100);
  }

  eliminar() {
    this.customClass = 'salir'
    setTimeout(() => {
      this.eliminarArchivo.emit();
    }, 500);
  }

}
