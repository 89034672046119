<div class="cont">
    <h1>Notificaciones</h1>
</div>

<div class="p-4">
    <h2>Todas las notificaciones</h2>

    <mat-drawer-container class="container-p">
        <mat-drawer class="sides" mode="side" #listdrawer2 opened>
            <div style="position: relative;">
                <div class="search">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar notificaciones"
                            [(ngModel)]="buscar" (ngModelChange)="detectChange()" aria-label="Amount (to the nearest dollar)">
                        <div class="input-group-append">
                            <button mat-button (click)="buscarNotificacion()">
                                <img src="assets/img/loupe.svg" height="25px">
                            </button>
                        </div>
                    </div>
                </div>
                <div class="list-container">
                    <div *ngFor="let item of notificaciones let i = index" [class]="i==itemColor ? 'notification' : ''">
                        <app-notificacion 
                            (click)="openNotificacion(item.numeroCarpeta,i, item.descripcion)"
                            [mensaje]="item.mensaje"
                            [fecha]="item.fechaInserta"
                            [cedula]="item.identificacion"
                            [nombre]="item.nombreCliente"
                            [carpeta]="item.numeroCarpeta"
                        >
                        </app-notificacion>
                    </div>
                </div>

            </div>



        </mat-drawer>
        <mat-drawer-content>
            <div class="message-container">
                <div class="text-message">
                    <app-simple-message
                        (datosCapturados)="pdfs = $event"
                        (regresar)="regresar($event)"
                        [nombreCliente]="notificacion.nombreCliente"
                        [numeroCarpeta]="notificacion.numeroCarpeta"
                        [mensaje]="notificacion.descripcion"
                        [cedula]="notificacion.identificacion"
                    >
                    </app-simple-message>
                </div>

                <div class="foot">
                    <app-send
                        (isDeleted)="recargando($event)"
                        [listaPDF]="pdfs"
                        [notificacion]="notificacion">
                    </app-send>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>

    <div class="tb-not-container">
        <div style="position: relative;">
            <div class="search">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Buscar notificaciones"
                        aria-label="Amount (to the nearest dollar)">
                    <div class="input-group-append">
                        <button mat-button>
                            <img src="assets/img/loupe.svg" height="25px">
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <div id="accordion">
                <div class="card" *ngFor="let item of [1,2,3,4] let i=index">
                    <div class="card-header"  [id]="'heading'+i" data-toggle="collapse" [attr.data-target]="'#notificacion'+i" >
                       <div [class]="i==0 ? 'notification' : ''">
<app-notificacion></app-notificacion>
                       </div>

                    </div>

                    <div [id]="'notificacion'+i" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                        <div class="card-body">
                           <app-simple-message></app-simple-message>
                           <app-send></app-send>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
