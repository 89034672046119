<app-login-header-mb>
  <div menuResponsive class="sesion" routerLink="/iniciar-sesion">
    <span style="color: var(--p-blue);" class="material-icons top-money">person</span>
    <span style="width: 90%; color: var(--p-blue); line-height: 15px; font-size: 18px; text-align: center;"
      >Iniciar
      sesión</span
    >
  </div>
</app-login-header-mb>
<app-header-login-tb>
  <div menuResponsive class="sesion-tb" routerLink="/crear-cuenta">
    <span class="material-icons s-30">person</span>
    <span class="nuevo-credito-desc">Iniciar<br />Sesión</span>
  </div>
</app-header-login-tb>
<div class="content">
  <div class="cont-form">
    <h1>Cambiar contraseña</h1>
    <p class="sub">
      Ingresa tu nueva contraseña. Recuerda que debe cumplir con los requisitos que mencionamos más abajo.
    </p>
    <form [formGroup]="changePasswordForm">
      <h5></h5>
      <div class="d-flex flex-column">
        <mat-label><strong> Ingresa la contraseña que te enviamos </strong></mat-label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="actual"
            maxlength="33"
            [errorStateMatcher]="matcher"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error *ngIf="changePasswordForm.controls.actual.hasError('required')">
            El password es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.actual.hasError('pattern')">
            El password no coincide con el <strong>patrón</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.actual.hasError('minlength')">
            Minimo 8 caracteres
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.actual.hasError('maxlength')">
            Maximo 33 caracteres
          </mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column">
        <mat-label><strong> Nueva contraseña </strong></mat-label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="nueva"
            maxlength="33"
            [errorStateMatcher]="matcher"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error *ngIf="changePasswordForm.controls.nueva.hasError('required')">
            El password es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column">
        <mat-label><strong> Contraseña actual </strong></mat-label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="confirmar"
            maxlength="33"
            [errorStateMatcher]="matcher"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error *ngIf="changePasswordForm.controls.confirmar.hasError('required')">
            El password es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.confirmar.hasError('pattern')">
            El password no coincide con el <strong>patrón</strong>
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.confirmar.hasError('minlength')">
            Minimo 8 caracteres
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls.confirmar.hasError('maxlength')">
            Maximo 33 caracteres
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <p></p>

    <div class="validaciones">

      <div class="indicador-box">
        <div class="indicador" [ngClass]="changePasswordForm.controls['nueva'].hasError('required') || changePasswordForm.controls['nueva'].hasError('minlength')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 8 caractéres</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="changePasswordForm.controls['nueva'].hasError('required') || changePasswordForm.controls['nueva'].hasError('hasCapitalCase')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 mayúscula</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="changePasswordForm.controls['nueva'].hasError('required') || changePasswordForm.controls['nueva'].hasError('hasSmallCase')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 minúscula</span>
      </div>

      <div class="indicador-box">
        <div class="indicador" [ngClass]="changePasswordForm.controls['nueva'].hasError('required') || changePasswordForm.controls['nueva'].hasError('hasSpecialCharacters')  ? 'indicador' : 'indicador-selected'">
          <span class="material-icons">done</span>
        </div>
        <span class="indicador-label">Al menos 1 caractér especial (+*%$_)</span>
      </div>

      <div *ngIf="changePasswordForm.invalid && showError" class="create-account-error animate__animated animate__fadeIn">
        <p class="error">La contraseña ingresada no cumple con los requisitos necesarios. Revisa nuevamente</p>
      </div>

    </div>


    <!--<button type="button" class="btn btn-primary" (click)="loginCallback()">Recuperar contraseña</button>-->
    <button type="button" class="btn btn-primary" (click)="actRc()">Cambiar contraseña</button>

    <!--<button class="g-recaptcha"
        data-sitekey="6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP"
        data-callback='onSubmit'
        data-action='submit'>Submit</button>-->
  </div>
</div>
