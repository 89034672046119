<app-main-container header="Validación de identidad" subheader="Actualización de información del solicitante">
  <div class="contenido" style="padding-bottom: 1rem;">
    <p>
      Solicita la siguiente información para continuar proceso de autorización de tratamiento de datos y validación de
      identidad.
    </p>
    <form class="form" [formGroup]="formInfoCorreo">
      <div class="row">
        <div class="col-md-6 form-field">
          <label for="correo" class="form-label">Correo electrónico</label>
          <input type="text" inputmode="email" class="form-control" placeholder="*Correo electrónico" id="correo"
            formControlName="correo" autocomplete="off">
          <div class="form-error" *ngIf="formInfoCorreo.controls.correo.hasError('required') && !formInfoCorreo.controls.correo.pristine">
            El correo electrónico es obligatorio
          </div>
          <div class="form-error" *ngIf="formInfoCorreo.controls.correo.hasError('pattern')">
            El formato de correo electrónico es incorrecto
          </div>
        </div>
        <div class="col-md-6 form-field">
          <label for="confirmarCorreo" class="form-label">Confirmar correo electrónico</label>
          <input type="text" inputmode="email" class="form-control" placeholder="*Confirmar correo electrónico" id="confirmarCorreo"
            formControlName="confirmarCorreo" autocomplete="off">
          <div class="form-error" *ngIf="formInfoCorreo.controls.confirmarCorreo.hasError('emailsNoCoinciden') && !formInfoCorreo.controls.confirmarCorreo.pristine">
            No coinciden los correos ingresados
          </div>
          <div class="form-error" *ngIf="formInfoCorreo.controls.confirmarCorreo.hasError('required') && !formInfoCorreo.controls.confirmarCorreo.pristine">
            La confirmacion del correo electrónico es obligatoria
          </div>
        </div>
      </div>
    </form>
    <br>
    <p>
      Al ingresar los datos personales del solicitante, como asesor/corredor declaras que cuentas con la autorización
      para
      transferir estos datos personales a Bayport y cuentas con los consentimientos necesarios para realizar el
      tratamiento de datos personales, en calidad de responsable de acuerdo con las obligaciones establecidas para dicho
      rol por la <b>Ley 1581 de 2012</b> y sus decretos reglamentarios.
    </p>
    <button-look-feel color="secondary" [loading]="loading" (clicked)="confirmacion()"
      [disabled]="!formInfoCorreo.valid">Guardar</button-look-feel>
  </div>
