import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comfir-model',
  templateUrl: './comfir-model.component.html',
  styleUrls: ['./comfir-model.component.css']
})
export class ComfirModelComponent implements OnInit {

  constructor(
    private dialogRef:MatDialogRef<ComfirModelComponent>
  ) { }

  ngOnInit(): void {
  }

  confirmarEnvio(){
    const envio = {
      ok:true
    }

    this.dialogRef.close(envio);
  }

  cerrarModal(){
    this.dialogRef.close();
  }

}
