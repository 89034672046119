<div class="alert-container dialog-change-password">
  <h2 mat-dialog-title>
    Contraseña enviada
  </h2>
  <mat-dialog-content class="mat-typography">
    <p>En tu correo recibirás la información con la <strong>solicitud de cambio de contraseña</strong></p>
  </mat-dialog-content>
  <button
    style="width: 100%;"
    class="cerrar"
    mat-flat-button
    mat-dialog-close
  >Aceptar</button>
</div>
