import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, map } from 'rxjs/operators';
import { Identidad } from 'src/app/models/identidad';
import { Otp } from 'src/app/models/Otp';
import { CryptoService } from 'src/app/services/crypto.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { AceptacionCentralesService } from 'src/app/services/preOferta/aceptacion-centrales.service';
import { EdoClienteService } from 'src/app/services/preOferta/edo-cliente.service';
import { EvidenteOtpExpService } from 'src/app/services/preOferta/evidente-otp-exp.service';
import { NotificacionSmsService } from 'src/app/services/preOferta/notificacion-sms.service';
import { DataService } from '../../../services/data.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';

@Component({
  selector: 'app-autorizacioncentralriesgo',
  templateUrl: './autorizacioncentralriesgo.component.html',
  styleUrls: ['./autorizacioncentralriesgo.component.css'],
})
export class AutorizacioncentralriesgoComponent implements OnInit {
  @ViewChild('espera', { static: true }) wait: any;
  public seccion: string = 'terminos';
  private estadoCliente: number = 0;
  public codigoOtp: string = '';
  private idTransaccionOTP: string = '';
  public loading: boolean = false;
  // private informacion: any = {};
  private url: string = '';
  private numIntentos: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogServ: DialogService,
    private evidenteOtpServ: EvidenteOtpExpService,
    private enviosmsSrvc: NotificacionSmsService,
    private cryptoServ: CryptoService,
    private edoClienreServ: EdoClienteService,
    private aceptacentralessrv: AceptacionCentralesService,
    private cambioEstadoServ: CambioestadocarpetaService,
    public data: DataService,
    private getinfoServ: GetInformacionService,
    private evidentServ: EvidenteOtpExpService
  ) { }

  ngOnInit(): void {


    // this.informacion = {
    //   cedula: this.data.cedula,
    //   cellphone: this.data.cellphone,
    //   regValidacion: this.data.regValidacion,
    //   numeroCarpeta: this.data.numeroCarpeta,
    //   idTransaction: this.idTransaccionOTP
    // };
    this.edoClienreServ.getEdoCliente(this.data.cedula).subscribe((res) => {
      console.log("::", this.data.cedula);
      this.estadoCliente = res.activo;
    });
  }

  onCodeCompleted(code: string) {
    this.codigoOtp = code;
  }

  continuar(event) {
    if (!this.loading) {
      this.loading = true;
      this.aceptacentralessrv.aceptarConsulta(parseInt(this.data.numeroCarpeta), 1)
        .subscribe(
          (res) => {
            // console.log(res)
            if (res.status == 200) {
              //this.OtpInit();
              this.validarIdentidad()
            } else {
              this.loading = false;
              this.dialogServ.openDialog('Advertencia', res.message, false);
            }
          },
          (err) => {
            this.loading = false;
            this.dialogServ.openDialog('Advertencia', err.error?.message[0].msg, false);
          }
        );
    }

  }

  OtpInit() {
    this.loading = false;
    if (this.data.caminoOtp == 'mostrarPreguntas') {
      this.moverPreguntas()
    } else if (this.data.caminoOtp == 'mostrarOtp') {
      this.seccion = 'validacion';
    }
  }

  public validarOtp() {
    let otp: Otp = {
      cedula: this.data.cedula,
      cellphone: this.data.cellphone,
      code: this.codigoOtp,
      idTransaccionOTP: this.data.idTransaccionOTP,
      regValidacion: this.data.regValidacion,
    };
    this.evidenteOtpServ.validarOtp(otp).subscribe(
      (res) => {
        if (!res.continue && res.next == 'end' && res.info == 'validation-completed') {
          this.cambioEstadoServ
            .cambioEstadocarpeta(
              Number(this.data.numeroCarpeta),
              '30',
              'Cambio de estado aprobado por el Asesor'
            )
            .subscribe((res) => { });
          this.router.navigateByUrl('/formulario-perfilamiento');
          return
        }

        if (res.continue && res.next == 'questions') {
          this.router.navigateByUrl('/preguntasSeguridad');
          return
        }

        if (!res.continue && res.next == 'end' && res.info == 'restart-process') {
          this.router.navigateByUrl('/datoscliente');
          return;
        }

        this.router.navigateByUrl('/formatoAcr')

      },
      (err) => {
        this.router.navigateByUrl('/formatoAcr')
        //ir a preguntas y añadir parametros
      }
    );
  }

  moverPreguntas() {
    this.router.navigateByUrl('/preguntasSeguridad', {
      state: {
        cedula: this.data.cedula,
        regValidacion: this.data.regValidacion,
        cellphone: this.data.cellphone,
        numeroSolicitud: this.data.numeroSolicitud,
        numeroCarpeta: this.data.numeroCarpeta,
      },
    });
  }

  async validarIdentidad() {
    this.loading = true;
    let numCelular = localStorage.getItem('celular');

    const response = await this.getinfoServ.getPhone(this.data.numeroCarpeta.toString()).toPromise()

    if (response.error) {
      console.log(response.error)
      this.dialogServ.openDialog('Advertencia', response.error.message, false);
    }

    if (response || typeof response.data !== 'undefined') {
      console.log('Get info phone', response);
      localStorage.setItem('celular', response.data.phone)
      numCelular = localStorage.getItem('celular');
    }


    this.getinfoServ.getInfoPersonal(this.data.numeroSolicitud + "")
      .subscribe((res) => {
        console.log('Get info personal', res)

        //await this.delay(3000);

        let identidad: Identidad;
        this.data.cellphone = numCelular;
        console.log(this.data.cellphone);
        console.log(localStorage.getItem('celular'))
        console.log(numCelular)
        let f = res.fechaExpedicion.split('-');
        let fecha = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]));
        let nombre = res.primerNombre + ' ';
        identidad = {
          apellido: this.data.apellido,
          cedula: this.data.cedula,
          fechaExpedicion: fecha.getTime() / 1000,
          nombres: res.segundoNombre ? nombre + res.segundoNombre : nombre
        };

        // if(!res.celular){
        //   this.data.cellphone = numCelular;
        //   let f = res.fechaExpedicion.split('-');
        //   let fecha = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]));
        //   let nombre = res.primerNombre + ' ';

        //   var identidad: Identidad = {
        //     apellido: this.data.apellido,
        //     cedula: this.data.cedula,
        //     fechaExpedicion: fecha.getTime() / 1000,
        //     nombres: res.segundoNombre ? nombre + res.segundoNombre : nombre,
        //     flujo:'only-questions'
        //   };
        // }else{
        //   this.data.cellphone = res.celular;
        //   let f = res.fechaExpedicion.split('-');
        //   let fecha = new Date(parseInt(f[0]), parseInt(f[1]) - 1, parseInt(f[2]));
        //   let nombre = res.primerNombre + ' ';

        //    var identidad: Identidad = {
        //     apellido: this.data.apellido,
        //     cedula: this.data.cedula,
        //     fechaExpedicion: fecha.getTime() / 1000,
        //     nombres: res.segundoNombre ? nombre + res.segundoNombre : nombre
        //   };
        // }

        this.evidentServ.validarIdentidad(identidad).subscribe(res => {
          console.log("::Validacion identidad", res);
          this.cambioEstadoServ.cambioEstadocarpeta(Number(this.data.numeroCarpeta), '20', 'Cambio de estado aprobado por el Asesor').subscribe(res => {
            console.log(res)
          })

          if (res.regValidacion) {
            this.data.regValidacion = res.regValidacion;
            if (res.next == 'otp') {
              console.log('Dentro OTP, data service ->', this.data);
              this.evidentServ.SolicitarOtp(this.data.cedula, this.data.regValidacion, this.data.cellphone).subscribe(res => {
                if (res.next) {
                  console.log('Resultado de OTP Init', res)
                  switch (res.next) {
                    case 'questions':
                      this.data.caminoOtp = "mostrarPreguntas";
                      this.OtpInit()
                      break;
                    case 'otp-validate':
                      this.data.caminoOtp = "mostrarOtp";
                      this.data.idTransaccionOTP = res.idTransaccionOTP;
                      this.OtpInit()
                      break;
                    default:
                      this.router.navigateByUrl('/formatoAcr')
                      break;
                  }
                } else {
                  this.loading = false;
                  this.dialogServ.openDialog('Advertencia', 'Ocurrió un error inesperado al procesar la solicitud, por favor inténtalo de nuevo.', false);
                }
              }, err => {
                this.loading = false;
                this.dialogServ.openDialog('Advertencia', err.error.message, false);
              });
            } else if (res.next === 'questions') {
              this.data.caminoOtp = "mostrarPreguntas";
              this.OtpInit();
            } else {
              this.router.navigateByUrl('/formatoAcr')
              return
            }
          } else {
            this.loading = false;
            this.dialogServ.openDialog('Advertencia', res.error, false)
            this.router.navigateByUrl('/formatoAcr')
          }
        }, err => {
          this.loading = false;
          this.dialogServ.openDialog('Advertencia', err.error.message, false);
        });
      }, err => {
        this.dialogServ.openDialog('Advertencia', err.error.message, false);
        this.loading = false;
      })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
