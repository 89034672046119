import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdicionalesService {

  private endpoint = environment.ApiConecta + 'parametros/';

  constructor(private http: HttpClient) {}

  getEstadoCivil(): Observable<any> {
    return this.http.get(this.endpoint + 'estado-civil');
  }

  getPaises(): Observable<any> {
    return this.http.get(this.endpoint + 'paises');
  }

  getCentroPoblado(): Observable<any> {
    return this.http.get(this.endpoint + 'centro-poblado');
  }

  getDepartamentos(): Observable<any> {
    return this.http.get(this.endpoint + 'departamentos');
  }

  getCiudades(codigDepto: number): Observable<any> {
    return this.http.get(`${this.endpoint}ciudades?codDepto=${codigDepto}`);
  }

  getAllCiudades(): Observable<any> {
    return this.http.get(`${this.endpoint}ciudades`);
  }

  getCuidadesPorLike(nombreCuidad): Observable<any> {
    return this.http.get(`${this.endpoint}ciudades?nombreCiudad=${nombreCuidad}`);
  }

  getSucursales(): Observable<any> {
    return this.http.get(this.endpoint + 'sucursales');
  }

  getPensiones(): Observable<any> {
    return this.http.get(this.endpoint + 'pensiones');
  }

  getMonedas(): Observable<any> {
    return this.http.get(this.endpoint + 'monedas');
  }

  getProfesiones(): Observable<any> {
    return this.http.get(this.endpoint + 'profesion');
  }

  getBancos(): Observable<any> {
    return this.http.get(this.endpoint + 'bancos');
  }

  getEps(): Observable<any> {
    return this.http.get(this.endpoint + 'eps');
  }

  getArp(): Observable<any> {
    return this.http.get(this.endpoint + 'arp');
  }

  getFuerza(): Observable<any> {
    return this.http.get(this.endpoint + 'grado/fuerza');
  }

  getTipoNomina(): Observable<any> {
    return this.http.get(this.endpoint + 'tipo-nomina');
  }

  getTipoPension(): Observable<any> {
    return this.http.get(environment.ApiConecta + 'parametros/tipo-pension');
  }

  getCagen(): Observable<any> {
    return this.http.get(this.endpoint + 'tipo-pension/cagen');
  }

  getOrigenFondos(): Observable<any> {
    return this.http.get(this.endpoint + 'origen-fondos');
  }

  getTipoDesembolso(): Observable<any> {
    return this.http.get(environment.ApiConecta + 'cartera/tipo-desembolso');
  }

  getTipoCuenta(): Observable<any> {
    return this.http.get(environment.ApiConecta + 'parametros/tipo-cuenta');
  }

  getDestinoCredito(folder: string): Observable<any> {
    return this.http.get(environment.ApiConecta + 'cartera/destino-credito/' + folder);
  }

  getTipoVivienda(): Observable<any> {
    return this.http.get(this.endpoint + 'tipo-vivienda');
  }

}
