<div class="tabs-component">
    <div #tabsContainer class="container mt-5">
      <div id="tabsN">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" *ngFor="let tab of tabs; let i = index">
            <button class="nav-link" [ngClass]="{ active: i === activeTab }" (click)="selectTab(i)"
              [attr.aria-selected]="i === activeTab ? 'true' : 'false'">
              {{ tab.title }}
            </button>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div class="tab-pane fade" [ngClass]="{ 'show active': i === activeTab }" *ngFor="let tab of tabs; let i = index">
          <ng-container *ngTemplateOutlet="tab.content"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <br><br>