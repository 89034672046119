<main class="bg-login w-screen h-screen relative overflow-auto">
  <figure class="icon-clic hidden md:block">
    <img src="../../../assets/img/logo.png" alt="logo bayport" class="w-3rem" />
  </figure>
  <article class="w-full h-full absolute top-0 left-0 grid">
    <div class="relative bg-blue-gradient m-auto w-11 sm:w-5 lg:w-4 p-5 sm:border-1 border-round border-sky-200" style="background: transparent linear-gradient(180deg, var(--unnamed-color-00405f) 0%, var(--unnamed-color-0f113e) 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid var(--unnamed-color-81daff);
    background: transparent linear-gradient(180deg, #00405F 0%, #0F113E 100%) 0% 0% no-repeat padding-box;
    border: 0.5px solid #81DAFF;
    border-radius: 4px;">
      <figure class="md:hidden icon-clic-2">
        <img src="../../../assets/img/logo-2.svg" alt="logo bayport" class="w-4rem mb-2" />
      </figure>
      <div class="cont-form">
        <!-- <div class="row"> -->
        <div class="col-sm-12 col-md-12 col-lg-12">
          <form [formGroup]="recuperacionForm">
            <h2 class="font-omnes-semibold mb-2 font-bold text-xl text-white">Contraseña caducada</h2>
            <p class="mb-4 text-sm text-white font-omnes-medium" style="font-size: 16px !important;">
              Tu contraseña actual ha caducado. Por favor, registra una nueva contraseña
            </p>
            <div class="row">
              <div class="col-md-12">
                <div class="font-bold text-white">
                  <label>Contraseña actual</label>
                  <p-password formControlName="actual" type="text" placeholder="*contraseña" required
                    [toggleMask]="true" [feedback]="false" inputStyleClass="max-w-none w-full"
                    styleClass="max-w-none w-full"></p-password>
                  <!-- <mat-error *ngIf="recuperacionForm.controls.actual.hasError('required')">
                    La contraseña actual es <strong>requerida</strong>
                  </mat-error> -->
                  <!-- </mat-form-field> -->
                </div>
                <br>
              </div>

              <hr style="color: #A7AAF5;">
              <div class="col-md-12">
                <div class="font-bold text-white nueva">
                  <label>Nueva contraseña</label>
                  <p-password formControlName="nueva" type="text" placeholder="*contraseña" required [toggleMask]="true"
                    [feedback]="false" inputStyleClass="max-w-none w-full" styleClass="max-w-none w-full"
                    (ngModelChange)="showValid($event)"></p-password>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="mb-2 block font-omnes-medium" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('minlength') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg> &nbsp; -->
                        Mínimo 8 caracteres
                      </span>
                    </div>
                    <div class="col-md-6">
                      <span class="mb-2 block font-omnes-medium" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notMayus') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 letra mayúscula
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="mb-2 block font-omnes-medium" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notMinus') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 letra minúscula
                      </span>
                    </div>
                    <div class="col-md-6">
                      <span class="mb-2 block font-omnes-medium" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notNumber') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 número
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="mb-2 block font-omnes-medium" *ngIf="passwordnew.value"
                        [class]="passwordnew.hasError('notSpecialCharacter') ? 'text-gray-400' : 'text-lime'">
                        <img src="./../../../../../assets/icons/Validation.svg"> &nbsp;
                        Mínimo 1 un caracter especial (+*%$)
                      </span>
                    </div>
                  </div>
                  <!-- <mat-form-field class="example-full-width amb-28" appearance="outline">
                    <input matInput [type]="showPassword ? 'text':'password'" class="w-100" name="nueva"
                      formControlName="nueva" maxlength="15" (ngModelChange)="showValid($event)" />
                    <mat-icon matSuffix (click)="showPassword = !showPassword">{{
                      showPassword ? "visibility" : "visibility_off"
                      }}</mat-icon>
                    <mat-error *ngIf="recuperacionForm.controls.nueva.hasError('required')">
                      La contraseña es <strong>requerida</strong>
                    </mat-error>
                  </mat-form-field> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="font-bold text-white">
                  <label>Confirmar contraseña</label>
                  <p-password formControlName="confirmar" type="text" placeholder="*contraseña" required
                  [toggleMask]="true" [feedback]="false" inputStyleClass="max-w-none w-full"
                  styleClass="max-w-none w-full" #txtQuery (keyup)="changeComfirmar(txtQuery.value)"></p-password>

                  <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput [type]="showConfirmar ? 'text':'password'" class="w-100" name="confirmar"
                      formControlName="confirmar" maxlength="15" #txtQuery (keyup)="changeComfirmar(txtQuery.value)">

                    <mat-icon matSuffix (click)="showConfirmar = !showConfirmar">{{
                      showConfirmar ? "visibility" : "visibility_off"
                      }}</mat-icon>

                    <mat-error *ngIf="recuperacionForm.controls.confirmar.hasError('required')">
                      Confirmar contraseña es <strong>requerida</strong>
                    </mat-error>
                  </mat-form-field> -->
                </div>
              </div>
            </div><br>



            <!-- <div class="aw-validaciones">
              <div class="validaciones" *ngIf="showV">
                <div class="col-md-6">
                  <div class="indicador-box">
                    <div class="indicador"
                      [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('minlength')  ? 'indicador' : 'indicador-selected'">
                      <span class="material-icons afontsize-16">done</span>
                    </div>
                    <span class="indicador-label">Al menos 8 caractéres</span>
                  </div>

                  <div class="indicador-box">
                    <div class="indicador"
                      [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasCapitalCase')  ? 'indicador' : 'indicador-selected'">
                      <span class="material-icons afontsize-16">done</span>
                    </div>
                    <span class="indicador-label">Al menos 1 mayúscula</span>
                  </div>

                  <div class="indicador-box" style="margin-right: 32px;">
                    <div class="indicador"
                      [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasNumber')  ? 'indicador' : 'indicador-selected'">
                      <span class="material-icons afontsize-16">done</span>
                    </div>
                    <span class="indicador-label">Al menos 1 número</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="indicador-box amr-4">
                    <div class="indicador"
                      [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasSmallCase')  ? 'indicador' : 'indicador-selected'">
                      <span class="material-icons afontsize-16">done</span>
                    </div>
                    <span class="indicador-label">Al menos 1 minúscula</span>
                  </div>

                  <div class="indicador-box amb-15">
                    <div class="indicador"
                      [ngClass]="recuperacionForm.controls['nueva'].hasError('required') || recuperacionForm.controls['nueva'].hasError('hasSpecialCharacters')  ? 'indicador' : 'indicador-selected'">
                      <span class="material-icons afontsize-16">done</span>
                    </div>
                    <span class="indicador-label">Al menos 1 caractér especial (+*%$_)</span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div *ngIf="recuperacionForm.invalid && showError"
                    class="create-account-error animate__animated animate__fadeIn">
                    <p class="error">La contraseña ingresada no cumple con los requisitos necesarios. Revisa nuevamente
                    </p>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="d-flex justify-content-center">
              <button type="submit" [disabled]="!recuperacionForm.valid" id="recuperar" class="btn btn-primary w-100"
                (click)="changePassword()"
                [ngClass]="recuperacionForm.valid ? 'btn-primary:disabled' : 'btn-primary:active'">
                Cambiar contraseña
              </button>
            </div>
          </form>
        </div>
        <!-- </div> -->

      </div>
    </div>
  </article>
</main>