<div class="header-container mt-3 me-3" [ngClass]="[customClass]">
  <div class="menu-container p-1 p-md-2">
    <div class="outline fw-semibold me-1 me-md-2 d-flex credito" routerLink="/autorizacionTransferencia">
      <div class="top-scroll d-none d-md-block" style="padding-top: 2px;">Nuevo crédito</div>
      <div class="scrolled d-block d-md-none">
        <i aria-hidden="true" class="fa fa-usd" style="transform: translateX(-5px);"></i>
      </div>
    </div>
    <div class="outline me-1 me-md-2 d-flex p-0" [ngClass]="[color]" routerLink="/notificaciones">
      <div class="top-scroll description d-none d-md-flex">
        <div class="title fw-semibold">{{title}} |</div>
        <span class="marquee">{{text}}</span>
      </div>
      <div class="notifications">
        <i aria-hidden="true" class="fa fa-bell"></i>
      </div>
    </div>
    <div class="outline fw-semibold d-flex d-none d-md-block" (click)="showProfileMenu = !showProfileMenu">
      <i aria-hidden="true" class="fa fa-user" style="transform: translateX(-7px);"></i>
    </div>
    <div class="outline fw-semibold d-flex d-md-none" (click)="toogleMenuMobile()">
      <i aria-hidden="true" class="fa fa-bars" style="transform: translateX(-7px);"></i>
    </div>
  </div>
  <div class="profile-container p-2 mt-2 me-3 d-none d-md-block" *ngIf="showProfileMenu" @fadeOut @fadeIn>
    <h4 class="fw-semibold text-center profile-title mb-2 text-capitalize">¡Hola,&nbsp; {{ user | titlecase }} !</h4>
    <div class="d-flex w-100">
      <div class="outline profile rounded-end-0" routerLink="/mi-perfil" style="padding-top: 5px;">
        Mi perfil
      </div>
      <div class="outline logout rounded-start-0 border-start-0" (click)="cerrarSesion()">
        <i aria-hidden="true" class="fa fa-sign-out"></i>
        <span class="ms-3">Cerrar sesión</span>
      </div>
    </div>
  </div>
</div>
