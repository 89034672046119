import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss']
})
export class CopyLinkComponent {

  @Input() titulo!: string;
  @Input() mensaje!: string;
  @Input() url: string = '';

  public alertConfig: { color: 'success' | 'warning' | 'info', mensaje: string } = {
    color: 'success',
    mensaje: ''
  }

  constructor(public activeModal: NgbActiveModal) { }

  copied = false;

  copyToClipboard() {
    this.copied = !this.copied;
    navigator.clipboard.writeText(this.url)
      .then(() => {
        this.alertConfig.color = "success"
        this.alertConfig.mensaje = "Vínculo copiado"
      })
      .catch(err => {
        this.alertConfig.color = "warning"
        this.alertConfig.mensaje = "no se pudo copiar el vinculo"
      });
  }

  cerrar() {
    this.activeModal.close();
  }
}
