import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hyperlink-icon',
  templateUrl: './hyperlink-icon.component.html',
  styleUrls: ['./hyperlink-icon.component.css']
})
export class HyperlinkIconComponent {
  // Evento de clic
  @Output() clicked = new EventEmitter<void>();
  @Input() icon: 'none' | 'delete' | 'arrow' | 'plus' = 'none'; // Por defecto: ninguno
  @Input() iconPosition: 'none' | 'start' | 'end' = 'none';
  @Input() disabled: boolean = false;            // Estado de carga
  @Input() customClass: string = '';            // Clases personalizadas

  onClick() {
    if (!this.disabled) {
      this.clicked.emit(); // Emitir evento si no está deshabilitado
    }
  }
}

