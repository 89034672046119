import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { MatDialog } from '@angular/material/dialog';

import jspdf from 'jspdf';

import { DataService } from '../../../services/data.service';
import { UploadService } from 'src/app/services/cargaArchivos/upload.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service'


import { CogQrComponent } from '../../carga-cedula/cog-qr/cog-qr.component';
import { CameraComponent } from './components/camera/camera.component';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';

@Component({
  selector: 'app-formato-acr',
  templateUrl: './formato-acr.component.html',
  styleUrls: ['./formato-acr.component.scss'],
})
export class FormatoAcrComponent {

  private selectedFiles: any;
  private fileType: string = '';
  public loading = false;
  private documentoConvertido = null;
  public isLoaded = false;
  public isLoading = false;
  private docName: string='';

  constructor(
    public dialog: MatDialog,
    private docSolSrv: DocSolicitudService,
    public datepipe: DatePipe,
    private router: Router,
    private UploadService: UploadService,
    private cambioEstadoServ: CambioestadocarpetaService,
    private dataOfertaService: GenOfertaService,
    public data: DataService,
    private serviceCarga: ArchivoCargaService,
    private serviceDoc: DocSolicitudService
  ) { }

  ngOnInit(): void {
    // this.data.numeroCarpeta = "3595350";
    // this.data.numeroSolicitud = "114579";
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(CogQrComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public async selectFile(event: any, tipo: string): Promise<void> {
    this.selectedFiles = event.target.files;
    this.fileType = this.selectedFiles.item(0).name.split('.').pop();

    let file = event.target.files[0];

    if (this.fileType == 'pdf') {
      this.isLoading = true;
      this.getUrl();
    } else {
      this.convertirImagen(file);
    }
  }

  private getUrl(): void {
    let timestamp: string = this.datepipe.transform(new Date(), 'yyyyMMddHHmmss');
    let archivo = this.documentoConvertido ? this.documentoConvertido : this.selectedFiles[0];
    const carpeta = `${this.data.numeroCarpeta}`;
    const identificacion = 'certification';
    const fileName = `PORTAL-AGENTES-formatoAcr-${timestamp}.pdf`;
    this.docName=fileName;

    this.serviceCarga.cargandoArchivo(carpeta, identificacion, fileName).subscribe(
      respuesta => {
        let form = new FormData();
        Object.keys(respuesta.fields).forEach(key => form.append(key, respuesta.fields[key]));
        form.append('Content-Type', "application/pdf");
        form.append('file', archivo);
        fetch(respuesta.url, { method: 'POST', body: form }).then(res => {
          if (res.ok) {
            //carga de archivo
            this.serviceDoc.uploadAwsRoute(carpeta, respuesta.fields.key, 67).subscribe(
              result => {
                Swal.fire({
                  imageUrl: '../../../../assets/img/palomita.png',
                  text: "Archivo cargado correctamente",
                  confirmButtonColor: '#009BDC'
                });
                this.isLoading = false;
                this.isLoaded = true;
              }, error => {
                console.log(<any>error);
                Swal.fire({
                  title: "Opps !!",
                  text: "Ocurrio un problema al cargar el archivo",
                  icon: 'error',
                  confirmButtonColor: '#009BDC'
                });
                this.isLoading = false;
                this.isLoaded = false;
              }
            );
          } else {
            console.log(res);
            if (res.status === 400) {
              Swal.fire({
                title: "Opps !!",
                text: "El tamaño del archivo es demaciado grande, favor de reducir el tamaño o cargar otro !",
                icon: 'error',
                confirmButtonColor: '#009BDC'
              });

              this.isLoading = false;
              this.isLoaded = false;
            } else {
              Swal.fire({
                title: "Opps !!",
                text: "Error al cargar el archivo, intenta nuevamente por favor",
                icon: 'error',
                confirmButtonColor: '#009BDC'
              });
            }

            this.isLoading = false;
            this.isLoaded = false;
          }
        });
      }, error => {
        console.log("No hay respuesta del BUCKET");
        console.log(<any>error);
        this.isLoading = false;
      }
    );
  }

  public irFormulario(): void {
    this.isLoading = true;

    this.dataOfertaService.saveEvidente(this.data.numeroCarpeta.toString(), this.data.cedula.toString(), this.docName.toString()).subscribe((res) => {
    }, (err) => {
      console.log("fallo guardado Persona");
    }
    )

    this.cambioEstadoServ.cambioEstadocarpeta(
        Number(this.data.numeroCarpeta),
        '30',
        'Cambio de estado aprobado por el Asesor'
      ).subscribe((res) => {
        if (res.status == 200) {
          this.isLoading = false;
          this.router.navigateByUrl('/formulario-perfilamiento');
        } else {
          this.isLoading = false;
        }
      }, err => {
        alert(err.error.message);
        this.isLoading = false;
      });
  }

  private async convertirImagen(file: any) {
    let doc = new jspdf({ compress: true });


    let base64Image = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result.toString());
      fileReader.readAsDataURL(file);
    });

    doc.addImage(String(base64Image), 'JPEG', 10, 10, 180, 150);

    this.documentoConvertido = new File([doc.output('blob')], "pdf");
    this.getUrl();
  }

  public openCamera(): void {
    const diag = this.dialog.open(CameraComponent);

    diag.afterClosed().subscribe(async () => {
      if (this.data.base64 !== undefined) {
        const base64 = this.data.base64;
        const imageName = 'doc.jpeg';
        const imageBlob = this.dataURItoBlob(base64);
        const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

        await this.convertirImagen(imageFile);
        this.docName=imageName;        
      }

    });
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(btoa(dataURI));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: 'image/jpeg' });

    return blob;
  }

  public eliminar(): void {
    this.isLoaded = false;
    this.selectedFiles = null;
  }

}
