import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-container-resumen',
  templateUrl: './container-resumen.component.html',
  styleUrls: ['./container-resumen.component.scss']
})
export class ContainerResumenComponent {
  @Input() header?: string;
  @Input() subheader?: string;
}
