import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';

import { parseJwt } from '../token';

import { Subscription } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { IdleModalComponent } from '../modules/shared/components/idle-modal/idle-modal.component';

import { DialogService } from '../modules/shared/services/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.css'],
})
export class PwaComponent implements OnInit, OnDestroy {

  

  constructor( private router: Router) {


}

  ngOnInit(): void {
    const [, path, token] = location.pathname.split('/');

    if (path === 'redireccionamiento') {
      let credentials = {
        access_token: token,
        usuario: null,
      };

      localStorage.setItem('credentials', JSON.stringify(credentials));
      return;
    }

    const { chng } = parseJwt(localStorage.getItem('credentials'));

    if (location.pathname === '/home') {
      chng === '1'
        ? this.router.navigate(['cambio'])
        : this.router.navigate(['home']);
    }
  }

  ngOnDestroy(): void {

  }


}
