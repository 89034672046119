import { Component } from '@angular/core';

@Component({
  selector: 'app-confirmada',
  templateUrl: './confirmada.component.html',
  styleUrls: ['./confirmada.component.scss']
})
export class ConfirmadaComponent {

}
