import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FinancieraInfo } from '../../models/financieraInfo.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerfInfFinancieraService {

  private endpoint = environment.ApiConecta+'perfilamiento/informacion/financiera';

  constructor(
    private http:HttpClient
  ) { }

  savInfFin(financieraInfo:FinancieraInfo):Observable<any>{
    return this.http.post<FinancieraInfo>(this.endpoint,financieraInfo);
  }
}
