<!--app-header></app-header-->
<app-spinner *ngIf="loadSpinner"></app-spinner>
<div class="circle yellow"></div>
<div class="circle blue"></div>
<div class="row vh-100 justify-content-center align-items-center">
    <div class="col-md-12 cursos">
        <div class="titulo font-omnes-semibold">
            Tus cursos
        </div><br>
        <div class="subTitulo font-omnes-semibold">
            ¡Tu crecimiento profesional no se detiene!
        </div>
        <div class="texto font-omnes">
            Como asesor Bayport tienes acceso a contenidos educativos únicos para fortalecer tus competencias
            profesionales.
        </div>
        <button pButton class="font-omnes-semibold px-6 py-2 mt-4 text-gray-900 bg-sky-200 border-none continuarCur"
            (click)="onContinue()">
            ¡Comencemos!
        </button>
    </div>
</div>