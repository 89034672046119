<!--app-header></app-header-->
<mat-horizontal-stepper class="contenido">
  <mat-step label="Análisis de solicitud del crédito ">
    <h1><strong>Aceptación condiciones y firma digital </strong></h1>
    <p>
      Para continuar el proceso de solicitud, recuerdale a tu cliente tener en
      cuenta lo siguiente.
    </p>
    <div class="row mt-3">
      <div class="table-responsive">
        <h3 style="padding-bottom: 15px">¡Recomendaciones antes de empezar!</h3>
        <div class="indicaciones">
          <div class="item-indicacion">
            <div class="icon-item">
              <mat-icon>block</mat-icon>
            </div>
            <div class="content content-flex-align">
              <p>
                Debes tomar la foto sin lentes, sombreros o cualquier otro
                objeto que cubra tu rostro
              </p>
            </div>
          </div>

          <div class="item-indicacion">
            <div class="icon-item">
              <mat-icon>contacts</mat-icon>
            </div>
            <div
              class="content content-flex-align"
              style="background-color: white !important"
            >
              <p>Recuerda tener a la mano tu cédula</p>
            </div>
          </div>

          <div class="item-indicacion">
            <div class="icon-item">
              <mat-icon>light_mode</mat-icon>
            </div>
            <div class="content content-flex-align">
              <p>
                Debes contar con buena iluminación y evitar brillos en las
                fotografias
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 34px;">
      <p>
        <strong>¡IMPORTANTE! </strong> Una vez finalice el proceso de firma
        digital debes regresar a está pantalla para continuar.
      </p>
    </div>
    </div>

    <div style="text-align: end; margin-top: 1rem;">
      <button class="continuar" (click)="evaluaPagaduria()" mat-button>
        Guardar y Continuar
      </button>
    </div>
  </mat-step>
  <mat-step disabled label="Pre-oferta de crédito"> </mat-step>
  <mat-step label="Validación de información"> </mat-step>
  <mat-step label="Desembolso del crédito"> </mat-step>
</mat-horizontal-stepper>
