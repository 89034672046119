// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Components
import { ConfirmadaComponent } from './home/cambio-contrasena/confirmada/confirmada.component';
import { MenubarComponent } from './home/menubar/menubar.component';

@NgModule({
    declarations: [
        ConfirmadaComponent,
        MenubarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
    ],
    exports: [MenubarComponent, ConfirmadaComponent]
})
export class ComponentsV2Module { }
