<app-main-container header="Validación de identidad" subheader="Autorización transferencia de datos personales">
  <div class="contenido" style="padding-bottom: 1rem;">
    <p>
      Antes de continuar, debes leer detenidamente la siguiente información sobre transferencia de datos personales.
    </p>
    <app-terminos-autorizacion style="width: 500px;"
      [texto]="'Por medio de la suscripción de este aviso, el agente/asesor comercial declara que el tratamiento de los datos personales de los titulares potenciales clientes de Bayport Colombia S.A. (la “Compañía”) lo realizará exclusivamente por su propia cuenta, en calidad de responsable del tratamiento y no por cuenta de la Compañía, de acuerdo con las obligaciones establecidas para dicho rol por la Ley 1581 de 2012 y el Decreto 1377 de 2013 (compilado en el Decreto 1074 de 2015) y la demás normativa aplicable en materia de protección de datos. El agente/asesor comercial garantiza que obtendrá los consentimientos necesarios para realizar el tratamiento de los datos personales de los titulares en su calidad de responsable del tratamiento y, particularmente, garantiza que obtendrá el consentimiento previo de los titulares para transferir su información personal a la Compañía (incluyendo sus nombres, apellidos, número de cédula de ciudadanía, celular y correo electrónico) con el fin de que la Compañía los contacte para el ofrecimiento de sus productos y servicios y realizar el perfilamiento. El agente/asesor comercial de los datos personales mantendrá indemne a la Compañía frente a cualquier reclamación presentada por los titulares de los datos personales por cualquier causa que sea atribuible al agente/asesor comercial, con ocasión a un tratamiento efectuado en contra de las exigencias de la normativa aplicable. Adicionalmente, el agente/asesor comercial reconoce y acepta que, en caso de que el crédito originado corresponda a un potencial cliente con una actividad productiva (línea Bancoldex ), informará oportunamente al solicitante/cliente que los dineros entregados serán para una actividad económica productiva, según las actividades establecidas por la Clasificación Industrial Internacional Uniforme (CIIU), y que ha recibido dicha confirmación de uso por parte del potencial cliente.'">
    </app-terminos-autorizacion>
    <p>
      Al hacer clic en <strong>Continuar</strong> declaras que has leído la autorización para la transferencia de datos
      personales y las demás declaraciones allí contenidas, asi como aceptas expresamente su contenido.
    </p>
    <button-look-feel color="secondary" [routerLink]="['/datoscliente']" (clicked)="modalAuto()">Continuar</button-look-feel>
  </div>
</app-main-container>
