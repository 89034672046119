import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { VideoService } from '../../services/video.service';
import jwt_decode from "jwt-decode";
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('VideoSrc') videoSrc: ElementRef;


  videoUrl = '';
  mimeType;
  user = "";
  user_id: string;
  dashboard: string;
  showNews: { url: string, name: string } = { url: '', name: '' };

  constructor(private video: VideoService, private _newsService: NewsService) {
    this._newsService.newsActive.subscribe(
      news => {
        this.showNews = news;
        console.log('news: ', { news })
      }
    );

  }
  ngAfterViewInit(): void {
    this.getVideo();
    setTimeout(() => {
      this.nombreUsuario();
    }, 0);
  }

  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    this.user_id = decoded['sub'];
    localStorage.setItem('tpcnl', decoded['tpcnl'].toLowerCase());
    if (screen.width <= 430)
      this.dashboard = `https://lookerstudio.google.com/embed/u/1/reporting/e934d681-204c-48ff-b822-c9489004f001/page/p_jqgvmfss6c?params=%7B%22ds0.id_asesor%22:%22${this.user_id}%22,%22ds79.id_asesor%22:%22${this.user_id}%22%7D`;
    else if (screen.width > 430) {
      this.dashboard = `https://lookerstudio.google.com/embed/u/1/reporting/1e40cf85-b2b3-4c10-a6b4-b6714ca19f55/page/2DgBD?params=%7B%22ds0.id_asesor%22:%22${this.user_id}%22,%22ds79.id_asesor%22:%22${this.user_id}%22%7D`;
    }
    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`
    } else {
      this.user = `${this.user[0]}`
    }
  }

  getVideo() {
    this.video.getVideo().subscribe((data) => {
      this.mimeType = data[0].VideoIndex[0].mime;
      this.videoUrl = data[0].VideoIndex[0].url;
      this.videoSrc.nativeElement.src = this.videoUrl;
    });
  }


}
