import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdoServiceService {

  constructor() { }

  public numeroCarpeta: string;
  public cedula: number;
  public monto: number;
  public nui= "0";
  public requiere_firma= false;
  public canal="digital";
  public session="session";
}
