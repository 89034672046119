import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EvidenteRespuesta } from 'src/app/models/EvidenteRespuestas';
import { Respuesta } from 'src/app/models/respuestaPreguntas';
import { DialogService } from 'src/app/services/dialog.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { EvidenteOtpExpService } from 'src/app/services/preOferta/evidente-otp-exp.service';
import { DataService } from '../../../services/data.service';


@Component({
  selector: 'app-preg-seg',
  templateUrl: './preg-seg.component.html',
  styleUrls: ['./preg-seg.component.css']
})
export class PregSegComponent implements OnInit {
  firstquest: FormGroup;
  secondquest: FormGroup;
  isOptional = true;
  cedula: string = '';
  regValidacion: string = '';
  preguntasS: any;
  cellphone: string = '';
  numeroSolicitud: string = '';
  numeroCarpeta: string = "";
  preguntas: any = {}
  /* { "id": "50057928",
   "resultado": "01",
   "registro": "4457013",
   "excluirCliente": "false",
   "alertas": "false",
   "respuestaAlerta": "03",
   "codigoAlerta": "00",
   "Pregunta": [
     {
       "id": "005001002",
       "texto": "EN MARZO DE 2021 SU CREDITO PARA COMPRA DE PRODUCTOS VARIOS  CON MISSION SAS:",
       "orden": "1",
       "idRespuestaCorrecta": "00",
       "peso": "2",
       "Respuesta": [
         {
           "id": "001",
           "texto": "ESTABA ABIERTO/VIGENTE"
         },
         {
           "id": "002",
           "texto": "ESTABA CANCELADA/SALDADA/CERRADA/INACTIVA"
         },
         {
           "id": "003",
           "texto": "NUNCA HE TENIDO CREDITO PARA COMPRA DE PRODUCTOS VARIOS CON LA ENTIDAD"
         }
       ]
     },
     {
       "id": "005001004",
       "texto": "EN LOS ULTIMOS 2 AÑOS (ENTRE ABRIL DE 2019 Y MARZO DE 2021) SU ULTIMO(A) CREDITO  CON BANCO FINANDINA S A FUE CERRADO(A) / CANCELADO ENTRE:",
       "orden": "2",
       "idRespuestaCorrecta": "00",
       "peso": "2",
       "Respuesta": [
         {
           "id": "001",
           "texto": "ENTRE OCTUBRE Y DICIEMBRE DE 2019"
         },
         {
           "id": "002",
           "texto": "ENTRE ABRIL Y JUNIO DE 2020"
         },
         {
           "id": "003",
           "texto": "ENTRE OCTUBRE Y DICIEMBRE DE 2020"
         },
         {
           "id": "004",
           "texto": "ENTRE ABRIL Y JUNIO DE 2019"
         },
         {
           "id": "005",
           "texto": "NUNCA HE TENIDO CREDITO CON LA ENTIDAD"
         }
       ]
     },
     {
       "id": "001004003",
       "texto": "CUAL ES EL DEPARTAMENTO DE EXPEDICION DE SU DOCUMENTO DE IDENTIDAD?",
       "orden": "3",
       "idRespuestaCorrecta": "00",
       "peso": "1",
       "Respuesta": [
         {
           "id": "001",
           "texto": "CUNDINAMARCA"
         },
         {
           "id": "002",
           "texto": "GUAJIRA"
         },
         {
           "id": "003",
           "texto": "CAUCA"
         },
         {
           "id": "004",
           "texto": "CALDAS"
         },
         {
           "id": "005",
           "texto": "CESAR"
         },
         {
           "id": "006",
           "texto": "NINGUNA DE LAS ANTERIORES"
         }
       ]
     },
     {
       "id": "004010002",
       "texto": "SU CUENTA CORRIENTE CON BANCO POPULAR S. A. HA TENIDO ALGUNA VEZ PROBLEMAS COMO EMBARGOS O CANCELACIONES POR MAL MANEJO?",
       "orden": "4",
       "idRespuestaCorrecta": "00",
       "peso": "2",
       "Respuesta": [
         {
           "id": "001",
           "texto": "SI"
         },
         {
           "id": "002",
           "texto": "NO"
         },
         {
           "id": "003",
           "texto": "NUNCA HE TENIDO CUENTA CORRIENTE CON LA ENTIDAD"
         }
       ]
     }
   ],
   "continue": true,
   "next": "questions-validate"
 }
*/
  formPreguntas: FormGroup[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private evidentepreguntas: EvidenteOtpExpService,
    private router: Router,
    private dialogservc: DialogService,
    private cambioEstadoServ:CambioestadocarpetaService,
    private activatedRoute: ActivatedRoute,
    public data: DataService
    ) {


    this.firstquest = this._formBuilder.group({
      // firstCtrl: ['', Validators.required]
    });
    this.secondquest = this._formBuilder.group({
      // secondCtrl: ''
    });
  }

  ngOnInit(): void {
    let state = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state))

    state.subscribe((datos: any) => {
      console.log(this.data);
      console.log(datos);

      this.numeroCarpeta = datos.numeroCarpeta;
      this.cedula = datos.cedula;
      this.regValidacion = datos.regValidacion;
      this.cellphone = datos.cellphone;
      this.numeroSolicitud = datos.numeroSolicitud;

    });

    this.cedula = String(this.data.cedula);
    this.regValidacion = this.data.regValidacion;

    this.getPreguntas(this.cedula, this.regValidacion);


  }

  getPreguntas(cedula: string, regValidacion: string) {
    this.evidentepreguntas.solicitarPreguntas(cedula, regValidacion)
      .subscribe(res => {

        if(res.next !== 'questions-validate'){
          //Mover a documentos
          this.continuarFlujo('/formatoAcr');
          return;
        }

        if(res.Pregunta.length == 0){
          this.continuarFlujo('/formatoAcr');
          return;
        }

        this.preguntas = res;
        try{
          for (let index = 0; index < this.preguntas.Pregunta.length; index++) {
            this.formPreguntas.push(this._formBuilder.group({
              idRespuesta: ['', Validators.required]
            }))
          }
        }catch(e){}
        console.log(res);

      }, err => {
        console.log(err);
        //this.dialogservc.openDialog('Advertencia','No se pudieron obtener preguntas, se requiere carga de cedula',false);
        this.router.navigateByUrl('/formatoAcr', { state: { numeroSolicitud: this.numeroSolicitud, cedula: this.cedula, regValidacion: this.regValidacion, cellphone: this.cellphone, numeroCarpeta: this.data.numeroCarpeta } })
      })
  }


  enviar(numeroPreg: number) {
    if (numeroPreg == this.formPreguntas.length) {
      let respuestas: Respuesta[] = [];
      this.preguntas.Pregunta.forEach((resp, index) => {
        respuestas.push({
          idPregunta: resp.orden,
          idRespuesta: this.formPreguntas[index].get('idRespuesta').value
        })
      })
      let regPreguntas: EvidenteRespuesta = {
        cedula: this.cedula,
        idCuestionario: this.preguntas.id,
        regCuestionario: this.preguntas.registro,
        respuestas
      }
      this.evidentepreguntas.ValidarPreguntas(regPreguntas)
        .subscribe(res => {

          if(res.next == 'end' && res.info == 'validation-completed'){
            this.cambioEstadoServ.cambioEstadocarpeta(Number(this.data.numeroCarpeta),'30','Cambio de estado aprobado por el Asesor')
            .subscribe(resp=>{

            })
            this.router.navigateByUrl('/formulario-perfilamiento', { state: { numeroSolicitud: this.numeroSolicitud, cedula: this.cedula, regValidacion: this.regValidacion, cellphone: this.cellphone, numeroCarpeta: this.data.numeroCarpeta } })
            return;
          }

          this.router.navigateByUrl('/formatoAcr', { state: { numeroSolicitud: this.numeroSolicitud, cedula: this.cedula, regValidacion: this.regValidacion, cellphone: this.cellphone, numeroCarpeta: this.data.numeroCarpeta } })

        },err => {
          this.router.navigateByUrl('/formatoAcr', { state: { numeroSolicitud: this.numeroSolicitud, cedula: this.cedula, regValidacion: this.regValidacion, cellphone: this.cellphone, numeroCarpeta: this.data.numeroCarpeta } })
          })
    }
  }

  continuarFlujo(ruta) {
    this.router.navigateByUrl(ruta, {
      state: {
        cedula: this.data.cedula,
        regValidacion: this.data.regValidacion,
        cellphone: this.data.cellphone,
        numeroSolicitud: this.data.numeroSolicitud,
        numeroCarpeta: this.data.numeroCarpeta,
      },
    });
  }
}
