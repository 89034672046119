<div class="d-cotainer">
  <div>
    <h2>Rechazar oferta de crédito</h2>
    <p>¿Estás seguro que deseas rechazar la oferta de crédito? por favor selecciona un motivo:</p>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Selecionar motivo</mat-label>
        <mat-select disableRipple [(ngModel)]="motivo">
          <mat-option *ngFor="let motiv of motivosRechazo" [value]="motiv.codigo">{{motiv.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-field col-md-6">
        <label for="comments" class="form-label">Observaciones</label>
        <input type="text" inputmode="numeric" class="form-control" placeholder="*Observaciones" id="comments"
          [(ngModel)]="observaciones" autocomplete="off">
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center">
    <button-look-feel color="outline" (clicked)="rechazarOferta()">Rechazar oferta</button-look-feel>
    <button-look-feel color="secondary" (clicked)="cerrar()">Volver a ofertar</button-look-feel>
  </mat-dialog-actions>
</div>
