import { EventEmitter, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  
  @Input() index:number;
  @Input() nombre:string;
  @Output() eliminar = new EventEmitter<number>(); 

  constructor() { }

  ngOnInit(): void {
  }

  borrar(){
    this.eliminar.emit(this.index);
  }

}


