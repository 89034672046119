
<div class="content">
  <div class="cont-form">
      <h1>Información enviada</h1>
      <p id="mensaje">En tu correo recibirás la información para ingresar a tu cuenta.
      </p>
      <button type="submit" id="recuperar" routerLink="/" class="btn btn-primary w-100" >
        Ir al inicio
      </button>
  </div>
</div>
