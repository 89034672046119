import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from 'src/app/services/crypto.service';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss']
})
export class PoliticasComponent implements OnInit {

  aceptado:boolean = false;
  order: any;
  data:any={};
  constructor(private Acrouter:ActivatedRoute,private router:Router,private cryptserv:CryptoService) { }

  ngOnInit(): void {
    this.Acrouter.queryParams.subscribe(
      res=>{
        let data = this.cryptserv.desencriptar(res.data.replace(/\s/g, '+'))
        this.data=data;
        console.log(data);
        
      }
      
    )
  }

  continuarValidacion(){
    this.router.navigateByUrl('/validacion', { state: { cedula: this.data.cedula, regValidacion:this.data.regValidacion,cellphone:this.data.cellphone,numeroSolicitud:this.data.numeroSolicitud,numeroCarpeta:this.data.numeroCarpeta,idTransaccionOTP:this.data.idTransaccionOTP} })
  }

}
