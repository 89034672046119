import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { StepperComponent } from './stepper/stepper.component';
import { AdvertenciasComponent } from './advertencias/advertencias.component';
import { LoadFileCardComponent } from './load-file-card/load-file-card.component';
import { ProgressComponent } from './progress/progress.component';
import { HyperlinkIconComponent } from './hyperlink-icon/hyperlink-icon.component';
import { FileUploadPhysicalDocsComponent } from './file-upload-physical-docs/file-upload-physical-docs.component';
import { MultipleLoadFilesComponent } from './multiple-load-files/multiple-load-files.component';
import { CardFileUploadComponent } from './card-file-upload/card-file-upload.component';
import { ProcessResultsComponent } from './process-results/process-results.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SharedModule } from '@modules/shared/shared.module';
import { MultipleFileUploadCardDottedComponent } from './multiple-file-upload-card-dotted/multiple-file-upload-card-dotted.component';
import { LoadingComponent } from './dialogs/loading/loading.component';
import { CopyLinkComponent } from './dialogs/copy-link/copy-link.component';


@NgModule({
  declarations: [
    StepperComponent,
    LoadFileCardComponent,
    AdvertenciasComponent,
    ProgressComponent,
    HyperlinkIconComponent,
    FileUploadPhysicalDocsComponent,
    MultipleLoadFilesComponent,
    CardFileUploadComponent,
    ProcessResultsComponent,
    SpinnerComponent,
    MultipleFileUploadCardDottedComponent,
    LoadingComponent,
    CopyLinkComponent
  ],
  imports: [CommonModule, RouterModule, MaterialModule,SharedModule],
  exports: [
    AdvertenciasComponent,
    LoadFileCardComponent,
    ProgressComponent,
    HyperlinkIconComponent,
    FileUploadPhysicalDocsComponent,
    StepperComponent,
    MultipleLoadFilesComponent,
    CardFileUploadComponent,
    ProcessResultsComponent,
    MultipleFileUploadCardDottedComponent,
    LoadingComponent,
    CopyLinkComponent

  ],
})
export class ComponentsModule {}
