import { Component } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-debes-saber",
  templateUrl: "./debes-saber.component.html",
  styleUrls: ["./debes-saber.component.scss"],
  host: { class: "app-debes-saber" }
})
export class DebesSaberComponent {

  private baseView: string = "https://debessaber.col.prod.bayport-digital.com/"

  public currentPath: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseView + "directo");

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const path = params["path"] || "directo"
      this.debesSaberView(path)
    });
  }

  public debesSaberView(newView: "directo" | "calendario" | "politicas" | "megafono"): void {
    this.currentPath = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseView + newView)
  }

}
