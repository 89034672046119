// Angular
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import jwt_decode from "jwt-decode";
import { NavigationEnd, Router } from '@angular/router';
import { IdleModalComponent } from '@modules/shared/components/idle-modal/idle-modal.component';
import { DialogService } from '@modules/shared/services/dialog.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

  public openSisebar: boolean = true;
  private subList: Array<Subscription> = [];
  private idleActive: boolean = false;
  private dialog: any;
  private TIMER_ID: any;
  user = "";
  user_id = "";

  constructor(
    protected serviceAuth: AuthService,
    private router: Router,
    private _idleService: Idle,
    private _dialogService: DialogService
  ) {
    _idleService.setIdle(540);
    _idleService.setTimeout(60);
    _idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIdleActivation(event.urlAfterRedirects);
        this.scrollToTop();
      }
    });
  }


  // Método para manejar el evento de scroll para el tamaño del menu
  onScroll(event: Event): void {
    const scrollContainer = event.target as HTMLElement;
    // Actualizar el color del elemento animado
    const menuContainer = scrollContainer.querySelector('.header-container') as HTMLElement;
    if (scrollContainer.scrollTop > 100) {
      if (menuContainer) {
        menuContainer.classList.add('to-small');
      }
    } else {
      if (menuContainer) {
        menuContainer.classList.remove('to-small');
      }
    }
  }

  // Método para manejar el evento de scroll para cuando se cambia de vista
  scrollToTop() {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollTop = 0;
    }
  }

  /**
   * Metodo para cambiar el estado del sidebar
   *
   * @param value Valor opcional para cambiar el estado del sidebar
   */

  protected toggleSidebar(): void {
    this.openSisebar = !this.openSisebar
  }

  nombreUsuario() {
    const { access_token } = JSON.parse(localStorage.getItem('credentials'));
    const decoded = jwt_decode(access_token);
    this.user = decoded['nm'].split(' ');
    this.user_id = decoded['sub'];
    localStorage.setItem('tpcnl', decoded['tpcnl'].toLowerCase());

    if (this.user.length == 4) {
      this.user = `${this.user[0]} ${this.user[1]}`
    } else {
      this.user = `${this.user[0]}`
    }
  }

  ngOnInit(): void {
    this.idleSubs();
    this.idleReset();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.openSisebar = true
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.nombreUsuario();
    }, 0);
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (localStorage.getItem('credentials')) {
          const refresh = localStorage.getItem('refrescar');
          if (!refresh) {
            localStorage.setItem('refrescar', '0');
          }
          if (refresh == '0') {
            localStorage.setItem('refrescar', '1');
          }
        } else {
          const scriptElement = document.getElementById(
            'hubspot-messages-iframe-container'
          );
          if (scriptElement) {
            scriptElement.remove();
          }
        }
      }
    });
  }

  private checkIdleActivation(url: string): void {
    const excludedRoutes = ['/iniciar-sesion', '/recup-pass'];
    if (excludedRoutes.includes(url)) {
      this._idleService.stop();
    } else {
      this.idleReset();
    }
  }

  private idleReset(): void {
    this._idleService.watch();
  }

  private idleSubs(): void {
    this.subList.push(
      this._dialogService.idleActive.subscribe((res: any) => {
        this.idleActive = res;
        if (!res) clearInterval(this.TIMER_ID);
      })
    );
    this.subList.push(
      this._dialogService.idleActiveSession.subscribe((res: any) => {
        if (!res) {
          this.cerrarModal();
          return;
        }
        this.idleReset();
      })
    );
    this.subList.push(
      this._idleService.onTimeoutWarning.subscribe((res: any) => {
        if (res === 60 && !this.idleActive) {
          this.dialog = this._dialogService.openDialog(IdleModalComponent, {
            disableClose: true,
            width: '100%',
            maxWidth: '320px',
          });
          let timeout = res;
          this.TIMER_ID = setInterval(() => {
            timeout -= 1;
            this._dialogService.idleTimeOut.emit(timeout);
            if (timeout === 0) {
              this.cerrarModal();
            }
          }, 1000);
        }
      })
    );
  }

  private cerrarModal(): void {
    this._dialogService.closeDialog(this.dialog);
    clearInterval(this.TIMER_ID);
    this.cerrarSesion()
  }

  public cerrarSesion(): void {
    localStorage.clear();
    this.router.navigate(['/', 'iniciar-sesion']);
  }
}
