import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Documento } from 'src/app/models/documento';
import { ControlTamañoService } from 'src/app/services/controlTamaño/controlTamaño.service';
import { Utils } from 'src/app/utils/utils';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-card-file-upload',
  templateUrl: './card-file-upload.component.html',
  styleUrls: ['./card-file-upload.component.scss']
})
export class CardFileUploadComponent implements OnInit {

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Input() iconoCentral = '';
  @Input() iconoLoading = '';
  @Input() iconoLoaded = '';
  @Input() titulo = '';
  @Input() texto = '';
  @Input() file: Documento = null;
  @Input() rules: string[] = null;

  public loading: boolean = false;
  public isError: boolean = false;
  public utils = new Utils();
  public nombreArchivo;
  public fileSize;
  constructor(
    private datepipe: DatePipe,
    private controlTamañoService: ControlTamañoService,
  ) { }

  ngOnInit(): void {
    this.iconoCentral = !this.iconoCentral ? 'assets/icons/subir.svg' : this.iconoCentral;
    this.iconoLoading = !this.iconoLoading ? 'assets/icons/archivo-cargado.svg' : this.iconoLoading;
    this.iconoLoaded = !this.iconoLoaded ? 'assets/icons/archivo-cargado.svg' : this.iconoLoaded;
    this.nombreArchivo = this.file.archivo? this.file.archivo.name : '';
    this.fileSize = this.file.archivo? this.file.archivo.size : 0;
  }

  async onFileChange(event: any) {
    this.fileSize = event.target.files[0].size;

    if (this.fileSize  / (1024 * 1024) >= 5) {
      this.isError = true;
      return;
    }
    this.loading = true;
    this.isError = false;
    let timestamp: string = this.datepipe.transform(
      new Date(),
      'yyyyMMddHHmmss'
    );

    this.file.nombre = `${this.titulo}-${timestamp}.pdf`;
    this.file.status = true
    this.file.upload = false
    this.file.archivo = event.target.files[0];

    if (event.target.files[0].type != 'application/pdf') {
      const filePdf = await this.utils.convertImageFileToPDF(this.file.archivo);
      this.file.archivo = filePdf;
    }

    if (this.file.archivo.size  / (1024 * 1024) >= 2) {
      const fileBase64 = (await this.fileToBase64(this.file.archivo)) as string;
      try {
        const response = await this.controlTamañoService
          .documentCompressor(fileBase64)
          .toPromise();
        const buffer = Buffer.from(response.data.documento, 'base64');
        const file = new File([buffer], this.file.nombre, {
          type: 'application/pdf',
        });
        this.file.archivo = file;
      } catch (error) {
        this.loading = false;
        this.isError = true;
        this.onClearFile()
        console.log({ error });
      }
    }
    setTimeout(() => {
      this.nombreArchivo = this.file.archivo.name
      this.loading = false; // Cambia el valor de la bandera
    }, 2000);
  }

  async fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.toString().split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  onClearFile() {
    this.nombreArchivo = '';
    this.fileSize = 0;
    this.file.status = false;
    delete this.file.archivo;
    delete this.file.upload;
    delete this.file.nombre;
  }

  public clicFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

}
