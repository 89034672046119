import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-valid-ident',
  templateUrl: './valid-ident.component.html',
  styleUrls: ['./valid-ident.component.css']
})
export class ValidIdentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
