import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientModel } from 'src/app/models/cliente';
import { ClientLogModel } from 'src/app/models/clienteLog';
import { ExperianPhoneModel } from 'src/app/models/experianPhone.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetInformacionService {
  private endpoint = `${environment.ApiConecta}perfilamiento/obtener/`;
  private endpointSat = `${environment.ApiConecta}`;

  constructor(private http: HttpClient) {}

  getInfoPersonal(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'informacion/personal', {
      numeroSolicitud,
    });
  }

  getInfoLaboral(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'informacion/laboral', {
      numeroSolicitud,
    });
  }

  getInfoFinanciera(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'informacion/financiera', {
      numeroSolicitud,
    });
  }

  getInfoSolicitud(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'informacion/solicitud', {
      numeroSolicitud,
    });
  }

  getRefinanciacion(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'refinanciacion', {
      numeroSolicitud,
    });
  }

  getComprasCartera(numeroSolicitud: string): Observable<any> {
    return this.http.post(this.endpoint + 'compras-cartera', {
      numeroSolicitud,
    });
  }

  // Serivios administración celular

  savePhone(data: ExperianPhoneModel): Observable<any> {
    return this.http.post(this.endpointSat + 'save-phone', data);
  }

  getPhone(folder_id: string): Observable<any> {
    return this.http.post(this.endpointSat + 'get-phone', { folder_id });
  }

  saveCascadeClient(data: ClientModel): Observable<any> {
    return this.http.post(this.endpointSat + 'save-client-cascada', data);
  }

  clientLog(data: ClientLogModel): Observable<any> {
    return this.http.post(this.endpointSat + 'client-log-cascada', data);
  }

  saveCascadaPhone(data: any): Observable<any> {
    return this.http.post(this.endpointSat + 'save-phone-cascada', data);
  }

  saveCascadaMail(data: any): Observable<any> {
    return this.http.post(this.endpointSat + 'save-mail-cascada', data);
  }

  validatePhoneReconocer(data: any): Observable<any> {
    return this.http.post(this.endpointSat + 'validate-phones', data);
  }

  getInfoClient(data: any): Observable<any> {
    return this.http.post(this.endpointSat + 'getInfoClient', data);
  }

  sendOtpCode(data: any): Observable<any> {
    return this.http.post(`${this.endpointSat}sendOtp`, data);
  }

  changeNumberPhone(data: any): Observable<any> {
    return this.http.put(`${this.endpointSat}changeNumberPhone`, data);
  }

  getvalidateOtp(data: any): Observable<any> {
    return this.http.post(this.endpointSat + 'validateOtp', data);
  }

  updateEmailAndPhoneDialog(data: any): Observable<any> {
    return this.http.post(
      `${this.endpointSat}/perfilamiento/actualizar/informacion/correo-celular`,
      data
    );
  }

  rejectFolder(data: any): Observable<any> {
    return this.http.post(`${this.endpointSat}rejectFolder`, data);
  }
}
