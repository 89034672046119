<app-main-container header="Formulario de perfilamiento"
  subheader="Completa la información de tu cliente en cada uno de los campos solicitados a continuación.">
  <div id="accordion">
    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" data-bs-target="#collapsepersonal" id="headingOne">
        <h2 class="mb-0 acordion-title">Información personal adicional</h2>
        <div class="collapsable-button">
          <i class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapsepersonal" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="infPersonalAdicionalForm">
            <ng-template matStepLabel>Información Personal</ng-template>
            <div class="row">
              <div class="col-md-4 form-field">
                <label for="fechaExpedicionIdentificacion" class="form-label">Fecha de expedición cédula</label>
                <input type="text" class="form-control"
                  placeholder="*Fecha de expedición cédula" id="fechaExpedicionIdentificacion"
                  formControlName="fechaExpedicionIdentificacion" autocomplete="off" readonly>
              </div>
              <div class="col-md-4 form-field">
                <label for="lugarExpedicionIdentificacion" class="form-label">Lugar de expedición de cédula</label>
                <input type="text" class="form-control" placeholder="Buscar" id="lugarExpedicionIdentificacion"
                  formControlName="lugarExpedicionIdentificacion" autocomplete="off" #txtQuery
                  (keyup)="changeDinamicoCuidades(txtQuery.value, 1)">
                <div class="form-error"
                  *ngIf="infPersonalAdicionalForm.controls.lugarExpedicionIdentificacion.hasError('required') && !infPersonalAdicionalForm.controls.lugarExpedicionIdentificacion.pristine">
                  Lugar de expedición de cédula es obligatorio
                </div>
                <div *ngIf="showListaExpedicion" [ngClass]="
                  alturaListaDesplegable > 3
                    ? 'list_expedicion_scroll'
                    : 'list_expedicion'
                ">
                  <div *ngFor="let item of filteredCiudadesLugarExpedicion" class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="
                      seleccionarExpedicion(item.filteredName, item.codMunic)
                    ">
                      {{ item.filteredName }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-4 form-field">
                <label for="lugarNacimiento" class="form-label">Lugar de nacimiento</label>
                <input type="text" class="form-control" placeholder="Buscar" id="lugarNacimiento"
                  formControlName="lugarNacimiento" autocomplete="off" #txtnacimiento
                  (keyup)="changeDinamicoCuidades(txtnacimiento.value, 2)">
                <div class="form-error"
                  *ngIf="infPersonalAdicionalForm.controls.lugarNacimiento.hasError('required') && !infPersonalAdicionalForm.controls.lugarNacimiento.pristine">
                  Lugar de expedición de cédula es obligatorio
                </div>
                <div *ngIf="showListaNacimientos" [ngClass]="
                  alturaListaDesplegable > 3
                    ? 'list_expedicion_scroll'
                    : 'list_expedicion'
                ">
                  <div *ngFor="let item of filteredCiudadesModel" class="list-group">
                    <a class="list-group-item list-group-item-action" (click)="
                      seleccionarNacimiento(item.filteredName, item.codMunic)
                    ">
                      {{ item.filteredName }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-label>Estado civil</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="estadoCivil" name="estadoCivil" required
                    (ngModelChange)="inputPersonal()">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="estadoCivil"
                      (filteredReturn)="filteredEstadoCivil = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredEstadoCivil" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infPersonalAdicionalForm.controls.estadoCivil.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-label>Profesión / Oficio</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="profesionOficio" name="profesionOficio"
                    required (ngModelChange)="inputPersonal()">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="profesiones"
                      (filteredReturn)="filteredProfesiones = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredProfesiones" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infPersonalAdicionalForm.controls.profesionOficio.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4 form-field">
                <label for="direccion" class="form-label">Dirección notificación</label>
                <input type="text" class="form-control" placeholder="*Dirección notificación" id="direccion"
                  [value]="direccion" autocomplete="off">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-label>Destino de crédito</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="destinoCredito" name="destinoCredito"
                    required (ngModelChange)="inputPersonal()">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="destinoc"
                      (filteredReturn)="filteredDestinoc = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredDestinoc" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infPersonalAdicionalForm.controls.destinoCredito.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-label>Departamento</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input type="text" placeholder="Selecciona ..." aria-label="Number" matInput
                    formControlName="departamento" [matAutocomplete]="auto" name="departamento" required
                    (ngModelChange)="inputPersonal()"
                    (keyup)="filterDeptos(infPersonalAdicionalForm.controls.departamento.value)">

                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option (click)="getCiudades(item.codDepto)"
                      *ngFor="let item of (filteredDeptos_.length == 0 ? filteredDepartamentos : filteredDeptos_)"
                      [value]="item.nomDepto">
                      {{ item.nomDepto }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="error" *ngIf="
                    infPersonalAdicionalForm.controls.departamento.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-label>Ciudad</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input type="text" placeholder="Selecciona ..." aria-label="Number" matInput formControlName="ciudad"
                    [matAutocomplete]="autoCity" name="ciudad" required (ngModelChange)="inputPersonal()"
                    (keyup)="filterCityes(infPersonalAdicionalForm.controls.ciudad.value)">

                  <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                    <mat-option *ngFor="let item of (filteredCity_.length == 0 ? filteredCiudades : filteredCity_)"
                      [value]="item.nomCtroPobla" (click)="valueCity = item.codMunic">
                      {{ item.nomCtroPobla }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="error" *ngIf="
                    infPersonalAdicionalForm.controls.ciudad.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-md-3">
                <mat-label>Dirección de residencia</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="direccionResidencia"
                    name="direccionResidencia">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'NOMBRE_LARGO'"
                      [array]="direccionResidenciaModel"
                      (filteredReturn)="filteredDireccionResidencia = $event"></mat-select-filter>
                    <mat-option (click)="setDireccion()" *ngFor="let item of filteredDireccionResidencia"
                      [value]="item.NOMBRE_LARGO">
                      {{ item.NOMBRE_LARGO }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2 sm-input col-md-1 ps-0">
                <mat-label style="color: white">.</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput name="box1" formControlName="box1" (keyup)="setDireccion()" />
                </mat-form-field>
              </div>
              <div class="col-2 sm-input col-md-1 ps-0">
                <mat-label style="color: white">.</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput name="box2" formControlName="box2" (keyup)="setDireccion()" />
                </mat-form-field>
              </div>
              <div class="col-2 sm-input col-md-1 ps-0">
                <mat-label style="color: white">.</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput name="box3" formControlName="box3" (keyup)="setDireccion()" />
                </mat-form-field>
              </div>
              <div class="col-6 col-md-3">
                <mat-label>Torre - Interior</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="direccionTorre" name="direccionTorre"
                    id="torre">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'NOMBRE_LARGO'"
                      [array]="direccionTorreInterior"
                      (filteredReturn)="filteredDireccionTorreInterior = $event"></mat-select-filter>
                    <mat-option (click)="setDireccion()" *ngFor="let item of filteredDireccionTorreInterior"
                      [value]="item.NOMBRE_LARGO">
                      {{ item.NOMBRE_LARGO }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="sm-input col-6 col-md-3 ps-0">
                <mat-label style="color: white">.</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput name="box4" formControlName="box4" (keyup)="setDireccion()" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="form-field">
                <label for="completa" class="form-label">La dirección de su cliente es:</label>
                <input type="text" class="form-control" placeholder="*Direccion" id="completa"
                  formControlName="completa" autocomplete="off" readonly [(ngModel)]="direccionCompleta">
              </div>
            </div>
            <div class="justify-right">
              <p class="condicion">
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>
            <div class="justify-right">
              <button-look-feel [type]="'submit'" [disabled]="isLoadingPersonalInfo || infPersonalAdicionalForm.invalid"
                color="secondary" (clicked)="sendInfoPersonal()">Guardar y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" data-bs-target="#collapselaboral" id="headingOne">
        <h2 class="mb-0 acordion-title">Información laboral adicional</h2>
        <div class="collapsable-button">
          <i class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapselaboral" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="infLaboralAdicionalForm">
            <ng-template matStepLabel>Información laboral adicional</ng-template>
            <div class="row pb-3">
              <div class="col-md-3 form-field">
                <label for="codigoVerificacion1" class="form-label">Código verificación 1 <br />(Opcional)</label>
                <input type="text" class="form-control" placeholder="*Código verificación 1" id="codigoVerificacion1"
                  formControlName="codigoVerificacion1" autocomplete="off">
              </div>
              <div class="col-md-3 form-field">
                <label for="codigoVerificacion2" class="form-label" data-bs-toggle="tooltip" data-bs-placement="top"
                  data-bs-title="Ferrocarriles Nacionales de Colombia, Colfondos S.A. - Pensionado y LM, Secretaría Educación Bogotá, Ministerio de Defensa - Pensionado y LM, Caja de Retiro FFMM - Cremil y LM, Secretaría Educación Magdalena, Secretaría Educación Norte de Santander, Colpensiones - Pensionado y LM.">Código
                  verificación 2<br />(Opcional) <i class="fa-solid fa-circle-info"></i></label>
                <input type="text" class="form-control" placeholder="*Código verificación 2" id="codigoVerificacion2"
                  formControlName="codigoVerificacion2" autocomplete="off">
              </div>
              <div class="col-md-3 form-field">
                <label for="codigoMilitar" class="form-label" data-bs-toggle="tooltip" data-bs-placement="top"
                  data-bs-title="Código que aparece en la parte superior del documento de tiempos y haberes del cliente">Código
                  militar<br />(Opcional) <i class="fa-solid fa-circle-info"></i></label>
                <input type="text" class="form-control" placeholder="*Código militar" id="codigoMilitar"
                  formControlName="codigoMilitar" autocomplete="off">
              </div>
              <div class="col-md-3 form-field">
                <label for="codigoConfirm" class="form-label" data-bs-toggle="tooltip" data-bs-placement="top"
                  data-bs-title="Código que aparece en la parte superior del documento de tiempos y haberes del cliente">Confirmar
                  código militar<br />(Opcional) <i class="fa-solid fa-circle-info"></i></label>
                <input type="text" class="form-control" placeholder="*Confirmar código militar" id="codigoConfirm"
                  (blur)="validEqual()" formControlName="codigoConfirm" autocomplete="off">
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-label>Fuerza (Opcional)</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select formControlName="fuerza">
                    <mat-option [value]="fuerza.codigo" *ngFor="let fuerza of fuerzas">
                      {{ fuerza.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-field">
                <label for="numeroAfiliacion" class="form-label" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-title="Aplica exclusivamente para Colpensiones">Número afiliación (Opcional) <i class="fa-solid fa-circle-info"></i></label>
                <input type="text" class="form-control" placeholder="*Número afiliación" id="numeroAfiliacion"
                  formControlName="numeroAfiliacion" autocomplete="off" onkeypress="return isNumberKey(event)"
                  [maxlength]="
                    dataCarpeta !== undefined &&
                    (dataCarpeta.codigoPagaduria === 716 || dataCarpeta.codigoPagaduria === 1100)? 12:30">
                <div class="form-error" *ngIf="infLaboralAdicionalForm.controls.numeroAfiliacion.hasError('minlength')">
                  Se permite como mínimo 8 caracteres
                </div>
              </div>
              <div class="col-md-3">
                <mat-label>Tipo de pensión (Opcional) </mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select formControlName="tipoPension">
                    <mat-option [value]="pension.codigo" *ngFor="let pension of tipoPensiones">
                      {{ pension.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-field">
                <label for="cedulaTitular" class="form-label" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-title="Campo requerido si aplica a pensión de sustitución">Cédula del titular (Opcional) <i class="fa-solid fa-circle-info"></i></label>
                <input type="number" inputmode="numeric" class="form-control" placeholder="*Cédula del titular" id="cedulaTitular"
                  formControlName="cedulaTitular" autocomplete="off">
              </div>
            </div>
            <div class="col-md-3">
              <div class="dir-notificacion">
                <mat-label>Tipo pensión / código afiliacion <br />
                  (Opcional)
                  <i aria-hidden="false" aria-label="Example home icon" matTooltip="Aplica exclusivamente para CAGEN"
                    class="fa-solid fa-circle-info"></i>
                </mat-label>
                <div class="input-ayuda">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-select formControlName="codigoAfiliacion">
                      <mat-option [value]="codigo.codigo" *ngFor="let codigo of codigoPensiones">
                        {{ codigo.codigo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="justify-right">
              <p class="condicion">
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>
            <div class="justify-right">
              <button-look-feel color="secondary" [disabled]="isLoadingLaborInfo" (clicked)="sendInfoLaboral()">Guardar
                y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" data-bs-target="#collapsefinanciera" id="headingOne">
        <h2 class="mb-0 acordion-title">Información financiera adicional</h2>
        <div class="collapsable-button">
          <i class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapsefinanciera" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="infFinancieraAdicionalForm">
            <ng-template matStepLabel>Información Personal</ng-template>
            <div class="row tb-row">
              <div class="col-md-3">
                <mat-label>Total activos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <!-- <span style="font-size: 18px;" matPrefix>$ &nbsp;</span> -->
                  <input type="tel" matInput required formControlName="totalActivos" name="totalActivos"
                    autocomplete="off" currencyMask />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.totalActivos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Total pasivos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <!-- <span style="font-size: 18px;" matPrefix>$ &nbsp;</span> -->
                  <input type="tel" matInput required formControlName="totalPasivos" name="totalPasivos"
                    autocomplete="off" currencyMask />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.totalPasivos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Ingresos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <!-- <span style="font-size: 18px;" matPrefix>$ &nbsp;</span> -->
                  <input type="tel" matInput required formControlName="ingresos" name="ingresos" autocomplete="off"
                    currencyMask />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.ingresos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Gastos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input type="tel" matInput required formControlName="gastos" name="gastos" autocomplete="off"
                    currencyMask />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.gastos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Otros ingresos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput type="tel" formControlName="otrosIngresos" autocomplete="off" name="otrosIngresos"
                    currencyMask />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.otrosIngresos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="dir-notificacion col-md-3">
                <mat-label>Descripción de otros ingresos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput type="text" formControlName="descripcionOtrosIngresos" />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.descripcionOtrosIngresos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-label>Origen fondos</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select required formControlName="origenFondos">
                    <mat-option [value]="origen.codigo" *ngFor="let origen of origenFondos">
                      {{ origen.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.origenFondos.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="lg-container col-md-3">
                <mat-label>Realiza operaciones en moneda extranjera</mat-label>
                <div style="height: 50px; display: flex; align-items: center">
                  <mat-slide-toggle class="color-slide" color="--p-blue" formControlName="monedaExtranjera" size="large"
                    (click)="validaMoneda()"></mat-slide-toggle>
                </div>
              </div>
            </div>
            <!-- </form> -->
            <!-- Aqui va el otro form -->
            <!-- <form [formGroup]="infFinancieraAdicionalForm2"> -->
            <div class="row tb-row" *ngIf="infFinancieraAdicionalForm.get('monedaExtranjera').value">
              <div class="inp-wrap col-3 col-sm-3 col-md-3 col-lg-3 mb-err-inp">
                <mat-label>Tipo de producto</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="tipoProducto">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="tipoProductos"
                      (filteredReturn)="filteredTipoProductos = $event">
                    </mat-select-filter>
                    <mat-option *ngFor="let item of filteredTipoProductos" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.tipoProducto.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Moneda</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="moneda">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="monedas"
                      (filteredReturn)="filteredMonedas = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredMonedas" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.moneda.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Ciudad</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <input matInput type="text" formControlName="ciudad" />
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.ciudad.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>País</mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="pais">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombreEstado'" [array]="paises"
                      (filteredReturn)="filteredTipoPaises = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredTipoPaises" [value]="item.codigoEstado">
                      {{ item.nombreEstado }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infFinancieraAdicionalForm.controls.pais.hasError(
                      'required'
                    )
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- aqui termina el otro form -->
            </div>
            <div class="justify-right">
              <p class="condicion">
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>
            <div class="justify-right">
              <button-look-feel color="secondary" (clicked)="sendInfoFinanciera()"
                [disabled]="isLoadingFinancialInfo || infFinancieraAdicionalForm.invalid || infFinancieraAdicionalForm.controls.origenFondos.value == 0 || infFinancieraAdicionalForm.get('ingresos').value == 0 || infFinancieraAdicionalForm.get('gastos').value == 0">Guardar
                y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" data-bs-target="#collapsedesembolso" id="headingOne">
        <h2 class="mb-0 acordion-title">Información desembolso</h2>
        <div class="collapsable-button">
          <i class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapsedesembolso" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="infDesembolsoForm">
            <ng-template matStepLabel>Información Personal</ng-template>
            <div class="row">
              <div class="col-md-3">
                <mat-label>Modalidad desembolso
                  <i aria-hidden="false" aria-label="Example home icon"
                    matTooltip="Podra seleccionar desembolso de excedente a cliente en transferencia o pago masivo."
                    class="fa-solid fa-circle-info"></i>
                </mat-label>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-select formControlName="modalidadDesembolso" required (ngModelChange)="optionSelect()">
                    <mat-option [value]="desembolso.codigo" *ngFor="let desembolso of tipoDesembolso">
                      {{ desembolso.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error class="error" *ngIf="
                  infDesembolsoForm.controls.modalidadDesembolso.hasError(
                    'required'
                  )
                ">
                  Campo <strong>requerido</strong>
                </mat-error>
              </div>

              <div class="col-md-3">
                <mat-label *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                ">Banco</mat-label>
                <mat-form-field class="w-100" appearance="outline" *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                " style="bottom: -7px">
                  <mat-select placeholder="Selecciona ..." formControlName="banco" name="banco"
                    (ngModelChange)="inputChangeDesembolso()" required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="bancos"
                      (filteredReturn)="filteredBancos = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredBancos" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="infDesembolsoForm.controls.banco.hasError('required')">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                ">Tipo de cuenta</mat-label>
                <mat-form-field class="w-100" appearance="outline" *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                " style="bottom: -7px">
                  <mat-select formControlName="tipoCuenta" required (ngModelChange)="inputChangeDesembolso()">
                    <mat-option [value]="cuenta.codigo" *ngFor="let cuenta of tipoCuenta">
                      {{ cuenta.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    infDesembolsoForm.controls.tipoCuenta.hasError('required')
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                ">Número de cuenta
                  <i class="fa-solid fa-circle-info"
                    *ngIf="this.infDesembolsoForm.get('modalidadDesembolso').value == 1" aria-hidden="false"
                    aria-label="Example home icon" matTooltip="Verifica el número de cuenta Bancaria"></i>
                </mat-label>
                <mat-form-field class="w-100" appearance="outline" *ngIf="
                  this.infDesembolsoForm.get('modalidadDesembolso').value == 1
                ">
                  <input matInput formControlName="numeroCuenta" required type="tel"
                    (change)="inputChangeDesembolso()" />
                  <mat-error class="error" *ngIf="
                    infDesembolsoForm.controls.numeroCuenta.hasError('required')
                  ">
                    Campo <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="justify-right">
              <p class="condicion">
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>

            <div class="justify-right">
              <button-look-feel color="secondary"
                [disabled]="infDesembolsoForm.controls.modalidadDesembolso.value == 0 || infDesembolsoForm.invalid || isLoadingDisbursementInfo"
                (clicked)="sendInfoDesembolso()">Guardar y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" data-bs-target="#collapseasegurabilidad" id="headingOne">
        <h2 class="mb-0 acordion-title">Información asegurabilidad</h2>
        <div class="collapsable-button">
          <i class="fa-solid fa-caret-down"></i>
        </div>
      </div>
      <div id="collapseasegurabilidad" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body" style="padding-top: 0">
          <div class="row" style="display: flex; align-items: center">
            <div class="col-10">
              <p style="padding: 0px !important">
                <strong>A continuación, encontrarás la declaración de asegurabilidad
                  del cliente.</strong>
              </p>
              <p>
                1. ¿El cliente tiene alguna limitación física o psicológica que
                haya disminuido la capacidad laboral igual o superior al 50%?
              </p>
              <p>
                Si la respuesta es afirmativa, por favor diligenciar los campos
                indicados.
              </p>
            </div>
            <div class="col-2">
              <div style="height: 50px; display: flex; align-items: center">
                <div class="switch-toggle switch-3 switch-candy">
                  <input (click)="
                    onChangeSlideTogglePhysical($event, 'onPhysicalLabel')
                  " [value]="1" id="onPhysical" name="state-d" type="radio" />
                  <label id="onPhysicalLabel" class="with-border no-selected" for="onPhysical">SI</label>

                  <input (click)="
                    onChangeSlideTogglePhysical($event, 'naPhysicalLabel')
                  " [value]="2" id="naPhysical" name="state-d" type="radio" />
                  <label id="naPhysicalLabel" for="naPhysical" class="disabled without-state">
                    &nbsp;&nbsp;
                  </label>

                  <input (click)="
                    onChangeSlideTogglePhysical($event, 'offPhysicalLabel')
                  " [value]="0" id="offPhysical" name="state-d" type="radio" />
                  <label id="offPhysicalLabel" class="no-selected" for="offPhysical">NO</label>
                </div>
              </div>
            </div>
          </div>

          <div class="separador"></div>
          <div *ngIf="limitacionfisica">
            <div [formGroup]="limitacion" *ngFor="let limitacion of infLimitacionFisicaForm; let i = index">
              <div *ngIf="i != 0" style="display: flex; justify-content: flex-end">
                <app-eliminar (click)="eliminar(infLimitacionFisicaForm, i)" [texto]="'Eliminar'">
                </app-eliminar>
              </div>
              <div class="row">
                <div class="lg-input col-md-5">
                  <div style="width: 200px">
                    <mat-label>Enfermedad diagnosticada/ causa de invalidez</mat-label>
                  </div>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="enfermedad" matInput />
                    <mat-error class="error" *ngIf="limitacion.controls.enfermedad.hasError('required')">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="dir-notificacion col-md-5">
                  <div style="width: 250px">
                    <mat-label>Fecha del diagnóstico / fecha dictamen pérdida
                      laboral</mat-label>
                  </div>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="fechaEnfermedad" matInput type="date" />
                    <mat-error class="error" *ngIf="
                      limitacion.controls.fechaEnfermedad.hasError('required')
                    ">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="dir-notificacion col-md-5">
                  <mat-label><br />
                    Duración del tratamiento</mat-label>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="duracion" matInput />
                    <mat-error class="error" *ngIf="limitacion.controls.duracion.hasError('required')">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="lg-input col-md-5">
                  <div style="width: 250px">
                    <mat-label>Centro hospitalario tratante / junta médica laboral de
                      calificación
                    </mat-label>
                  </div>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="datosHospital" matInput />
                    <mat-error class="error" *ngIf="
                      limitacion.controls.datosHospital.hasError('required')
                    ">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <a [ngClass]="
                formulariosAgregados == 1 ? 'desactivarBotonAgregar' : ''
              " role="button" (click)="addLimitacionVacia()" style="
                cursor: pointer;
                color: var(--p-blue);
                text-decoration: underline;
              ">Agregar</a>
            </div>
          </div>
          <div class="row" style="display: flex; align-items: center">
            <div class="col-10">
              <p>
                2. ¿El cliente padeció o padece, recibió o recibe algún
                tratamiento por enfermedades congénitas, enfermedades
                cardiovasculares, hipertensión, diabetes, enfermedades pulmonares,
                hepatitis B, cirrosis, VIH sida, insuficiencia renal, cáncer,
                enfermedades neurológicas o psiquiátricas?
              </p>
              <p>
                Si la respuesta es afirmativa, por favor diligenciar los campos
                indicados.
              </p>
            </div>
            <div class="col-2">
              <div style="height: 50px; display: flex; align-items: center">
                <div class="switch-toggle switch-3 switch-candy">
                  <input (click)="
                    onChangeSlideToggleChronicle($event, 'onChronicleLabel')
                  " [value]="1" id="onChronicle" name="state-d" type="radio" />
                  <label id="onChronicleLabel" class="with-border no-selected" for="onChronicle">SI</label>

                  <input (click)="
                    onChangeSlideToggleChronicle($event, 'naChronicleLabel')
                  " [value]="2" id="naChronicle" name="state-d" type="radio" />
                  <label id="naChronicleLabel" for="naChronicle" class="disabled without-state">
                    &nbsp;&nbsp;
                  </label>

                  <input (click)="
                    onChangeSlideToggleChronicle($event, 'offChronicleLabel')
                  " [value]="0" id="offChronicle" name="state-d" type="radio" />
                  <label id="offChronicleLabel" class="no-selected" for="offChronicle">NO</label>
                </div>
              </div>
            </div>
          </div>
          <div class="separador"></div>
          <div *ngIf="limitacioncronica">
            <div [formGroup]="enfermedad" *ngFor="let enfermedad of infEnfermedadForm; let i = index">
              <div *ngIf="i != 0" style="display: flex; justify-content: flex-end">
                <app-eliminar (click)="eliminarLim(infEnfermedadForm, i)" [texto]="'Eliminar'">
                </app-eliminar>
              </div>
              <div class="row">
                <div class="lg-input inp-wrap col-md-5">
                  <div style="width: 200px">
                    <mat-label>Enfermedad diagnosticada/ causa de invalidez</mat-label>
                  </div>

                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="enfermedad" matInput />
                    <mat-error class="error" *ngIf="enfermedad.controls.enfermedad.hasError('required')">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="dir-notificacion col-md-5">
                  <div style="width: 250px">
                    <mat-label>Fecha del diagnóstico / fecha dictamen pérdida
                      laboral</mat-label>
                  </div>
                  <mat-form-field class="w-100" appearance="outline">
                    <input matInput formControlName="fechaEnfermedad" type="date" />
                    <mat-error class="error" *ngIf="
                      enfermedad.controls.fechaEnfermedad.hasError('required')
                    ">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="dir-notificacion col-md-5">
                  <mat-label><br />
                    Duración del tratamiento</mat-label>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="duracion" matInput /><mat-error class="error"
                      *ngIf="enfermedad.controls.duracion.hasError('required')">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="lg-input col-md-5">
                  <div style="width: 250px">
                    <mat-label>Centro hospitalario tratante / junta médica laboral de
                      calificación
                    </mat-label>
                  </div>
                  <mat-form-field class="w-100" appearance="outline">
                    <input formControlName="datosHospital" matInput />
                    <mat-error class="error" *ngIf="
                      enfermedad.controls.datosHospital.hasError('required')
                    ">
                      Campo <strong>requerido</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <a role="button" [ngClass]="
                formulariosAgregados2 == 1 ? 'desactivarBotonAgregar' : ''
              " (click)="addEnfermedadVacia()" style="
                cursor: pointer;
                color: var(--p-blue);
                text-decoration: underline;
              ">
                Agregar
              </a>
            </div>
          </div>

          <div class="justify-right" *ngIf="
          !wasSaved.personalForm || !wasSaved.laboralForm ||
          !wasSaved.financialForm || !wasSaved.desembolsoForm
        ">
            <p class="condicion">
              No olvide <b>Guardar</b> los cambios de: <span *ngFor="let item of validateForms()">
                <b>{{item}}</b>,
              </span>
              para poder continuar con el perfilamiento.
            </p>
          </div>
          <div class="justify-right">
            <p class="condicion">
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás aceptando
              que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button-look-feel [disabled]="!wasSaved.personalForm || !wasSaved.laboralForm || !wasSaved.financialForm ||
          !wasSaved.desembolsoForm ||
          valueOne == 2 ||
          valueTwo == 2 ||
          infDesembolsoForm.invalid ||
          isLoadingInsurabilityInfo ||
          !firstLoadedData ||
          !secondLoadedData ||
          !thirdLoadedData ||
          !fourthlLoadedData" color="secondary" (clicked)="guardarAsegurabilidad()">Guardar y
              Continuar</button-look-feel>
            <div id="ZN_esRLbr5J5Upbmse"><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-container>
