import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { String } from 'aws-sdk/clients/appstream';

@Injectable({
  providedIn: 'root'
})
export class NotificacionSmsService {

  private endpoint = environment.ApiConecta+'messaging/client/provisional-url';

  constructor(private http:HttpClient) { }

  notificaSms(phone:string, url:string):Observable<any>{
    return this.http.post(this.endpoint,{phone,url});
  }
}
