<app-main-container header="Selección seguro voluntario">

  <div class="p-4">
    <div *ngIf="seccion == 'seleccion'" id="seleccionplan">
      <p id="descripcion">
        Este proceso es importante para la verificación de nuestro cliente, se
        requiere alguna información por parte del cliente para finalizar el
        proceso.
      </p>

      <div class="card-body" style="max-width: 1440px">
        <h1>
          Seguro individual
        </h1>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="nplan sdow" style="width: 50px" scope="col">
                  Nombre <br />
                  plan
                </th>
                <!-- <th class="thBody" style="width: 70px">Valor comisión</th> -->
                <th class="thBody" style="width: 70px">Valor prima total</th>
                <th class="thBody" style="width: 70px">Valor prima mensual</th>
                <th class="thBody" style="width: 70px">Valor prima diaria</th>
                <th class="thBody" style="width: 70px">Monto asegurado</th>
                <th class="thBody" style="width: 50px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dato of planesIndividuales; let i = index" class="grow"
                (click)="seleccionaPlan(dato, 'individual')" style="cursor: pointer">
                <th [style]="
                  planseleccionado == dato.codigoPlan
                    ? 'border-left: 5px solid #F9C532 !important'
                    : ''
                " [class]="i == planesIndividuales.length - 1 ? 'border' : ''" style="
                  width: 50px;
                  background-color: white;
                  border-bottom: 1px solid white;
                " class="right left sdow">
                  {{ dato.detalleSeguro }}
                </th>
                <!-- <td style="width: 70px; border-left: none !important">
                  $ {{ dato?.valorComision | number: "1.0-0" }}
                </td> -->
                <td style="width: 70px" currencyMask>
                  $ {{ dato.valorSeguro | number: "1.0-0" }}
                </td>
                <td style="width: 70px" currencyMask>
                  $ {{ dato.primaMensual | number: "1.0-0" }}
                </td>
                <td style="width: 70px" currencyMask>
                  $ {{ dato.primaDiaria | number: "1.0-0" }}
                </td>
                <td style="width: 70px" currencyMask>
                  $ {{ dato.valorCobertura | number: "1.0-0" }}
                </td>
                <td style="height: 100%">
                  <div class="select-container">
                    <mat-radio-button style="padding: 0" [checked]="planseleccionado == dato.codigoPlan">
                    </mat-radio-button>
                  </div>
                </td>
                <!--  <td style="width: 50px;"><div class="seguro"><input [value]="dato.codigoPlan" [(ngModel)]='planseleccionado' name="inlineRadioOptions" type="radio"></div></td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="loading" style="display: flex; justify-content: center">
          <mat-spinner diameter="70"></mat-spinner>
        </div>
      </div>
      <br>
      <div class="card-body" style="max-width: 1440px">
        <h1>
          Seguro familiar
        </h1>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="nplan sdow" style="width: 50px" scope="col">
                  Nombre <br />
                  plan
                </th>
                <!-- <th class="thBody" style="width: 70px">Valor comisión</th> -->
                <th class="thBody" style="width: 70px">Valor prima total</th>
                <th class="thBody" style="width: 70px">Valor prima mensual</th>
                <th class="thBody" style="width: 70px">Valor prima diaria</th>
                <th class="thBody" style="width: 70px">Monto asegurado</th>
                <th class="thBody" style="width: 50px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dato of planesFamiliares; let i = index" class="grow"
                (click)="seleccionaPlan(dato, 'familiar')" style="cursor: pointer">
                <th [style]="
                  planseleccionado == dato.codigoPlan
                    ? 'border-left: 5px solid #F9C532 !important'
                    : ''
                " style="
                  width: 50px;
                  background-color: white;
                  border-bottom: 1px solid white;
                " class="right left sdow" [class]="i == planesFamiliares.length - 1 ? 'border' : ''">
                  {{ dato.detalleSeguro }}
                </th>
                <!-- <td style="width: 70px">
                  $ {{ dato?.valorComision | number: "1.0-0" }}
                </td> -->
                <td style="width: 70px">
                  $ {{ dato.valorSeguro | number: "1.0-0" }}
                </td>
                <td style="width: 70px">
                  $ {{ dato.primaMensual | number: "1.0-0" }}
                </td>
                <td style="width: 70px">
                  $ {{ dato.primaDiaria | number: "1.0-0" }}
                </td>
                <td style="width: 70px">
                  $ {{ dato.valorCobertura | number: "1.0-0" }}
                </td>
                <td style="height: 100%">
                  <div class="select-container">
                    <mat-radio-button [checked]="planseleccionado == dato.codigoPlan"></mat-radio-button>
                  </div>
                </td>
                <!--  <td style="width: 50px;"><div class="seguro"><input [value]="dato.codigoPlan" [(ngModel)]='planseleccionado' name="inlineRadioOptions" type="radio"></div></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="loading" style="display: flex; justify-content: center">
          <mat-spinner diameter="70"></mat-spinner>
        </div>
        <div class="planes">
          <button-look-feel color="outline" (clicked)="regresar()">Regresar</button-look-feel>
          <button-look-feel color="secondary" (clicked)="continuar()">Guardar y Continuar</button-look-feel>
        </div>
      </div>
    </div>

    <div id="planfamiliar" *ngIf="seccion == 'planfamiliar'">
      <h1>Seguro voluntario familiar</h1><br>
      <p id="descripcion">
        Este proceso es importante para la verificación de nuestro cliente, se
        requiere alguna información por parte del cliente para finalizar el
        proceso.
      </p>
      <!--  <h2>Información del beneficiario principal</h2> -->
      <div>
        <h1>Información de beneficiarios</h1>
        <div>
          <div *ngFor="let asegurado of dynamicForms; let i = index">
            <form [formGroup]="asegurado">
              <div style="width: 100%">
                <div style="display: flex; justify-content: space-between">
                  <mat-label>Parentesco</mat-label>
                  <app-eliminar [texto]="'Eliminar asegurado'" *ngIf="i != 0" (click)="eliminarFamiliar(i)">
                  </app-eliminar>
                </div>
              </div>
              <div class="row">
                <div>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="parentesco" required>
                      <mat-option [value]="parent.codigo" *ngFor="let parent of parentescos">
                        {{ parent.nombre }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="error" *ngIf="asegurado.controls.parentesco.hasError('required')">
                      Campo obligatorio
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 form-field">
                  <label for="primerNombre" class="form-label">Primer nombre</label>
                  <input type="text" class="form-control" placeholder="*Primer nombre" id="primerNombre"
                    autocomplete="off" formControlName="primerNombre">
                  <div class="form-error" *ngIf="asegurado.controls.primerNombre.hasError('required') && !asegurado.controls.primerNombre.pristine">
                      Campo obligatorio
                  </div>
                </div>
                <div class="col-md-3 form-field">
                  <label for="segundoNombre" class="form-label">Segundo nombre (opcional)</label>
                  <input type="text" class="form-control" placeholder="*Segundo nombre" id="segundoNombre"
                    autocomplete="off" formControlName="segundoNombre">
                </div>
                <div class="col-md-3 form-field">
                  <label for="primerApellido" class="form-label">Primer apellido</label>
                  <input type="text" class="form-control" placeholder="*Primer apellido" id="primerApellido"
                    autocomplete="off" formControlName="primerApellido">
                  <div class="form-error" *ngIf="asegurado.controls.primerApellido.hasError('required') && !asegurado.controls.primerApellido.pristine">
                      Campo obligatorio
                  </div>
                </div>
                <div class="col-md-3 form-field">
                  <label for="segundoApellido" class="form-label">Segundo apellido (opcional)</label>
                  <input type="text" class="form-control" placeholder="*Segundo apellido" id="segundoApellido"
                    autocomplete="off" formControlName="segundoApellido">
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <mat-label>Tipo de documento</mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="tipoDocumento" required>
                      <mat-option [value]="ident.codigoTipoIdentificacion" *ngFor="let ident of tiposIdentificacion">
                        {{ ident.nombre }}
                      </mat-option>
                    </mat-select>
                    <mat-error class="error" *ngIf="asegurado.controls.tipoDocumento.hasError('required')">
                      Campo obligatorio
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 form-field">
                  <label for="numeroDocumento" class="form-label">Número de documento</label>
                  <input type="number" inputmode="numeric" class="form-control" placeholder="*Número de documento"
                    id="numeroDocumento" formControlName="numeroDocumento" autocomplete="off">
                  <div class="form-error" *ngIf="asegurado.controls.numeroDocumento.invalid && !asegurado.controls.numeroDocumento.pristine">
                    Número de documento es obligatorio
                  </div>
                </div>
                <div class="col-md-3 form-field">
                  <label for="fechaNacimiento" class="form-label">Fecha de nacimiento</label>
                  <div class="input-group">
                    <input id="fechaNacimiento" class="form-control form-calendar" placeholder="*Selecciona una fecha"
                      autocomplete="off" formControlName="fechaNacimiento" ngbDatepicker #fechaNacimiento="ngbDatepicker"
                      [maxDate]="today" [minDate]="{ year: 1900, month: 1, day: 1 }" (click)="fechaNacimiento.toggle()" (dateSelect)="onValidateDate()"                      >
                  </div>
                  <div class="form-error" *ngIf="asegurado.get('fechaNacimiento')?.hasError('ageOutOfRange')">
                    La edad debe estar entre los 18 y 80 años
                  </div>
                  <div class="form-error"
                    *ngIf="asegurado.get('fechaNacimiento')?.hasError('ngbDate') && !asegurado.get('fechaNacimiento').pristine">
                    formato invalido (DD/MM/AAAA)
                  </div>
                  <div class="form-error"
                    *ngIf="asegurado.get('fechaNacimiento')?.hasError('required') && !asegurado.get('fechaNacimiento').pristine">
                    La fecha de nacimiento es obligatoria
                  </div>
                </div>
              </div>
              <div class="row">
                <a (click)="agregarAsegurado()" role="button" style="color: #009df3; text-decoration: underline">Agregar
                  otro familiar</a>
              </div>
              <div *ngIf="dynamicForms.length - 1 != i" style="
                border-bottom: 1px solid gray;
                padding-bottom: 10px;
                max-width: 1140px;
                margin-bottom: 10px;
              "></div>
            </form>
          </div>
        </div><br>
        <div class="justify-right">
          <p>
            Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás aceptando
            que la información es correcta
          </p>
        </div>
        <div class="justify-right">
          <button-look-feel color="outline" (clicked)="seccion = 'seleccion'">Regresar</button-look-feel>
          <button-look-feel color="secondary" (clicked)="guardarSeleccion()"
            [disabled]="disabledButtonBeneficiaries">Guardar y Continuar</button-look-feel>
        </div>
      </div>
    </div>
  </div>

  <div data-backdrop="static" class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <img src="https://media.giphy.com/media/3o85xITplfSixzj4cg/giphy.gif" width="200" height="200" />
      </div>
    </div>
  </div>
</app-main-container>
