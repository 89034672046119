import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { OfertaService } from './oferta.service';

import { DialogModificaComponent } from '../../pwa/pre-oferta/dialog-modifica/dialog-modifica.component';
import { DialogRechazaComponent } from '../../pwa/pre-oferta/dialog-rechaza/dialog-rechaza.component';

import { Oferta } from '../../models/oferta';
import { IProfilingLiteResponse } from './interfaces';

import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';
import { GenOfertaService } from '../../services/genOferta/gen-oferta.service';

import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { Condicion } from 'src/app/models/condicion';
import { DataOfertaService } from 'src/app/services/genOferta/dataOferta.service';
import Swal from 'sweetalert2';
// import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: [
    './oferta.component.css',
    '../../pwa/pre-oferta/pre-oferta.component.css',
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
})
export class OfertaComponent implements OnInit {
  profilingData: IProfilingLiteResponse;

  credito: string = '2';
  isLoaded: boolean = false;

  continuarSinSeguro: boolean = false;
  ofertas: any;
  seguros: any;
  disabledCheck: boolean = false;
  seguroVoluntario: any = null;
  generaOferta: any = null;
  montoMinimo: number | string;
  collapsePoli: string = 'false';
  valorPoliza: number | string;
  isUpdateMonto = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly _seizService: OfertaService,
    private readonly _data: DataService,
    private readonly _offerService: GenOfertaService,
    private readonly _dialogService: DialogService,
    private _geofertaServc: GenOfertaService,
    public _dataOferta: DataOfertaService,
    private _dialogSrvc: DialogService,
    private route: Router
  ) {}

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.remove('bg-blue-gradient-bottom');
    }
    this.seizService();
  }

  ngOnDestroy() {
    const article = document.querySelector('article');
    if (article) {
      article.classList.add('bg-blue-gradient-bottom');
    }
  }

  async seizService() {
    try {
      this.isLoaded = true;
      this.showModalLoading();
      const res = await this._seizService
        .profilingLite(this._data.numeroCarpeta)
        .toPromise(); // Convertimos el Observable en una Promesa

      if (!this._data.isUpdateSeguro) {
        this._data.valorMontoInicial = res.montoSeguro
          ? res.montoSeguro
          : res.montoSolicitado;
        this._data.isUpdateSeguro = true;
      }

      if (res.status === 400) {
        this._dialogService.openDialog('Advertencia', res.message, false);
        return;
      }

      console.log('dataOfertaInicial', this._dataOferta);

      this.profilingData = res;
      const monto = this._data.isUpdateSeguro
        ? this._data.valorMontoInicial
        : this.profilingData.montoSeguro
        ? this.profilingData.montoSeguro
        : this.profilingData.montoSolicitado;

      this.seguroVoluntario = this.profilingData.ofertaSeguro;
      this._dataOferta.seguroVoluntario = this.seguroVoluntario;
      this._dataOferta.montoSolicitado = monto;

      console.log('profilingData', res);
      console.log('seguroVoluntario106', this.seguroVoluntario);

      // Esperamos a que se complete generarOferta
      await this.generarOferta(this._data.numeroCarpeta);

      this.ofertas = this.profilingData;
      this.ofertas.monto = this.profilingData.montoSolicitado;
      this.ofertas.plazo = this.profilingData.plazoSolicitado;
      this.ofertas.cuota = this.profilingData.cuotaSolicitada;

      this._dataOferta.oferta = this.ofertas;

      if (
        !(
          this._dataOferta.oferta.ofertasSeguro &&
          this._dataOferta.oferta.ofertasSeguro.length > 0
        )
      ) {
        this._dataOferta.oferta.ofertasSeguro = this.seguros;
      }
      console.log('dataOferta130', this._dataOferta);
      console.log('plazoOferta', this._dataOferta.oferta.plazo);

      // Si no hay seguro voluntario, activa continuarSinSeguro
      if (this.seguroVoluntario.codigoPlan == 0) {
        this.continuarSinSeguro = true;
      }
      console.log('thisofertas145', this.ofertas);
      console.log('montosolicitado', this.ofertas.montoSolicitado);
      console.log('montomaximo', this.ofertas.montoMaximo);

      if (
        this.seguroVoluntario.codigoPlan > 0 &&
        Number(this.ofertas.montoSolicitado) < Number(this.ofertas.montoMaximo)
      ) {
        this.cambioCondiciones(true);
      }

      // Ajusta las condiciones y resetea ofertas
      this._dataOferta.ajustarCondiciones = true;
      this.ofertas = null;
      this.isLoaded = false;
      Swal.close();
    } catch (err) {
      this.isLoaded = false;
      Swal.close();
      console.log('Error PerfilamientoLite', err);
      this._dialogService.openDialog(
        'Advertencia',
        'Error cargando perfilamiento Seiz: ' + err,
        false
      );
    }
  }

  public collapse() {
    var elem = document.querySelector('.politicasColl');
    var b = elem.getAttribute('aria-expanded');
    this.collapsePoli = b;
  }

  public changeAmmount(): void {
    const dialogRef = this.dialog.open(DialogModificaComponent);

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.isUpdateMonto = true;
        if (this._seizService.statusService === 200) {
          this.profilingData.montoSolicitado =
            this._seizService?.refAmmount.toString() ||
            this.profilingData.montoSolicitado;
          this.profilingData.plazoSolicitado =
            this._seizService?.refTerm || this.profilingData.plazoSolicitado;
          this.profilingData.cuotaSolicitada =
            this._seizService?.refFee.toString() ||
            this.profilingData.cuotaSolicitada;

          this._dialogService.openDialog(
            'Oferta',
            this._seizService.messageService,
            true
          );
        } else {
          this._dialogService.openDialog(
            'Advertencia',
            this._seizService.messageService,
            true
          );
        }
      }, 1500);
    });
  }

  public acceptOffer(): void {
    this.isLoaded = true;

    console.log('profilingDataAceptarOferta', this.profilingData);

    const {
      cuotaSolicitada,
      cuotaMaxima,
      montoSolicitado,
      montoMaximo,
      plazoSolicitado,
      plazoMaximo,
      tasa,
      ofertaSeguro: { valorSeguro, codigoPlan },
    } = this.profilingData;

    let valSeg = 0;
    let codPlan = 0;

    if (!this.continuarSinSeguro && this.seguroVoluntario) {
      valSeg = this.seguroVoluntario.valorSeguro;
      codPlan = this.seguroVoluntario.codigoPlan;
    }

    let oferta: Oferta = {
      cuota:
        this.credito == '1' ? parseInt(cuotaSolicitada) : parseInt(cuotaMaxima),
      monto:
        this.credito == '1' ? parseInt(montoSolicitado) : parseInt(montoMaximo),
      montoSolicitado:
        this.credito == '1' ? parseInt(montoSolicitado) : parseInt(montoMaximo),
      numeroCarpeta: parseInt(this._data.numeroCarpeta),
      plazo: this.credito == '1' ? plazoSolicitado : plazoMaximo,
      plazoSolicitado: this.credito == '1' ? plazoSolicitado : plazoMaximo,
      tasa: parseFloat(tasa),
      tipoOferta: this.credito,
      valorSeguro: valSeg,
      codigoPlan: codPlan,
    };

    console.log('Oferta aceptada', oferta);

    this._offerService.aceptarOferta(oferta).subscribe(
      (res: any) => {
        if (res.code === 409) {
          this._dialogService.openDialog('Advertencia', res.message, false);
          this.isLoaded = false;
          return;
        }

        this.isLoaded = false;
        this.router.navigate(['/informacionadicional']);
      },
      (err) => {
        this.isLoaded = false;
        this._dialogService.openDialog('Advertencia', err.error.message, false);
      }
    );
  }

  public rejectOffer(): void {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(DialogRechazaComponent, dialogConfig);

    dialogConfig.data = { numeroCarpeta: this._data.numeroCarpeta };
    dialogRef.afterClosed().subscribe(console.log);
  }

  public continuarSinSeguroFunc(): void {
    this.cambioCondiciones(true);
    this.onDeletePoliza();
  }

  public cambioCondiciones(
    showConfirmation: boolean,
    retakeSecure: boolean = false
  ): void {
    this.disabledCheck = true;

    let valSeg = 0;

    if (!this.continuarSinSeguro && this.seguroVoluntario) {
      valSeg = this.seguroVoluntario.valorSeguro;
    }
    //this.getMontoSolicitado();

    console.log('seguroVoluntario235', this.seguroVoluntario);
    console.log('_dataOferta260', this._dataOferta);

    let condicion: Condicion = {
      monto: Number(this._dataOferta.montoSolicitado),
      tasa: parseFloat(this.profilingData.tasa),
      plazo: this._dataOferta.oferta.plazo,
      numeroCarpeta: parseInt(this._data.numeroCarpeta),
      valorSeguro: retakeSecure ? 0 : valSeg,
    };

    this._geofertaServc.cambioCondiciones(condicion).subscribe(
      (res) => {
        this.disabledCheck = false;
        this._dataOferta.ofertasCambioCondicion = res.ofertas;
        this.ofertas = this._dataOferta.oferta;
        this.ofertas.monto = res.montoSeguro;
        this.montoMinimo = res.montoSeguro;
        this.ofertas.cuota = res.valorCuotaSeguro;

        let nuevaOferta = this._dataOferta.ofertasCambioCondicion.find(
          (oferta: any) => oferta.tasa == parseFloat(this.profilingData.tasa)
        );

        this.ofertas.cuotaMaxima = nuevaOferta.cuotaSugerida;
        this.ofertas.montoMaximo = nuevaOferta.valorCreditoSegerido;
        this.ofertas.plazoMaximo = nuevaOferta.plazo;
        this.ofertas.porcentajeCorretaje = nuevaOferta.porcentajeCorretaje;
        this.ofertas.comisionMillon = nuevaOferta.valorComision;
        this._dataOferta.oferta = this.ofertas;

        this.profilingData.montoSolicitado = this.ofertas.monto;
        this.profilingData.plazoSolicitado = nuevaOferta.plazoSolicitado;
        this.profilingData.cuotaSolicitada = this.ofertas.cuota;

        this._dataOferta.montoSolicitado = nuevaOferta.monto;

        console.log('cambioscondiciones', res);
        console.log('profilingDataCambioCondiciones', this.profilingData);

        if (showConfirmation) {
          this._dialogSrvc.openDialog(
            'Hecho',
            'Cambio de condiciones realizado con éxito.',
            true
          );
        }
      },
      (err) => {
        this.ofertas = null;
        this.disabledCheck = false;
        this._dialogSrvc.openDialog(
          'Advertencia',
          'Error en cambio de condiciones: ' + err.error.message,
          false
        );
      }
    );
  }

  private onDeletePoliza(): void {
    if (this.continuarSinSeguro) {
      this._geofertaServc.eliminarPoliza(this._data.numeroCarpeta).subscribe();
    } else {
      this._geofertaServc.crearPoliza(this._data.numeroCarpeta, 22).subscribe(
        () => {
          this.ofertas = null;
          this.seguroVoluntario = null;
          this.generarOferta(this._data.numeroCarpeta);
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  // private async generarOferta(numeroCarpeta: string){
  //   if (!this.ofertas) {
  //     this._geofertaServc.genOferta(numeroCarpeta).subscribe((res) => {
  //       console.log("generarOferta", res);
  //       this._dataOferta.oferta = res;
  //       this._dataOferta.montoSolicitado = res.monto;

  //       let segurosIndividuales: any = [];

  //       if (res.ofertasSeguro.length > 0) {
  //         res.ofertasSeguro.forEach((oferta: any) => {
  //           if (oferta.seguroFamiliar.length == 0) {
  //             segurosIndividuales.push(oferta);
  //           }
  //         });
  //       } else {
  //         console.log('No hay ofertas de seguro');
  //       }
  //       /*No aplica para Seiz porque carga una póliza si no viene seguro de preoferta*/
  //       /*if (!this.seguroVoluntario) {
  //         //  Ordenar, prima más alta primero
  //         segurosIndividuales.sort((a: any, b: any) => {
  //           if (a.primaDiaria < b.primaDiaria) {
  //             return 1;
  //           }

  //           if (a.primaDiaria > b.primaDiaria) {
  //             return -1;
  //           }
  //           // a must be equal to b
  //           return 0;
  //         });

  //         this.seguroVoluntario = segurosIndividuales[0];
  //         this._dataOferta.seguroVoluntario = this.seguroVoluntario;

  //         if (this.seguroVoluntario) {
  //           this.crearPoliza();
  //         }
  //         console.log("cambiodecondiciones");
  //         this.cambioCondiciones(true);
  //       }*/
  //     }, (err) => {
  //       this._dialogSrvc.openDialog('Advertencia', err.error.error.message, false);
  //     }
  //     );
  //   }
  // }

  private async generarOferta(numeroCarpeta: string): Promise<void> {
    try {
      if (!this.ofertas) {
        const res = await this._geofertaServc
          .genOferta(numeroCarpeta)
          .toPromise();

        this.generaOferta = res;
        console.log('generarOferta', this.generaOferta);

        //this._dataOferta.oferta.ofertasSeguro = this.generaOferta.ofertasSeguro;
        //console.log("dataOferta378",this._dataOferta);

        this.seguros = this.generaOferta.ofertasSeguro;
        //this._dataOferta.montoSolicitado = this.generaOferta.monto;
        //this.getMontoSolicitado();

        /*let segurosIndividuales: any = [];

        if (this.generaOferta.ofertasSeguro.length > 0) {
          this.generaOferta.ofertasSeguro.forEach((oferta: any) => {
            if (oferta.seguroFamiliar.length == 0) {
              segurosIndividuales.push(oferta);
            }
          });
        } else {
          console.log('No hay ofertas de seguro');
        }*/
      }
    } catch (err) {
      this._dialogService.openDialog(
        'Advertencia',
        'Error generando la oferta: ' + err,
        false
      );
    }
  }

  private crearPoliza(): void {
    this._geofertaServc
      .checkPoliza(
        this._data.numeroCarpeta,
        Number(this._dataOferta.seguroVoluntario.codigoPlan)
      )
      .subscribe(
        (res) => {
          console.log('poliza creada', res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public irSeguroVoluntario(): void {
    this.route.navigateByUrl('/segurovoluntario', {
      state: {
        back: '/seiz/oferta',
      },
    });
  }

  private getMontoSolicitado() {
    this._dataOferta.montoSolicitado = String(this._dataOferta.oferta.monto);
    if (this.seguroVoluntario) {
      const valorPoliza = this.seguroVoluntario.valorSeguro;
      this._dataOferta.montoSolicitado = String(
        this._dataOferta.oferta.monto - valorPoliza
      );
      console.log('seguroVoluentario396', this.seguroVoluntario);
      console.log('valorPoliza', valorPoliza);
      console.log('ofertaMonto', this._dataOferta.oferta.monto);
      console.log('getMontoSolicitado', this._dataOferta.montoSolicitado);
    }
  }

  showModalLoading() {
    Swal.fire({
      allowOutsideClick: false,
      html: `
        <div class="modal download-spinner">
            <div class="contente-loading">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="texts">
                    <h3>Estamos procesando tu solicitud</h3>
                    <h4>Por favor, espera un momento.</h4>
                    <h4>No actualices ni cierres esta ventana.</h4>
                </div>
            </div>
        </div>
      `,
      showConfirmButton: false,
    });
  }

  formatMoney(valor = '') {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      maximumFractionDigits: 0,
    }).format(Number(valor));
  }
}
