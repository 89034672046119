import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ageRangeValidator } from 'src/app/form-validators/age-range.validator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CargosService } from 'src/app/services/catalogos/cargos.service';
import { ConvenioService } from 'src/app/services/catalogos/convenios.service';
import { EntidadesFinancierasService } from 'src/app/services/catalogos/entidadesfinancieras.service';
import { GeneroService } from 'src/app/services/catalogos/genero.service';
import { LineaCreditoService } from 'src/app/services/catalogos/lineaCredito.service';
import { Pagaduriaservice } from 'src/app/services/catalogos/pagadurias.service';
import { TCreditoService } from 'src/app/services/catalogos/tCretito.service';
import { TiposIdentificacionService } from 'src/app/services/catalogos/tipos-identificacion.service';
import { TipoContratoService } from 'src/app/services/catalogos/tiposContrato.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DataOfertaService } from 'src/app/services/genOferta/dataOferta.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service';
import { CambioFlujoService } from 'src/app/services/perfilamiento/cambio-flujo.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { GuardarCarpetaService } from 'src/app/services/perfilamiento/guardarCarpeta.service';
import { PerfInfLabService } from 'src/app/services/perfilamiento/perf-inf-lab.service';
import { PerfilamientoService } from 'src/app/services/perfilamiento/perf-inf-personal.service';
import { VigenteFondeo } from 'src/app/services/vigenteFondeo.service';
import { EdoLaboralService } from '../../services/catalogos/edoLaboral.service';
import { DataService } from '../../services/data.service';
import { PerfInfFinancieraService } from '../../services/perfilamiento/perf-inf-financiera.service';
import { PerfInfSolicitudService } from '../../services/perfilamiento/perf-inf-solicitud.service';
import { RefinanciacionService } from '../../services/perfilamiento/refinanciacion.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
})
export class FormularioComponent implements OnInit, AfterViewInit {

  currentlyDisabled: string;
  isEditable = false;
  guardarPersonalInfo = true;
  personalInfo: UntypedFormGroup;
  laboralInfo: UntypedFormGroup;
  financieraInfo: UntypedFormGroup;
  solicitudInfo: UntypedFormGroup;
  formRefinanciacion: UntypedFormGroup;
  formLibreInversion: Array<any> = [];
  datosCompraCartera: Array<any> = [];
  formRefinanciacionCartera: Array<any> = [];
  hoy: Date = new Date();
  //  formulariosCompletos:any[]=[false,false,false,false];
  datosRefinanciacion: {
    numeroCredito: string;
    pagaduria: string;
    valorCuota: number;
  } = { numeroCredito: '', pagaduria: '', valorCuota: 0 };
  formularios = [
    { name: 'collapseOne', open: true, saved: false },
    { name: 'collapselaboral', open: false, saved: false },
    { name: 'collapseFinanciera', open: false, saved: false },
    { name: 'collapseSolicitud', open: false, saved: false },
  ];

  cellphone: string = '';
  numeroSolicitud: string = '';
  //---- datos de catalogos
  tipos_credito: any;
  pagaduarias: any;
  lineascredito: any;
  convenios: any;
  tiposContratos: any;
  generos: any;
  tiposId: any;
  edoLaboral: any;
  entidadesFinanc: any[];
  activeLibreInversion: boolean = false;
  cargos: any;
  loading: boolean = false;
  //datos de respuesta ok
  codigoSolicitud: string = '';
  numeroCarpeta: string = '';


  //valores para condicionales
  lineacredito: string = '';

  //Valores para refinanciacion
  libres = [];
  isRefinanciacion: boolean = false;
  isNewCredito: boolean = false;

  compraCartera: boolean = false;
  libreInversion: boolean = false;

  contFormLibreInversion: number = 1;
  codigoPagaduria: number;
  creditosAcumulados: any[] = [];
  creditoSeleccionado: number;
  creditosRefinanciacion: any[] = [];
  variosCreditos: boolean = false;
  libreNumeroCredito: any;
  selected: string = 'Selecciona ...';
  pagaduria: number;
  creditoObligatorio: string = '';
  validarCreditos: boolean = true;

  // Valores de Información Financiera
  creditosEnviados: Array<{ numeroCredito: number; valorCredito: number }> = [];
  otrosIngresosForm: number = 0;
  saludForm: number = 0;
  pensionForm: number = 0;
  retefuenteForm: number = 0;
  fondoSolidaridadForm: number = 0;
  caprovimpoForm: number = 0;
  otrosDescuentosForm: number = 0;
  montoForm: number = 0;
  valorCuotaForm: number = 0;
  valorCuota2Form: number = 0;
  montoCompraCarteraForm: number = 0;

  tipoContratoModel: any;

  creditosActivos: any;

  // Para filtros de select
  public filteredConvenio: any;
  public filteredPagaduria: any;
  public filteredTiposContrato: any;
  public filteredCargos: any;
  public filteredEdoLab: any;
  public filteredTiposCredito: any;
  public filteredLineaCredito: any;
  public filteredEntidadesFinanc: any;

  plazoModel: any;
  tipoCreditoModel: any = '';
  lineaCredito: any = '';

  public dataFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public _onDestroy = new Subject<void>();
  public filteredData: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  form: any;

  isLoaded: boolean = false;

  refinancialArr: Array<{
    numeroCredito: string;
    pagaduria: string;
    valorCuota: number;
    _i: any;
  }> = [];

  isOpenAlertaPlazo = false;
  today: NgbDateStruct;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private tCreditoService: TCreditoService,
    private pagaduriaService: Pagaduriaservice,
    private lineaCreditoService: LineaCreditoService,
    private convenioService: ConvenioService,
    public datepipe: DatePipe,
    private router: Router,
    private generoService: GeneroService,
    private edoLaboralService: EdoLaboralService,
    private dialogService: DialogService,
    private tipoIdService: TiposIdentificacionService,
    private laboperfilamientoService: PerfInfLabService,
    private finanperfilamientoService: PerfInfFinancieraService,
    private soliperfilamientoService: PerfInfSolicitudService,
    private infoperfilamientoService: PerfilamientoService,
    private guardarCarpetasrvc: GuardarCarpetaService,
    private tipoContratoService: TipoContratoService,
    private entfinancieras: EntidadesFinancierasService,
    private cargoserv: CargosService,
    private cambioflujoserv: CambioFlujoService,
    private getinfoServ: GetInformacionService,
    private cambioEstadoServ: CambioestadocarpetaService,
    public data: DataService,
    public dialog: MatDialog,
    private geOfertaSrvc: GenOfertaService,
    public refinanciacion: RefinanciacionService,
    private vigenteFondeo: VigenteFondeo,
    private _dataOferta: DataOfertaService,
    private cdr: ChangeDetectorRef,
    private calendar: NgbCalendar
  ) {
    this.today = this.calendar.getToday();
    this.hoy = new Date(this.hoy.getFullYear() - 1, 11, 31);
    this.personalInfo = this._formBuilder.group({
      primerNombre: [{ value: '' }, Validators.required],
      segundoNombre: [{ value: '' }],
      primerApellido: [{ value: '' }, Validators.required],
      segundoApellido: [{ value: '' }],
      fechaNacimiento: ['', [Validators.required, ageRangeValidator(18, 80)]],
      genero: [{ value: 1 }, Validators.required],
      tipoIdentificacion: [{ value: 'C.C.' }, Validators.required],
      numeroIdentificacion: [{ value: '' }, Validators.required,],
      correo: [{ value: '' }, Validators.required,],
      celular: [{ value: '' }, [Validators.required, Validators.pattern('3[0-9]{9}')],],
      numeroCarpeta: [{ value: '' }, Validators.required,],
    });

    this.laboralInfo = this._formBuilder.group({
      codigoConvenio: ['', Validators.required],
      codigoPagaduria: ['', Validators.required],
      codigoEstadoLaboral: ['', Validators.required],
      codigoTipoContrato: ['', Validators.required],
      codigoCargo: ['', Validators.required],
      fechaIngreso: ['', Validators.required],
    });

    this.financieraInfo = this._formBuilder.group({
      salario: ['', [Validators.required, Validators.min(1)]],
      otrosIngresos: [0, Validators.min(0)],
      salud: ['', [Validators.required, Validators.min(1)]],
      pension: [0, [Validators.min(0)]],
      retefuente: [0, Validators.min(0)],
      fondoSolidaridad: [0, Validators.min(0)],
      caproimpo: [0, Validators.min(0)],
      otrosDescuentos: [0, Validators.min(0)],
    });

    this.solicitudInfo = this._formBuilder.group({
      tipoCredito: ['', Validators.required],
      lineaCredito: ['', Validators.required],
      plazo: ['', [Validators.required, Validators.min(10), Validators.max(999)]],
      monto: ['', [Validators.required, Validators.min(0)]],
    });

    this.formRefinanciacion = this._formBuilder.group({
      numeroCredito: [''],
      pagaduria: [''],
      valorCuota: [0],
    });

    this.formRefinanciacionCartera.push({
      lineaCredito: { value: '', valid: false },
      pagaduria: { value: '', valid: false },
      valorCuota: { value: '', valid: false },
      saldo: { value: '', valid: false },
    });
  }

  async ngOnInit(): Promise<void> {
    this._dataOferta.cleanData();

    this.cellphone = this.data.cellphone;
    this.numeroSolicitud = this.data.numeroSolicitud;
    this.codigoSolicitud = this.data.numeroSolicitud;
    this.numeroCarpeta = this.data.numeroCarpeta;

    this.getInfoLaboral(this.numeroSolicitud);
    this.getLineasCredito();
    this.getConvenios();
    this.getGeneros();
    await this.getTipoIdentificacion();

    this.inicializarFormularios();

    this.modalWarning(
      'Prevé errores verificando que nombres, apellidos y la fecha de nacimiento coincidan con los datos del solicitante.'
    );

    this.dataFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterData();
      });

    this.cambioEstadoServ
      .cambioEstadocarpeta(
        Number(this.data.numeroCarpeta),
        '40',
        'Cambio de estado aprobado por el Asesor'
      )
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.error(err);
        }
      );

    await this.getInforPersonal(this.numeroSolicitud);

    await this.getEntidadesFinancieras();
    this.getInformacionFinanciera(this.numeroSolicitud);
    this.getInformacionSolicitud(this.numeroSolicitud);
    this.getDatosCompra(this.numeroSolicitud);
    this.getRefinanciacion(this.numeroSolicitud);
    let fechaMax = this.datepipe.transform(this.hoy, 'yyyy-MM-dd');
  }

  async ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  //Funciones refinanciacion
  selectEstadoLaboral(estado) {
    this.data.estado = estado;
  }

  inicializarFormularios() {
    this.creditosAcumulados = [];

    console.log('Inicializar form', this.libres, this.formLibreInversion);
    if (this.libres.length <= 0) {
      this.libres = [
        {
          libreNumeroCredito: { value: '', valid: false },
          librePagaduria: { value: '', valid: false },
          libreValorCuota: { value: '', valid: false },
          libreSaldo: { value: '', valid: false },
        },
      ];
    }

    if (this.formLibreInversion.length <= 0) {
      this.formLibreInversion = [
        {
          libreNumeroCredito: { value: '', valid: false },
          librePagaduria: { value: '', valid: false },
          libreValorCuota: { value: '', valid: false },
          libreSaldo: { value: '', valid: false },
        },
      ];
    }
  }

  selecTipoCredito(elemento) {
    if (elemento == 'NUEVO') {
      this.isNewCredito = true;
      this.isRefinanciacion = false;
      this.refinancialArr = [];
      this.inicializarFormularios();
    } else {
      this.showCreditosLibreInversion();
      this.isRefinanciacion = true;
      this.isNewCredito = false;
    }
  }

  showCreditosLibreInversion() {
    this.refinanciacion
      .busquedaCreditos(this.data.cedula, this.data.pagaduriaRef)
      .subscribe((resp) => {
        this.creditosRefinanciacion = resp;
        console.log(resp);
      });
  }

  seleccionarNumeroCredito(numero, credito: any, i) {
    let contForm = this.formLibreInversion.length - 1;
    let buscar = this.creditosAcumulados.indexOf(numero);

    if (buscar == -1) {
      this.creditosRefinanciacion.filter((item: any) => {
        if (item.numeroCredito == numero) {
          this.libres[contForm].librePagaduria.value = item.nombrePagaduria;
          this.libres[contForm].libreValorCuota.value = item.valorCuota;
          this.libres[contForm].libreSaldo.value = item.saldo;
          this.libres[contForm].libreNumeroCredito.value = numero;
        }
      });

      this.creditoSeleccionado = numero;
    } else {
      this.dialogService.openDialog(
        'Error',
        `El credito ${numero}, se encuentra seleccionado`,
        false
      );
    }
    let next: boolean = true;
    let new_i: boolean = true;
    const obj: {
      numeroCredito: string;
      pagaduria: string;
      valorCuota: number;
      _i;
    } = {
      numeroCredito: String(credito.numeroCredito),
      pagaduria: String(credito.codigoPagaduria),
      valorCuota: Number(credito.valorCuota),
      _i: i,
    };
    this.refinancialArr.filter((item) => {
      if (item.numeroCredito == obj.numeroCredito) {
        this.dialogService.openDialog(
          'Crédito seleccionado',
          `El crédito ${obj.numeroCredito}, ya ha sido seleccionado.`,
          true
        );
        next = false;
      }
    });
    if (next) {
      if (this.refinancialArr.length == 0) {
        this.refinancialArr.push(obj);
        return;
      }
      this.refinancialArr.filter((item, index) => {
        if (item._i === i) {
          console.log('replace', this.refinancialArr.splice(index, 1));
          this.refinancialArr.push(obj);
          new_i = false;
        }
      });
      if (new_i) {
        this.refinancialArr.push(obj);
      }
      return;
    }
  }

  addRefinanciacion() {
    const { numeroCreditosActivos } = this.creditosActivos;

    if (
      numeroCreditosActivos > 1 &&
      this.formLibreInversion.length < numeroCreditosActivos
    ) {
      this.formLibreInversion.push({
        libreNumeroCredito: { value: '', valid: false },
        librePagaduria: { value: '', valid: false },
        libreValorCuota: { value: '', valid: false },
        libreSaldo: { value: '', valid: false },
      });

      this.libres.push({
        libreNumeroCredito: { value: '', valid: false },
        librePagaduria: { value: '', valid: false },
        libreValorCuota: { value: '', valid: false },
        libreSaldo: { value: '', valid: false },
      });

      this.contFormLibreInversion++;
      this.creditosAcumulados.push(this.creditoSeleccionado);
    }
  }

  addRefinanciacionCompra() {
    this.formRefinanciacionCartera.push({
      lineaCredito: { value: '', valid: false },
      pagaduria: { value: '', valid: false },
      valorCuota: { value: '', valid: false },
      saldo: { value: '', valid: false },
    });
  }

  eliminarRefinanciacion(inversion: any, index) {
    inversion.splice(index, 1);
    this.libres.splice(index, 1);
    this.creditosAcumulados.splice(index, 1);
    this.contFormLibreInversion--;
    this.creditosAcumulados.length == 1 ? (this.creditosAcumulados = []) : '';
    let refinancialTmp = this.refinancialArr;
    this.refinancialArr.filter((item) => {
      if (item._i == index) {
        refinancialTmp.splice(index, 1);
      }
    });
  }

  eliminarRefinanciacionCompra(compra: any, index) {
    compra.splice(index, 1);
  }

  public busquedaCreditosPorPagaduria(pagaduria, cedula) {
    this.refinanciacion.busquedaCreditoActivo(cedula, pagaduria).subscribe(
      (res) => {
        this.creditosActivos = res;
        this.codigoPagaduria = pagaduria;
        this.data.pagaduriaRef = this.codigoPagaduria.toString();
        const { numeroCreditosActivos, indPermiteParalelos } =
          this.creditosActivos;
        numeroCreditosActivos > 1
          ? (this.variosCreditos = true)
          : (this.variosCreditos = false);
        let paralelos = indPermiteParalelos;
        numeroCreditosActivos !== 0
          ? this.getTipoCredito(1, paralelos)
          : this.getTipoCredito(0, paralelos);
      },
      (err) => {
        console.error('Busqueda por creditos activos', err);
      }
    );
  }

  /********************FIN********************* */

  private getCarteras(): void {
    this.soliperfilamientoService
      .getCompraCartera(parseInt(this.data.numeroSolicitud))
      .subscribe((res) => {
        this.cambiarLineaCredito('COMPRA CARTERA');
        this.datosCompraCartera = [];

        res.rows.forEach((e) => {
          this.datosCompraCartera.push({
            entidadCompra: {
              value:
                e.entidadCompra || this.entidadesFinanc[0][0]?.codigo,
              valid: true,
            },
            valorCuota: { value: parseInt(e.valorCuota), valid: true },
            monto: { value: parseInt(e.monto), valid: true },
            desprendible: e.desprendible == 'Si' ? true : false,
            refName: '',
          });
        });

        console.log('before inside get carteras', this.compraCartera);

        if (res.rows.length == 0 && this.compraCartera == true) {
          console.log('inside  get carteras');
          this.cambiarLineaCredito('COMPRA CARTERA');
        }
      });
  }

  private getRefinanciacionData(): void {
    this.soliperfilamientoService
      .getRefinanciacion(parseInt(this.data.numeroSolicitud))
      .subscribe((res) => {
        this.isRefinanciacion = true;
      });
  }

  public guardarInformacionPersonal(): void {
    this.isLoaded = true;

    if (this.personalInfo.get('genero').value === 0) {
      this.dialogService.openDialog(
        'Error',
        'Debes seleccionar un género',
        false
      );

      this.guardarPersonalInfo = true;
      this.isLoaded = false;

      return;
    }

    if (
      this.personalInfo.valid
    ) {
      let info: any = {};

      Object.assign(info, this.personalInfo.getRawValue());

      info.fechaNacimiento = `${info.fechaNacimiento.day}-${info.fechaNacimiento.month}-${info.fechaNacimiento.year}`
      if (this.codigoSolicitud) {
        info.numeroSolicitud = this.codigoSolicitud;
      }

      this.infoperfilamientoService.guardarPerfil(info).subscribe(
        (res: any) => {
          if (res.codigoSolicitud) {
            this.isLoaded = false;
            this.formularios[0].saved = true;

            this.openDialogPersonal();
            this.codigoSolicitud = res.codigoSolicitud;

            if (res.numeroCarpetaWfc) {
              this.personalInfo.patchValue({
                numeroCarpeta: res.numeroCarpetaWfc,
              });
            }
          } else {
            this.dialogService.openDialog('Advertencia', res.message, false);
            this.isLoaded = false;
          }
        },
        (err) => {
          this.dialogService.openDialog(
            'Advertencia',
            err.error.message,
            false
          );
          this.isLoaded = false;
        }
      );
    }
  }

  public guardarInformacionLaboral(): void {
    this.isLoaded = true;

    if (!this.laboralInfo.valid) {
      return;
    }

    let infolab: any = {};
    Object.assign(infolab, this.laboralInfo.value);
    infolab.codigoPagaduria = infolab.codigoPagaduria.toString();
    // this.data.pagaduria = infolab.codigoPagaduria;
    infolab.numeroSolicitud = this.codigoSolicitud.toString();
    infolab.fechaIngreso = `${infolab.fechaIngreso.day}-${infolab.fechaIngreso.month}-${infolab.fechaIngreso.year}`
    //Aqui se valida si permite la firma digital o no
    if (this.data.pagadurias) {
      let filteredPagadurias = this.data.pagadurias.find(
        (pagaduria) => pagaduria.codigoPagaduria == infolab.codigoPagaduria
      );
      this.data.firmaDigital = filteredPagadurias.firmaDigital;
      this.data.pagaduriaRef = filteredPagadurias.codigoPagaduria;

      this.isNewCredito = true;
    }

    this.laboperfilamientoService.savInfLab(infolab).subscribe(
      (res: any) => {
        if (res.json.status == 200) {
          this.openDialogLaboral();
          this.formularios[1].saved = true;
          this.isLoaded = false;
        }

        if (res.json.status == 409) {
          this.dialogService.openDialog('Advertencia', res.json.message, false);
          this.isLoaded = false;
        }
      },
      (err) => {
        this.dialogService.openDialog('Advertencia', err.error.message, false);
        this.isLoaded = false;
      }
    );
  }

  public guardarInformacionFinanciera(): void {
    this.isLoaded = true;

    let infofin = this.financieraInfo.value;

    infofin.numeroSolicitud = this.codigoSolicitud;

    if (this.financieraInfo.valid) {
      let {
        otrosIngresos,
        pension,
        retefuente,
        fondoSolidaridad,
        caproimpo,
        otrosDescuentos,
      } = this.financieraInfo.value;

      if (otrosIngresos === null) {
        infofin.otrosIngresos = 0;
      }

      if (pension === null) {
        infofin.pension = 0;
      }

      if (retefuente === null) {
        infofin.retefuente = 0;
      }

      if (fondoSolidaridad === null) {
        infofin.fondoSolidaridad = 0;
      }

      if (caproimpo === null) {
        infofin.caproimpo = 0;
      }

      if (otrosDescuentos === null) {
        infofin.otrosDescuentos = 0;
      }

      this.finanperfilamientoService.savInfFin(infofin).subscribe(
        (res: any) => {
          if (res.json.status == 200) {
            this.openDialogFinanciera();
            this.formularios[2].saved = true;
            this.isLoaded = false;
          }

          if (res.json.status == 409) {
            this.dialogService.openDialog(
              'Advertencia',
              res.json.message,
              false
            );
            this.isLoaded = false;
          }
        },
        (err) => {
          this.dialogService.openDialog(
            'Advertencia',
            err.error.message,
            false
          );
          this.isLoaded = false;
        }
      );
    }
  }

  public async guardarInformacionSolicitud(): Promise<void> {
    this.isLoaded = true;

    if (this.solicitudInfo.valid) {
      console.log({ compra: this.datosCompraCartera, libres: this.libres });
      const esRefinanciacion = this.solicitudInfo.value.tipoCredito == 2;

      const creditosFondeados = [];

      if (esRefinanciacion) {
        for (const libre of this.libres) {
          const { creditos } = await this.vigenteFondeo
            .validacionCreditos({ credito: libre.libreNumeroCredito.value })
            .toPromise();
          if (creditos) {
            creditosFondeados.push(libre.libreNumeroCredito.value);
          }
        }
        if (creditosFondeados.length != 0) {
          console.log({ creditosFondeados, libres: this.libres });
          const creditosRefinanciables = this.libres
            .filter(
              (cre) => !creditosFondeados.includes(cre.libreNumeroCredito.value)
            )
            .map((cre) => cre.libreNumeroCredito.value);

          let mensaje = `El crédito ${creditosFondeados.join(
            ', '
          )} no es sujeto de refinanciación. Valida la opción de tramitarlo como nuevo.`;
          if (creditosFondeados.length != this.libres.length) {
            mensaje = `El crédito ${creditosFondeados.join(
              ', '
            )} no es sujeto de refinanciación. El crédito ${creditosRefinanciables.join(
              ', '
            )} es sujeto de refinanciación. Ajusta las condiciones.`;
          }
          this.dialogService.openDialog(
            'Advertencia',
            mensaje,
            false,
            creditosFondeados.length != this.libres.length
              ? 'Ajustar'
              : 'Ir a inicio',
            () => {
              this.isLoaded = false;
              creditosFondeados.length != this.libres.length
                ? this.dialogService.closeDialog()
                : this.router.navigateByUrl('/home');
            }
          );
        }
      }

      this.guardarNuevoCredito();

      if (this.validarCreditos === false) {
        return;
      }

      let validacion = true;
      let infosoli = this.solicitudInfo.value;
      let carteras: Array<any> = [];
      infosoli.numeroSolicitud = Number(this.codigoSolicitud);
      infosoli.numeroCarpeta = Number(this.data.numeroCarpeta);
      infosoli.refinanciacion =
        this.solicitudInfo.value.tipoCredito == 2 ? 'Si' : 'No';
      (infosoli.datosRefinanciacion = []), (infosoli.datosCompra = []);
      infosoli.compraCartera =
        this.lineacredito == 'COMPRA CARTERA' ? 'Si' : 'No';
      if (infosoli.compraCartera == 'Si') {
        if (this.validarCarteras()) {
          console.log({ compra: this.datosCompraCartera });
          this.datosCompraCartera.forEach((cart) => {
            carteras.push({
              desprendible: cart.desprendible ? 'Si' : 'No',
              monto: Number(cart.monto.value),
              valorCuota: Number(cart.valorCuota.value),
              entidadCompra: cart.entidadCompra.value,
            });
          });

          infosoli.datosCompra = carteras;
        } else {
          this.dialogService.openDialog(
            'Advertencia',
            'Verifique los datos de las carpetas antes de continuar',
            false
          );

          this.isLoaded = false;
          validacion = false;
        }
      }

      if (infosoli.refinanciacion == 'Si') {
        const { numeroCredito, pagaduria, valorCuota } =
          this.formRefinanciacion.value;

        this.refinancialArr.forEach((item) => {
          delete item['_i'];
          infosoli.datosRefinanciacion.push(item);
        });
      }

      if (validacion) {
        this.guardarInfoSolicitud(infosoli);
      }
    }
  }

  MontoValido() {
    const { numeroCreditosActivos } = this.creditosActivos;
    const montoTotal = this.solicitudInfo.value['monto'];
    let sumatoria = 0;
    let isValid = true;
    let mensaje = '';

    if (numeroCreditosActivos !== 0 && this.isRefinanciacion) {
      this.libres.map((item) => {
        const { libreSaldo } = item;
        sumatoria = sumatoria + parseInt(libreSaldo.value);
      });
      mensaje =
        'El monto del crédito no puede ser menor a las refinanciaciones seleccionadas.';
    } else if (this.isNewCredito && this.compraCartera) {
      this.datosCompraCartera.map((item) => {
        const { monto } = item;
        monto.value === 'NaN'
          ? ''
          : (sumatoria = sumatoria + parseInt(monto.value));
      });
      mensaje =
        'El monto del crédito no puede ser menor a las compras de cartera.';
    }

    if (montoTotal > sumatoria) {
      isValid = true;
    } else {
      this.dialogService.openDialog('', mensaje, false);
      isValid = false;
    }

    this.isLoaded = false;
    return isValid;
  }

  private async guardarNuevoCredito(): Promise<void> {
    this.creditosEnviados = [];
    const { indPermiteParalelos, numeroCreditosActivos } = this.creditosActivos;
    let camposCompletos = false;

    if (
      this.libres.length == 1 &&
      indPermiteParalelos == 1 &&
      this.creditoObligatorio == '1'
    ) {
      this.dialogService.openDialog(
        'Advertencia',
        'La pagaduria no permite paralelos es necesario seleccionar un credito obligario',
        false
      );
      this.isLoaded = false;
      return;
    }

    const info = {
      cedula: this.data.cedula,
      numeroSolicitud: this.data.numeroSolicitud,
      numeroCarpeta: this.data.numeroCarpeta,
    };

    this.refinanciacion
      .loggerPorRefinanciacion(
        this.isRefinanciacion,
        numeroCreditosActivos,
        info
      )
      .subscribe();

    const inputSelectValue = this.solicitudInfo.get('tipoCredito').value;

    if (
      numeroCreditosActivos !== 0 &&
      (this.isRefinanciacion || inputSelectValue == '2')
    ) {
      this.libres.map((item) => {
        const { librePagaduria, libreValorCuota, libreSaldo } = item;

        if (
          librePagaduria.value !== '' &&
          libreValorCuota.value !== '' &&
          libreSaldo.value !== ''
        ) {
          camposCompletos = true;
          this.validarCreditos = true;
        } else {
          this.dialogService.openDialog(
            'Advertencia',
            'Debes seleccionar al menos un credito',
            false
          );
          this.isLoaded = false;
          camposCompletos = false;
        }
      });

      if (!camposCompletos) {
        this.validarCreditos = false;
        return;
      }

      if (!this.MontoValido()) {
        this.validarCreditos = false;
        return;
      }

      this.libres.map((credito) => {
        const { libreNumeroCredito, libreSaldo } = credito;
        this.creditosEnviados.push({
          numeroCredito: parseInt(libreNumeroCredito.value),
          valorCredito: parseInt(libreSaldo.value),
        });
      });

      let nuevoCredito = {
        numeroCarpeta: this.data.numeroCarpeta,
        listaCreditos: this.creditosEnviados,
      };

      this.refinanciacion.crearCredito(nuevoCredito).subscribe(
        (resp) => {
          console.log(resp);
        },
        (error) => {
          console.log({ error1: error });
          console.log(<any>error);
          this.dialogService.openDialog(
            'Advertencia',
            error.error.message,
            false
          );
          this.isLoaded = false;
        }
      );
    }
    this.isLoaded = false;
  }

  private guardarInfoSolicitud(infosoli: any) {
    this.dialogService.openDialogLoadingService("Estamos procesando tu solicitud", "Por favor, espera un momento.<br/>No actualices ni cierres esta ventana.")
    this.soliperfilamientoService.savInfSol(infosoli).subscribe(
      (res: any) => {
        if (res.status === 200) {
          if (!res.avanzar) {
            this.dialogService.closeDialog()
            this.dialogService.openDialog("Información Solicitud", res.message, false);
            return;
          }
          //aqui va el cambio de flujo
          this.cambioflujoserv.cambiarFlujo(this.codigoSolicitud).subscribe(
            (res) => {
              console.log({ res });
              if (res.status === 200) {
                this.guardarCarpeta();
                //this.dialogService.openDialog("Correcto",res.message,true);
              } else {
                this.dialogService.closeDialog()
                this.dialogService.openDialog(
                  'Ocurrió un problema',
                  res.message,
                  false
                );

                this.isLoaded = false;
              }
            },
            (err) => {
              console.log({ err });
              this.isLoaded = false;
              this.dialogService.closeDialog()
              this.dialogService.openDialog(
                'Advertencia',
                err.error.message,
                false
              );
            }
          );
        }
      },
      (err) => {
        this.dialogService.closeDialog()
        this.dialogService.openDialog('Advertencia', err.error.message, false);
        this.isLoaded = false;
      }
    );
  }

  public async guardarCarpeta() {
    this.isLoaded = false;
    this.guardarCarpetasrvc.saveCarpeta(this.codigoSolicitud).subscribe();
    let estadocarpeta = 1;
    let mensaje;
    let codigoValidador;
    let continua;
    let detalle_continua;

    while (estadocarpeta == 1) {
      this.guardarCarpetasrvc
        .flagCarpeta(Number(this.numeroCarpeta))
        .subscribe((resp: any) => {
          console.log(resp);
          estadocarpeta = resp.data.message.estado;
          typeof estadocarpeta === 'undefined'
            ? (estadocarpeta = 1)
            : (estadocarpeta = resp.data.message.estado);
          mensaje = resp.data.message.respuesta
            .split('<detalle>')[1]
            .split('</detalle>')[0];
          codigoValidador = resp.data.message.respuesta
            .split('<codigo_validador>')[1]
            .split('</codigo_validador>')[0];
          continua = resp.data.message.respuesta
            .split('<continua>')[1]
            .split('</continua>')[0];

          if (resp.data.message.respuesta.includes('xml')) {
            detalle_continua = resp.data.message.respuesta
              .split('<detalle_continua>')[1]
              .split('</detalle_continua>')[0];
          } else {
            detalle_continua = resp.data.message.respuesta.detalle_continua;
          }
        });
      await this.delay(5000);
    }

    if (estadocarpeta == 2) {
      if (codigoValidador == '01' && continua == 'SI') {
        this.cambioEstadoServ
          .cambioEstadocarpeta(
            Number(this.data.numeroCarpeta),
            '50',
            'Cambio de estado aprobado por el Asesor'
          )
          .subscribe(
            (res) => {
              if (res.status == 200) {
                this.dialogService.closeDialog()
                this.dialogService.openDialog('Hecho', res.message, true);
                this.router.navigateByUrl('/rob-analisis', {
                  state: {
                    numeroSolicitud: this.codigoSolicitud,
                    infoSolicitud: this.solicitudInfo.value,
                    numeroCarpeta: this.data.numeroCarpeta,
                  },
                });
              }
            },
            (err) => {
              this.isLoaded = false;
              this.dialogService.closeDialog()
              this.dialogService.openDialog(
                'Advertencia',
                err.error.message,
                false
              );
            }
          );
      } else if (codigoValidador == '10' && continua == 'SI') {
        this.isLoaded = false;
        this.dialogService.closeDialog()
        this.dialogService.openDialog('Advertencia', detalle_continua, false);
      } else {
        this.isLoaded = false;
        this.dialogService.closeDialog()
        this.rechazo(detalle_continua ? detalle_continua : mensaje);
      }
    } else {
      console.log('992');
      this.isLoaded = false;
      this.dialogService.closeDialog()
      this.rechazo(mensaje);
    }
  }

  rechazo(mensaje: string) {
    this.dialogService.openDialog('Advertencia', mensaje, false);
    this.geOfertaSrvc
      .rechazarOferta(
        this.data.numeroCarpeta,
        '10',
        'Rechazo por documentación incompleta'
      )
      .subscribe(
        () => {
          this.router.navigateByUrl('/home');
        },
        () => {
          this.router.navigateByUrl('/home');
        }
      );
  }

  delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  private async getEntidadesFinancieras() {
    const res = await this.entfinancieras.getEntidades().toPromise();

    let i = 0;
    let o = [];
    let q = [];
    while (i < 6) {
      o.push(res.rows);
      i++;
    }
    this.entidadesFinanc = o;
    i = 0;
    while (i < 6) {
      this.entidadesFinanc[i] = this.sortCatalog(
        this.entidadesFinanc[i],
        'nombre'
      );
      i++;
    }
    i = 0;
    while (i < 6) {
      q.push(this.entidadesFinanc[i].slice());
      i++;
    }

    this.filteredEntidadesFinanc = q;
  }

  private async getTipoIdentificacion() {
    const res = await this.tipoIdService.getTipoIdentificacion().toPromise();
    const [filtroCC] = res.rows.filter(
      (row) => row.codigoTipoIdentificacion === 'C.C.'
    );

    this.tiposId = res.rows;
    this.personalInfo.patchValue({
      tipoIdentificacion: filtroCC.codigoTipoIdentificacion,
    });
    this.tiposId = this.tiposId.slice(0, 2);
    console.log({ tttt: this.tiposId });
  }

  private getTipoCredito(hayCreditos = 1, paralelos = 1) {
    this.tCreditoService.getTipoCredito().subscribe((creditos) => {
      // 1-> hay creditos
      // 0-> no hay creditos

      if (hayCreditos !== 0) {
        this.tipos_credito = creditos.rows;

        this.refinanciacion
          .busquedaCreditos(this.data.cedula, this.data.pagaduriaRef)
          .subscribe((resp) => {
            this.creditosRefinanciacion = resp;
          });

        if (paralelos == 1) {
          // this.isRefinanciacion = true;
          this.showCreditosLibreInversion();
          this.tipos_credito = creditos.rows.filter(
            (tipo) => tipo.nombre !== 'NUEVO'
          );
        }
      } else {
        this.tipos_credito = creditos.rows.filter(
          (tipo) => tipo.nombre !== 'REFINANCIACION'
        );
      }

      this.filteredTiposCredito = this.tipos_credito.slice();

      // if(hayCreditos !== 0 && paralelos == 0 ){
      //    this.tipos_credito = creditos.rows;
      // }else if(hayCreditos !== 0 && paralelos == 1){
      //   this.tipos_credito = creditos.rows.filter(tipo=>tipo.nombre !== 'NUEVO');
      // }else{
      //   this.tipos_credito = creditos.rows.filter(tipo=>tipo.nombre !== 'REFINANCIACION');
      // }
    });
  }

  public getPagadurias(convenio: string): Subscription {
    this.tiposContratos = [];
    this.edoLaboral = [];
    this.cargos = [];

    return this.pagaduriaService
      .getPagadurias(convenio)
      .subscribe((pagaduria) => {
        this.pagaduarias = pagaduria.rows;

        this.data.pagadurias = pagaduria.rows;

        console.log(':: Pagadurias', this.pagaduarias);

        this.pagaduarias = this.pagaduarias.sort(function (a, b) {
          var nameA = a.nombre.toUpperCase(); // ignore upper and lowercase
          var nameB = b.nombre.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        this.filteredPagaduria = this.pagaduarias.slice();

        if (this.laboralInfo.controls.codigoConvenio) {
          if (this.laboralInfo.controls.codigoConvenio.value != '') {
            this.getTiposContrato(
              this.laboralInfo.controls.codigoPagaduria.value
            );
          }
        }

        this.laboralInfo.controls.codigoPagaduria.setValue('');
      });
  }

  private getLineasCredito() {
    return this.lineaCreditoService.getLineasCredito().subscribe((lineas) => {
      this.lineascredito = lineas.rows;
      this.filteredLineaCredito = this.lineascredito.slice();
      console.log('lineas', lineas);
    });
  }

  private getConvenios() {
    this.convenioService.getConvenios().subscribe((convenios) => {
      this.convenios = convenios.rows;
      this.filteredConvenio = this.convenios.slice();
    });
  }

  public getTiposContrato(pagaduria: string, convenio?: string) {
    this.tipoContratoService
      .getTipoContrato(
        this.laboralInfo.value.codigoConvenio || convenio,
        pagaduria
      )
      .subscribe((contrato) => {
        this.tiposContratos = contrato.rows;
        this.filteredTiposContrato = this.tiposContratos.slice();
      });
  }

  private getGeneros() {
    this.generoService.getGenero().subscribe((res) => {
      this.generos = res.rows;
      console.log({ generos: this.generos });
      //this.personalInfo.patchValue({genero:this.generos[0].codigo})
    });
  }

  public getEdoLab(pagaduria: string) {
    this.edoLaboralService.getEdoLab(pagaduria).subscribe((res) => {
      this.edoLaboral = res.rows;
      this.filteredEdoLab = this.edoLaboral.slice();
      this.filteredEdoLab.shift();
    });
  }

  public getCargos(codigoPagaduria: string): void {
    this.cargoserv.getCargos(codigoPagaduria).subscribe((res) => {
      this.cargos = res.rows;
      this.filteredCargos = this.cargos.slice();
    });
  }

  public openDialogPersonal(): void {
    this.dialogService.openDialog(
      'Información personal',
      'Sección Información personal creada exitosamente.',
      true
    );
  }

  public openDialogLaboral(): void {
    this.dialogService.openDialog(
      'Información laboral',
      'Sección Información laboral creada exitosamente.',
      true
    );
  }

  public openDialogFinanciera(): void {
    this.dialogService.openDialog(
      'Información financiera',
      'Sección Información financiera creada exitosamente.',
      true
    );
  }

  public addCarteraVacia(): void {
    this.datosCompraCartera.push({
      entidadCompra: { value: '', valid: false },
      valorCuota: { value: '', valid: false },
      monto: { value: '', valid: false },
      desprendible: false,
      refName: '',
    });
  }

  public quitarCartera(index: number): void {
    this.datosCompraCartera.splice(index, 1);
  }

  public cambiarLineaCredito(nombre: string) {
    this.lineacredito = nombre;
    console.log('Cambiar Linea de credito', nombre);
    if (nombre == 'COMPRA CARTERA') {
      this.inicializarFormularios();
      this.activeLibreInversion = false;
      this.datosCompraCartera = [];
      this.addCarteraVacia();
      this.libreInversion = false;
      this.compraCartera = true;
      console.log('libre', this.libreInversion);
      console.log('compra', this.compraCartera);
    } else if (nombre == 'LIBRE INVERSION ') {
      this.inicializarFormularios();
      this.libreInversion = true;
      this.compraCartera = false;
      console.log('libre', this.libreInversion);
      console.log('compra', this.compraCartera);
    }
  }

  public guardarCompra(): void {
    console.log(this.datosCompraCartera);
    if (this.datosCompraCartera.length < 6) {
      if (this.validarCarteras()) {
        this.addCarteraVacia();
      } else {
        this.dialogService.openDialog(
          'Advertencia',
          'Debe completar adecuadamente los campos antes de agregar una nueva',
          false
        );
      }
    } else {
      this.dialogService.openDialog(
        'Advertencia',
        'Se alcanzo el máximo de carteras.',
        false
      );
    }
  }

  private validarCarteras(): boolean {
    let valid: boolean = true;
    this.datosCompraCartera.forEach((cartera) => {
      if (
        !cartera.entidadCompra.value ||
        !cartera.valorCuota.value ||
        !cartera.monto.value
      )
        valid = false;
    });
    console.log('datoscompraCARTERA', this.datosCompraCartera);
    return valid;
  }

  public validarCarterasExistentes(codigoEntidad: number): void {
    let entidad: Array<any> = [];
    let index = 0;

    entidad = this.datosCompraCartera.filter((ent: any, i) => {
      if (ent.entidadCompra.value == codigoEntidad) {
        index = i;
        return ent;
      }
    });

    this.datosCompraCartera[index].entidadCompra.valid = true;
  }

  public validMontos(index: number, campo: string): void {
    if (!this.datosCompraCartera[index][campo].value) {
      this.datosCompraCartera[index][campo].valid = false;
    } else {
      this.datosCompraCartera[index][campo].valid = true;
    }
  }

  public showMessages(): void {
    setTimeout(() => {
      this.formularios.forEach((forms) => {
        if (!$(`#${forms.name}`).hasClass('show')) {
          forms.open = false;
        } else {
          forms.open = true;
          this.showWarning(forms.name);
        }
      });
    }, 500);
  }

  private getInformacionFinanciera(numeroSolicitud: string): void {
    this.getinfoServ.getInfoFinanciera(numeroSolicitud).subscribe((res) => {
      this.otrosIngresosForm = res.otrosIngresos;
      this.saludForm = res.salud;
      this.pensionForm = res.pension;
      this.retefuenteForm = res.reteFuente;
      this.fondoSolidaridadForm = res.fondoSolidaridad;
      this.caprovimpoForm = res.caproimpo;
      this.otrosDescuentosForm = res.otrosDescuentos;

      res.retefuente = res.reteFuente;
      delete res['reteFuente'];
      this.financieraInfo.setValue(res);
    });
  }

  private getInformacionSolicitud(numeroSolicitud: string): void {
    this.isNewCredito = true;
    console.log('Al cargar informacion Solicitud', this.isRefinanciacion); //False

    this.getinfoServ.getInfoSolicitud(numeroSolicitud).subscribe((res) => {
      if (res.compraCartera === 1) {
        this.getCarteras();
      }

      if (res.refinanciacion === 1) {
        this.getRefinanciacionData();
      }

      if (res.tipoCredito === 2) {
        this.selecTipoCredito('REFINANCIACION');
      }

      this.montoForm = res.monto !== 0 ? res.monto : null;
      this.plazoModel = res.plazo !== 0 ? res.plazo : null;
      this.tipoCreditoModel =
        res.tipoCredito !== 0 ? String(res.tipoCredito) : null;
      this.lineaCredito =
        res.lineaCredito !== 0 ? String(res.lineaCredito) : null;

      this.datosCompraCartera = res.datosCompra ? res.datosCompra : [];
      this.datosRefinanciacion = res.refinanciacion ? res.refinanciacion : [];
      delete res['compraCartera'];
      delete res['refinanciacion'];
      res.tipoCredito = res.tipoCredito.toString();
      res.lineaCredito = res.lineaCredito.toString();
      // this.solicitudInfo.setValue(res);
      this.solicitudInfo
        .get('plazo')
        .setValue(res.plazo !== 0 ? res.plazo : null);
      this.solicitudInfo
        .get('monto')
        .setValue(res.monto !== 0 ? res.monto : null);
      this.solicitudInfo
        .get('tipoCredito')
        .setValue(res.tipoCredito !== '0' ? res.tipoCredito : null);
      this.solicitudInfo
        .get('lineaCredito')
        .setValue(res.lineaCredito !== '0' ? res.lineaCredito : null);
    });
  }

  private getRefinanciacion(numeroSolicitud: string): void {
    this.getinfoServ.getRefinanciacion(numeroSolicitud).subscribe((res) => {
      this.datosRefinanciacion = res.rows;
    });
  }

  private getDatosCompra(numeroSolicitud: string): void {
    this.getinfoServ.getComprasCartera(numeroSolicitud).subscribe((res) => {
      this.datosCompraCartera = res.rows;

      if (res.rows.length == 0 && this.compraCartera == true) {
        console.log('inside  get carteras');
        this.cambiarLineaCredito('COMPRA CARTERA');
      }
    });
  }

  private async getInforPersonal(numeroSolicitud: string) {
    const responseInfo = await this.getinfoServ
      .getInfoClient({
        carpeta: this.data.numeroCarpeta.toString(),
      })
      .toPromise();
    const { celular, correo } = responseInfo.data.resultado.infoClient;

    this.getinfoServ.getInfoPersonal(numeroSolicitud).subscribe((res) => {
      delete res['fechaExpedicion'];
      res.numeroCarpeta = this.numeroCarpeta;
      res.tipoIdentificacion =
        res.tipoIdentificacion == 1
          ? this.tiposId[0].codigoTipoIdentificacion
          : this.tiposId[1].codigoTipoIdentificacion;

      if (res.fechaNacimiento) {
        const partes = res.fechaNacimiento.split('-');
        const date: NgbDateStruct = {
          year: +partes[0],
          month: +partes[1],
          day: +partes[2]
        }
        res.fechaNacimiento = date;
        this.guardarPersonalInfo = false;
      }

      this.personalInfo.patchValue({
        ...res,
        celular: celular.toString(),
        correo,
      });
    });
  }

  public getInfoLaboral(numeroSolicitud: string) {
    this.getinfoServ.getInfoLaboral(numeroSolicitud).subscribe((res) => {
      if (res.codigoConvenio) {
        this.getPagadurias(res.codigoConvenio);
      }
      this.getTiposContrato(
        res.codigoPagaduria.toString(),
        res.codigoConvenio.toString()
      );
      this.data.pagaduriaRef = res.codigoPagaduria;

      //Se ejecuta servicio de busqueda creditos activos
      this.busquedaCreditosPorPagaduria(
        this.data.pagaduriaRef,
        this.data.cedula
      );

      res.codigoConvenio = res.codigoConvenio.toString();
      res.codigoTipoContrato = res.codigoTipoContrato.toString();

      this.getEdoLab(res.codigoPagaduria);
      this.getCargos(res.codigoPagaduria);
      if (res.fechaIngreso) {
        const partes = res.fechaIngreso.split('-');
        const date: NgbDateStruct = {
          year: +partes[0],
          month: +partes[1],
          day: +partes[2].split('T')[0]
        }
        res.fechaIngreso = date;
      }
      res.codigoCargo = res.codigoCargo.toString();
      res.codigoPagaduria = Number(res.codigoPagaduria);
      res.codigoEstadoLaboral = res.codigoEstadoLaboral.toString();
      this.laboralInfo.setValue(res);
    });
  }

  public validarMsgFormulario(): boolean {
    return (
      this.laboralInfo.invalid ||
      this.personalInfo.invalid ||
      this.financieraInfo.invalid ||
      this.solicitudInfo.invalid
    );
  }

  // validacion de las secciones anteriores para el guardado final
  validateForms(): Array<string> {
    let values: Array<string> = [];
    !this.formularios[0].saved ? values.push('Información personal') : '';
    !this.formularios[1].saved ? values.push('Información laboral ') : '';
    !this.formularios[2].saved ? values.push('Información financiera ') : '';
    return values;
  }

  //Funcion para filtrado de select
  protected filterData() {
    if (!this.convenios) {
      return;
    }
    // get the search keyword
    let search = this.dataFilterCtrl.value;
    console.log(search);

    if (!search) {
      this.filteredData.next(this.convenios.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.convenios.filter(
      (convenio) => convenio.nombre.toLowerCase().indexOf(search) > -1
    );
    this.filteredData.next(
      this.convenios.filter(
        (convenio) => convenio.nombre.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private sortCatalog(catlog: any, sort: string) {
    catlog = catlog.sort(function (a, b) {
      var nameA = a[sort].toUpperCase(); // ignore upper and lowercase
      var nameB = b[sort].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    return catlog;
  }

  modalWarning(message = '', confirmButtonText = 'Entendido') {
    this.dialogService.openDialog(
      'Advertencia',
      message,
      false,
      confirmButtonText
    );
  }

  showWarning(name = '') {
    if (name === 'collapseOne') {
      this.modalWarning(
        'Prevé errores verificando que nombres, apellidos y la fecha de nacimiento coincidan con los datos del solicitante.'
      );
    }
    if (name === 'collapselaboral') {
      this.modalWarning(
        'Es importante que verifiques la información del nombre de la pagaduría y la fecha de ingreso.'
      );
    }
    if (name === 'collapseSolicitud') {
      this.modalWarning(
        'Debes revisar que el plazo solicitado esté de acuerdo a las condiciones de crédito: ingreso a la pagaduría y/o edad del solicitante.'
      );
    }
  }

  limitarLongitud(event: any) {
    const input = event.target as HTMLInputElement;
    let sanitizedValue = input.value.replace(/[^\d]/g, '');
    if (sanitizedValue.length > 3) {
      sanitizedValue = sanitizedValue.slice(0, 3);
    }
    input.value = sanitizedValue;
    this.solicitudInfo.controls.plazo.setValue(sanitizedValue);
  }

  alertaPlazo() {
    if (!this.isOpenAlertaPlazo) {
      this.modalWarning(
        'Valida la informacíon del plazo que requiere el cliente antes de registrarlo.',
        'Aceptar'
      );
      this.isOpenAlertaPlazo = true;
    }
  }

  async rechazarCarpeta(carpeta) {
    await this.cambioEstadoServ
      .cambioEstadocarpeta(Number(carpeta), '900000', 'Rechazo por asesor')
      .toPromise();
  }

  compareFn = (selectedValue: any, option: any): boolean => {
    return selectedValue == option;
  };
}
