import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {getFilesByFolder, itemFile} from '../../models/busCarpeta.interface'
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class BuscCarpetaService {

  private apiurl = `${environment.ApiConecta}resumen`
  constructor(
    private http:HttpClient
  ) { }

  getResultadosBusqueda(params:any):Observable<any>{
    return this.http.get(this.apiurl,{params});
  }

  montoCarpeta(carpeta: number) {
    return this.http.get<any>(`${environment.ApiConecta}obtenerMonto/`+carpeta);
  }

  obtenerComprobantesPago(body: getFilesByFolder) {
    return this.http.post<itemFile[]>(`${environment.ApiConecta}carpeta/obtener-archivos`, body).toPromise();
  }

}
