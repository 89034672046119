import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AdicionalesService } from 'src/app/services/catalogos/adicionales.service';
import { TipoOpService } from 'src/app/services/catalogos/tipoOperacion.service';
import { InfoadicionalService } from 'src/app/services/perfilamiento/infoadicional.service';
import { DataService } from '../../services/data.service';
import { DialogService } from '../../services/dialog.service';
import { InfoAdicionalService } from '../../services/info-adicional/info-adicional.service';
import { CambioestadocarpetaService } from '../../services/perfilamiento/cambioestadocarpeta.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { UploadService } from 'src/app/services/busqueda/busqueda.service';
import { DialogComponent } from '../../components/dialogs/standar/dialog.component';
import { IInfoDesembolsoBody } from '../../models/info-adicional/infoDesembolso';
import { IInfoFinancieraBody } from '../../models/info-adicional/infoFinanciera';
import { IInfoLaboralBody } from '../../models/info-adicional/infoLaboral';
import { IInfoPersonalBody } from '../../models/info-adicional/infoPersonal';
import { Segurabilidad } from '../../models/info-adicional/infoSegurabilidad';
import { ResumenAdoService } from '../../services/resumen-ado.service';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-informacion-adicional',
  templateUrl: './informacion-adicional.component.html',
  styleUrls: ['./informacion-adicional.component.scss'],
})
export class InformacionAdicionalComponent {
  public limitacionfisica: boolean = false;
  public limitacioncronica: boolean = false;
  public existeFecha: boolean = false;
  public validarLimitacionFicica: boolean = false;
  private numeroCarpeta: string;
  private numeroSolicitud: string;
  public formulariosAgregados: number = 0;
  public formulariosAgregados2: number = 0;
  public stepper: number = 0;
  public formulariosValidos: boolean;
  public buttonPersonal = true;
  public buttonFinanciera = true;
  public buttonDesembolso = true;
  public buttonAsegurabilidad = true;
  public estadoCivil: Array<any> = [];
  public activeMonedaExtranjera: boolean = false;
  public toggle: boolean = false;
  public paises: Array<any> = [];
  public monedas: Array<any> = [];
  public departamentos: Array<any> = [];
  public ciudades: Array<any> = [];
  public profesiones: Array<any> = [];
  public destinoc: Array<any> = [];
  public fuerzas: Array<any> = [];
  public tipoPensiones: Array<any> = [];
  public codigoPensiones: Array<any> = [];
  public tipoProductos: Array<any> = [];
  public origenFondos: Array<any> = [];
  public bancos: Array<any> = [];
  public tipoDesembolso: Array<any> = [];
  public tipoCuenta: Array<any> = [];
  public direcc: any;
  public isLoading: boolean = false;

  wasSaved: {
    personalForm: boolean;
    laboralForm: boolean;
    financialForm: boolean;
    desembolsoForm: boolean;
    asegurabilidadForm: boolean;
  } = {
      asegurabilidadForm: false,
      desembolsoForm: false,
      financialForm: false,
      laboralForm: false,
      personalForm: false,
    };

  private pagaduriaList = [
    {
      nombre_pagaduria: 'FERROCARRILES NACIONALES DE COLOMBIA',
      codigo_pagaduria: 41,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'COLFONDOS S.A. - PENSIONADO',
      codigo_pagaduria: 141,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CONSORCIO FOPEP',
      codigo_pagaduria: 250,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CASUR',
      codigo_pagaduria: 253,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'MINISTERIO DE DEFENSA - PENSIONADO',
      codigo_pagaduria: 255,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CAJA DE RETIRO FFMM - CREMIL',
      codigo_pagaduria: 256,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'POSITIVA DE SEGUROS',
      codigo_pagaduria: 333,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CAGEN - TEGEN',
      codigo_pagaduria: 363,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'FIDUPREVISORA FOMAG',
      codigo_pagaduria: 414,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'COLPENSIONES - PENSIONADO',
      codigo_pagaduria: 716,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'PORVENIR SA',
      codigo_pagaduria: 897,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'SEGUROS DE VIDA ALFA SA',
      codigo_pagaduria: 1003,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'COLPENSIONES - PENSIONADO LM',
      codigo_pagaduria: 1100,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'PROTECCION SA',
      codigo_pagaduria: 1138,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CASUR LM',
      codigo_pagaduria: 1156,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CAJA DE RETIRO FFMM - CREMIL LM',
      codigo_pagaduria: 1157,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'CONSORCIO FOPEP LM',
      codigo_pagaduria: 1160,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'SEGUROS BOLIVAR SA',
      codigo_pagaduria: 1178,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'FIDUPREVISORA FOMAG - LM',
      codigo_pagaduria: 1223,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'SEGUROS DE VIDA ALFA SA LM',
      codigo_pagaduria: 1257,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'PROTECCION SA LM',
      codigo_pagaduria: 1259,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'PORVENIR SA LM',
      codigo_pagaduria: 1261,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'MINISTERIO DE DEFENSA - PENSIONADO LM',
      codigo_pagaduria: 1262,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'COLFONDOS S.A - PENSIONADO LM',
      codigo_pagaduria: 1264,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
    {
      nombre_pagaduria: 'ASULADO SEGUROS DE VIDA SA',
      codigo_pagaduria: 1439,
      tipo_pagaduria: 'Pensionados',
      aplica_pensionado: 'SÍ',
    },
  ];

  infPersonalAdicionalForm: FormGroup;
  infLaboralAdicionalForm: FormGroup;
  infFinancieraAdicionalForm: FormGroup;
  // infFinancieraAdicionalForm2: FormGroup;
  infLimitacionFisicaForm: Array<FormGroup> = [];
  infEnfermedadForm: Array<FormGroup> = [];
  infDesembolsoForm: FormGroup;

  totalActivosForm: number = 0;
  totalPasivosForm: number = 0;
  ingresosForm: number = 0;
  gastosForm: number = 0;
  otrosIngresosForm: number = 0;
  direccion: any;

  // Direccion
  direccionCompleta: string;
  d1: string = '';
  d2: string = '';
  d3: string = '';
  d4: string = '';
  d5: string = '';
  d6: string = '';

  // Data adicional catalogos
  ciudadesModel: any[] = [];
  ciudadesLugarExpedicionModel: any[];
  direccionResidenciaModel: any[] = [];
  direccionTorreInterior: any[] = [];

  // Para filtros de select
  public filteredCiudadesModel: any;
  public filteredEstadoCivil: any;
  public filteredProfesiones: any;
  public filteredDestinoc: any;
  public filteredDepartamentos: any;
  public filteredDeptos_: any[] = [];
  public filteredCity_: any[] = [];
  public filteredCiudades: any;
  public filteredCiudadesLugarExpedicion: any;
  public filteredDireccionResidencia: any;
  public filteredDireccionTorreInterior: any;
  public filteredBancos: any;
  public filteredMonedas: any;
  public filteredTipoProductos: any;
  public filteredTipoPaises: any;
  private emailRecocer: string;
  private codReconocer: string;

  valueOne: any = 2;
  valueTwo: any = 2;

  disabledButtonLoboral: boolean = true;

  public filteredData: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  public valoresFormulario: Array<any> = [];
  public showListaExpedicion: boolean = false;
  public showListaNacimientos: boolean = false;
  public valueLugarExpedicion: string;
  public valueLugarNacimiento: string;
  public alturaListaDesplegable: number = 0;
  private debounceTime?: NodeJS.Timeout;
  private valueDepto: string;
  public valueCity: string;

  public isSelectedToggle1: boolean = false;
  public isSelectedToggle2: boolean = false;

  isLoadingPersonalInfo: boolean = false;
  isLoadingLaborInfo: boolean = false;
  isLoadingFinancialInfo: boolean = false;
  isLoadingDisbursementInfo: boolean = false;
  isLoadingInsurabilityInfo: boolean = false;

  firstLoadedData: boolean = false;
  secondLoadedData: boolean = false;
  thirdLoadedData: boolean = false;
  fourthlLoadedData: boolean = false;

  dataCarpeta: any;

  numeroAfiliacion = 0;
  numeroCodigoMilitar = 0;
  today: NgbDateStruct;

  constructor(
    private adicionalService: AdicionalesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public data: DataService,
    private formBuilder: FormBuilder,
    private infoadicionalService: InfoadicionalService,
    private tipoOpServ: TipoOpService,
    private _infoAdicionalService: InfoAdicionalService,
    private _dataService: DataService,
    private _dialogService: DialogService,
    private _cambioEstadoService: CambioestadocarpetaService,
    private resumenAdoService: ResumenAdoService,
    private dialog: MatDialog,
    private _busquedaService: UploadService,
    private calendar: NgbCalendar
  ) {
    this.today = this.calendar.getToday();
    const isColpensiones =
      Number(this.data.pagaduriaRef) === 716
        ? true
        : Number(this.data.pagaduriaRef) === 1100;
    const isEjercito = Number(this.data.pagaduriaRef) === 248;
    const isArmada = Number(this.data.pagaduriaRef) === 360;

    this.infPersonalAdicionalForm = this.formBuilder.group({
      fechaExpedicionIdentificacion: [
        { value: ''},
      ],
      lugarExpedicionIdentificacion: ['', Validators.required],
      lugarNacimiento: ['', Validators.required],
      estadoCivil: ['', Validators.required],
      profesionOficio: ['', Validators.required],
      destinoCredito: ['', Validators.required],
      correo: [''],
      direccion: [''],
      departamento: ['', Validators.required],
      ciudad: ['', Validators.required],
      direccionResidencia: [''],
      box1: [''],
      box2: [''],
      box3: ['', this.direccion !== null ? '' : Validators.required],
      box4: [''],
      direccionTorre: [''],
      completa: [{ value: '' }],
    });

    this.infLaboralAdicionalForm = this.formBuilder.group({
      numeroCarpeta: [''],
      codigoVerificacion1: [''],
      codigoVerificacion2: [''],
      codigoMilitar: [
        {
          value: '',
          disabled: isEjercito || isArmada,
        },
      ],
      codigoConfirm: [
        {
          value: '',
          disabled: isEjercito || isArmada,
        },
      ],
      fuerza: [''],
      numeroAfiliacion: [
        {
          value: '',
          disabled: isColpensiones,
        },
        Validators.minLength(8),
      ],
      tipoPension: [''],
      cedulaTitular: [''],
      codigoAfiliacion: [''],
    });

    this.infFinancieraAdicionalForm = this.formBuilder.group({
      nuemeroCarpeta: [''],
      totalActivos: ['', Validators.required],
      totalPasivos: ['', Validators.required],
      ingresos: ['', Validators.required],
      gastos: ['', Validators.required],
      otrosIngresos: [''],
      descripcionOtrosIngresos: [''],
      monedaExtranjera: [false],
      tipoProducto: [''],
      origenFondos: ['', Validators.required],
      moneda: [''],
      pais: [''],
      ciudad: [''],
    });

    this.infLimitacionFisicaForm.push(
      this.formBuilder.group({
        numeroCarpeta: ['', Validators.required],
        pregunta: [
          '¿Padeció o padece, recibió o recibe algún tratamiento?',
          Validators.required,
        ],
        indRespuesta: [1, Validators.required],
        enfermedad: ['', Validators.required],
        fechaEnfermedad: ['', Validators.required],
        duracion: ['', Validators.required],
        datosMedico: [''],
        datosHospital: ['', Validators.required],
        codPregunta: [1, Validators.required],
      })
    );

    this.infEnfermedadForm.push(
      this.formBuilder.group({
        numeroCarpeta: ['', Validators.required],
        pregunta: [
          '¿Padece usted alguna enfermedad crónica?',
          Validators.required,
        ],
        indRespuesta: [1, Validators.required],
        enfermedad: ['', Validators.required],
        fechaEnfermedad: ['', Validators.required],
        duracion: ['', Validators.required],
        datosMedico: [''],
        datosHospital: ['', Validators.required],
        codPregunta: [2, Validators.required],
      })
    );

    this.infDesembolsoForm = this.formBuilder.group({
      modalidadDesembolso: [3],
      banco: [''],
      tipoCuenta: [''],
      numeroCuenta: [''],
    });
  }

  addLimitacionVacia() {
    this.formulariosAgregados++;
    if (this.formulariosAgregados <= 1) {
      this.infLimitacionFisicaForm.push(
        this.formBuilder.group({
          numeroCarpeta: [''],
          pregunta: [
            '¿Padeció o padece, recibió o recibe algún tratamiento?',
            Validators.required,
          ],
          indRespuesta: [1, Validators.required],
          enfermedad: ['', Validators.required],
          fechaEnfermedad: ['', Validators.required],
          duracion: ['', Validators.required],
          datosMedico: [''],
          datosHospital: ['', Validators.required],
          codPregunta: [1, Validators.required],
        })
      );
    }
  }

  addEnfermedadVacia() {
    this.formulariosAgregados2++;
    if (this.formulariosAgregados2 <= 1) {
      this.infEnfermedadForm.push(
        this.formBuilder.group({
          numeroCarpeta: [''],
          pregunta: [
            '¿Padece usted alguna enfermedad crónica?',
            Validators.required,
          ],
          indRespuesta: [1, Validators.required],
          enfermedad: ['', Validators.required],
          fechaEnfermedad: ['', Validators.required],
          duracion: ['', Validators.required],
          datosMedico: [''],
          datosHospital: ['', Validators.required],
          codPregunta: [2, Validators.required],
        })
      );
    }
  }

  eliminar(enfermedad: Array<FormGroup>, index) {
    enfermedad.splice(index, 1);
    this.formulariosAgregados--;
  }

  eliminarLim(limitacion: Array<FormGroup>, index) {
    limitacion.splice(index, 1);
    this.formulariosAgregados2--;
  }

  ngAfterViewInit(): void {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
    this.getReconocimiento();
    this.getAllCiudades();
    this.getDireccionesResidencia();
    this.getDireccionesTorre();
    this.getEstadoCivil();
    this.getDepartamentos();
    this.getMonedas();
    this.getPaises();
    this.getProfesiones();
    this.getFuerza();
    this.getTipoPension();
    this.getCodigoPension();
    this.getTipoProducto();
    this.getOrigenFondos();
    this.getTipoDesembolso();
    this.getTipoCuenta();
    this.getBancos();
    this.getDestinoCredito();

    let state = this.activatedRoute.paramMap.pipe(
      map(() => window.history.state)
    );

    state.subscribe((datos: any) => {
      if (datos) {
        this.numeroSolicitud = datos.numeroSolicitud;
        this.numeroCarpeta = this.data.numeroCarpeta;
      }
    });
    setTimeout(() => this.getInformation(), 0);
  }
  // 1->campo lugar de Expedicion
  // 2->campo lugar de nacimiento
  changeDinamicoCuidades(query: string = '', numeroDeCampo: number) {
    if (this.debounceTime) clearTimeout(this.debounceTime);

    this.debounceTime = setTimeout(() => {
      this.adicionalService.getCuidadesPorLike(query).subscribe((resp) => {
        console.log(resp);
        //Vaciamos los valores
        if (query === '' && numeroDeCampo === 1) {
          this.valueLugarExpedicion = '';
          this.showListaExpedicion = false;
          return;
        }

        if (query === '' && numeroDeCampo === 2) {
          this.valueLugarNacimiento = '';
          this.showListaNacimientos = false;
          return;
        }

        this.alturaListaDesplegable = resp.count;

        if (numeroDeCampo === 1) {
          this.filteredCiudadesLugarExpedicion = resp.rows;
          resp.count > 0
            ? (this.showListaExpedicion = true)
            : (this.showListaExpedicion = false);
        } else {
          this.filteredCiudadesModel = resp.rows;
          resp.count > 0
            ? (this.showListaNacimientos = true)
            : (this.showListaNacimientos = false);
        }
      });
    }, 500);
  }

  seleccionarExpedicion(expedicion, value) {
    this.infPersonalAdicionalForm
      .get('lugarExpedicionIdentificacion')
      .setValue(expedicion);
    this.valueLugarExpedicion = value;
    this.showListaExpedicion = false;
  }

  seleccionarNacimiento(lugarNacimiento, value) {
    this.infPersonalAdicionalForm
      .get('lugarNacimiento')
      .setValue(lugarNacimiento);
    this.valueLugarNacimiento = value;
    this.showListaNacimientos = false;
  }

  public optionSelect(): void {
    if (this.infDesembolsoForm.get('modalidadDesembolso').value === 3) {
      for (const key in this.infDesembolsoForm.controls) {
        this.infDesembolsoForm.get(key).clearValidators();
        this.infDesembolsoForm.get(key).updateValueAndValidity();
      }
    } else if (this.infDesembolsoForm.get('modalidadDesembolso').value === 1) {
      this.infDesembolsoForm.get('banco').setValue(null);
      this.infDesembolsoForm.get('tipoCuenta').setValue(null);
      this.infDesembolsoForm.get('numeroCuenta').setValue(null);
    }
  }

  public inputChangeDesembolso(): void {
    // if (this.infDesembolsoForm.valid) {
    //   this.buttonDesembolso = false;
    // } else if (!this.infDesembolsoForm.get('numeroCuenta').value) {
    //   console.log('::btnDesembolso', this.buttonDesembolso);
    //   this.buttonDesembolso = true;
    //   console.log('::numeroCuenta', this.infDesembolsoForm.get('numeroCuenta').value);
    //   // this.stepper--;
    // }
    // console.log('::btnDesembolso', this.buttonDesembolso);
  }

  inputPersonal() {
    if (
      this.infPersonalAdicionalForm.get('fechaExpedicionIdentificacion').value
    ) {
      this.buttonPersonal = false;
    } else if (
      this.infPersonalAdicionalForm.get('lugarExpedicionIdentificacion').value
    ) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('lugarNacimiento').value) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('estadoCivil').value) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('profesionOficio').value) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('destinoCredito').value) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('departamento').value) {
      this.buttonPersonal = false;
    } else if (this.infPersonalAdicionalForm.get('ciudad').value) {
      this.buttonPersonal = false;
    }
  }

  public guardarInformacionLaboral() {
    this.infLaboralAdicionalForm.patchValue({
      numeroCarpeta: this.data.numeroCarpeta,
    });
    this.infoadicionalService
      .laboralAdicional(this.infLaboralAdicionalForm.value)
      .subscribe(
        (res) => {
          this._dialogService.openDialog("!",`${res.message}`,false)
        },
        (err) => {
          this._dialogService.openDialog("Error",`${err.error.message}`,false)
        }
      );
  }

  public guardarInfFinanciera() {
    this.infFinancieraAdicionalForm.patchValue({
      numeroCarpeta: this.data.numeroCarpeta,
    });
    this.infoadicionalService
      .financieraAdicional(this.infFinancieraAdicionalForm.value)
      .subscribe(
        (res) => {
          this._dialogService.openDialog("!",`${res.message}`,false)
        },
        (err) => {
          this._dialogService.openDialog("Error",`${err.error.message}`,false)
        }
      );
  }

  private clearTogglePhysical(): void {
    document
      .getElementById('onPhysicalLabel')
      .classList.remove('selected', 'no-selected');
    document
      .getElementById('naPhysicalLabel')
      .classList.remove('selected', 'no-selected');
    document
      .getElementById('offPhysicalLabel')
      .classList.remove('selected', 'no-selected');
  }

  private clearToggleChronicle(): void {
    document
      .getElementById('onChronicleLabel')
      .classList.remove('selected', 'no-selected');
    document
      .getElementById('naChronicleLabel')
      .classList.remove('selected', 'no-selected');
    document
      .getElementById('offChronicleLabel')
      .classList.remove('selected', 'no-selected');
  }

  public onChangeSlideTogglePhysical(event: any, id: string): void {
    this.clearTogglePhysical();

    id === 'naPhysicalLabel'
      ? document.getElementById(id).classList.add('without-state')
      : document.getElementById(id).classList.add('selected');

    const {
      target: { value },
    } = event;
    this.valueOne = value;
    value == 1
      ? (this.limitacionfisica = true)
      : (this.limitacionfisica = false);

    if (value == '1') {
      for (let i = 0; i < this.infLimitacionFisicaForm.length; i++) {
        this.infLimitacionFisicaForm[i].controls.enfermedad.setValidators([
          Validators.required,
        ]);
        this.infLimitacionFisicaForm[
          i
        ].controls.enfermedad.updateValueAndValidity();

        this.infLimitacionFisicaForm[i].controls.fechaEnfermedad.setValidators([
          Validators.required,
        ]);
        this.infLimitacionFisicaForm[
          i
        ].controls.fechaEnfermedad.updateValueAndValidity();

        this.infLimitacionFisicaForm[i].controls.duracion.setValidators([
          Validators.required,
        ]);
        this.infLimitacionFisicaForm[
          i
        ].controls.duracion.updateValueAndValidity();

        this.infLimitacionFisicaForm[i].controls.datosHospital.setValidators([
          Validators.required,
        ]);
        this.infLimitacionFisicaForm[
          i
        ].controls.datosHospital.updateValueAndValidity();
      }
    } else {
      for (let i = 0; i < this.infLimitacionFisicaForm.length; i++) {
        Object.keys(this.infLimitacionFisicaForm[i].controls).forEach(
          (controlName) => {
            const control = this.infLimitacionFisicaForm[i].get(controlName);
            control.clearValidators();
            control.updateValueAndValidity();
          }
        );
      }
    }
  }

  public onChangeSlideToggleChronicle(event: any, id: string): void {
    this.clearToggleChronicle();

    id === 'naChronicleLabel'
      ? document.getElementById(id).classList.add('without-state')
      : document.getElementById(id).classList.add('selected');

    const {
      target: { value },
    } = event;
    this.valueTwo = value;
    value == 1
      ? (this.limitacioncronica = true)
      : (this.limitacioncronica = false);

    if (value == '1') {
      for (let i = 0; i < this.infEnfermedadForm.length; i++) {
        this.infEnfermedadForm[i].controls.enfermedad.setValidators([
          Validators.required,
        ]);
        this.infEnfermedadForm[i].controls.enfermedad.updateValueAndValidity();

        this.infEnfermedadForm[i].controls.fechaEnfermedad.setValidators([
          Validators.required,
        ]);
        this.infEnfermedadForm[
          i
        ].controls.fechaEnfermedad.updateValueAndValidity();

        this.infEnfermedadForm[i].controls.duracion.setValidators([
          Validators.required,
        ]);
        this.infEnfermedadForm[i].controls.duracion.updateValueAndValidity();

        this.infEnfermedadForm[i].controls.datosHospital.setValidators([
          Validators.required,
        ]);
        this.infEnfermedadForm[
          i
        ].controls.datosHospital.updateValueAndValidity();
      }
    } else {
      for (let i = 0; i < this.infEnfermedadForm.length; i++) {
        Object.keys(this.infEnfermedadForm[i].controls).forEach(
          (controlName) => {
            const control = this.infEnfermedadForm[i].get(controlName);
            control.clearValidators();
            control.updateValueAndValidity();
          }
        );
      }
    }
  }

  public guardarAsegurabilidad() {
    for (let i = 0; i < this.infLimitacionFisicaForm.length; i++) {
      if (!this.infLimitacionFisicaForm[i].valid) {
        Object.keys(this.infLimitacionFisicaForm[i].controls).forEach(
          (controlName) => {
            const control = this.infLimitacionFisicaForm[i].get(controlName);
            control.markAsTouched();
          }
        );
      }
    }

    for (let i = 0; i < this.infEnfermedadForm.length; i++) {
      if (!this.infEnfermedadForm[i].valid) {
        Object.keys(this.infEnfermedadForm[i].controls).forEach(
          (controlName) => {
            const control = this.infEnfermedadForm[i].get(controlName);
            control.markAsTouched();
          }
        );
      }
    }

    let enfermedadesForm: Array<FormGroup> =
      this.infLimitacionFisicaForm.concat(this.infEnfermedadForm);
    let enfermedades: Array<Segurabilidad> = [];

    enfermedadesForm.forEach((enfermedad) => {
      this.valoresFormulario = [];
      this.valoresFormulario.push(enfermedad.value);

      this.valoresFormulario.forEach((resp) => {
        if (this.limitacionfisica && resp.codPregunta == 1) {
          if (
            resp.enfermedad == '' ||
            resp.fechaEnfermedad == '' ||
            resp.duracion == '' ||
            resp.datosHospital == ''
          ) {
            this._dialogService.openDialog(
              'Antes de continuar',
              'Favor completar todos los campos',
              false
            );
            this.formulariosValidos = false;
          } else {
            this.formulariosValidos = true;
          }
        }
        if (this.limitacioncronica && resp.codPregunta == 2) {
          if (
            resp.enfermedad == '' ||
            resp.fechaEnfermedad == '' ||
            resp.duracion == '' ||
            resp.datosHospital == ''
          ) {
            this._dialogService.openDialog(
              'Antes de continuar',
              'Favor completar todos los campos',
              false
            );
            this.formulariosValidos = false;
          } else {
            this.formulariosValidos = true;
          }
        }
      });

      let fechaTemp: string;

      if (enfermedad.value.fechaEnfermedad !== '') {
        const split = enfermedad.value.fechaEnfermedad.split('-');
        fechaTemp = `${split[2]}-${split[1]}-${split[0]}`;
      }
      if (enfermedad.value.codPregunta == 1) {
        enfermedades.push({
          numeroCarpeta: parseInt(this.data.numeroCarpeta),
          pregunta:
            '¿Tiene usted alguna limitación física o psicológica que haya disminuido la capacidad laboral igual o superior al 50%?',
          indRespuesta: this.limitacionfisica ? 1 : 0,
          enfermedad: enfermedad.value.enfermedad,
          fechaEnfermedad: fechaTemp ? fechaTemp : '',
          duracion: enfermedad.value.duracion,
          datosMedico: '', //TODO revisar con UX o tecno (falta campo en el diseno)
          datoshospital: enfermedad.value.datosHospital,
          codPregunta: enfermedad.value.codPregunta,
        });
      } else {
        enfermedades.push({
          numeroCarpeta: parseInt(this.data.numeroCarpeta),
          pregunta:
            '¿Padeció o padece, recibió o recibe algún tratamiento por enfermedades congénita, enfermedades cardiovasculares, hipertensión arterial, diabetes, enfermedades pulmonares, hepatitis B, cirrosis, VIH sida, insuficiencia renal, cáncer, enfermedades neurológicas o psiquiátricas?',
          indRespuesta: this.limitacioncronica ? 1 : 0,
          enfermedad: enfermedad.value.enfermedad,
          fechaEnfermedad: fechaTemp ? fechaTemp : '',
          duracion: enfermedad.value.duracion,
          datosMedico: '', //TODO revisar con UX o tecno (falta campo en el diseno)
          datoshospital: enfermedad.value.datosHospital,
          codPregunta: enfermedad.value.codPregunta,
        });
      }
    });

    console.log('::fomsValidos', this.formulariosValidos);
    if (!this.limitacionfisica && !this.limitacioncronica) {
      this.activarServicio(enfermedades);
      // console.log(':: funcion cheks desactivados')
    } else if (this.limitacionfisica || this.limitacioncronica) {
      if (this.formulariosValidos) {
        this.activarServicio(enfermedades);
        // console.log(':: funcion solo un check desactivado')
      }
    }
  }

  private activarServicio(enfermedades: any): void {
    this.isLoadingInsurabilityInfo = true;

    this._infoAdicionalService
      .postInfoSegurabilidadAdicional({ segurabilidad: enfermedades })
      .subscribe(
        (resp: any) => {
          if (resp.status === 200) {
            this.wasSaved.asegurabilidadForm = true;
            this._dialogService.openDialog(
              'Información asegurabilidad',
              resp.message,
              true
            );

            this.isLoadingInsurabilityInfo = false;
          }

          if (resp.status !== 200) {
            this._dialogService.openDialog(
              'Información asegurabilidad',
              'Hubo un error al mandar los datos de asegurabilidad',
              true
            );

            this.isLoadingInsurabilityInfo = false;
          }

          if (resp.status === 200) {
            this._cambioEstadoService
              .cambioEstadocarpeta(
                Number(this._dataService.numeroCarpeta),
                '800',
                'Cambio de estado aprobado por el asesor'
              )
              .subscribe(
                (resp: any) => {
                  if (!resp.code) {
                    this.router.navigateByUrl('pro-solicitud');
                  }
                },
                (err) => {
                  this._dialogService.openDialog(
                    'Advertencia',
                    err.error.message,
                    true
                  );
                }
              );
          }
        },
        () => {
          this._dialogService.openDialog(
            'Advertencia',
            'Los datos no pudieron ser enviados correctamente, intentalo de nuevo.',
            true
          );

          this.isLoadingInsurabilityInfo = false;
        }
      );
  }

  private getBancos() {
    this.adicionalService.getBancos().subscribe((res) => {
      this.bancos = res.rows;
      this.filteredBancos = this.bancos.slice();
    });
  }

  private getTipoProducto(next: Function = () => { }) {
    this.tipoOpServ.getTipoOperacion().subscribe((res) => {
      this.tipoProductos = res.rows;
      this.tipoProductos = this.sortCatalog(this.tipoProductos, 'nombre');
      this.filteredTipoProductos = this.tipoProductos.slice();
      next();
    });
  }

  private getEstadoCivil() {
    this.adicionalService.getEstadoCivil().subscribe((res) => {
      this.estadoCivil = res.rows;
      this.filteredEstadoCivil = this.estadoCivil.slice();
    });
  }

  private getDepartamentos(next: Function = () => { }) {
    this.adicionalService.getDepartamentos().subscribe((res) => {
      this.departamentos = res.rows;
      this.filteredDepartamentos = this.departamentos.slice();
      next();
    });
  }

  public filterDeptos(nomDepto: string): void {
    this.filteredDeptos_ = [];
    this.filteredDepartamentos.forEach((item) => {
      if (item.nomDepto.includes(nomDepto.toUpperCase())) {
        this.filteredDeptos_.push(item);
      }
    });
  }

  public getCiudades(numDepto: number, next: Function = () => { }) {
    this.adicionalService.getCiudades(numDepto).subscribe((res) => {
      this.ciudades = res.rows;
      this.filteredCiudades = this.ciudades.slice();
      next();
    });
    this.valueDepto = String(numDepto);
  }

  public filterCityes(nomCtroPobla: string): void {
    this.filteredCity_ = [];
    this.filteredCiudades.forEach((item) => {
      if (item.nomCtroPobla.includes(nomCtroPobla.toUpperCase())) {
        this.filteredCity_.push(item);
      }
    });
  }

  private getPaises() {
    this.adicionalService.getPaises().subscribe((res) => {
      this.paises = res.rows.sort((a, b) =>
        a.nombreEstado.localeCompare(b.nombreEstado)
      );

      this.filteredTipoPaises = this.paises.slice();
    });
  }

  private getProfesiones() {
    this.adicionalService.getProfesiones().subscribe((res) => {
      this.profesiones = res.rows;
      this.filteredProfesiones = this.profesiones.slice();
    });
  }

  private getDestinoCredito() {
    this.adicionalService
      .getDestinoCredito(this._dataService.numeroCarpeta)
      .subscribe((res) => {
        this.destinoc = res.rows;
        this.filteredDestinoc = this.destinoc.slice();
      });
  }

  private getFuerza() {
    this.adicionalService.getFuerza().subscribe((res) => {
      this.fuerzas = res.rows;
      this.fuerzas = this.sortCatalog(this.fuerzas, 'nombre');
    });
  }

  private getTipoPension() {
    this.adicionalService.getTipoPension().subscribe((res) => {
      this.tipoPensiones = res.rows;
      this.tipoPensiones = this.sortCatalog(this.tipoPensiones, 'nombre');
    });
  }

  private getCodigoPension() {
    this.adicionalService.getCagen().subscribe((res) => {
      this.codigoPensiones = res.rows;
      this.codigoPensiones = this.sortCatalog(this.codigoPensiones, 'codigo');
    });
  }

  public getMonedas() {
    this.adicionalService.getMonedas().subscribe((res) => {
      this.monedas = res.rows.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this.filteredMonedas = this.monedas.slice();
    });
  }

  private getAllCiudades(next: Function = () => { }): void {
    this.adicionalService.getAllCiudades().subscribe((resp: any) => {
      this.ciudadesModel = resp.rows;
      this.filteredCiudadesModel = this.ciudadesModel.slice();
      this.filteredCiudadesLugarExpedicion = this.ciudadesModel.slice();
      next();
    });
  }

  private getDireccionesResidencia(): void {
    this._infoAdicionalService.getDataDireccion(3).subscribe((resp: any) => {
      this.direccionResidenciaModel = resp.rows;
      this.filteredDireccionResidencia = this.direccionResidenciaModel.slice();
    });
  }

  private getDireccionesTorre(): void {
    this._infoAdicionalService.getDataDireccion(7).subscribe((resp: any) => {
      this.direccionTorreInterior = resp.rows;
      this.direccionTorreInterior = this.sortCatalog(
        this.direccionTorreInterior,
        'NOMBRE_LARGO'
      );
      this.filteredDireccionTorreInterior = this.direccionTorreInterior.slice();
    });
  }

  private getOrigenFondos() {
    this.adicionalService.getOrigenFondos().subscribe((res) => {
      this.origenFondos = res.rows;
      // this.origenFondos = this.sortCatalog(this.origenFondos, 'nombre');
    });
  }

  private getTipoDesembolso(): void {
    this.adicionalService.getTipoDesembolso().subscribe((res) => {
      if (this.data.estado !== '2') {
        this.tipoDesembolso = res.rows.filter((tipo: any) => tipo.codigo !== 3);

        this.infDesembolsoForm.get('modalidadDesembolso').setValue(1);

        for (const key in this.infDesembolsoForm.controls) {
          this.infDesembolsoForm.get(key).setValidators(Validators.required);
          this.infDesembolsoForm.get(key).updateValueAndValidity();
        }
      } else {
        this.tipoDesembolso = res.rows;
      }
    });
  }

  private getTipoCuenta() {
    this.adicionalService.getTipoCuenta().subscribe((res) => {
      this.tipoCuenta = res.rows;
    });
  }

  public validEqual(): void {
    const { codigoMilitar, codigoConfirm } = this.infLaboralAdicionalForm.value;

    if (codigoConfirm != '') {
      if (codigoMilitar != codigoConfirm) {
        this._dialogService.openDialog("!",`Los códigos militares no son iguales`,false)
        this.disabledButtonLoboral = true;
      } else {
        this.disabledButtonLoboral = false;
      }
    }
  }

  private getReconocimiento(): void {
    this._infoAdicionalService
      .getReconocimiento(parseInt(this._dataService.numeroSolicitud))
      .subscribe((resp: any) => {
        let { fechaExpedicion, direccion, email, codReconocer } = resp;
        let partesFecha = fechaExpedicion.split('-')
        this.infPersonalAdicionalForm.controls.fechaExpedicionIdentificacion.setValue(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`)
        this.direccion = direccion[0].direccion;
        this.emailRecocer = email.length > 0 ? email[0].email : '';
        this.codReconocer = codReconocer;

        if (!this.direccion) {
          this.infPersonalAdicionalForm.controls.direccionResidencia.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.controls.box1.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.controls.box2.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.controls.box3.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.controls.box4.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.controls.direccionTorre.setValidators(
            Validators.required
          );
          this.infPersonalAdicionalForm.updateValueAndValidity();
        }
      });
  }

  public setDireccion(): void {
    const { direccionResidencia, direccionTorre, box1, box2, box3, box4 } =
      this.infPersonalAdicionalForm.value;

    this.d1 = direccionResidencia;
    this.d2 = box1;
    this.d3 = box2;
    this.d4 = box3;
    this.d5 = direccionTorre;
    this.d6 = box4;

    this.direccionCompleta = `${this.d1} ${this.d2} ${this.d3} ${this.d4} ${this.d5} ${this.d6}`;
  }

  public sendInfoPersonal(): void {
    this.isLoadingPersonalInfo = true;

    this.resumenAdoService.getResumenAd(this.data.numeroCarpeta).subscribe(
      (resp: any) => {
        if (resp.marcaAuditoria === 1) {
          const ref = this.dialog.open(DialogComponent, {
            data: {
              titulo: 'Advertencia',
              mensaje: 'Carpeta se encuentra en validación. Intente mas tarde',
              success: true,
            },
            disableClose: true,
          });

          this._dialogService.openDialog(
            'Advertencia',
            'Carpeta se encuentra en validación. Intente mas tarde',
            true
          );

          this.isLoadingPersonalInfo = false;

          ref.beforeClosed().subscribe(() => this.router.navigate(['/home']));
          return;
        }

        const {
          lugarExpedicionIdentificacion,
          lugarNacimiento,
          estadoCivil,
          profesionOficio,
          destinoCredito,
          departamento,
          ciudad,
        } = this.infPersonalAdicionalForm.value;

        //Se formatea fech
        const body: IInfoPersonalBody = {
          numeroCarpeta: Number(this._dataService.numeroCarpeta),
          fechaExpedicionIdentificacion: this.infPersonalAdicionalForm.controls.fechaExpedicionIdentificacion.value,
          lugarExpedicionIdentificacion: this.valueLugarExpedicion.toString(),
          lugarNacimiento: this.valueLugarNacimiento.toString(),
          estadoCivil: estadoCivil.toString(),
          profesionOficio,
          direccion: this.codReconocer
            ? this.direccion
            : this.direccionCompleta,
          departamento: this.valueDepto.toString(),
          ciudad: this.valueCity.toString(),
          destinoCredito: parseInt(destinoCredito),
          correo: this.emailRecocer,
        };

        this._infoAdicionalService.postInfoPersonalAdicional(body).subscribe(
          (resp: any) => {
            if (resp.status === 200) {
              this.wasSaved.personalForm = true;
              this._dialogService.openDialog(
                'Información Personal',
                resp.menssage,
                true
              );

              this._cambioEstadoService
                .cambioEstadocarpeta(
                  Number(this._dataService.numeroCarpeta),
                  '700',
                  'Cambio de estado aprobado por el asesor'
                )
                .subscribe(
                  (resp: any) => {
                    !resp.code && console.log('Ok estado');
                    this.stepper++;
                    this.isLoadingPersonalInfo = false;
                    this.firstLoadedData = true;
                  },
                  (err) => {
                    this._dialogService.openDialog(
                      'Advertencia',
                      err.error.message,
                      true
                    );

                    this.isLoadingPersonalInfo = false;
                  }
                );
            }
          },
          (err) => {
            this._dialogService.openDialog(
              'Advertencia',
              err.error.message,
              true
            );

            this.isLoadingPersonalInfo = false;
          }
        );
      },
      (err) => {
        this._dialogService.openDialog('Advertencia', err.error.message, true);

        this.isLoadingPersonalInfo = false;
      }
    );
  }

  public sendInfoLaboral(): void {
    this.isLoadingLaborInfo = true;

    const body: IInfoLaboralBody = {
      ...this.infLaboralAdicionalForm.value,
      numeroCarpeta: Number(this._dataService.numeroCarpeta),
    };

    const codigoMilitarFormValue =
      this.infLaboralAdicionalForm.controls.codigoMilitar.value;

    const codigoMilitar =
      codigoMilitarFormValue !== undefined && codigoMilitarFormValue !== null
        ? codigoMilitarFormValue
        : this.numeroCodigoMilitar ?? 0;

    const newBody = {
      ...body,
      codigoMilitar,
      numeroAfiliacion: this.numeroAfiliacion,
      cedulaTitular: Number(body.cedulaTitular),
    };

    this._infoAdicionalService.postInfoLaboralAdicional(newBody).subscribe(
      (resp: any) => {
        if (resp.status === 200) {
          this.wasSaved.laboralForm = true;
          this._dialogService.openDialog(
            'Información laboral',
            resp.message,
            true
          );

          this.isLoadingLaborInfo = false;
          this.stepper++;
          this.secondLoadedData = true;
        }
      },
      (err) => {
        this._dialogService.openDialog(
          'Información laboral',
          err.error.message,
          true
        );

        this.isLoadingLaborInfo = false;
      }
    );
  }

  validaMoneda() {
    this.activeMonedaExtranjera = !this.activeMonedaExtranjera;
    const {
      totalActivos,
      totalPasivos,
      ingresos,
      gastos,
      otrosIngresos,
      origenFondos,
    } = this.infFinancieraAdicionalForm.value;
    console.log(this.activeMonedaExtranjera);

    if (this.activeMonedaExtranjera) {
      this.infFinancieraAdicionalForm = this.formBuilder.group({
        nuemeroCarpeta: [''],
        totalActivos: totalActivos,
        totalPasivos: totalPasivos,
        ingresos: ingresos,
        gastos: gastos,
        otrosIngresos: otrosIngresos,
        descripcionOtrosIngresos: [''],
        tipoProducto: ['', Validators.required],
        monedaExtranjera: [false],
        origenFondos: origenFondos,
        moneda: ['', Validators.required],
        pais: ['', Validators.required],
        ciudad: ['', Validators.required],
      });
    } else if (!this.activeMonedaExtranjera) {
      this.infFinancieraAdicionalForm = this.formBuilder.group({
        nuemeroCarpeta: [''],
        totalActivos: totalActivos,
        totalPasivos: totalPasivos,
        ingresos: ingresos,
        gastos: gastos,
        otrosIngresos: otrosIngresos,
        descripcionOtrosIngresos: [''],
        tipoProducto: [''],
        monedaExtranjera: [true],
        origenFondos: origenFondos,
        moneda: [''],
        pais: [''],
        ciudad: [''],
      });
    }
  }

  public sendInfoFinanciera(): void {
    this.isLoadingFinancialInfo = true;

    if (!this.infFinancieraAdicionalForm.valid) {
      return;
    }

    let {
      descripcionOtrosIngresos,
      gastos,
      ingresos,
      moneda,
      monedaExtranjera,
      origenFondos,
      otrosIngresos,
      pais,
      tipoProducto,
      totalActivos,
      totalPasivos,
      ciudad,
    } = this.infFinancieraAdicionalForm.value;

    let monedaExt: number;

    monedaExtranjera ? (monedaExt = 1) : (monedaExt = 2);

    const body: IInfoFinancieraBody = {
      nui: 0,
      numeroCarpeta: parseInt(this._dataService.numeroCarpeta),
      totalActivos,
      totalPasivos,
      ingresos,
      gastos,
      otrosIngresos: !otrosIngresos ? 0 : parseInt(otrosIngresos),
      descripcionOtrosIngresos,
      origenFondos: origenFondos.toString(),
      operacionesMonedaExtrangera: monedaExt,
      tipoProducto: String(tipoProducto),
      moneda,
      pais: pais.toString(),
      ciudad: '', //TODO -> Se manda vacío ya que en diálogo no hay un espacio para almacenar el valor,
      // Tecno debería corregir este campo para que acepte ciudades como "CDMX" "Bogotá" y no códigos de país
    };

    this._infoAdicionalService.postInfoFinancieraAdicional(body).subscribe(
      (resp: any) => {
        if (resp.status === 200) {
          this.wasSaved.financialForm = true;
          this._dialogService.openDialog(
            'Información financiera',
            resp.message,
            true
          );

          this.isLoadingFinancialInfo = false;
          this.stepper++;
          this.thirdLoadedData = true;
        }
      },
      (err) => {
        this._dialogService.openDialog(
          'Información financiera',
          err.error.message,
          true
        );

        this.isLoadingFinancialInfo = false;
      }
    );
  }

  public sendInfoDesembolso(): void {
    this.isLoadingDisbursementInfo = true;

    const { banco, modalidadDesembolso, numeroCuenta, tipoCuenta } =
      this.infDesembolsoForm.value;

    const body: IInfoDesembolsoBody = {
      nui: 0,
      numeroCarpeta: parseInt(this._dataService.numeroCarpeta),
      modalidadDesembolso: modalidadDesembolso.toString(),
      ciudadEntrega: '5001',
      banco: modalidadDesembolso == '1' ? banco.toString() : '0',
      tipoCuenta: modalidadDesembolso == '1' ? tipoCuenta.toString() : '0',
      numeroCuenta: modalidadDesembolso == '1' ? numeroCuenta : '0',
    };

    this._infoAdicionalService.postInfoDesembolsoAdicional(body).subscribe(
      (resp: any) => {
        if (resp.status === 200) {
          this.wasSaved.desembolsoForm = true;
          this._dialogService.openDialog(
            'Información financiera',
            resp.message,
            true
          );

          this.isLoadingDisbursementInfo = false;
          this.stepper++;
          this.fourthlLoadedData = true;
        } else if (resp.status !== 200) {
          this._dialogService.openDialog(
            'Información financiera',
            resp.message,
            true
          );

          this.infDesembolsoForm.get('banco').setValue(null);
          this.infDesembolsoForm.get('tipoCuenta').setValue(null);
          this.infDesembolsoForm.get('numeroCuenta').setValue(null);
          return;
        }
      },
      (err) => {
        if (err.error.code === 409) {
          this._dialogService.openDialog(
            'Información financiera',
            'Verifique los datos de la cuenta ingresada',
            true
          );

          this.infDesembolsoForm.get('banco').setValue(null);
          this.infDesembolsoForm.get('tipoCuenta').setValue(null);
          this.infDesembolsoForm.get('numeroCuenta').setValue(null);

          this.isLoadingDisbursementInfo = false;

          return;
        }
      }
    );
  }

  private sortCatalog(catlog: any, sort: string) {
    catlog = catlog.sort(function (a, b) {
      var nameA = a[sort].toUpperCase(); // ignore upper and lowercase
      var nameB = b[sort].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    return catlog;
  }

  private async getInformation(): Promise<void> {
    this.isLoading = true;
    this.getPersonalInformation();
    this.getEmploymentInformation();
    this.getFinancialInformation();
    /**
     * Si no hay información de la carpeta, la consulta.
     */
    const folderData = await this._busquedaService.getResultadosBusqueda({
      numeroCarpeta: this.data.numeroCarpeta,
    });
    this.dataCarpeta = folderData.rows[0];
    this.getDisbrusementInformation();
  }

  private getPersonalInformation(): void {
    this._infoAdicionalService
      .getPersonalInformation(this._dataService.numeroCarpeta)
      .subscribe(
        (res) => {
          this.infPersonalAdicionalForm.controls.fechaExpedicionIdentificacion.setValue(res.fechaExpedicionIdentificacion);
          this.valueLugarExpedicion = String(res.lugarExpedicionIdentificacion);
          this.valueLugarNacimiento = String(res.lugarNacimiento);
          this.getAllCiudades(() => {
            this.isLoading = false;
            this.ciudadesModel.filter((item) => {
              if (item.codMunic === String(res.lugarNacimiento))
                this.infPersonalAdicionalForm.controls.lugarNacimiento.setValue(
                  item.filteredName
                );
              if (item.codMunic === String(res.lugarExpedicionIdentificacion))
                this.infPersonalAdicionalForm.controls.lugarExpedicionIdentificacion.setValue(
                  item.filteredName
                );
            });
          });
          this.infPersonalAdicionalForm.controls.estadoCivil.setValue(
            res.estadoCivil
          );
          this.infPersonalAdicionalForm.controls.profesionOficio.setValue(
            String(res.profesionOficio)
          );
          this.infPersonalAdicionalForm.controls.destinoCredito.setValue(
            res.destinoCredito
          );
          this.infPersonalAdicionalForm.controls.direccion.setValue(
            res.direccion
          );
          this.valueDepto = String(res.departamento);
          this.valueCity = String(res.ciudad);
          this.getDepartamentos(() => {
            this.departamentos.filter((item) => {
              if (item.codDepto === String(res.departamento)) {
                this.infPersonalAdicionalForm.controls.departamento.setValue(
                  item.nomDepto
                );
              }
            });
          });
          this.getCiudades(res.departamento, () => {
            this.ciudades.filter((item) => {
              if (item.codMunic === String(res.ciudad)) {
                this.infPersonalAdicionalForm.controls.ciudad.setValue(
                  item.nomMunic
                );
              }
            });
          });
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
        }
      );
  }

  private getEmploymentInformation(): void {
    this._infoAdicionalService
      .getEmploymentInformation(this._dataService.numeroCarpeta)
      .subscribe(
        (res) => {
          this.infLaboralAdicionalForm.controls.numeroCarpeta.setValue(
            res.numeroCarpeta
          );
          this.infLaboralAdicionalForm.controls.codigoVerificacion1.setValue(
            res.codigoVerificacion1
          );
          this.infLaboralAdicionalForm.controls.codigoVerificacion2.setValue(
            Number(res.codigoVerificacion2) === 0 ? '' : res.codigoVerificacion2
          );
          this.numeroCodigoMilitar =
            res.codigoMilitar == '0' ? 0 : Number(res.codigoMilitar);
          this.infLaboralAdicionalForm.controls.codigoMilitar.setValue(
            Number(res.codigoMilitar) === 0 ? '' : res.codigoMilitar
          );
          // this.infLaboralAdicionalForm.controls.codigoConfirm.setValue();
          // this.infLaboralAdicionalForm.controls.fuerza.setValue(String(res.fuerza));
          this.numeroAfiliacion =
            Number(res.numeroAfiliacion) === 0 ? 0 : res.numeroAfiliacion;
          this.infLaboralAdicionalForm.controls.numeroAfiliacion.setValue(
            Number(res.numeroAfiliacion) === 0 ? '' : res.numeroAfiliacion
          );
          this.infLaboralAdicionalForm.controls.tipoPension.setValue(
            res.tipoPension
          );
          this.infLaboralAdicionalForm.controls.cedulaTitular.setValue(
            Number(res.cedulaTitular) === 0 ? '' : res.cedulaTitular
          );
          this.infLaboralAdicionalForm.controls.codigoAfiliacion.setValue(
            res.codigoAfiliacion
          );
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
        }
      );
  }

  private getFinancialInformation(): void {
    this._infoAdicionalService
      .getFinancialInformation(this._dataService.numeroCarpeta)
      .subscribe(
        (res) => {
          this.infFinancieraAdicionalForm.controls.nuemeroCarpeta.setValue(
            res.numeroCarpeta
          );
          this.infFinancieraAdicionalForm.controls.totalActivos.setValue(
            Number(res.totalActivos) === 0 ? null : res.totalActivos
          );
          this.infFinancieraAdicionalForm.controls.totalPasivos.setValue(
            Number(res.totalPasivos) === 0 ? null : res.totalPasivos
          );
          this.infFinancieraAdicionalForm.controls.ingresos.setValue(
            Number(res.ingresos) === 0 ? null : res.ingresos
          );
          this.infFinancieraAdicionalForm.controls.gastos.setValue(
            Number(res.gastos) === 0 ? null : res.gastos
          );
          this.infFinancieraAdicionalForm.controls.otrosIngresos.setValue(
            Number(res.otrosIngresos) === 0 ? null : res.otrosIngresos
          );
          this.infFinancieraAdicionalForm.controls.descripcionOtrosIngresos.setValue(
            res.descripcionOtrosIngresos
          );
          this.infFinancieraAdicionalForm.controls.monedaExtranjera.setValue(
            Number(res.operacionesMonedaExtrangera) === 1
          );
          this.infFinancieraAdicionalForm.controls.tipoProducto.setValue(
            Number(res.tipoProducto)
          );
          this.infFinancieraAdicionalForm.controls.origenFondos.setValue(
            typeof this.pagaduriaList.find(
              (element) =>
                Number(element.codigo_pagaduria) ===
                Number(this._dataService.pagaduriaRef)
            ) === 'undefined'
              ? 2
              : 1
          );
          this.infFinancieraAdicionalForm.controls.moneda.setValue(res.moneda);
          this.infFinancieraAdicionalForm.controls.pais.setValue(res.pais);
          this.infFinancieraAdicionalForm.controls.ciudad.setValue(res.ciudad);
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
        }
      );
  }

  private setDisbrusement(value: number): number {
    if (
      this.dataCarpeta['estadoLaboral'].toLowerCase().includes('pensionado') &&
      value === 0
    ) {
      return 3;
    }
    return value;
  }

  private getDisbrusementInformation(): void {
    this._infoAdicionalService
      .getDisbrusementInformation(this._dataService.numeroCarpeta)
      .subscribe(
        (res) => {
          this.infDesembolsoForm.controls.modalidadDesembolso.setValue(
            this.setDisbrusement(res.modalidadDesembolso)
          );
          this.infDesembolsoForm.controls.banco.setValue(String(res.banco));
          this.infDesembolsoForm.controls.tipoCuenta.setValue(res.tipoCuenta);
          this.infDesembolsoForm.controls.numeroCuenta.setValue(
            res.numeroCuenta
          );
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
        }
      );
  }

  validateForms(): Array<string> {
    let values: Array<string> = [];
    !this.wasSaved.personalForm
      ? values.push('Información personal adicional')
      : '';
    !this.wasSaved.laboralForm
      ? values.push('Información laboral adicional')
      : '';
    !this.wasSaved.financialForm
      ? values.push('Información financiera adicional')
      : '';
    !this.wasSaved.desembolsoForm ? values.push('Información desembolso') : '';
    return values;
  }
}
