<app-login-header-mb>
  <div menuResponsive class="sesion" routerLink="/iniciar-sesion">
    <span style="color: var(--p-blue);" class="material-icons top-money">person</span>
    <span style="width: 90%; color: var(--p-blue); line-height: 15px; font-size: 18px; text-align: center;"
      >Iniciar
      sesión</span
    >
  </div>
</app-login-header-mb>
<app-header-login-tb>
  <div menuResponsive class="sesion-tb" routerLink="/crear-cuenta">
    <span class="material-icons s-30">person</span>
    <span class="nuevo-credito-desc">Iniciar<br />Sesión</span>
  </div>
</app-header-login-tb>
<div class="content">
  <div class="cont-form">
    <h1>Validar cuenta</h1>
    <p class="sub">
      Ingresa el código de verificación de <strong>6 dígitos </strong> que hemos
      enviado al número celular {{usuario?.telefono}} o al correo {{usuario?.correo}}
    </p>
    <code-input
      class="code-input"
      id="otp"
      (codeCompleted)="onCodeCompleted($event)"
      #input
      [isCodeHidden]="false"
      [isNonDigitsCode]="false"
      [codeLength]="6"
    >
    </code-input>
    <p style="color: red; font-weight: bold;">{{ errorLog }}</p>
    <p class="plink sub">
      ¿No has recibido el código?
      <a class="olvide-pass" role="button" (click)="reenviar()">Enviar nuevamente</a>
    </p>
    <p></p>
    <button
      type="button"
      class="btn btn-primary"
      (click)="valOtp()"
    >
      Continuar
    </button>
    <!--<button class="g-recaptcha"
        data-sitekey="6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP"
        data-callback='onSubmit'
        data-action='submit'>Submit</button>-->
  </div>
</div>
