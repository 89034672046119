import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function ageRangeValidator(minAge: number, maxAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const dateStruct: NgbDateStruct = control.value;
    if (!dateStruct.year || !dateStruct.month || !dateStruct.day) {
      return { invalidDate: true };
    }

    // Convertir NgbDateStruct a un string con formato YYYY-MM-DD
    const birthDateString = `${dateStruct.year}-${dateStruct.month.toString().padStart(2, '0')}-${dateStruct.day.toString().padStart(2, '0')}`;
    const birthDate = moment(birthDateString, 'YYYY-MM-DD', true);

    if (!birthDate.isValid()) {
      return { invalidDate: true };
    }

    const age = moment().diff(birthDate, 'years');
    return age < minAge || age > maxAge ? { ageOutOfRange: { age } } : null;
  };
}
