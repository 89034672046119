import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Documento } from 'src/app/models/documento';
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ArchivoCargaService } from 'src/app/services/notificaciones/archivoCarga.service';
import { DocSolicitudService } from 'src/app/services/perfilamiento/doc-solicitud.service';
import { Utils } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload-physical-docs',
  templateUrl: './file-upload-physical-docs.component.html',
  styleUrls: ['./file-upload-physical-docs.component.scss']
})
export class FileUploadPhysicalDocsComponent {

  @Input() rules: string[] = []; // reglas para carga de archivos
  @Input() title: string = ""; // titulo de la tarjeta
  @Input() subtitle: string = ""; // subtitulo de la tarjeta
  @Input() contract: boolean = false; // titulo de la tarjeta
  @Input() secuencia: number = 0; // secuencia para aws
  @Input() idFolder: number = 0; // idFolder aws
  @Input() identificacion: string = ""; // Identificacion fileserver
  @Input() nombreArchivoFinal: string = ""; // nombre del comprimido

  @Output() finalizaCarga = new EventEmitter<string>();

  constructor(
    public deviceService: DeviceDetectorService,
    public data: DataService,
    private http: HttpClient,
    private readonly datepipe: DatePipe,
    private readonly serviceCarga: ArchivoCargaService,
    private readonly serviceDoc: DocSolicitudService,
    private dialogService: DialogService
  ) {
  }

  private readonly utils = new Utils();
  public masAdicionals: Documento[] = [];
  public loading = false;

  ngOnInit(): void {
    if (this.contract) {
      this.docsContrato();
    }
  }

  public docsContrato() {
    this.http
      .post(`${environment.ApiConecta}generacion/documentos/ADO`, {
        numeroCarpeta: this.data.numeroCarpeta,
      })
      .subscribe(
        (http: any) => {
          this.http = http;
          this.data.contrato_url = http.contrato_url;
          this.data.pagare_url = http.pagare_url;
          let splited = http.contrato_url.split('/');
          this.data.nombre_contrato = splited[splited.length - 1].split('?')[0];
          let splited2 = http.pagare_url.split('/');
          this.data.nombre_pagare = splited2[splited.length - 1].split('?')[0];
        },
        (error) => {
          this.dialogService.openDialog(
            'Opps',
            'Los documentos no fueron cargados correctamente, por favor intentanlo más tarde',
            false
          )
        }
      );
  }

  public async subirArchivos() {
    let document: Documento[] = this.masAdicionals;
    if (document.length > 0) {
      this.loading = true;
      let pdfBytes = await this.utils.appendPDF(
        document.map((doc) => doc.archivo)
      );
      let timestamp: string = this.datepipe.transform(
        new Date(),
        'yyyyMMddHHmmss'
      );
      let name = `${this.nombreArchivoFinal}-${timestamp}.pdf`;
      let pdfFile = new File(
        [new Blob([pdfBytes as ArrayBuffer])],
        name,
        { type: 'application/pdf', lastModified: new Date().getTime() }
      );
      let pdfUnificado: Documento = {
        status: true,
        nombre: name,
        archivo: pdfFile,
        secuencia: this.secuencia,
      };
      this.serviceCarga
        .cargandoArchivo(this.data.numeroCarpeta, this.identificacion, pdfUnificado.nombre)
        .subscribe(
          (respuesta) => {
            let form = new FormData();
            Object.keys(respuesta.fields).forEach((key) =>
              form.append(key, respuesta.fields[key])
            );
            form.append('Content-Type', 'application/pdf');
            form.append('file', pdfUnificado.archivo);
            fetch(respuesta.url, { method: 'POST', body: form }).then(
              (res) => {
                if (res.ok) {
                  //carga de archivo
                  this.serviceDoc
                    .uploadAwsRoute(
                      this.data.numeroCarpeta,
                      respuesta.fields.key,
                      pdfUnificado.secuencia,
                      this.idFolder
                    )
                    .subscribe(
                      (result) => {
                        this.finalizaCarga.emit();
                      },
                      (error) => {

                      }
                    );
                } else {
                  this.dialogService.openDialog(
                    'Opps',
                    'Error al cargar el archivo, intenta nuevamente por favor',
                    false
                  )
                }
              }
            );
          },
          (error) => {
          }
        );
    }
  }

}
