import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '@modules/shared/services/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss']
})
export class IdleModalComponent implements OnInit, OnDestroy {
  timeOut: number = 60;
  private subList: Array<Subscription> = [];
  constructor(
    public dialogRef: MatDialogRef<IdleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public _dialogService: DialogService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this._dialogService.idleActive.emit(true);
    this.subList.push(this._dialogService.idleTimeOut.subscribe(
      res=>{
        this.timeOut = res;
      }
    ));
  }
  ngOnDestroy(): void {
    this.subList.forEach(item=>item.unsubscribe());
    this._dialogService.idleActive.emit(false);
  }

  logout(): void {
    this._dialogService.idleActiveSession.emit(false);
  }
  continue(): void {
    this._dialogService.idleActiveSession.emit(true);
    this.dialogRef.close();
  }

}
