import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { OfertaService } from '../../../pwa/oferta/oferta.service';
import { DataService } from '../../../services/data.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-modifica',
  templateUrl: './dialog-modifica.component.html',
  styleUrls: ['./dialog-modifica.component.scss'],
})
export class DialogModificaComponent {

  dialogForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _seizService: OfertaService,
    private _dataService: DataService,
    private dialogRef: MatDialogRef<DialogModificaComponent>

  ) {
    this.dialogForm = this.formBuilder.group({
      term: ['', [Validators.required, Validators.min(10), Validators.max(999)]],
      ammount: ['', [Validators.required, Validators.min(0)]],
    });
  }

  public changeData(): void {
    const { ammount, term } = this.dialogForm.value;

    this._seizService
      .changeConditions({
        carpeta: parseInt(this._dataService.numeroCarpeta),
        monto: ammount,
        plazo: term,
      })
      .subscribe(({ data: { cuota }, message, status }) => {
        this._seizService.refAmmount = ammount;
        this._seizService.refTerm = term;
        this._seizService.refFee = cuota;
        this._seizService.messageService = message;
        this._seizService.statusService = status;
        this.dialogRef.close();
      }, ({ error: { message, code } }) => {
        this._seizService.messageService = message;
        this._seizService.statusService = code;
        this.dialogRef.close();
      });
  }

}
