import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { BuscCarpetaService } from 'src/app/services/perfilamiento/busc-carpeta.service';
import { GetInformacionService } from 'src/app/services/perfilamiento/get-informacion.service';
import { MotorService } from 'src/app/services/perfilamiento/motor.service';
import { ReconocerService } from 'src/app/services/preOferta/reconocer.service';
import { itemFile } from '../../models/busCarpeta.interface';
import { ConvenioService } from '../../services/catalogos/convenios.service';
import { Pagaduriaservice } from '../../services/catalogos/pagadurias.service';
import { DataService } from '../../services/data.service';
import { GenOfertaService } from '../../services/genOferta/gen-oferta.service';
import { ResumenAdoService } from '../../services/resumen-ado.service';

import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
@Component({
  selector: 'app-busqueda-carpetas',
  templateUrl: './busqueda-carpetas.component.html',
  styleUrls: ['./busqueda-carpetas.component.scss'],
})
export class BusquedaCarpetasComponent {

  @ViewChild('cedulaTemplate', { static: true }) cedulaTemplate!: TemplateRef<any>;
  @ViewChild('fechaTemplate', { static: true }) fechaTemplate!: TemplateRef<any>;
  @ViewChild('pagaduriaTemplate', { static: true }) pagaduriaTemplate!: TemplateRef<any>;
  // @ViewChild('nombreTemplate', { static: true }) nombreTemplate!: TemplateRef<any>;
  @ViewChild('apellidoTemplate', { static: true }) apellidoTemplate!: TemplateRef<any>;
  @ViewChild('carpetaTemplate', { static: true }) carpetaTemplate!: TemplateRef<any>;

  @ViewChild('generalTemplate', { static: true }) generalTemplate!: TemplateRef<any>;
  @ViewChild('motorTemplate', { static: true }) motorTemplate!: TemplateRef<any>;
  @ViewChild('carteraTemplate', { static: true }) carteraTemplate!: TemplateRef<any>;

  myTabs: { title: string; content: TemplateRef<any> }[] = [];
  myTabsDetalle: { title: string; content: TemplateRef<any> }[] = [];

  panelOpenState = false;
  placement = 'bottom';
  private calendar = inject(NgbCalendar);

  model: NgbDateStruct;

  isDisabled = (date: NgbDate, current: { month: number; year: number }) =>
    date.month !== current.month;
  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

  pageNumber: number = 1;
  page_size: number = 3;
  pageSizeOptions = [3, 5, 10];
  isLoading = false;
  searching: boolean = false;
  estado: string = 'busqueda';
  tipoBusqueda: string = 'cedula';
  entradabusqueda: string = '';
  fechaInicial: any = '';
  fechaFinal: any = '';
  convenio: string = '';
  pagaduria: string = '';
  resultadosBusqueda: any[] = [];
  resultadoDetalle: any;
  page_number: number = 0;
  total_pages: number = 0;
  arrayTotal: any[] = [];
  activePage: boolean = false;
  contPages: number = 1;
  resAdo: any[] = [];
  genOf: any = "";
  convenios: any;
  tiposContratos: any;
  edoLaboral: any;
  cargos: any;
  pagaduarias: any;

  public filteredConvenio: any;
  public filteredPagaduria: any;

  dataSource: any;
  numeroCarpeta: string = '';
  numeroSolicitud: string = '';
  infoSolicitud: any;
  mostrarBotonConsultaMotor = false;
  mostrarConsultaMotor = false;
  responseCarpeta = 0;

  estadoColor: any;
  estadoIcono: SafeHtml;
  svgContainer1: any;

  @Input() datosCarpeta: any;

  @ViewChild(PdfViewerComponent) pdfViewer?: PdfViewerComponent;
  page = 1;
  zoom = 1;

  constructor(
    private busService: BuscCarpetaService,
    private router: Router,
    private dialogService: DialogService,
    public data: DataService,
    private getofrtaServ: GenOfertaService,
    private pagaduriaService: Pagaduriaservice,
    private reconocerSrvc: ReconocerService,
    private convenioService: ConvenioService,
    private http: HttpClient,
    private resumenAdoService: ResumenAdoService,
    private getinfoServ: GetInformacionService,
    public dialog: MatDialog,
    private motorService: MotorService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.data.setters();
    this.getConvenios();

    this.myTabs = [
      { title: 'Cédula', content: this.cedulaTemplate },
      { title: 'Fecha de creación', content: this.fechaTemplate },
      { title: 'Pagaduría', content: this.pagaduriaTemplate },
      // { title: 'Nombre', content: this.nombreTemplate },
      { title: 'Apellido', content: this.apellidoTemplate },
      { title: 'Número de carpeta', content: this.carpetaTemplate }
    ];

    // this.myTabsDetalle = [
    //   { title: 'Información general', content: this.generalTemplate },
    //   { title: 'Motor de decisión', content: this.motorTemplate },
    //   { title: 'Comprobantes de compras de cartera', content: this.carteraTemplate }
    // ];
  }

  onPageChange(page: number): void {
    console.log('Página seleccionada:', page);
  }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.pageNumber = e.pageIndex + 1;
  }

  async buscar(dataBusqueda) {
    this.page_number = 0;
    this.searching = true;
    this.tipoBusqueda = dataBusqueda;

    switch (this.tipoBusqueda) {
      case 'cedula':
        let numeroDocumento = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroDocumento });
        break;
      case 'pagaduria':
        let codigoPagaduria = parseInt(this.pagaduria);
        this.exeBusqueda({ codigoPagaduria });
        break;
      case 'nombre':
        let nombreCliente = this.entradabusqueda.toString();
        this.exeBusqueda({ nombreCliente });
        break;
      case 'apellido':
        let primerApellido = this.entradabusqueda;
        this.exeBusqueda({ primerApellido });
        break;
      case 'numsolicitud':
        let numeroSolicitud = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroSolicitud });
        break;
      case 'numcarpeta':
        let numeroCarpeta = parseInt(this.entradabusqueda);
        this.exeBusqueda({ numeroCarpeta });
        break;
      case 'fecha':
        const {
          year: initYear,
          month: initMonth,
          day: initDay,
        } = this.fechaInicial;
        const {
          year: lastYear,
          month: lastMonth,
          day: lastDay,
        } = this.fechaFinal;

        const initMonthCom = initMonth < 10 ? `0${initMonth}` : `${initMonth}`;
        const initDayCom = initDay < 10 ? `0${initDay}` : `${initDay}`;
        const initDate = `${initYear}-${initMonthCom}-${initDayCom}`;
        const lastMonthCom = lastMonth < 10 ? `0${lastMonth}` : `${lastMonth}`;
        const lastDayCom = lastDay < 10 ? `0${lastDay}` : `${lastDay}`;
        const lastDate = `${lastYear}-${lastMonthCom}-${lastDayCom}`;

        this.exeBusqueda({ fechaInicial: initDate, fechaFinal: lastDate });
        break;
    }
  }

  private exeBusqueda(value: any): void {
    this.data.setters();
    this.busService.getResultadosBusqueda(value).subscribe(
      async (res) => {
        if (res.count == 0) {
          this.dialogService.openDialog(
            'Advertencia',
            'No se encontraron resultados',
            false
          );
          this.searching = false;
          return;
        }
        this.resultadosBusqueda = res.rows;
        this.searching = false;
        res.rows[0].estadoLaboral == 'PENSIONADO'
          ? (this.data.estado = '2')
          : (this.data.estado = '1');
        this.getConvenios();
        this.estado = 'resultados';
        this.data.pagaduriaRef = res.rows[0].codigoPagaduria;
        this.responseCarpeta = res.rows[0].numeroCarpeta;
        this.getofrtaServ
          .genOferta(res.rows[0].numeroCarpeta)
          .subscribe((resOf) => {
            this.genOf = resOf;
            console.log(':: Gen Ofert', this.genOf);
          });

          console.log('respuesta',res)
          this.router.navigate(['/resultado-carpetas'], { state: { respuesta: JSON.stringify(res)} });

      },
      (err) => {
        this.dialogService.openDialog('Advertencia', err.error.mensaje, false);
        this.searching = false;
      }
    );
  }


  public getConvenios() {
    this.convenioService.getConvenios().subscribe((convenios) => {
      this.convenios = convenios.rows;
      console.log('::Convenios', convenios);
      this.filteredConvenio = this.convenios.slice();
    });
  }

  getPagadurias(convenio: any) {
    console.log(convenio);
    this.pagaduriaService.getPagadurias(convenio).subscribe((pagaduria) => {
      this.pagaduarias = pagaduria.rows;
      console.log(':: Pagadurias', this.pagaduarias);
      this.filteredPagaduria = this.pagaduarias.slice();
    });
  }

  display: string = 'none';
  listFiles: itemFile[];
  loading: boolean = false;
  fileView: boolean = false;
  fileSelected: itemFile;
  pathFile: string;

}
