import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MotivosRechazoService } from 'src/app/services/catalogos/motivos-rechazo.service';
import { GenOfertaService } from 'src/app/services/genOferta/gen-oferta.service';
import { CambioestadocarpetaService } from 'src/app/services/perfilamiento/cambioestadocarpeta.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-dialog-rechaza',
  templateUrl: './dialog-rechaza.component.html',
  styleUrls: ['./dialog-rechaza.component.css'],
})
export class DialogRechazaComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mrechazoServ: MotivosRechazoService,
    private dialogRef: MatDialogRef<DialogRechazaComponent>,
    private cambioEstadoServ: CambioestadocarpetaService,
    private geOfertaSrvc: GenOfertaService,
    private readonly _data: DataService
  ) {}

  public motivosRechazo: Array<any> = [];
  public observaciones: string = '';
  public otroMotivo: string = '';
  public motivo: string = ' ';
  ngOnInit(): void {
    this.mrechazoServ.getMotivos().subscribe((res) => {
      this.motivosRechazo = res.rows;
    });
  }

  rechazarOferta(): void {
    this.geOfertaSrvc
      .rechazarOferta(this._data.numeroCarpeta, this.motivo, this.observaciones)
      .subscribe(
        (res) => {
          this.cambioEstadoServ
            .cambioEstadocarpeta(
              parseInt(this._data.numeroCarpeta),
              '900000',
              'Cambio de estado aprobado por el Asesor'
            )
            .subscribe((res) => {
              console.log(res);
            });
          alert(res.message);
          this.dialogRef.close({ data: this.data });
        },
        (err) => {
          alert(err.error.message);
        }
      );
  }
}
