<app-spinner *ngIf="loadSpinner"></app-spinner>
<div class="d-flex flex-column align-items-center h-100 px-4 position-relative main overflow-hidden">
    <div class="cursos d-flex flex-column justify-content-center align-items-center p-4 my-auto position-relative">
        <h1 class="text-light m-0 fw-semibold">
            Tus cursos
        </h1>
        <h3 class="text-light fw-semibold">
            ¡Tu crecimiento profesional no se detiene!
        </h3>
        <p class="text-light px-4">
            Como asesor Bayport tienes acceso a contenidos educativos
            unicos para fortalecer tus competencias profesionales.
        </p>
        <button-look-feel color="secondary" (clicked)="onContinue()">¡Comencemos!</button-look-feel>
    </div>
    <div class="orange"></div>
    <div class="pink"></div>
</div>