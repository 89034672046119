<app-main-container header="Formulario de perfilamiento" subheader="Completa la información de tu cliente en cada uno de los campos solicitados
a continuación.">

  <div id="accordion">
    <div class="formulario">
      <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseOne"
        id="headingOne">
        <h2 class="mb-0 acordion-title">Información Personal</h2>
        <div class="collapsable-button">
          <i *ngIf="formularios[0].open" aria-hidden="false" class="fa-solid fa-caret-up"></i>
          <i *ngIf="!formularios[0].open" aria-hidden="false" class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="personalInfo">
            <div class="row">
              <div class="col-md-3 form-field">
                <label for="primerNombre" class="form-label">Primer nombre</label>
                <input type="text" class="form-control" placeholder="*Primer nombre" id="primerNombre"
                  formControlName="primerNombre" autocomplete="off">
                <div class="form-error" *ngIf="personalInfo.controls.primerNombre.invalid">
                  Primer nombre es obligatorio
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="segundoNombre" class="form-label">Segundo nombre <span
                    class="fw-normal">(opcional)</span></label>
                <input type="text" class="form-control" placeholder="*Segundo nombre" id="segundoNombre"
                  formControlName="segundoNombre" autocomplete="off">
              </div>
              <div class="col-md-3 form-field">
                <label for="primerApellido" class="form-label">Primer apellido</label>
                <input type="text" class="form-control" placeholder="*Primer apellido" id="primerApellido"
                  formControlName="primerApellido" autocomplete="off">
                <div class="form-error" *ngIf="personalInfo.controls.primerApellido.hasError('required')">
                  Primer apellido es obligatorio
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="segundoApellido" class="form-label">Segundo apellido <span
                    class="fw-normal">(opcional)</span></label>
                <input type="text" class="form-control" placeholder="*Segundo apellido" id="segundoApellido"
                  formControlName="segundoApellido" autocomplete="off">
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 form-field">
                <label for="fechaNacimiento" class="form-label">Fecha de nacimiento</label>
                <div class="input-group">
                  <input id="fechaNacimiento" class="form-control form-calendar" placeholder="*Selecciona una fecha"
                    autocomplete="off" formControlName="fechaNacimiento" ngbDatepicker #fechaNacimiento="ngbDatepicker"
                    [maxDate]="today" [minDate]="{ year: 1900, month: 1, day: 1 }" (click)="fechaNacimiento.toggle()">
                </div>
                <div class="form-error" *ngIf="personalInfo.get('fechaNacimiento')?.hasError('ageOutOfRange')">
                  La edad debe estar entre los 18 y 80 años
                </div>
                <div class="form-error"
                  *ngIf="personalInfo.get('fechaNacimiento')?.hasError('ngbDate') && !personalInfo.get('fechaNacimiento').pristine">
                  formato invalido (DD/MM/AAAA)
                </div>
                <div class="form-error"
                  *ngIf="personalInfo.get('fechaNacimiento')?.hasError('required') && !personalInfo.get('fechaNacimiento').pristine">
                  La fecha de nacimiento es obligatoria
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="genero" class="form-label">Género</label>
                <div class="radio-group">
                  <div *ngFor="let id of generos">
                    <input type="radio" [id]="'codigo_' + id.codigo" name="genero" [value]="id.codigo"
                      formControlName="genero" />
                    <label [htmlFor]="'codigo_' + id.codigo">{{
                      id.nombre
                      }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-field">
                <label for="fechaNacimiento" class="form-label">Tipo de documento</label>
                <div class="radio-group">
                  <div *ngFor="let id of tiposId">
                    <input type="radio" [id]="'tipo_' + id.id" name="tipoIdentificacion" [value]="id.codigoTipoIdentificacion"
                      formControlName="tipoIdentificacion" />
                    <label [htmlFor]="'tipo_' + id.id">{{ id.nombre }}</label>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3 form-field">
                <label for="numeroIdentificacion" class="form-label">Número de documento</label>
                <input type="number" class="form-control" placeholder="*Número de documento" id="numeroIdentificacion"
                  required formControlName="numeroIdentificacion" readonly>
                <div class="form-error" *ngIf="personalInfo.controls.numeroIdentificacion.hasError('required')">
                  Este campo es obligatorio
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="correo" class="form-label">Correo electrónico</label>
                <input type="text" class="form-control" placeholder="*Correo electrónico" id="correo"
                  formControlName="correo" readonly>
              </div>
              <div class="col-md-3 form-field">
                <label for="celular" class="form-label">Teléfono celular</label>
                <input type="text" class="form-control" placeholder="*Teléfono celular" id="celular"
                  formControlName="celular" readonly>
                <div class="form-error" *ngIf="personalInfo.controls.celular.hasError('pattern')">
                  Ingrese un formato valido
                </div>
                <div class="form-error" *ngIf="personalInfo.controls.celular.hasError('required')">
                  Este campo es obligatorio
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="numeroCarpeta" class="form-label">Número de carpeta</label>
                <input type="number" class="form-control" placeholder="*Número de carpeta" id="numeroCarpeta"
                  formControlName="numeroCarpeta" readonly>
                <div class="form-error" *ngIf="personalInfo.controls.numeroCarpeta.hasError('required')">
                  Este campo es obligatorio
                </div>
              </div>
            </div>
          </form>
          <div class="justify-right mt-4">
            <p>
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
              aceptando que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button-look-feel color="secondary" (clicked)="guardarInformacionPersonal()" [loading]="isLoaded"
              [disabled]="personalInfo.invalid || personalInfo.get('genero').value === 0">Guardar y
              Continuar</button-look-feel>
          </div>
        </div>
      </div>
      <p *ngIf="!formularios[0].open" class="descripcion">
        Ingresa la información referente a nombres, apellidos, documento de
        identidad, datos de contacto, entre otros.
      </p>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapselaboral"
        id="headinglaboral">
        <h2 class="mb-0 acordion-title">Información Laboral</h2>
        <div class="collapsable-button">
          <i *ngIf="formularios[1].open" aria-hidden="false" class="fa-solid fa-caret-up"></i>
          <i *ngIf="!formularios[1].open" aria-hidden="false" class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapselaboral" class="collapse" aria-labelledby="headinglaboral" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="laboralInfo">
            <div class="row">
              <div class="col-md-3">
                <mat-label>Convenio</mat-label><br />
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="codigoConvenio" required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="convenios"
                      (filteredReturn)="filteredConvenio = $event"></mat-select-filter>
                    <mat-option (click)="getPagadurias(item.codigo)" *ngFor="let item of filteredConvenio"
                      [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoConvenio.hasError('required')
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Pagaduría</mat-label><br />
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="codigoPagaduria" required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="pagaduarias"
                      (filteredReturn)="filteredPagaduria = $event"></mat-select-filter>
                    <mat-option (click)="
                      getTiposContrato(item.codigoPagaduria);
                      getCargos(item.codigoPagaduria);
                      getEdoLab(item.codigoPagaduria);
                      busquedaCreditosPorPagaduria(
                        item.codigoPagaduria,
                        this.data.cedula
                      )
                    " *ngFor="let item of filteredPagaduria" [value]="item.codigoPagaduria">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoPagaduria.hasError('required')
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-label>Estado laboral</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="codigoEstadoLaboral" required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'descripcion'" [array]="edoLaboral"
                      (filteredReturn)="filteredEdoLab = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredEdoLab" (click)="selectEstadoLaboral(item.codigo)"
                      [value]="item.codigo">
                      {{ item.descripcion }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoEstadoLaboral.hasError(
                      'required'
                    )
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-label>Tipo de contrato</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="codigoTipoContrato" required
                    [(value)]="tipoContratoModel">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="tiposContratos"
                      (filteredReturn)="filteredTiposContrato = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredTiposContrato" [value]="item.codigoContrato">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    laboralInfo.controls.codigoTipoContrato.hasError('required')
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-label>Rango / Cargo</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="codigoCargo" required>
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="cargos"
                      (filteredReturn)="filteredCargos = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredCargos" [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="laboralInfo.controls.codigoCargo.hasError('required')">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-field">
                <label for="fechaIngreso" class="form-label">Fecha de ingreso Pagaduría</label>
                <div class="position-relative input-group">
                  <input id="fechaIngreso" class="form-control" placeholder="*Selecciona una fecha" autocomplete="off"
                    formControlName="fechaIngreso" ngbDatepicker #fechaIngreso="ngbDatepicker" [maxDate]="today"
                    [minDate]="{ year: 1900, month: 1, day: 1 }" (click)="fechaIngreso.toggle()">
                  <i class="fa-regular fa-calendar position-absolute top-50 end-0 translate-middle-y me-2"></i>
                </div>
                <div class="form-error"
                  *ngIf="laboralInfo.get('fechaIngreso')?.hasError('ngbDate') && !laboralInfo.get('fechaIngreso').pristine">
                  formato invalido (DD/MM/AAAA)
                </div>
                <div class="form-error"
                  *ngIf="laboralInfo.get('fechaIngreso')?.hasError('required') && !laboralInfo.get('fechaIngreso').pristine">
                  La fecha de ingreso a la pagaduría es obligatoria
                </div>
              </div>
            </div>

            <div class="justify-right mt-4">
              <p>
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>
            <div class="justify-right">
              <button-look-feel color="secondary" (clicked)="guardarInformacionLaboral()" [loading]="isLoaded"
                [disabled]="laboralInfo.invalid">Guardar y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
      <p class="descripcion" *ngIf="!formularios[1].open">
        Ingresa la información referente al empleador, tipo de contrato, cargo,
        entre otros.
      </p>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseFinanciera"
        id="headingFinanciera">
        <h2 class="mb-0 acordion-title">Información Financiera</h2>
        <div class="collapsable-button">
          <i *ngIf="formularios[2].open" aria-hidden="false" class="fa-solid fa-caret-up"></i>
          <i *ngIf="!formularios[2].open" aria-hidden="false" class="fa-solid fa-caret-down"></i>
        </div>
      </div>
      <div id="collapseFinanciera" class="collapse" aria-labelledby="headingFinanciera" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="financieraInfo">
            <h4>Ingresos</h4>
            <hr />
            <div class="row">
              <div class="col-md-6 form-field">
                <label for="salario" class="form-label">Salario</label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Salario"
                  id="salario" formControlName="salario">
                <div class="form-error"
                  *ngIf="financieraInfo.controls.salario.hasError('required') && !financieraInfo.controls.salario.pristine">
                  Salario es obligatorio
                </div>
                <div class="form-error" *ngIf="financieraInfo.controls.salario.hasError('min')">
                  Ingresar un valor igual o superior al salario mínimo.
                </div>
              </div>
              <div class="col-md-6 form-field">
                <label for="otrosIngresos" class="form-label">Otros ingresos <span
                    class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Otros ingresos"
                  id="otrosIngresos" formControlName="otrosIngresos">
                <div class="form-error" *ngIf="financieraInfo.controls.otrosIngresos.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <p class="mt-3">
                <strong>Nota:</strong> Incluye los ingresos que Bayport tiene en
                cuenta para el cálculo de capacidad.
              </p>
            </div>

            <h4>Egresos</h4>
            <hr />
            <div class="row">
              <div class="col-md-3 form-field">
                <label for="salud" class="form-label">Salud</label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Salud" id="salud"
                  formControlName="salud">
                <div class="form-error"
                  *ngIf="financieraInfo.controls.salud.hasError('required') && !financieraInfo.controls.salud.pristine">
                  Salud es obligatorio
                </div>
                <div class="form-error" *ngIf="financieraInfo.controls.salud.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="pension" class="form-label">Pensión <span class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Pensión"
                  id="pension" formControlName="pension">
                <div class="form-error" *ngIf="financieraInfo.controls.pension.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="retefuente" class="form-label">Retefuente <span class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Retefuente"
                  id="retefuente" formControlName="retefuente">
                <div class="form-error" *ngIf="financieraInfo.controls.retefuente.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="fondoSolidaridad" class="form-label">Fondo solidaridad <span
                    class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control"
                  placeholder="*Fondo solidaridad" id="fondoSolidaridad" formControlName="fondoSolidaridad">
                <div class="form-error" *ngIf="financieraInfo.controls.fondoSolidaridad.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 form-field">
                <label for="caproimpo" class="form-label">Caprovimpo <span class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Caprovimpo"
                  id="caproimpo" formControlName="caproimpo">
                <div class="form-error" *ngIf="financieraInfo.controls.caproimpo.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="otrosDescuentos" class="form-label">Otros descuentos <span
                    class="fw-normal">(opcional)</span></label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Otros descuentos"
                  id="otrosDescuentos" formControlName="otrosDescuentos">
                <div class="form-error" *ngIf="financieraInfo.controls.otrosDescuentos.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <p class="mt-3">
                <strong>Caprovimpo:</strong> Sólo registrar si es obligatorio, en
                caso que sea voluntario no aplica.
              </p>
            </div>
            <div class="justify-right mt-4">
              <p>
                Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
                aceptando que la información es correcta
              </p>
            </div>
            <div class="justify-right">
              <button-look-feel color="secondary" (clicked)="guardarInformacionFinanciera()" [loading]="isLoaded"
                [disabled]="!codigoSolicitud || !financieraInfo.valid">Guardar y Continuar</button-look-feel>
            </div>
          </form>
        </div>
      </div>
      <p class="descripcion" *ngIf="!formularios[2].open">
        Ingresa la información relacionada con los ingresos y egresos de tu
        cliente.
      </p>
    </div>

    <div class="card formulario">
      <div class="card-header" data-bs-toggle="collapse" (click)="showMessages()" data-bs-target="#collapseSolicitud"
        id="headingSolicitud">
        <h2 class="mb-0 acordion-title">Información Solicitud</h2>
        <div class="collapsable-button">
          <i *ngIf="formularios[3].open" aria-hidden="false" class="fa-solid fa-caret-up"></i>
          <i *ngIf="!formularios[3].open" aria-hidden="false" class="fa-solid fa-caret-down"></i>
        </div>
      </div>

      <div id="collapseSolicitud" class="collapse" aria-labelledby="headingSolicitud" data-bs-parent="#accordion">
        <div class="card-body">
          <form [formGroup]="solicitudInfo">
            <div class="row">
              <div class="col-md-3">
                <mat-label>Tipo de crédito</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="tipoCredito" required
                    [(value)]="tipoCreditoModel">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="tipos_credito"
                      (filteredReturn)="filteredTiposCredito = $event"></mat-select-filter>
                    <mat-option *ngFor="let item of filteredTiposCredito" (click)="selecTipoCredito(item.nombre)"
                      [value]="item.codigoTipoCredito">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    solicitudInfo.controls.tipoCredito.hasError('required')
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-label>Línea de crédito</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Selecciona ..." formControlName="lineaCredito" required
                    [(value)]="lineaCredito">
                    <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'" [array]="lineascredito"
                      (filteredReturn)="filteredLineaCredito = $event"></mat-select-filter>
                    <mat-option (click)="cambiarLineaCredito(item.nombre)" *ngFor="let item of filteredLineaCredito"
                      [value]="item.codigo">
                      {{ item.nombre }}
                    </mat-option>
                  </mat-select>
                  <mat-error class="error" *ngIf="
                    solicitudInfo.controls.lineaCredito.hasError('required')
                  ">
                    Este campo es obligatorio
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 form-field" (click)="alertaPlazo()">
                <label for="plazo" class="form-label">Plazo</label>
                <input type="number" inputmode="numeric" class="form-control" placeholder="*Plazo" id="plazo"
                  autocomplete="off" formControlName="plazo">
                <div class="form-error"
                  *ngIf="solicitudInfo.controls.plazo.hasError('required') && !solicitudInfo.controls.plazo.pristine">
                  Plazo es obligatorio
                </div>
                <div class="form-error" *ngIf="solicitudInfo.controls.plazo.hasError('min')">
                  Ingresa un valor igual o superior a dos cifras.
                </div>
                <div class="form-error" *ngIf="solicitudInfo.controls.plazo.hasError('max')">
                  Ingresa un valor igual o inferior a tres cifras.
                </div>
              </div>
              <div class="col-md-3 form-field">
                <label for="monto" class="form-label">Monto</label>
                <input type="text" inputmode="numeric" currencyMask class="form-control" placeholder="*Monto" id="monto"
                  formControlName="monto" autocomplete="off">
                <div class="form-error"
                  *ngIf="solicitudInfo.controls.monto.hasError('required') && !solicitudInfo.controls.monto.pristine">
                  Monto es obligatorio
                </div>
                <div class="form-error" *ngIf="solicitudInfo.controls.monto.hasError('min')">
                  Ingresar un valor igual o superior a 0.
                </div>
              </div>
              <div>
                <p>
                  <strong>Nota:</strong> Los montos y valores diligenciados serán tomados en pesos
                  colombianos (COP)
                </p>
                <p *ngIf="compraCartera">
                  Recuerda que ante la compañía podrás gestionar mínimo una (1) compra
                  de cartera y máximo seis (6)
                </p>
              </div>
            </div>
          </form>

          <div *ngIf="isRefinanciacion && !isNewCredito">
            <hr/>
            <div class="row" style="margin-top: 10px">
              <div class="row libre__inversion" *ngFor="let libre of formLibreInversion; let i = index">
                <div class="delete" *ngIf="i > 0">
                  <app-hyperlink-icon icon="delete" iconPosition="end"
                    (clicked)="eliminarRefinanciacion(formLibreInversion, i)">Eliminar</app-hyperlink-icon>
                </div>
                <h3>Refinanciación {{ i + 1 }}</h3>
                <div class="row">
                  <div class="col-md-3">
                    <mat-label>Número de crédito</mat-label>
                    <mat-form-field appearance="outline">
                      <mat-select placeholder="Selecciona ..." [(ngModel)]="libres[i].libreNumeroCredito.value"
                        [required]="isRefinanciacion && !isNewCredito">
                        <mat-option *ngFor="let credito of creditosRefinanciacion" [value]="credito.numeroCredito"
                          (click)="
                          seleccionarNumeroCredito(
                            credito.numeroCredito,
                            credito,
                            i
                          )
                        ">
                          {{ credito.numeroCredito }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 form-field">
                    <label for="librePagaduria" class="form-label">Pagaduría</label>
                    <input type="text" [(ngModel)]="libres[i].librePagaduria.value" readonly class="form-control" placeholder="*Pagaduría" id="librePagaduria">
                  </div>
                  <div class="col-md-3 form-field">
                    <label for="libreValorCuota" class="form-label">Valor cuota</label>
                    <input type="text" [ngModel]="libres[i].libreValorCuota.value" currencyMask readonly class="form-control" placeholder="*Valor cuota" id="libreValorCuota">
                  </div>
                  <div class="col-md-3 form-field">
                    <label for="libreSaldo" class="form-label">Saldo</label>
                    <input type="text" currencyMask [ngModel]="libres[i].libreSaldo.value" readonly class="form-control" placeholder="*Saldo" id="libreSaldo">
                  </div>
                </div>
              </div>
              <div class="row">
                <button-look-feel color="outline" size="medium" [compressed]="true" (clicked)="addRefinanciacion()"
                  [hidden]="contFormLibreInversion == 6 || !variosCreditos">Agregar refinanciacion
                  adicional</button-look-feel>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" style="margin-top: 10px" *ngIf="compraCartera == true">
            <div *ngFor="let cartera of datosCompraCartera; let i = index" class="row libre__inversion">
              <h3>Compra de Cartera {{ i + 1 }}</h3>
              <div class="row">
                <div class="delete inp-wrap col-1 col-sm-1 col-md-1 col-lg-1 mb-err-inp py-auto" *ngIf="i != 0">
                  <app-hyperlink-icon icon="delete" iconPosition="end"
                    (clicked)="quitarCartera(i)">Eliminar</app-hyperlink-icon>
                </div>
                <div class="col-md-3">
                  <mat-label>Entidad financiera (max. 6)</mat-label>
                  <mat-form-field style="margin-bottom: 8px" appearance="outline" [class]="
                    cartera.entidadCompra.valid ? '' : 'mat-form-field-invalid'
                  ">
                    <mat-select placeholder="Selecciona ..." minlength="1" [(ngModel)]="cartera.entidadCompra.value"
                      required>
                      <mat-select-filter [placeholder]="'Buscar'" [displayMember]="'nombre'"
                        [array]="entidadesFinanc[i]"
                        (filteredReturn)="filteredEntidadesFinanc[i] = $event"></mat-select-filter>
                      <mat-option (click)="validarCarterasExistentes(item.codigo)"
                        *ngFor="let item of filteredEntidadesFinanc[i]" [value]="item.codigo">
                        {{ item.nombre }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span class="error" *ngIf="!cartera.entidadCompra.valid">Este campo es obligatorio
                  </span>
                </div>
                <div class="col-md-3 form-field">
                  <label for="cuota" class="form-label">Valor cuota</label>
                  <input type="text" inputmode="numeric" class="form-control" placeholder="*Valor cuota" id="cuota"
                    min="1" autocomplete="off" [(ngModel)]="cartera.valorCuota.value" currencyMask
                    (keyup)="validMontos(i, 'valorCuota')">
                  <div class="form-error" *ngIf="!cartera.valorCuota.valid || cartera.valorCuota.value === ''">
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="col-md-3 form-field">
                  <label for="monto" class="form-label">Monto compra cartera</label>
                  <input type="text" inputmode="numeric" class="form-control" placeholder="*Monto compra cartera"
                    id="monto" min="1" autocomplete="off" [(ngModel)]="cartera.monto.value" currencyMask
                    (keyup)="validMontos(i, 'monto')">
                  <div class="form-error" *ngIf="!cartera.monto.valid || cartera.monto.value === ''">
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <mat-label>¿Opera en desprendible?</mat-label>
                  </div>
                  <div style="height: 50px; display: flex; align-items: center">
                    <mat-slide-toggle [(ngModel)]="cartera.desprendible" size="large"></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="lineacredito == 'COMPRA CARTERA'" class="row">
              <p style="font-size: 20px; margin-top: 25px; margin-bottom: 44px">
                <strong>Nota:</strong> recuerda que por política podrás realizar
                mínimo 1 compra de cartera y maximo 6.
              </p>
            </div>
          </div>

          <div *ngIf="lineacredito == 'COMPRA CARTERA'" class="row">
            <!-- <div style="align-items: center; display: flex; width: 235px"> -->
            <div>
              <button-look-feel color="outline" size="medium" [compressed]="true" (clicked)="guardarCompra()">Agregar
                entidad adicional</button-look-feel>
            </div>
          </div>
          <div class="mt-4 text-md-end">
            <p *ngIf="validarMsgFormulario()">
              Aún tienes campos sin completar. Puedes guardar y volver luego, pero
              recuerda que necesitas tener la información completa para radicar el
              crédito.
            </p>
            <p *ngIf="!formularios[0].saved || !formularios[1].saved || !formularios[2].saved">
              No olvide <strong>Guardar</strong> los cambios de:
              <span *ngFor="let item of validateForms()">
                <strong>{{ item }}</strong>,
              </span>
              para poder continuar con el perfilamiento.
            </p>
            <p *ngIf="!formularios[3].open">
              Ingresa la información relacionada con el tipo de producto, monto, plazo,
              entre otros.
            </p>
            <p>
              Al dar clic en <strong>GUARDAR Y CONTINUAR</strong> estás
              aceptando que la información es correcta
            </p>
          </div>
          <div class="justify-right">
            <button-look-feel color="secondary" (clicked)="guardarInformacionSolicitud()" [loading]="isLoaded"
              [disabled]="solicitudInfo.invalid ||
            (isRefinanciacion &&
              !isNewCredito &&
              refinancialArr.length == 0) ||
            !formularios[0].saved ||
            !formularios[1].saved ||
            !formularios[2].saved">Guardar y Continuar</button-look-feel>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-container>
