<!-- <app-spinner *ngIf="isLoaded"></app-spinner> -->
<div class="contenido">
  <h1><strong>Formato de autorización centrales de riesgo</strong></h1>
  <p>
    Para continuar el proceso debes enviar al solicitante el formato de autorización
    en centrales de riesgo. Este documento debe ser firmado por el cliente y
    retornado a Bayport escaneado.
  </p>
  <div class="col-lg-5 col-md-6 col-sm-12" style="padding: 0px">
    <mat-card class="example-card">
      <span class="t-card"><strong> Formato de autorización</strong></span>
      <div class="no-upload" *ngIf="!isLoaded" #ref1 id="upload-1">
        <div class="p-card">
          <span class="">
            Puedes subir PDF, PNG, JPG o utilizar tu teléfono
            <br class="no-responsive" />
            móvil para tomar una fotografía
          </span>
        </div>
        <div class="botones">
          <div class="div-file">
            <label for="bayport-file">
              <span class="material-icons"> attach_file </span> Cargar desde una
              carpeta</label>
            <input (change)="selectFile($event, 'anverso')" accept=".png, .jpg, .pdf" type="file" id="bayport-file"
              value="Cargar desde una carpeta" />
          </div>
          <button class="btn-cam-rag-card" mat-button (click)="openCamera()">
            <span class="material-icons"> camera_alt </span>
            Cargar usando la cámara
          </button>
        </div>
      </div>
      <div *ngIf="isLoaded">
        <img id="tarjeta" src="assets/img/tarjeta.PNG" alt="tarjeta" />
      </div>
      <div *ngIf="isLoaded">
        <a class="eliminar" (click)="eliminar()" role="button">Eliminar archivo y cargar otro</a>
      </div>
    </mat-card>
  </div>
  
  <div class="row">
    <div class="t-start">
      <div class="botones-rag">
        <div class="btn-mbl">
          <button [disabled]="!isLoaded || isLoading" class="btn-cam-rag" mat-button (click)="irFormulario()">
            <mat-icon *ngIf="isLoading" style="margin-right: 5px;"><mat-spinner diameter="25">
              </mat-spinner></mat-icon>
            <strong>Guardar y continuar</strong>
          </button>

          <button [disabled]="!isLoaded || isLoading" class="m-bot" mat-button (click)="irFormulario()">
            <strong>Aceptar y continuar</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>