<app-card-dotted-line *ngIf="!viewpdf">
  <div class="p-2">
    <div *ngIf="paso1">
      <h4>{{ titulo }}</h4>
    </div>
    <div class="cont-card" *ngIf="paso1">
      <span style="font-size: 16px;">
        {{text}}
      </span>
      <ng-container [ngTemplateOutlet]="onLoad" *ngIf="type==='load'"></ng-container>
      <ng-container [ngTemplateOutlet]="onCommon" *ngIf="type==='common'"></ng-container>
    </div>
    <div *ngIf="paso2">
      <div class="cont-card">
        <i class="fa-solid fa-file-circle-check"></i>
        <div style="margin-top: 10px;">
          <span style="font-size: 16px;">Archivo cargado exitosamente.</span>
        </div>
      </div>
      <div class="cont-card">
        <a class="elimiar cont-card" (click)="eliminarArchivo()" role="button" style="font-size: 16px;">Eliminar archivo
          y
          cargar otro</a>
      </div>
    </div>
  </div>
</app-card-dotted-line>

<div class="cards" *ngIf="viewpdf">
  <object *ngIf="pdfSource.length > 0" class="obj-pdf s-pdf" [data]="pdfSource | safeUrl" type="application/pdf">
    <iframe *ngIf="pdfSource.length > 0" [src]="pdfSource | safeUrl"></iframe>
  </object>
</div>

<!-- TEMPLATES -->
<ng-template #onLoad>

  <button-look-feel color="outline" (clicked)="clicFileUpload()" class="d-none d-md-flex"><i class="fa fa-paperclip me-2" aria-hidden="true"></i>Cargar
    desde una carpeta</button-look-feel>
  <button-look-feel color="outline" (clicked)="clicFileUpload()" class="d-md-none d-flex"><i class="fa fa-camera me-2" aria-hidden="true"></i>Cargar
    Cargar usando la cámara</button-look-feel>
  <input #fileInput accept=".pdf" type="file" class="bayport-file" style="display: none"
    (change)="onAddFile($event)" />
</ng-template>

<ng-template #onCommon>
  <div class="btn-select-card d-flex justify-content-center" (click)="viewp()">
    <label for="update-account" class="common-label h-100 d-flex align-items-center justify-content-center">
      <i class="fa-solid fa-eye"></i>
      <span style="font-size: 16px;">Ver</span>
    </label>
    <input id="update-account" type="button" class="bayport-file" style="display: none" />
  </div>
  <div class="btn-select-card additional d-flex justify-content-center" (click)="onDownload()">
    <label for="download" class="common-label h-100 d-flex align-items-center justify-content-center">
      <i class="fa-solid fa-cloud-arrow-down"></i>
      <!-- <a [href]="" style="font-size: 16px;" [download]="pdfSource">Descargar</a> -->
      <span style="font-size: 16px;">Descargar</span>
      <!-- <a href="pdf-test.pdf" download>Descarga</a> -->
    </label>
    <input id="download" type="button" class="bayport-file" style="display: none" />
  </div>
</ng-template>
