<div class="h-100">
  <div class="d-md-flex justify-content-center home pb-5 pb-md-2 overflow-x-auto overflow-y-hidden">
    <div class="main-container w-md-75 px-4 p-md-4 overflow-hidden">
      <h1 class="text-light fw-semibold title" @fadeIn>¡Hola,&nbsp; {{ user | titlecase }} !</h1>
      <iframe class="d-md-none d-flex w-100 position-relative z-3" style="aspect-ratio: 100 / 200; overflow: hidden;"
        [src]="'https://lookerstudio.google.com/embed/u/1/reporting/e934d681-204c-48ff-b822-c9489004f001/page/p_jqgvmfss6c?params=%7B%22ds0.id_asesor%22:%22'+user_id+'%22,%22ds79.id_asesor%22:%22'+user_id+'%22%7D' | safeUrl"></iframe>
      <iframe class="d-none d-md-flex w-100 position-relative z-3" style="aspect-ratio: 100 / 29; overflow: hidden;"
        [src]="'https://lookerstudio.google.com/embed/u/1/reporting/1e40cf85-b2b3-4c10-a6b4-b6714ca19f55/page/2DgBD?params=%7B%22ds0.id_asesor%22:%22'+user_id+'%22,%22ds79.id_asesor%22:%22'+user_id+'%22%7D' | safeUrl"></iframe>
      <div class="d-md-flex">
        <div class="position-relative w-100 my-2 me-md-1">
          <div
            class="tarjeta-solicitar text-center d-flex flex-column justify-content-between p-3 h-100 position-relative"
            @fadeIn>
            <h2 class="text-light">Nuevo crédito</h2>
            <p class="text-light my-auto py-3">Empieza el proceso de solicitud de crédito nuevo, refinanciación o compra
              de cartera
            </p>
            <button-look-feel [routerLink]="['/autorizacionTransferencia']" customClass="d-none d-md-inline"
              color="primary" size="large">Solicitar</button-look-feel>
            <button-look-feel [routerLink]="['/autorizacionTransferencia']" customClass="d-md-none" color="primary"
              size="medium">Solicitar</button-look-feel>
          </div>
          <div class="circle-yellow"></div>
        </div>
        <div class="position-relative w-100 my-2 ms-md-1">
          <div class="tarjeta-buscar text-center d-flex flex-column justify-content-between p-3 h-100 position-relative"
            @fadeIn>
            <h2 class="text-light">Mis créditos</h2>
            <p class="text-light my-auto py-3">Busca las carpetas de tus clientes, por cédula, pagaduría, fecha de
              creación, nombre y
              apellido o número de carpeta</p>
              <button-look-feel [routerLink]="['/busqueda-carpetas']" customClass="d-none d-md-inline" color="secondary"
              size="large">Buscar carpetas</button-look-feel>
            <button-look-feel [routerLink]="['/busqueda-carpetas']" customClass="d-md-none" color="secondary"
              size="medium">Buscar carpetas</button-look-feel>
          </div>
          <div class="circle-blue"></div>
        </div>
      </div>
    </div>
    <div class="side-container px-4 px-md-2 mt-5 mt-md-0 w-md-25">
      <iframe class="d-md-none w-100 position-relative z-3" style="aspect-ratio: 5 / 3; overflow: hidden;"
        [src]="'https://debessaber.col.prod.bayport-digital.com/cambios-de-politicas/movil' | safeUrl"
        frameborder="0"></iframe>
      <iframe class="d-none d-md-block h-100 position-relative z-3" style="overflow: hidden;"
        [src]="'https://debessaber.col.prod.bayport-digital.com/cambios-de-politicas' | safeUrl"
        frameborder="0"></iframe>
    </div>
  </div>
</div>