import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'celular'
})
export class CelularPipe implements PipeTransform {

  transform(celular:string): string {
    return '*******'+celular.substring(7,10)
  }

}
