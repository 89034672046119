<!--app-header></app-header-->
<div class="container">
  <div class="circle blue"></div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <h1 class="title-user">
        ¡Hola, <span class="text-uppercase">{{ user }}</span> !
      </h1>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12">
      <div
        class="cambioContra font-omnes"
        [routerLink]="['/home/cambio-contrasena']"
      >
        Cambio de contraseña
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-12 capa1" align="center">
      <div id="tabsN">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#cedula"
              role="button"
              aria-controls="home"
              aria-selected="true"
              >Información asesor</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#fecha"
              role="button"
              aria-controls="profile"
              aria-selected="false"
              >Información personal</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#pagaduria"
              role="button"
              aria-controls="contact"
              aria-selected="false"
              >Información bancaria</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="cedula"
          role="tabpanel"
          aria-labelledby="cedula-tab"
        >
          <section class="mt-1 flex">
            <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
              <div class="labelP font-omnes-semibold">Información asesor</div>
              <hr />
              <div class="labelS font-omnes-semibold">Datos asesor</div>
              <form [formGroup]="bayportInfoForm" class="form">
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Tipo de ejecutivo
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ tipoEjecDes }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Código ejecutivo
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ codigoAsesor }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Estado</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ estado }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Fecha de creación
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ fechaCrea | date : "yyyy-MM-dd" }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Regional</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ regionalNombre }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Sucursal</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ sucursalNombre }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Tipo de canal</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ canalNombre }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Canal de venta</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ canalVentaNombre }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Nombre a quien refiere
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ nombreRefiere }}
                  </div>
                </div>
              </form>
              <br />
              <hr />
              <div class="labelS font-omnes-semibold">Datos de contacto</div>
              <form [formGroup]="bayportInfoForm" class="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Correo electrónico 1
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="bayportEmail"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Correo electrónico 2
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="bayportEmailSegundo"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Celular<br />
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="celular"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 2rem"
                >
                  <button class="btn__save">Guardar</button>
                </div>
              </form>
            </article>
          </section>
        </div>
        <div
          class="tab-pane fade show"
          id="fecha"
          role="tabpanel"
          aria-labelledby="cedula-tab"
        >
          <section class="mt-1 flex">
            <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
              <div class="labelP font-omnes-semibold">Información personal</div>
              <hr />
              <div class="labelS font-omnes-semibold">
                Datos documento de identidad
              </div>
              <form [formGroup]="bayportInfoForm" class="form">
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Tipo de documento
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ tipoEjecDes }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Número de documento
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ numeroDocumento }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Fecha de expedición
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ setFormatDate(fechaExpedicion) }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Lugar de expedición
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ lugarExpedicion }}
                  </div>
                </div>
              </form>
              <br />
              <hr />
              <div class="labelS font-omnes-semibold">Datos generales</div>
              <form [formGroup]="bayportInfoForm" class="form">
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Primer nombre</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ primerNombre }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Segundo nombre</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ segundoNombre }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Primer apellido
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ primerApellido }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Segundo apellido
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ segundoApellido }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Fecha de nacimiento
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ setFormatDate(fechaNacimiento) }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Lugar de nacimiento
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ lugarNacimiento }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Género
                      <div class="row">
                        <div
                          class="item col-md-6"
                          *ngFor="let item of items; let i = index"
                          [ngClass]="{ active: activeIndex === i }"
                          (click)="setActive(i)"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Estado civil
                      <select
                        class="input__select"
                        formControlName="estadoCivil"
                      >
                        <option
                          selected
                          *ngFor="let item of edoCivil"
                          [value]="item.codigo"
                        >
                          {{ item.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Estudios realizados
                      <select
                        class="input__select"
                        formControlName="estudiosRealizados"
                      >
                        <option
                          selected
                          *ngFor="let item of scholarship"
                          [value]="item.codigoEstudio"
                        >
                          {{ item.nombreEstudio }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <hr />
              <div class="labelS font-omnes-semibold">Datos de residencia</div>
              <form [formGroup]="formInfoPersonal" class="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Departamento
                      <select
                        class="input__select"
                        aria-label="Default select example"
                        (change)="setDeptoValue($event)"
                        formControlName="departamento"
                      >
                        <option
                          selected
                          *ngFor="let item of filteredOptionsDep | async"
                        >
                          {{ item.nomDepto }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Ciudad<br />
                      <select
                        class="input__select"
                        (change)="setCityValue($event)"
                        formControlName="ciudad"
                      >
                        <option
                          selected
                          *ngFor="let item of filteredOptionsCiudad | async"
                        >
                          {{ item.nomMunic }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Dirección
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="direccion"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Tipo de vivienda<br />
                      <select
                        class="input__select"
                        (change)="setCityValue($event)"
                        formControlName="tipoVivienda"
                      >
                        <option
                          selected
                          *ngFor="let item of vivienda"
                          [value]="item.codigo"
                        >
                          {{ item.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <hr />
              <div class="labelS font-omnes-semibold">
                Datos prestaciones sociales
              </div>
              <form [formGroup]="socialBenefitsInfoForm" class="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      EPS
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="eps"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      AFP<br />
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="afp"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      ARL
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="arl"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <hr />
              <div class="labelS font-omnes-semibold">
                Datos información financiera
              </div>
              <form [formGroup]="formInfoFinanciera" class="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Total activos
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="totalActivos"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Total pasivos<br />
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="totalPasivos"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Gastos mensuales
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="gastos"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Ingresos mensuales
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="ingresos"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Otros ingresos mensuales
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="otrosIngresos"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Personas a cargo
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        (click)="onSubmitPersonal()"
                        formControlName="personasAcargo"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 2rem"
                >
                  <button class="btn__save">Guardar</button>
                </div>
              </form>
            </article>
          </section>
        </div>
        <div
          class="tab-pane fade show"
          id="pagaduria"
          role="tabpanel"
          aria-labelledby="cedula-tab"
        >
          <section class="mt-1 flex">
            <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
              <div class="labelP font-omnes-semibold">Información Bancaria</div>
              <hr />
              <div class="labelS font-omnes-semibold">
                Datos información bancaria
              </div>
              <form
                [formGroup]="bayportInfoForm"
                class="form"
                *ngIf="!isUpdate"
              >
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Banco</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ banco }}
                  </div>
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">Tipo de cuenta</div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ tipoCuenta }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-3">
                    <div class="labelL font-omnes-semibold">
                      Número de cuenta
                    </div>
                  </div>
                  <div class="col-md-3 datoB font-omnes">
                    {{ numeroCuenta }}
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 2rem"
                >
                  <button
                    class="btn__save"
                    (click)="actualizarInformacionBancaria()"
                  >
                    Editar
                  </button>
                </div>
              </form>
              <form
                [formGroup]="formInfoBancaria"
                class="form"
                *ngIf="isUpdate"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Banco
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="banco"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Tipo de cuenta<br />
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="tipoCuenta"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <div class="labelL font-omnes-semibold">
                      Número de cuenta
                      <input
                        class="font-omnes"
                        pInputText
                        [style]="{ width: '100%', 'font-size': '14px' }"
                        formControlName="numeroCuenta"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <app-card-file-upload-bancaria
                      iconoCentral="./../../../assets/icons/subir_bancario.svg"
                      titulo="Certificación bancaria"
                      (fileChange)="onFileChange($event)"
                    ></app-card-file-upload-bancaria>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 2rem"
                >
                  <button class="btn__save" (click)="upload()">Guardar</button>
                </div>
              </form>
            </article>
          </section>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
