import { Component } from '@angular/core';

@Component({
  selector: 'app-app',
  templateUrl: './app.v2.component.html',
  styleUrls: ['./app.v2.component.css']
})
export class AppV2Component {

}
