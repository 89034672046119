import { Injectable } from '@angular/core';
import { bool } from 'aws-sdk/clients/signer';

import { Condicion } from '../../models/condicion';

@Injectable({
  providedIn: 'root',
})
export class DataOfertaService {
  constructor() {}

  public tasas: any;
  public oferta: any;
  public ofertasCambioCondicion: any;
  public condicion: Condicion;
  public seguroVoluntario: any = null;
  public ajustarCondiciones: bool = false;
  public montoSolicitado: String;

  cleanData(next:Function = ()=>{}):void {
    this.tasas = null;
    this.oferta = null;
    this.ofertasCambioCondicion = null;
    this.condicion = null;
    this.seguroVoluntario = null;
    this.ajustarCondiciones = false;
    this.montoSolicitado = '';
    next();
  }

}
