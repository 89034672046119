import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Documento } from 'src/app/models/documento';
import { CameraComponent } from 'src/app/pwa/pre-autorizacion/formato-acr/components/camera/camera.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.css']
})
export class DialogUploadComponent implements OnInit {

  constructor(public deviceService: DeviceDetectorService,private dialogRef:MatDialogRef<DialogUploadComponent>,private dialog:MatDialog,private datepipe:DatePipe) { }

  public archivo:Documento={status:false,secuencia:5};
  public nuevoNombre:string = "";
  private util = new Utils();

  ngOnInit(): void {
  }

  fileChange(event){
    this.archivo.nombre=event.target.files[0].name;
    this.archivo.archivo=event.target.files[0];
    this.archivo.status=true;
  }

  eliminar(event){
    this.archivo={status:false,secuencia:5}
    this.nuevoNombre="";
  }

  enviar(){
    let timestamp:string=this.datepipe.transform(new Date(),'yyyyMMddHHmmss');
    this.archivo.nombre=`${this.nuevoNombre.trim()}-${timestamp}.pdf`;
    this.archivo.upload= false;
    if(this.archivo.archivo.type!="application/pdf"){
      this.util.convertImageFileToPDF(this.archivo.archivo).then( file => {
        this.archivo.archivo = file
      })
    }
    this.dialogRef.close(this.archivo);
  }

  openCamera(){
    let cameradialog=this.dialog.open(CameraComponent);
    cameradialog.afterClosed()
    .subscribe(res=>{
      if(res){
        this.archivo.nombre="unnamed.pdf"
        this.archivo.status=true;
        this.util.convertBase64toPdf(res.data)
        .then(data=>{
          this.archivo.archivo=data;
        })
      }
    })
  }
}
