<!-- <!--app-header></app-header--> -->
<mat-horizontal-stepper class="contenido">
  <mat-step label="Análisis de solicitud del crédito ">
    <h1><strong>Resumen de crédito</strong></h1>
    <p>
      A continuación puedes consultar las condiciones de crédito aprobadas.
      Llegará un correo electrónico a tu cliente con ésta información para su
      aceptación.
    </p>
    <div class="row mt-3">
      <div class="table-responsive">
        <h3 style="padding-bottom: 15px">Resumen de tu solicitud</h3>
        <div class="" style="padding-bottom: 32px">
          <table class="">
            <tbody>
              <tr>
                <th scope="row"><h4>Número de crédito:</h4></th>
                <td style="font-size: 20px">
                  {{ datosCarpeta.numeroCarpeta }}
                </td>
              </tr>
              <tr>
                <th scope="row"><h4>Tipo de crédito:</h4></th>
                <td style="font-size: 20px">
                  {{ datosCarpeta.nombreTipoCredito }}
                </td>
              </tr>
              <tr>
                <th scope="row"><h4>Convenio:</h4></th>
                <td style="font-size: 20px">{{ datosCarpeta.convenio }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="" style="padding-bottom: 25px; font-size: 16px">
          <table class="">
            <tbody>
              <tr>
                <th scope="row">N.M.V: Tasa de interés corriente nóminal mensual vencido:</th>
                <td>{{ datosCarpeta.tasaAprobada }} %</td>
              </tr>
              <tr>
                <th scope="row">E.A: Tasa de interés corriente efectiva anual:</th>
                <td>{{ datosCarpeta.tasaAprobadaefectiva }} %</td>
              </tr>
              <tr>
                <th scope="row">Tasa interés máxima legal permitida N.M.V. {{datosCarpeta.fechaCreacion}}:</th>
                <td>{{ datosCarpeta.tasaUsuraMv }} %</td>
              </tr>
              <tr>
                <th scope="row">Seguro de vida grupo deudor:</th>
                <td>{{ datosCarpeta.seguroVida }} %</td>
              </tr>
              <tr>
                <th scope="row">Aval</th>
                <td>{{ datosCarpeta.aval }} %</td>
              </tr>
              <tr>
                <th scope="row">Estudio y administración de crédito:</th>
                <td>{{ resAdo.tasaEstudioCredito }} %</td>
              </tr>
              <tr>
                <th scope="row">Seguro de accidentes personales:</th>
                <td>$ {{ resAdo.polizaValorPlan | number: "1.0-0" }}</td>
              </tr>
              <tr>
                <th scope="row">Monto aprobado:</th>
                <td>$ {{ datosCarpeta.montoAprobadoAnalista | number: "1.0-0" }}</td>
              </tr>
              <tr>
                <th scope="row">Plazo aprobado:</th>
                <td>{{ datosCarpeta.plazoAprobadoAnalista }} meses</td>
              </tr>
              <tr>
                <th scope="row">Cuota mensual:</th>
                <td>$ {{ resAdo.cuotaAprobado | number: "1.0-0" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 style="padding-bottom: 15px">Monto a desembolsar:</h4>
      </div>
    </div>

    <div style="text-align: end">
      <span style="font-size: 16px"
        >¿No recibiste el mensaje o correo para firmar tus documentos?
        <a (click)="resetMessage()" style="font-weight: 900; cursor: pointer; color: #009bdc">
          Haz clic aquí
        </a>
      </span>
      <br />
      <button class="continuar" [routerLink]="['/aceptacion-firma']" mat-button>
        Continuar
      </button>
    </div>
  </mat-step>
  <mat-step disabled label="Pre-oferta de crédito"> </mat-step>
  <mat-step label="Validación de información"> </mat-step>
  <mat-step label="Desembolso del crédito"> </mat-step>
</mat-horizontal-stepper>
