<div>
  <div class="t-dialog">
    <span><strong>Importante</strong></span>
  </div>
  <div class="txt-dialog">
<mat-dialog-content class="mat-typography">
  <p>No es posible continuar el proceso debido a políticas internas. La carpeta ha sido rechazada.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="finalizar" mat-button mat-dialog-close [routerLink]="['/rob-analisis']">Finalizar</button>
</mat-dialog-actions>
</div>
</div>
