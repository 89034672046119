<div class="container">
  <div class="titulos">
    <h1 style="color: #FFFFFF;">¡Hola, {{user | titlecase}}!</h1>
    <h6><a routerLink="/cambio-contrasena">Cambio de contraseña</a></h6>
  </div>

  <div class="circle blue"></div>
  <app-tabs [tabs]="myTabs" [defaultTabIndex]="0"></app-tabs>

  <!-- Template informacion asesor -->
  <ng-template #infoAsesorTemplate>
    <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
      <h3 style="color: #FFFFFF">Información asesor</h3>
      <hr />
      <h5 class="labelS ">Datos asesor</h5>
      <form [formGroup]="bayportInfoForm" class="form">
        <div class="row">
          <div class="col-md-3">
            <h6 class="labelL">
              Tipo de ejecutivo
            </h6>
          </div>
          <div class="col-md-3 datoB  ">
            {{ tipoEjecDes | capitalize }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Código ejecutivo
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ codigoAsesor }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">Estado</div>
          </div>
          <div class="col-md-3 datoB  ">
            <img src="../../../assets/icons/Validation.svg"> {{ estado | capitalize}}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Fecha de creación
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ fechaCrea | date : "yyyy-MM-dd" }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">Regional</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ regionalNombre | capitalize}}
          </div>
          <div class="col-md-3">
            <div class="labelL ">Sucursal</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ sucursalNombre | capitalize}}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">Tipo de canal</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ canalNombre | capitalize}}
          </div>
          <div class="col-md-3">
            <div class="labelL ">Canal de venta</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ canalVentaNombre | capitalize}}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Nombre a quien refiere
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ nombreRefiere | titlecase}}
          </div>
        </div>
      </form>
      <br />
      <hr />
      <h5 class="labelS ">Datos de contacto</h5>
      <form [formGroup]="bayportInfoForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Correo electrónico 1
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="bayportEmail" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Correo electrónico 2
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="correo2" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Celular<br />
              <input type="number" pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="celular" />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 2rem">
          <button-look-feel color="secondary" (clicked)="onSubmitBP()">Guardar</button-look-feel>
        </div>
      </form>
    </article>
  </ng-template>
  <!-- Template informacion asesor -->
  <!-- Template informacion personal -->
  <ng-template #infoPersonalTemplate>
    <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
      <h3 class="labelP ">Información personal</h3>
      <hr />
      <h5 class="labelS ">
        Datos documento de identidad
      </h5>
      <form [formGroup]="formInfoPersonal" class="form">
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Tipo de documento
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ tipoEjecDes | capitalize }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Número de documento
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ numeroDocumento }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Fecha de expedición
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ setFormatDate(fechaExpedicion) }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Lugar de expedición
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ lugarExpedicion | capitalize}}
          </div>
        </div>
      </form>
      <br />
      <hr />
      <h5 class="labelS ">Datos generales</h5>
      <form [formGroup]="formInfoPersonal" class="form">
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">Primer nombre</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ primerNombre | capitalize }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">Segundo nombre</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ segundoNombre | capitalize }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Primer apellido
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ primerApellido | capitalize }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Segundo apellido
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ segundoApellido | capitalize }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Fecha de nacimiento
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ setFormatDate(fechaNacimiento) }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">
              Lugar de nacimiento
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ lugarNacimiento | capitalize }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Género
              <div class="row">
                <div class="item col-md-6" *ngFor="let item of items; let i = index"
                  [ngClass]="{ active: activeIndex === i }" (click)="setActive(i)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Estado civil
              <select class="input__select  " formControlName="estadoCivil">
                <option selected *ngFor="let item of edoCivil" [value]="item.codigo">
                  {{ item.nombre | capitalize}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Estudios realizados
              <select class="input__select  " formControlName="estudiosRealizados">
                <option selected *ngFor="let item of scholarship" [value]="item.codigoEstudio">
                  {{ item.nombreEstudio | capitalize }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <br />
      <hr />
      <h5 class="labelS ">Datos de residencia</h5>
      <form [formGroup]="formInfoPersonal" class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Departamento
              <select class="input__select" aria-label="Default select example" (change)="setDeptoValue($event)"
                formControlName="departamento">
                <option selected *ngFor="let item of filteredOptionsDep | async">
                  {{ item.nomDepto }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Ciudad<br />
              <select class="input__select" (change)="setCityValue($event)" formControlName="ciudad">
                <option selected *ngFor="let item of filteredOptionsCiudad | async">
                  {{ item.nomMunic }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Dirección
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="direccion" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Tipo de vivienda<br />
              <select class="input__select" (change)="setCityValue($event)" formControlName="tipoVivienda">
                <option selected *ngFor="let item of vivienda" [value]="item.codigo">
                  {{ item.descripcion | capitalize}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <br />
      <hr />
      <h5 class="labelS ">
        Datos prestaciones sociales
      </h5>
      <form [formGroup]="socialBenefitsInfoForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              EPS
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="eps" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              AFP<br />
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="afp" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              ARL
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="arl" />
            </div>
          </div>
        </div>
      </form>
      <br />
      <hr />
      <h5 class="labelS ">
        Datos información financiera
      </h5>
      <form [formGroup]="formInfoFinanciera" class="form">
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Total activos
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="totalActivos" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Total pasivos<br />
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="totalPasivos" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Gastos mensuales
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="gastos" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Ingresos mensuales
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="ingresos" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Otros ingresos mensuales
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="otrosIngresos" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Personas a cargo
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }"
                formControlName="personasAcargo" />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 2rem">
          <button-look-feel color="secondary" (clicked)="onSubmitPersonal()">Guardar</button-look-feel>
        </div>
      </form>
    </article>
  </ng-template>
  <!-- Template informacion personal -->
  <!-- Template informacion bancaria -->
  <ng-template #infoBancariaTemplate>
    <article class="bg-blue-gradient w-1/2 px-6 sm:py-6">
      <h3 class="labelP ">Información Bancaria</h3>
      <hr />
      <h5 class="labelS ">
        Datos información bancaria
      </h5>
      <form [formGroup]="bayportInfoForm" class="form" *ngIf="!isUpdate">
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">Banco</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ banco }}
          </div>
          <div class="col-md-3">
            <div class="labelL ">Tipo de cuenta</div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ tipoCuenta }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="labelL ">
              Número de cuenta
            </div>
          </div>
          <div class="col-md-3 datoB  ">
            {{ numeroCuenta }}
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 2rem">
          <button-look-feel color="secondary" (clicked)="actualizarInformacionBancaria()">Editar</button-look-feel>
        </div>
      </form>
      <form [formGroup]="formInfoBancaria" class="form" *ngIf="isUpdate">
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Banco
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="banco" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="labelL ">
              Tipo de cuenta<br />
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="tipoCuenta" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <div class="labelL ">
              Número de cuenta
              <input pInputText [style]="{ width: '100%', 'font-size': '14px' }" formControlName="numeroCuenta" />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-6">
            <app-card-file-upload-bancaria iconoCentral="./../../../assets/icons/subir_bancario.svg"
              titulo="Certificación bancaria" (fileChange)="onFileChange($event)"></app-card-file-upload-bancaria>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 2rem">
          <button-look-feel color="secondary" (clicked)="upload()">Guardar</button-look-feel>
        </div>
      </form>
    </article>
  </ng-template>
  <br>
  <!-- Template informacion bancaria -->
</div>