<div class="content">
  <div class="cont-form">
    <h1>Iniciar sesión</h1>
    <form [formGroup]="loginForm">
      <mat-label>Usuario</mat-label>
      <br />
      <mat-form-field class="example-full-width" appearance="outline">
        <input matInput name="user" formControlName="user" />
      </mat-form-field>
      <mat-error *ngIf="loginForm.controls.user.hasError('required')">
        El Usuario es <strong>requerido</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.controls.password.hasError('minlength')">
        Minimo 8 caracteres
      </mat-error>
      <mat-error *ngIf="loginForm.controls.password.hasError('maxlength')">
        Maximo 30 caracteres
      </mat-error>
      <br />
      <mat-label>Contraseña</mat-label>
      <br />
      <mat-form-field class="example-full-width" appearance="outline">
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="loginForm.controls.password.hasError('required')">
        El password es <strong>requerido</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.controls.password.hasError('minlength')">
        Minimo 8 caracteres
      </mat-error>
      <mat-error *ngIf="loginForm.controls.password.hasError('maxlength')">
        Maximo 30 caracteres
      </mat-error>
      <p>{{errorLog}}</p>
    </form>

    <a class="olvide-pass">¿Olvidaste tu contraseña?</a>
    <p></p>
    <button type="button" class="btn btn-primary" (click)="loginCallback()">Iniciar sesión</button>
    <!--<button class="g-recaptcha"
        data-sitekey="6Le6mxAaAAAAAAEhbwbt8c3hCHP9H0flKu_jDWTP"
        data-callback='onSubmit'
        data-action='submit'>Submit</button>-->
  </div>
</div>
