<div class="cont">
    <a [routerLink]="['/autorizacionTransferencia']">
        <button mat-flat-button class="nuevoCredito">
            <img src="../../../assets/img/loanB.png">
            Nuevo crédito
        </button>
    </a>
    <div class="busqueda" *ngIf="isBusqueda">
        <div class="input-group" style="width: 300px;">
            <input type="text" class="form-control" placeholder="¿Deseas Buscar Algo?" aria-label="Recipient's username"
                aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="input-group-text">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>

        <button mat-flat-button class="agregar">
            <mat-icon>add</mat-icon>Nuevo Crédito
        </button>
    </div>
    <!-- <div *ngIf="!isBusqueda">
      <h1>Análisis de solicitud de crédito</h1>
  </div> -->
    <div class="notificacion">
        <div class="iconNotificacion" style="cursor: pointer;">
            <a [routerLink]="['/notificaciones']">
                <mat-icon *ngIf="totalNotificaciones > 0" matBadge="{{ totalNotificaciones }}" matBadgeColor="primary"
                    matBadgeSize="small">
                    notifications
                </mat-icon>
                <mat-icon *ngIf="totalNotificaciones === 0" matBadgeColor="primary" matBadgeSize="small">
                    notifications
                </mat-icon>
            </a>
        </div>
        <hr class="pipe">
        <mat-icon class="user-icon">account_circle</mat-icon>
        <mat-form-field>
            <mat-label style="color: black;">{{ user }}</mat-label>
            <mat-select>
                <mat-option (click)="authService.logout()">Cerrar sesión</mat-option>
                <mat-option routerLink="/home/cambio-contrasena">Cambio contraseña</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>